import React, { useEffect } from 'react'
import { Button, Descriptions, Breadcrumb, PageHeader,Tag, Empty, Timeline } from 'antd';
import { useState } from 'react';
import { connect } from 'react-redux';
import { getOrderDetailsForFinance,fileDownloader,refundFinanceOrder,rejectFinanceOrder,reviewOrder } from '../../actions/orders';

import { Title, Wrapper } from './detailStyle';
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import { BackwardOutlined, EyeOutlined, WarningOutlined } from '@ant-design/icons';

import Remarks from '../../component/remark';
import { isJSDocNullableType } from 'typescript';
const FinanceOrderDetails = ({location,getOrderDetailsForFinance,refundFinanceOrder,rejectFinanceOrder,reviewOrder}) => {
    const [data, setData] = useState([])
    const [review, setReview] = useState(false);
    const [refund, setRefund] = useState(false);
    const [reject, setReject] = useState(false);
    useEffect(async() => {
        const order = new URLSearchParams(location.search).get("order");
        const res = await getOrderDetailsForFinance(order)
        setData(res)
    }, [])
    const bill = data?.bill_info;
    const billing_info = () => {
         let res  = Object.keys(bill).map((item) => {
              return (<p>{item.toLocaleUpperCase()} : <strong>${bill[item]}</strong> </p>)
         })
         return res;
    }

    const onRejectClose = () => {
      setReject(false);
    };

    const handleRefresh = async () => {
      const res = await getOrderDetailsForFinance(data?.order_id)
        setData(res)
    }
    
    const makeLocalTime = (date) => {
      let hours = date.split('T')[1].split(':')[0];
      let minutes = date.split('T')[1].split(':')[1];
      var am = true;
      if (hours > 12) {
          am = false;
          hours -= 12
      } else if (hours == 12) {
          am = false;
      } else if (hours == 0) {
          hours = 12
      }
      return date.split('T')[0] + "," + hours+":"+minutes + " " + (am ? "A.M" : "P.M")
    }
    return ( 
      <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{padding:"10px 0px"}}>
              <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
              <Breadcrumb.Item>Order Details</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeader
              ghost={false}
              onBack={() => window.history.back()}
              title="Order Details"
              subTitle="Finance Management"
            >
            </PageHeader>
          <Wrapper >
            <h2>Service Name : {data?.service_name}</h2>
            <h3>Total Bill: ${data?.total_cost}</h3>
            <Tag color="#2db7f5">Order ID {data?.order_id}</Tag>
            
            {data?.status === 'Pending' ? <Tag color="#f39c12">{data?.status}</Tag> : null}
            {data?.status === 'Rejected' ? <Tag color="#f50">{data?.status}</Tag> : null}
            {data?.status === 'Processing' ? <Tag color="#2db7f5">{data?.status}</Tag> : null}
            {data?.status === 'Done' ? <Tag color="#1ad1a3">{data?.status}</Tag> : null}

            {data?.payment_status === 'Unpaid' ? <Tag color="#f50">{data?.payment_status}</Tag> : <Tag color="#108ee9">{data?.payment_status}</Tag>}
            {data?.payment_review === false ? <Tag color="#f50">Unreviewed</Tag> : <Tag color="#108ee9">Reviewed</Tag>}
            
            {data?.payment_review !== true ?
              data?.status === 'Processing' ?
                data?.payment_status === 'Paid' ?
                  <Button type="ghost" icon={<EyeOutlined />} size="small" style={{float: 'right',backgroundColor: '#45cef0',color:'#fff'}} onClick={()=>setReview(true)}>Review</Button>
                :null
              :null
            :null}

            {data?.payment_review !== true ?
              data?.status === 'Done' ?
                <Button type="ghost" icon={<EyeOutlined />} size="small" style={{float: 'right',backgroundColor: '#45cef0',color:'#fff'}} onClick={()=>setReview(true)}>Review</Button>
              :null
            :null}

            {data?.status !== 'Rejected' ?
              data?.status === 'Pending' ?
                <Button type="ghost" icon={<WarningOutlined />} size="small" style={{float: 'right',backgroundColor: '#f04545',color:'#fff'}} onClick={()=>setReject(true)}>Reject</Button>
              :null
            :null}
            {data?.status !== 'Rejected' ?
              data?.status === 'Processing' ?
                <Button type="ghost" icon={<WarningOutlined />} size="small" style={{float: 'right',backgroundColor: '#f04545',color:'#fff'}} onClick={()=>setReject(true)}>Reject</Button>
              :null
            :null}

            {data?.payment_status === 'Paid' ?
              data?.status === 'Rejected' ?
                    <Button type="ghost" icon={<BackwardOutlined />} size="small" style={{float: 'right',backgroundColor: 'green',color:'#fff'}} onClick={()=>setRefund(true)}>Refund</Button>
                 
              :null
            :null}
            {data?.payment_status === 'Paid' ?
                data?.status === 'Processing' ?
                    <Button type="ghost" icon={<BackwardOutlined />} size="small" style={{float: 'right',backgroundColor: 'green',color:'#fff'}} onClick={()=>setRefund(true)}>Refund</Button>
                  :null
             
            :null}
            <Tag color="#cd2df5">{data?.created_at ? makeLocalTime(data?.created_at) : null}</Tag>
            <br />
            <br />
        {data ?
        <>
        <Title>Client Information</Title>
        <Descriptions>
          <Descriptions.Item label="ID"><strong>{data?.client?.client_id}</strong></Descriptions.Item>
          <Descriptions.Item label="NAME"><strong>{data?.client?.name?.toUpperCase()}</strong></Descriptions.Item>
          <Descriptions.Item label="MOBILE"><strong>{data?.client?.mobile_number}</strong></Descriptions.Item>
          <Descriptions.Item label="EMAIL"><strong>{data?.client?.email?.toUpperCase()}</strong></Descriptions.Item>
          <Descriptions.Item label="CITY"><strong>{data?.client?.address?.city?.toUpperCase()}</strong></Descriptions.Item>
          <Descriptions.Item label="STREET"><strong>{data?.client?.address?.street_address?.toUpperCase()}</strong></Descriptions.Item>
          <Descriptions.Item label="POST CODE"><strong>{data?.client?.address?.post_code}</strong></Descriptions.Item>
        </Descriptions>
    
        <Title>Bill Information</Title>
        {data?.bill_info ? 
              billing_info()
              :
              <Empty />
              }

        <Title>Payment Information</Title>
              {data?.payment_info ? 
            <Descriptions>
              <Descriptions.Item label="AMOUNT"><strong>${data?.payment_info?.amount}</strong></Descriptions.Item>
              <Descriptions.Item label="RECEIVABLE AMOUNT"><strong>${data?.payment_info?.amount_capturable}</strong></Descriptions.Item>
              <Descriptions.Item label="RECEIVED AMOUNT"><strong>${data?.payment_info?.amount_received}</strong></Descriptions.Item>
              <Descriptions.Item label="COUNTRY"><strong>{data?.payment_info?.country}</strong></Descriptions.Item>
              <Descriptions.Item label="CURRENCY"><strong>{data?.payment_info?.currency}</strong></Descriptions.Item>
              <Descriptions.Item label="PAYMENT GATEWAY"><strong>{data?.payment_info?.payment_gateway}</strong></Descriptions.Item>
              <Descriptions.Item label="RECIPIENT EMAIL"><strong>{data?.payment_info?.receipt_email}</strong></Descriptions.Item>
              <Descriptions.Item label="ORDER STATUS"><strong>{data?.payment_info?.status}</strong></Descriptions.Item>
              <Descriptions.Item label="REVIEWED"><strong>{data?.payment_info?.payment_review ? 'YES' : 'NO'}</strong></Descriptions.Item>
              <Descriptions.Item label="ID"><strong>{data?.payment_info?.id}</strong></Descriptions.Item>
              <Descriptions.Item label="PAYMENT TIME"><strong>{data?.payment_info?.created_at ? makeLocalTime(data?.payment_info?.created_at) : null}</strong></Descriptions.Item>
            </Descriptions>
            :<Empty />}
        
        
        
        <Title>Logs</Title>
        <br />
        {data?.logs ? 
          <Timeline mode="left">
            {data.logs.map((item,index) => (
            <Timeline.Item label={makeLocalTime(item?.created_at)} keys={index}>{item?.message}</Timeline.Item>
            ))}
          </Timeline>
        : <Empty /> }

      </>
    :null}
    <br /><br />
    
          
    </Wrapper>
      <Remarks title=" " btn="Review" placement="bottom" visible={review} fnc={()=>setReview(false)} id={data?.order_id} delFnc = {reviewOrder} refresh = {handleRefresh} />
      <Remarks title=" " btn="Refund" placement="bottom" visible={refund} fnc={()=>setRefund(false)} id={data?.order_id} delFnc = {refundFinanceOrder} refresh = {handleRefresh} />
      <Remarks title=" " btn="Reject" placement="bottom" visible={reject} fnc={onRejectClose} id={data?.order_id} delFnc = {rejectFinanceOrder} refresh = {handleRefresh} />
    </Content>
     );
}
 
export default connect(null, {getOrderDetailsForFinance,fileDownloader,refundFinanceOrder,rejectFinanceOrder,reviewOrder})(FinanceOrderDetails);