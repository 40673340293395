import React from 'react'
import { Title,DTable} from './detailStyle';

const GrievancePolicyDetials = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                        
                           
                              <Title>Policy Details</Title> <hr/>
                              <p>Is the Grievance Policy available in matters of discrimination, workplace bullying and harassment of victimisation? : <strong>{formData?.is_available === 'true' ? 'Yes' : 'No'} </strong></p>
                              <p>If two or more employees think they have an identical/common problem, can they take action together? : <strong>{formData?.can_take_aaction === 'true' ? 'Yes' : 'No'} </strong></p>
                              <p>Who has the primary authority over the Grievance Policy?  : <strong>{formData?.who_has_authority} </strong></p>
                              <p>Do the Grievance Policy procedures pre-empt, limit, or delay the concurrent pursuit in other avenues of resolution?   : <strong>{formData?.do_grievance_policy_delay === 'true' ? 'Yes' : 'No'} </strong></p>
                              <p>What is the role of the complainant's immediate supervisor? : <strong>{formData?.role_of_complainant} </strong></p>
                              <p>Can the supervisor involve HR for consultation or in the grievance process?  : <strong>{formData?.can_involve === 'true' ? 'Yes' : 'No'} </strong></p>
                              <p>Will HR provide advice to the mangers and the employees involved in the grievance process? : <strong>{formData?.will_provide_advice === 'true' ? 'Yes' : 'No' } </strong></p>
                              <p>Who should be notified should the claim be unresolved, and referred to the next stage?   : <strong>{formData?.who_should_notified === 'true' ? 'Yes' : 'No'} </strong></p>
                              <p>What is the person's role to whom the issue will be referred to if it is unresolved at stage 1 and 2? : <strong>{formData?.persons_role} </strong></p>
                              <p>Does the Company have a Discipline Policy and Guidelines? : <strong>{formData?.have_descipline === 'true' ? 'Yes' : 'No'} </strong></p>
                              <p>Will HR have responsibilities regarding Grievance complaints? : <strong>{formData?.have_responsibility === 'true' ? 'Yes' : 'No'} </strong></p>
                              <p>If grievance complaint is withdrawn, who will advise the relevant parties of the withdrawal?   : <strong>{formData?.who_will_advise} </strong></p>
                           
                           
                              <Title>EAP</Title> <hr/>
                              <p>Is there an Employment Assistant Program? : <strong>{formData?.is_assistant === 'true' ? 'Yes' : 'No'} </strong></p>
                              {formData?.is_assistant === 'true' ? 
                              <>
                              <p>What is the name of the person who the employee will contact for more information about the EAP? : <strong> {formData?.name_of_person } </strong></p>
                              <p>What is the contact number of the person the employee can contact for more information about the EAP?   : <strong> {formData?.number_of_person } </strong></p>
                              </> : null }
                           
                           
                              <Title>Product Details</Title> <hr/>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                              <p>Describe if you have any other specific need that you want us to incorporate in the policy : <strong> {formData?.describe_other_specific } </strong></p>
                           
                                                            
                              <Title>Billing Information</Title> <hr/>
                              {billing_info()}  
                         
                     </DTable>
     );
}
 
export default GrievancePolicyDetials; 