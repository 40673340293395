import {Form, Drawer,Button } from 'antd';
import React from 'react';
import { useState } from 'react';
import { renderSelect, renderTextField } from './form/form';

const common = [
  {value:"Y", label:"Yes"},
  {value:"N", label:"No"}
];

const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };
const ASICRemarks = ({placement,visible,fnc,id, subFnc, refresh, title}) => {
  
  const [form] = Form.useForm();
    const onFinish = async (values) => {
        const code = await subFnc(id,values);
        if(code === 201 ){
          
            form.resetFields();
            refresh()
            fnc()
          }
};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};
    return ( 

        <Drawer
              title={title ? title :"Why you want to delete ?"}
              placement={placement}
              closable={false}
              onClose={fnc}
              visible={visible}
              key={placement}
              height={400}
            >
              <Form
                form={form}
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                style={{alignSelf: 'center'}}
                requiredMark={false}
              >
              {renderSelect('has_asic_consent_for_name', 
                'Has ASIC consent been granted for use of proposed name? If the applicant claims that ministerial consent has been granted for the use of the proposed name, the Form 201 will be queued for manual processing.',
                [
                  { required: true},
                ],
                'Select a option',
                common,
                'none')}
              {renderTextField('text_manual_review', 'Text in support of application, to be considered in manual review',
              [{ required: true, message: 'Remark is required' },] )}
              <Form.Item {...tailLayout}> 
                <Button type="primary" htmlType="submit">
                  Sunmit
                </Button>
              </Form.Item>
            </Form>
            </Drawer>
     );
}
{/* <ASICRemarks placement={placement} visible={visible} fnc={this.onClose} id={id} subFnc = {deleteRole} /> */}
 
export default ASICRemarks;