import React from 'react'
import { Title,DTable} from './detailStyle';

const LoanAgreementDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                        
                           
                              <Title>Borrower and Lender</Title> <hr/>
                              <Title>Borrower</Title>
                              <p>What is the name of the borrower? : <strong>{formData?.name_of_borrower} </strong></p>
                              <p>Is the borrower an individual or company : <strong>{formData?.borrower_type} </strong></p>
                              {formData?.borrower_type === 'Company' ? 
                                 <p>What is the ACN?: <strong>{formData?.borrower_acn} </strong></p>
                              : null}
                              <p>What is the address of the borrower? : <strong>{formData?.address_of_borrower} </strong></p>
                              
                              <Title>Lender</Title>
                              <p>What is the name of the lender? :<strong>{formData?.name_of_lender} </strong></p>
                              <p>Is the lender an individual or company :<strong>{formData?.lender_type} </strong></p>
                              {formData?.lender_type === 'Company' ?
                                 <p>What is the ACN?: <strong>{formData?.lenderr_acn} </strong></p>
                               : null}
                              <p>What is the address of the lender? :<strong>{formData?.address_of_lender} </strong></p>
                           

                           
                              <Title>Loan Interest and Payment</Title> <hr/>
                              <Title>Loan</Title>
                              <p>What is the amount of money being landed : <strong>{formData?.amount_of_money_landed} </strong></p>
                              <p>On what date will this agreement be signed? : <strong>{formData?.date_of_sign} </strong></p>
                              <p>When will the lender loan the money? : <strong>{formData?.when_loan_money} </strong></p>
                              
                              <Title>Interest</Title>
                              <p>Will interest be charged? : <strong>{formData?.will_change ? 'Yes' : 'No'} </strong></p>
                              {formData?.will_change === 'true' ? 
                                 <>
                                    <p>What is the annual interest rate of the loan? : <strong>{formData?.annual_interest_rate} </strong></p>
                                    <p>Will the interest rate increase if the borrower fails to make a payment? : <strong>{formData?.will_rate_increase ? 'Yes' : 'No'} </strong></p>
                                    {formData?.will_rate_increase === 'true' ?
                                       <p>What interest rate will rise compunded yearly : <strong>{formData?.what_rate_increase} </strong></p>
                                    :null}
                                    <p>Will the payments go toward both the interest and original loan amount? : <strong>{formData?.will_payment_go_toward ? 'Yes' : 'No'} </strong></p>
                                 </>
                              : null}

                              <Title>Payment</Title>
                              <p>Method of repayment : <strong>{formData?.method_of_repayment} </strong></p>
                              {formData?.method_of_repayment === 'Regular payment' ?
                              <>
                                 <p>Will all payments be an equal amount? : <strong>{formData?.will_payment_equal ? 'Yes' : 'No'} </strong></p>
                                 {formData?.will_payment_equal ? 
                                    <>
                                       <p>Amount : <strong>{formData?.amount} </strong></p>
                                       <p>Frequency : <strong>{formData?.frequency} </strong></p>
                                       {formData?.frequency === 'Others' ?
                                          <p>Specify : <strong>{formData?.frequency_specify} </strong></p>
                                       : null}
                                       <p>Date of the first payment : <strong>{formData?.date_of_first_payment} </strong></p>
                                    </>
                                 : null}
                              </>
                              :null}
                              <p>On what date does the loan have to be repaid in full? : <strong>{formData?.date_of_loan_repaid} </strong></p>
                           

                           
                              <Title>Collateral</Title> <hr/>
                              <p>Will collateral secure the loan?  : <strong>{formData?.will_collateral_secure ? 'Yes' : 'No'} </strong></p>
                              {formData?.will_collateral_secure ? 
                              <p>Describe the property being used as collateral  : <strong>{formData?.description_of_collateral} </strong></p>
                              : null}
                           

                           
                              <Title>Service Type</Title> <hr/>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                              <p>Describe if you have any other specific need that you want us to incorporate in the contract  : <strong> {formData?.describe_other_specific } </strong></p>
                              
                           
                              
                           <Title>Billing Information</Title> <hr/>
                           {billing_info()}

                     </DTable>
     );
}
 
export default LoanAgreementDetails;