import axios from "axios";
import history from "../history";
import api from "./apis";

import {
 
  ERROR_MESSAGE,
  GET_USERS,
  LOADED,
  LOADEDING,
  SUCCESS_MESSAGE,
  VALIDATION_ERROR
} from "./types";
// ==========================Create==============
export const createUser = (data) => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  const personal_info = {
    date_of_birth: data.date_of_birth,
    gender: data.gender,
    permanent_address: data.permanent_address,
    present_address: data.present_address,
    emergency_number: data.emergency_number,
  };
  const object = {
    name: data.name,
    email: data.email,
    mobile_number: data.mobile_number,
    department: data.department,
    password: data.password,
    authenticated: true,
    role: data.role,
  };

  try {
    const response = await api(localStorage.getItem("token")).post(
      "/user-management/user/create",
      { ...object }
    );

    if (response.status === 200) {
      
    dispatch({type: LOADED});
    dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
    return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const getUsers = () => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/user-management/user/all"
    );
    
    dispatch({type: LOADED});
    return ( response.data.users );
    
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const userHelper = () => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/user-management/helper"
    );
    
    dispatch({type: LOADED});
    return ( response.data );
    
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const getUsersById = (id) => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/user-management/user/detail?user=${id}`
    );
    
    dispatch({type: LOADED});
    return response.data.user
    // dispatch({ type: GET_USERS, payload: response.data.users });
    
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};


// ========================Update user==========================
export const updateUser = (data) => async (dispatch) => {
  dispatch({type: LOADEDING});
  const personal_info = {
    date_of_birth: data.date_of_birth,
    gender: data.gender,
    permanent_address: data.permanent_address,
    present_address: data.present_address,
    emergency_number: data.emergency_number,
  };
  const object = {
    remark:data.remark,
    name: data.name,
    email: data.email,
    mobile_number: data.mobile_number,
    password: data.password,
    department: data.department,
    authenticated: true,
    user: data.user_id,
    role: data.role,
  };
  dispatch({type: LOADED});

  try {
    const response = await api(localStorage.getItem("token")).patch("/user-management/user/update",{ ...object });

    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({type: SUCCESS_MESSAGE,payload: "User is updated succesfully"});
    
    return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};


export const updateUserPassword = (user, data ) => async (dispatch) => {
  dispatch({type: LOADEDING});

  const object = {
    user:user,
    password:data.password,
    confirm_password: data.confirm_password,
  };
  dispatch({type: LOADED});

  try {
    const response = await api(localStorage.getItem("token")).patch("/user-management/user/password/update",{ ...object });

    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({type: SUCCESS_MESSAGE,payload: "User password is updated successfully"});
    return response.status
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};


export const deleteUser = (user, remark) => async (dispatch) => {
  dispatch({type: LOADEDING});
  

  try {
    const response = await axios('https://api-ispt2.hidayahsmart.solutions/sailor/v1/user-management/user/delete',
    {
      headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${localStorage.getItem("token")}`
      },
      data: {
        user: user,
        remark: remark.remark,
      },
      method: 'delete'
    }
    
    );

    if (response.status === 201) {
      
      dispatch({type: LOADED});
      dispatch({type: SUCCESS_MESSAGE,payload: "User is deleted succesfully"});
      
      return response.status
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const updateAuth = (user, auth) => async (dispatch) => {
  dispatch({type: LOADEDING});
  
  const object = {
    user:user,
    authenticated:auth,
  };
  dispatch({type: LOADED});

  try {
    const response = await api(localStorage.getItem("token")).patch("/user-management/user/authenticate",{ ...object });

    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({type: SUCCESS_MESSAGE,payload: "User authentication is updated succesfully"});
    
    return response.status
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const updateOwnPassword = (old_password, password, confirm_password) => async (dispatch) => {
  dispatch({type: LOADEDING});

  const object = {
    
    old_password:old_password,
    password:password,
    confirm_password:confirm_password,
  };
  dispatch({type: LOADED});

  try {
    const response = await api(localStorage.getItem("token")).patch("/user-management/user/password/update-self",{ ...object });

    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({type: SUCCESS_MESSAGE,payload: "Your password is updated succesfully"});
    localStorage.setItem('token', response.data.token)
    return response.status;
    // history.push('/userList')
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};