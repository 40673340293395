import React from 'react'
import { Title,DTable} from './detailStyle';

const JointVentureAgreementDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
    
                        
                            
                                 <Title style={{textAlign: 'left'}}>Agreement Details</Title> <hr/>
                                 <p>Date of the agreement : <strong>{formData?.date_of_agreement} </strong></p>
                                 <p>Name of the venture : <strong>{formData?.name_of_venture} </strong></p>
                                 <p>Purpose of the venture : <strong>{formData?.purpose_of_venture} </strong></p>
                                 <p>Contact address for the venture : <strong>{formData?.address_for_venture} </strong></p>
                                 <Title style={{textAlign: 'left'}}>First Party </Title>
                                 <p>Name of the first party : <strong>{formData?.name_of_first_party} </strong></p>
                                 <p>ABN of the first party : <strong>{formData?.abn_of_first_party} </strong></p>
                                 <p>Address of the first party : <strong>{formData?.address_of_first_party}</strong></p>
                                 <p>Business type of first-party : <strong>{formData?.business_of_first_party}</strong></p>
                                 <Title style={{textAlign: 'left'}}>Second  Party </Title>
                                 <p>Name of the second party : <strong>{formData?.name_of_second_party} </strong></p>
                                 <p>ABN of the second party : <strong>{formData?.abn_of_second_party} </strong></p>
                                 <p>Address of the second party : <strong>{formData?.address_of_second_party}</strong></p>
                                 <p>Business type of second-party : <strong>{formData?.business_of_second_party}</strong></p>
                            
                            
                              
                                 <Title style={{textAlign: 'left'}}>Contributions</Title> <hr/>

                                 <Title style={{textAlign: 'left'}}>First party’s contributions</Title>
                                 <p>Capital contributions: <strong>{formData?.first_contribution} </strong></p>
                                 <p>Goods/services contributions : <strong>{formData?.first_services_contribution} </strong></p>
                                 <p>Percentage of the JV: <strong>{formData?.first_percentage_of_jv} </strong></p>
                                 <p>Duties and obligations of this member: <strong>{formData?.first_duties_of_member} </strong></p>
                                 
                                 <Title style={{textAlign: 'left'}}>First party’s contributions</Title>
                                 <p>Capital contributions: <strong>{formData?.second_contribution} </strong></p>
                                 <p>Goods/services contributions : <strong>{formData?.second_services_contribution} </strong></p>
                                 <p>Percentage of the JV: <strong>{formData?.second_percentage_of_jv} </strong></p>
                                 <p>Duties and obligations of this member: <strong>{formData?.second_duties_of_member} </strong></p>

                                 <Title style={{textAlign: 'left'}}>Profit Distribution </Title>
                                 <p>How will the profit be distributed: <strong>{formData?.how_profit_distributed} </strong></p>
                                 <p>Can a party sell his or her interest to a third party?: <strong>{formData?.can_sell} </strong></p>
                                 
                              
                            
                              
                                 <Title style={{textAlign: 'left'}}>Voting & meeting </Title> <hr/>
                                 <p>The voting right of each member : <strong> {formData?.voting_right } </strong></p>
                                 <p>How often will regular meetings for members be held? : <strong> {formData?.when_meeting_held} </strong></p>
                                 <Title style={{textAlign: 'left'}}>Management </Title>
                                 <p>Who will be responsible for managing the venture : <strong> {formData?.who_will_responsible} </strong></p>
                                 <Title style={{textAlign: 'left'}}>Dissolving the JV </Title>
                                 <p>What percentage of the vote is needed to dissolve the venture? : <strong> {formData?.what_percentage} </strong></p>
                                 
                              
                             
                              
                                 <Title style={{textAlign: 'left'}}>Account Details</Title> <hr/>
                                 <p>Bank Account for the JV : <strong> {formData?.account_for_jv} </strong></p>
                                 <p>Who will set up the bank account : <strong> {formData?.whol_will_setup}</strong></p>
                                 <p>How will you raise additional fund if needed in the future : <strong> {formData?.how_rise} </strong></p>
                                 {formData?.how_rise === 'JV partners will provide loan to the venture' ?
                                 <p>Percentage by the party <strong> {formData?.first_percentage} </strong></p>
                                 : null}
                                 {formData?.how_rise === 'JV partners will add more capital' ?
                                 <p>Percentage by the party <strong> {formData?.second_percentage} </strong></p>
                                 : null}
                                 {formData?.how_rise === 'Others' ?
                                 <p>Specification <strong> {formData?.specify} </strong></p>
                                 : null}
                                 <Title style={{textAlign: 'left'}}>Restrictions </Title>
                                 <p>Members restrictions : <strong> {formData?.member_restriction} </strong></p>
                                 
                                 {formData?.member_restriction === 'Confidentiality' ?
                                    <>
                                       <p>Members are restricted from communicating confidential information after leaving the venture for : <strong> {formData?.for_confidentiality} </strong></p>
                                    </>
                                 : null}
                                 {formData?.member_restriction === 'Non-compete clause' ?
                                    <>
                                       <p>Members are restricted from communicating confidential information after leaving the venture for : <strong> {formData?.for_non_compete} </strong></p>
                                    </>
                                 : null}
                              
                              
                              
                                 <Title style={{textAlign: 'left'}}>Product Type</Title> <hr/>
                                 <p>Select your product : <strong> {formData?.product_type } </strong></p>
                                 <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                                 <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                                 <p>Describe if you have any other specific need that you want us to incorporate in the Agreement : <strong> {formData?.describe_other_specific } </strong></p>
                                 
                                 <Title>Billing Information</Title> <hr/>
                                 {billing_info()}
                         
                     </DTable>
     );
}
 
export default JointVentureAgreementDetails;