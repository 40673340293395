import React from 'react'
import { Title,DTable} from './detailStyle';

const PreparationandLodgementofFinancialStatementsDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                              
                                 <Title>Company Details </Title> <hr/>
                                 <p>Company Name  : <strong>{formData?.company_name} </strong></p>
                                 <p>ARBN : <strong>{formData?.arbn} </strong></p>
                                 <p>Place of Incorporation	  : <strong>{formData?.place_of_Interporation} </strong></p>
                                 <p>financial year-end date : <strong>{formData?.financial_year_end_date} </strong></p>
                                < p>Company Name  : <strong>{formData?.company_name} </strong></p>
                                 <p>Annual general meeting held (leave blank if not applicable) : <strong>{formData?.annual_general_meeting} </strong></p>
                                 
                                 <p>Late Lodgement fee: <strong>{formData?.late_lodgement_fee} </strong></p>
                                

                                
                                 <Title>Details of the foreign company </Title> <hr/>
                                
                                 <p>Unit/Level   : <strong>{formData?.unit} </strong></p>
                                 <p>Street address  : <strong>{formData?.street_address} </strong></p>
                                 <p>Suburb : <strong>{formData?.suburb} </strong></p>
                                 <p>State  : <strong>{formData?.state} </strong></p>
                                 <p>Post code  : <strong>{formData?.post_code} </strong></p>
                                 <p>Is the registered office occupied by an entity other than this new company?  : <strong>{formData?.registered ===true ? "yes":"no"} </strong></p>
                                 <p>I agree that the occupier has consented in writing to the company using those premises as the address for its registered   : <strong>{formData?.final_declaration ===true ? "yes":"no"} </strong></p>
                                 <p>Has this address previously been notified to ASIC?  : <strong>{formData?.ASCI===true ? "yes":"no"} </strong></p>
                                 
                                
                              
                              
                              
                                 <Title>Details of directors</Title> <hr/>
                                  {formData.director ? formData.director.map((item,id) =>(
                                     <> 
                                        <p style={{textAlign:'center'}}>Director {id + 1 }</p>
                                        <p>Family name: <strong>{item?.family_name} </strong></p>
                                        <p>Given name: <strong>{item?.given_name} </strong></p>
                                         <p>Company name: <strong>{item?.company_name1} </strong></p>
                                        <p>CAN/ARBN No: <strong>{item?.can_no} </strong></p>
                                         <p>Street Number: <strong>{item?.street_number1} </strong></p>
                                         <p>Subrub: <strong>{item?.subrub2} </strong></p>
                                        <p>State: <strong>{item?.state2} </strong></p>
                                        <p>Post Code": <strong>{item?.post_code2} </strong></p>
                                        <p>Country: <strong>{item?.country2} </strong></p>
                                          <p>Date of birth: <strong>{item?.dob1} </strong></p>
                                        <p>Place of birth( town & country): <strong>{item?.pob1} </strong></p>
                                    </>
                                 ))
                                 :
                                 null}
                                 
                               
                            

                                 
                                 <Title>Services and attachment </Title> <hr/>
                                 <p>Select the service type  : <strong>{formData?.service_type2} </strong></p>
                                 {formData?.service_type2==="Preparing financial statement"?
                                    <>
                                 <p>Please upload bookkeeping record if you have it.  : <strong>{formData?.booking_record} </strong></p>
                                 <p>Provide all invoices of Income   : <strong>{formData?.invoice_income} </strong></p>
                                 <p>Provide receipts of all expenses   : <strong>{formData?.recepits_expenses} </strong></p>
                                 </>:null}
                                 {formData?.service_type2==="Preparing Audit Report"?
                                 <p>Please upload bookkeeping record if you have it.  : <strong>{formData?.upload_report} </strong></p>
                                 :null}
                                 {formData?.service_type2==="Translation of Documents"?
                                 <>
                                 <p>How many pages need to be translated?  : <strong>{formData?.how_many_page_translated} </strong></p>
                                 <p>Please upload documents you want to translate  : <strong>{formData?.upload_documnets_translate} </strong></p>
                                </>
                                 :null}
                                 {formData?.service_type2==="Lodging financial statement and Audit Report"?
                                    <>
                                 <p>Balance sheet made up to the end of the financial year 	Upload link  : <strong>{formData?.balance_sheet1} </strong></p>
                                 <p>Profit and loss statement for the last financial year   : <strong>{formData?.profit_loss} </strong></p>
                                 <p>Cash flow statement for the last financial year  : <strong>{formData?.cash_flow} </strong></p>
                                
                                 <p>An auditor’s report if you are required to be prepared in place of origin   : <strong>{formData?.auditor_report} </strong></p>
                                  </>:null}

                                 
                                 
                                 
                                 <Title>Details of local agents </Title> <hr/>
                                 
                                 <p>Declarant’s Name  : <strong>{formData?.d_name} </strong></p>
                                 <p>Designation within the Company   : <strong>{formData?.d_company} </strong></p>
                                 <p>onecompany local agent ID*  : <strong>{formData?.agent_id} </strong></p>
                                <p> I authorize onecompany to manage all legal documentation related to ASIC and submit all required documentations on behalf of my company.: <strong>{formData?.final_declaration === true ? "yes" : "no"} </strong> </p>
                                 
                                 

                                <Title>Billing Information</Title> <hr/>
                              {billing_info()}   
                              
                         
                     </DTable>
     );
}
 
export default PreparationandLodgementofFinancialStatementsDetails;