import React from 'react'
import { Title,DTable} from './detailStyle';

const AnnualReturnofAForeignCompanyDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                              
                                 <Title>Details of foreign company</Title> <hr/>
                                 <p>Company Name   : <strong>{formData?.c_name} </strong></p>
                                 <p>ARBN : <strong>{formData?.arbn} </strong></p>
                                 <p>Place of Incorporation: <strong>{formData?.place_incorporation} </strong></p>
                                 <p>financial year end date   : <strong>{formData?.financial_year_date} </strong></p>
                                 <p>Annual general meeting held (leave blank if not applicable)  : <strong>{formData?.annual_meeting} </strong></p>
                                 
                                 
                              
                              
                                 <Title>Declaration </Title> <hr/>
                                 <p>Unit/Level   : <strong>{formData?.unit} </strong></p>
                                 <p>Street address  : <strong>{formData?.street_address} </strong></p>
                                 <p>Suburb : <strong>{formData?.suburb} </strong></p>
                                 <p>State  : <strong>{formData?.state} </strong></p>
                                 <p>Post code  : <strong>{formData?.post_code} </strong></p>

                                 <p>Is the registered office occupied by an entity other than this new company?  : <strong>{formData?.body_name === true ? "yes" : "no"} </strong></p>
                                
                                 <p> I agree that the occupier has consented in writing to the company using those premises as the address for its registered : <strong>{formData?.identical_declaration === true ? "yes" : "no"} </strong> </p>
                                 
                                 <p>Has this address previously been notified to ASIC? : <strong>{formData?.asci === true ? "yes" : "no"} </strong></p>
                                
                                 <p>Provide date of change of address : <strong>{formData?.change_address === true ? "yes" : "no"} </strong> </p>
                                 
                                 
                                 
                                 <Title>Details of registered office in place of incorporation </Title> <hr/>
                                 <p>Unit/Level   : <strong>{formData?.unit1} </strong></p>
                                 <p>Street address  : <strong>{formData?.street_address1} </strong></p>
                                 <p>Suburb : <strong>{formData?.suburb1} </strong></p>
                                 <p>State  : <strong>{formData?.state1} </strong></p>
                                 <p>Post code  : <strong>{formData?.post_code1} </strong></p>
                                 <p>Country : <strong>{formData?.country1} </strong></p>
                                
                                 

                                 
                                 <Title>Details of directors</Title> <hr/>
                                  {formData.director ? formData.director.map((item,id) =>(
                                     <> 
                                        <p style={{textAlign:'center'}}>Director {id + 1 }</p>
                                        <p>Family name: <strong>{item?.family_name} </strong></p>
                                        <p>Given name: <strong>{item?.given_name} </strong></p>
                                         <p>Company name: <strong>{item?.company_name} </strong></p>
                                        <p>CAN/ARBN No: <strong>{item?.can_no} </strong></p>
                                         <p>Street Number: <strong>{item?.street_number1} </strong></p>
                                         <p>Subrub: <strong>{item?.subrub2} </strong></p>
                                        <p>State: <strong>{item?.state2} </strong></p>
                                        <p>Post Code": <strong>{item?.post_code2} </strong></p>
                                        <p>Country: <strong>{item?.country2} </strong></p>
                                          <p>Date of birth: <strong>{item?.dob1} </strong></p>
                                        <p>Place of birth( town & country): <strong>{item?.pob1} </strong></p>
                                    </>
                                 ))
                                 :
                                 null}

                                 <p>Up to one month late fee $82: <strong>{formData?.one_month === true ? "yes" : "no"} </strong></p>
                                
                                <p>Over one month late $340: <strong>{formData?.over_one_month === true ? "yes" : "no"} </strong> </p>
                                
                            
                            
                                 <Title>Details of local agents </Title> <hr/>
                                 <p>Declarant’s Name  : <strong>{formData?.d_name} </strong></p>
                                 <p>Designation within the Company   : <strong>{formData?.d_company} </strong></p>
                                 <p>onecompany local agent ID*  : <strong>{formData?.agent_id} </strong></p>
                                <p> I authorize onecompany to manage all legal documentation related to ASIC and submit all required documentations on behalf of my company.: <strong>{formData?.final_declaration1 === true ? "yes" : "no"} </strong> </p>
                                 
                                <Title>Billing Information</Title> <hr/>
                              {billing_info()}   


                         
                     </DTable>
     );
}
 
export default AnnualReturnofAForeignCompanyDetails;