import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 20px;
`;
export const Title = styled.div`
    width: 100%;
    background-color: #3498db;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 5px;
`;
export const DTable = styled.div`
p{
    text-transform: uppercase;
    font-size: 14px;
    margin: 1px;
    border-bottom: 1px solid lightgray;
    /* letter-spacing: 1px; */
}
table{
    width: 100%;
}
    table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
th, td {
  padding: 5px;
  text-align: left;
}
`;
export const BoxWrapper = styled.div`
    height: auto;
    padding: 10px;
    /* margin: 10px; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: lightgray;
`
export const MessageBox = styled.div`
border-radius: 5px;
    padding: 10px;
    width: 70%;
    display: block;
    position: relative;
    background-color: ${props => props.user ? '#f5f6fa' : '#dff9fb'};
    margin-bottom: 10px;
    left: ${props => props.user ? '0px' : '30%'};
    right: ${props => props.user ? '30%' : '0'};
`;