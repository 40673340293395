import React from 'react'
import { Title,DTable} from './detailStyle';

const ShareholderAgreementDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable >
             
                                 <Title>The Agreement and Company Details</Title> <hr/>
                                 <p>Date of the Agreement : <strong>{formData?.date_of_agreement} </strong></p>
                                 <p>When will this Agreement come into effect? : <strong>{formData?.when_effect} </strong></p>
                                 <Title>Company Details</Title> <hr/>
                                 <p>Registered name of the company : <strong>{formData?.company_name} </strong></p>
                                 <p>ACN of the company : <strong>{formData?.acn_of_company} </strong></p>
                                 <p>The registered address of the company : <strong>{formData?.address_of_company} </strong></p>
                                 <p>Registration date of the company? : <strong>{formData?.reg_date}</strong></p>
                                 <p>How much issued capital does the company have? : <strong>{formData?.how_much_issue}</strong></p>
                                 <p>Give a short description of the company? : <strong>{formData?.des_of_company} </strong></p>
                                 
                            
                            
                            
                                 <Title>Shareholder Details</Title> <hr/>
                                 {formData.director ? formData.director.map((item,id) =>(
                                     <> 
                                        <p style={{textAlign:'center'}}>Shareholder - {id + 1 }</p>
                                        <p>Full name of the shareholder: <strong>{item?.shareholder_name} </strong></p>
                                        <p>Type of the shareholder: <strong>{item?.shareholder_type} </strong></p>
                                        <p>Address of this shareholder: <strong>{item?.shareholder_address} </strong></p>
                                        <p>The contact number of this shareholder: <strong>{item?.shareholder_number} </strong></p>
                                        <p>How many shares does this shareholder hold?: <strong>{item?.number_of_shareholder} </strong></p>
                                        <p>Class of share: <strong>{item?.class_of_share} </strong></p>
                                    </>
                                 ))
                                 :
                                 null}
                            
                            
                              
                                 <Title>Conditions</Title> <hr/>
                                 <p>What voting percentage is needed for a special majority? : <strong> {formData?.voting_percentage } </strong></p>
                                 <p>Where will the company hold its board meetings? : <strong> {formData?.where_meeting } </strong></p>
                                 <p>Does this shareholders agreement have any transactional documents? : <strong> {formData?.have_document ? 'Yes' : 'No' } </strong></p>
                                 <p>Is this shareholders agreement subject to any conditions? : <strong> {formData?.is_any_condition ? 'Yes' : 'No' } </strong></p>
                                 {formData?.is_any_condition ? 
                                   <p>What is the condition? : <strong> {formData?.condition } </strong></p>
                                  : null }
                                 <p>How will a chairman of the board be appointed or removed? : <strong> {formData?.chairman_appointed_option } </strong></p>
                                 <p>How will an independent director be appointed? : <strong> {formData?.director_appointed_option } </strong></p>
                                 <p>Which decisions should be subjected to shareholder approval? : <strong> {formData?.which_decision } </strong></p>
                              
                              
                                 <Title>Business Plan</Title> <hr/>
                                 
                                 <p>How many years of financial forecasts have the company planned for?: <strong> {formData?.number_of_years} </strong></p>
                                 <p>How many days does this board have to consider adopting a business plan and budgets after the commencement of this shareholders agreement? : <strong> {formData?.how_many_days_commencement} </strong></p>
                                 <p>The board must consider and adopt a business plan and budget before the start of each financial year, how many days before the end of each financial year? : <strong> {formData?.how_many_days_financial } </strong></p>
                                 <p>How many days does the board have to consider the draft of the business plan proposed by the CEO? : <strong> {formData?.how_many_days_ceo } </strong></p>
                                 <p>Does the company have to prepare a profit and loss statement for each previous month? : <strong> {formData?.does_prepare ? 'Yes' : 'No' } </strong></p>
                              
                              
                                   <Title>Clauses</Title> <hr/>
                                   
                                   <p>A special majority is required for the sale or disposal of assets valued above a certain amount and the amount : <strong> {formData?.amount1 } </strong></p>
                                   <p>A special majority is required to purchase assets in one transaction or related transactions valued above a certain amount and the amount : <strong> {formData?.amount2 } </strong></p>
                                   <p>A special majority is required for capital expenditures by the company above a certain amount not included in the current business plan and the amount : <strong> {formData?.amount3 } </strong></p>
                                   <p>A special majority is required when incurring additional borrowing valued above a certain amount and the amount : <strong> {formData?.amount4 } </strong></p>
                                   
                              
                              
                                 <Title>Others</Title> <hr/>
                                 <p>How will you raise capital if needed? : <strong> {formData?.capital } </strong></p>
                                 <p>Is a special majority needed to determine that additional funds are required? : <strong> {formData?.is_majority_needed ? 'Yes' : 'No' } </strong></p>
                                 <p>Are shareholders prohibited from selling their shares? : <strong> {formData?.are_prohibited ? 'Yes' : 'No' } </strong></p>
                                 <p>How will the value of the shares be set? : <strong> {formData?.how_value_set } </strong></p>
                                 <p>When the shareholders fail to set the value, the board will appoint a professional business valuation service : <strong> {formData?.when_fail ? 'Yes' : 'No' } </strong></p>
                                 <p>Do you want to include provisions for dividend distribution? : <strong> {formData?.want_provision ? 'Yes' : 'No' } </strong></p>
                                 {formData?.wantProvision ? 
                                   <p>Specification : <strong> {formData?.specification } </strong></p>
                                  : null }
                                 <p>What happens when a shareholder becomes incapacitated or passes away? : <strong> {formData?.when_incapacitated} </strong></p>
                                 <p>Do you want to incorporate a non-compete clause? : <strong> {formData?.want_incorporate ? 'Yes' : 'No' } </strong></p>
                                 {formData?.want_incorporate ? 
                                   <p>How long is each shareholder prohibited from competing after leaving the company? : <strong> {formData?.how_long_prohibited } </strong></p>
                                 : null }
                                 <p>Do you want to include a non-solicitation clause? : <strong> {formData?.want_nonsolicitation ? 'Yes' : 'No'  } </strong></p>
                                 {formData?.want_nonsolicitation ? 
                                   <p>How long does the non-solicitation clause bind each shareholder after leaving the company? : <strong> {formData?.how_long_nonSolicitation } </strong></p>
                                 : null}
                                 <p>When does the next financial year start? : <strong> {formData?.when_financial_year } </strong></p>
                                 <p>In what location will all mediation proceeding take place : <strong> {formData?.what_location } </strong></p>
                              
                              
                              
                                 <Title>Agreement Type</Title> <hr/>
                                 <p>Select your product : <strong> {formData?.product_type } </strong></p>
                                 <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                                 <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                                 <p>Describe if you have any other specific need that you want us to incorporate in the Agreement : <strong> {formData?.describe_other_specific } </strong></p>
                                 <p>Do you want our lawyer to review your Company Constitution before drafting your Shareholder Agreement? : <strong> {formData?.want_lawyer ? 'Yes' : 'No' } </strong></p>
                                 {formData?.want_lawyer ? 
                                   <p>Company Constitution : <strong> {formData?.company_constitution ? 'Uploaded' : 'Not uploaded' } </strong></p>
                                 : null }
               <Title>Billing Information</Title> <hr/>
               {billing_info()}
                         
                     </DTable>
     );
}
 
export default ShareholderAgreementDetails;