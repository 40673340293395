import api from "./apis";
import {
  ERROR_MESSAGE,
  LOADED,
  LOADEDING,
  SUCCESS_MESSAGE,
  VALIDATION_ERROR,
} from "./types";
import history from "../history";

export const getPops = (p, id, n, all) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  try {
    let response = null;
    response = await api(localStorage.getItem("token")).get(
      `/pop-management/pop?p=${p}&id=${id ? id : ""}&n=${n ? n : ""}&all=${all ? all : ""}`
    );
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const getPopDetails = (pop) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/pop-management/pop/detail?pop=${pop}`
    );
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data.detail[0];
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const createPop = (data) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  
  try {
    // const obj = {
    //   email: data[0],
    //   authenticated: data[1],
    // };
    const response = await api(localStorage.getItem("token")).post(
      "/pop-management/pop/create",
      { ...data }
    );
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Created successfully",
      });

      dispatch({ type: LOADED });
      return response.status;
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const updatePop = (data, id) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  try {
    // const obj = {
    //   email: data[0],
    //   remark: remark.remark,
    //   authenticated: data[1],
    // };
    data.pop  =  id;
    const response = await api(localStorage.getItem("token")).patch(
      "/pop-management/pop/update",
      { ...data }
    );
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Updated successfully",
      });

      dispatch({ type: LOADED });
      return response.status;
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const deletePop = (id) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  
  try {
    const response = await api(localStorage.getItem("token")).patch(
      "/pop-management/pop/delete",
      { pop: id }
    );
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Deleted successfully",
      });

      dispatch({ type: LOADED });
      return response.status;
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};
