
import { Button, Tag, Modal } from 'antd';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import ASICRemarks from '../../../component/asicRemark';
import { Title,DTable, ErrorContainer} from './detailStyle';
import {getServiceDetails,AsicSubmission,AsicError,asicFileDownloader} from '../../../actions/orders'
import { DownCircleOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';


const CompanyRegistrationDetails = ({newData,getServiceDetails,AsicSubmission,AsicError,asicFileDownloader}) => {
     
     const [remark, setRemark] = useState(false)
     const [errors, setErrors] = useState(null)
     const [data, setData] = useState(null)
     const [isModalVisible, setIsModalVisible] = useState(false);
     useEffect(() => {
          setData(newData)
     }, [])
     
     const formData = data?.data?.form_data;
     const bill = data?.data?.bill_info;
     const billing_info = () => {
          let res  = Object.keys(bill).map((item) => {
               return (<p>{item} : <strong>${bill[item]}</strong> </p>)
          })
          return res;
     }
     const getErrors = async (order) => {
          const res = await AsicError(order)
          if(res !== undefined){
               setErrors(res)
               setIsModalVisible(true)
          }
     }
     const handleRefresh = async (order) => {
          const res = await getServiceDetails(order)
            setData(res)
        }
    return ( 
         data ? 
          <DTable>
                    
                         <Title>Comapny Informations</Title> <hr/>
                         <>
                              <p>Company Name : <strong> {formData?.company_name} </strong></p>
                              <p>Company Structure : <strong> {formData?.company_structure} </strong></p>
                              <p>State of Registration : <strong> {formData?.juridiction} </strong></p>
                         </>
                    
                         <Title>ASIC Informations</Title> <hr/>
                         <>
                              <p>Request ID : <strong> {data?.data?.asic_request_id} </strong></p>
                              { data?.data?.asic_status === 'Accepted' ? <p>Document ID : <strong> {data?.data?.asic_document_id} </strong></p> : null}
                              <p style={{padding:'5px 0px'}}>Status : &nbsp;&nbsp;&nbsp;
                                   <strong> 
                                   {
                                   data?.data?.asic_status === 'Accepted' 
                                        ? 
                                        <>
                                             <Tag color="green">{data?.data?.asic_status}</Tag> 
                                             <Button type="ghost" icon={<DownloadOutlined />} size="small" style={{backgroundColor: '#1ad1a3',color:'#fff'}} onClick={()=>asicFileDownloader(data?.order_id, 'document')}> Download Document </Button>
                                             <Button type="ghost" icon={<DownloadOutlined />} size="small" style={{backgroundColor: '#1ad1a3',color:'#fff'}} onClick={()=>asicFileDownloader(data?.order_id, 'certificate')}> Download Certificate </Button>
                                        </>
                                        :
                                   data?.data?.asic_status === 'Submitted' 
                                        ? 
                                             <Tag color="#1ad1a3">{data?.data?.asic_status}</Tag> 

                                        :
                                   data?.data?.asic_status === 'Rejected' ?
                                        <>
                                             <Tag color="#f50">{data?.data?.asic_status}</Tag> 
                                             <Button type="ghost" icon={<EyeOutlined />} size="small" style={{backgroundColor: 'green',color:'#fff'}} onClick={()=>getErrors(data?.order_id)}>View Errors </Button>
                                        </>
                                        :
                                   data?.data?.asic_status === 'Pending' ?
                                        <Tag color="#f39c12">{data?.data?.asic_status}</Tag> 
                                        :
                                        data?.data?.asic_status
                                   }
                                   </strong>
                              </p>
                              
                         <br />
                              {(data?.data?.asic_status === 'Pending' && data?.status === 'Processing' && data?.payment_review === true) ?
                                   <Button type="primary" onClick ={ ()=>setRemark(true) } >Submit</Button>
                              : 
                                   <Button type="primary" disabled >Submit</Button>
                              }
                         </>
                         <br /><br />
                         <ASICRemarks title=" " btn="Submit" placement="bottom" visible={remark} fnc={()=>setRemark(false)} id={data?.order_id} subFnc = {AsicSubmission} refresh = {() => handleRefresh(data?.order_id)} />
                         <Modal title="Error Logs" visible={isModalVisible} onOk={()=>setIsModalVisible(false)} onCancel={()=> setIsModalVisible(false)} width={900}>
                              {errors ? errors.map((item, key) => (
                                   <ErrorContainer>
                                        <h4>Error - {key+1}</h4>
                                        <span><strong>Number : </strong>{item?.number}</span>
                                        <span style={{float: 'right'}}><strong>Segment Tag: </strong>{item?.segment_tag}</span>
                                        <p><strong>Error: </strong>{item?.error}</p>
                                        <p><strong>contents: </strong>{item?.contents}</p>
                                   </ErrorContainer>
                              )) :null}
                         </Modal>
                    
                    
                         <Title>Registered Office Address</Title> <hr/>
                         <p>Care Of : <strong>{formData?.care_of} </strong></p>
                         <p>Address room, floor, building : <strong>{formData?.line2} </strong></p>
                         <p>Street number, name and type : <strong>{formData?.street} </strong></p>
                         <p>Suburb, town, city : <strong>{formData?.locality} </strong></p>
                         <p>Australian state or territory : <strong>{formData?.state} </strong></p>
                         <p>Australia Post postcode : <strong>{formData?.postcode} </strong></p>
                         
                    
                         <Title>Registered Office Occupier</Title> <hr/>
                         <p>Will the company occupy the address? : <strong>{formData?.occupy === 'Y' ? 'Yes' : 'No'} </strong></p>
                         {formData?.occupy === 'N' ? 
                         <>
                         <p>Occupier's name : <strong>{formData?.occupant_consent} </strong></p>
                         <p>Declaration : <strong>{formData?.when_send} </strong></p>
                         
                         </>
                         : null}

                         <Title>Principal place of business details</Title> <hr/>
                         <p>Is the principal place of business the same as the registered office? : <strong>{formData?.is_same === 'Y' ? 'Yes' : 'No'} </strong></p>
                        
                         {formData?.is_same === 'Y' ? 
                              <>
                              <p>Care Of : <strong>{formData?.care_of} </strong></p>
                              <p>Address room, floor, building : <strong>{formData?.line2} </strong></p>
                              <p>Street number, name and type : <strong>{formData?.street} </strong></p>
                              <p>Suburb, town, city : <strong>{formData?.locality} </strong></p>
                              <p>Australian state or territory : <strong>{formData?.state} </strong></p>
                              <p>Australia Post postcode : <strong>{formData?.postcode} </strong></p>
                              
                              </>
                         : null}
                         {formData?.is_same === 'N' ? 
                              <>
                                   <p>Care Of : <strong>{formData?.principal_care_of} </strong></p>
                                   <p>Address room, floor, building : <strong>{formData?.principal_line2} </strong></p>
                                   <p>Street number, name and type : <strong>{formData?.principal_street} </strong></p>
                                   <p>Suburb, town, city : <strong>{formData?.principal_locality} </strong></p>
                                   <p>Australian state or territory : <strong>{formData?.principal_state} </strong></p>
                                   <p>Australia Post postcode : <strong>{formData?.principal_postcode} </strong></p>
                              </>
                         : null}
                         <Title>Company Entities</Title> <hr/>
                         <p> <strong>{formData?.individual  ? 'Individual' : 'Company'} </strong></p>
                         {formData?.individual ?
                              formData?.individual.map(item => (
                              <div style={{backgroundColor: 'lightgray', padding: '0px 3px'}}>
                                   <Title>Personal Details</Title> <hr/>
                                   <p>First Name  : <strong>{item?.family_name} </strong></p>
                                   <p>Middle Name  : <strong>{item?.given_name1} </strong></p>
                                   <p>Last Name  : <strong>{item?.given_name2} </strong></p>
                                   <p>What role(s) will this individual hold?  : <strong>{item?.role.map(i=><Tag>{i}</Tag>)} </strong></p>
                                   {item?.role.includes('DIR') || item?.role.includes('SEC') ?
                                   <>
                                        <p>Do you have former name?  : <strong>{item?.has_former === 'Y' ? 'Yes' : 'No'} </strong></p>
                                        {item?.has_former === 'Y' ? 
                                        <>
                                             <p> Former First Name : <strong>{item?.former_family_name} </strong></p>
                                             <p> Former Middle Name : <strong>{item?.former_given_name1} </strong></p>
                                             <p> Former Last Name : <strong>{item?.former_given_name2} </strong></p>
                                        </>
                                        : null}
                                   <p> Date of Birth : <strong>{item?.role_dob} </strong></p>
                                   <p> Are you an australian? : <strong> {item?.is_aus === 'Y' ? 'Yes' : 'No'}</strong></p>
                                        {item?.is_aus === 'Y' ?
                                        <>
                                             <p>Birth Suburb : <strong>{item?.locality} </strong></p>
                                             <p>Birth State : <strong>{item?.jurisdiction} </strong></p>
                                        </>
                                        : 
                                        <>
                                             <p>City : <strong>{item?.locality} </strong></p>
                                             <p>Country : <strong>{item?.jurisdiction} </strong></p>
                                        </>
                                        } 
                                   <p> Former : <strong>{item?.former_given_name2} </strong></p>
                                   <p> Former : <strong>{item?.former_given_name2} </strong></p>
                                   </>
                                   :null }
                                   {item?.role.includes('Shareholder') ?
                                        <>
                                             <hr />
                                             <h3 style={{textAlign: 'left', margin: '10px 0px 0px 0px'}}>Allocate Shares</h3>
                                             <p>Class Code : <strong>{item?.share_class} </strong></p>
                                             <p>Amount Paid Per Share : <strong>${item?.amount_paid_per_share} </strong></p>
                                             <p>Amount Due Per Share : <strong>${item?.amount_due_per_share} </strong></p>
                                             <p>Number of Shares : <strong>{item?.number} </strong></p>
                                             <p>Total Amount Paid Per Share : <strong>{item?.total_amount_paid_per_share} </strong></p>
                                             <p>Total Amount Unpaid Per Share : <strong>{item?.total_amount_due_per_share} </strong></p>
                                             <p>Will these Shares be held on behalf of another trust, individual or organisation? : <strong>{item?.beneficial_owner === 'Y' ? 'Yes' : 'No'} </strong></p>
                                        </>
                                   : null}
                              <hr />
                              <h3 style={{textAlign: 'left', margin: '10px 0px 0px 0px'}}>Residential Address</h3>
                              <p>Care Of : <strong>${item?.role_care_of} </strong></p>
                              <p>Address room, floor, building : <strong>${item?.role_line2} </strong></p>
                              <p>Street number, name and type : <strong>${item?.role_street} </strong></p>
                              <p>Suburb, town, city : <strong>${item?.role_locality} </strong></p>
                              <p>Australian state or territory : <strong>${item?.role_state} </strong></p>
                              <p>Australia Post postcode : <strong>${item?.role_postcode} </strong></p>
                         
                         
                              </div>))
                         : null}
                         <br />
                         {formData?.company ?
                              formData?.company.map(item => (
                              <div style={{backgroundColor: 'lightgray', padding: '0px 3px'}}>
                                   <Title>Organisation Details</Title> <hr/>
                                   <p>Organisation Name  : <strong>{item?.organisation_name} </strong></p>
                                   <p>Is the Organisation Registered in Australia? : <strong> {item?.is_registered === 'Y' ? 'Yes' : 'No'}</strong></p>
                                        {item?.is_registered === 'Y' ?
                                        <>
                                             <p>ACN (Australian Company Number) : <strong>{item?.acn} </strong></p>
                                        </>
                                        : 
                                        null
                                        } 
                                  <hr />
                              <h3 style={{textAlign: 'left', margin: '10px 0px 0px 0px'}}>Allocate Shares</h3>
                              <p>Class Code : <strong>{item?.share_class} </strong></p>
                              <p>Amount Paid Per Share : <strong>${item?.amount_paid_per_share} </strong></p>
                              <p>Amount Due Per Share : <strong>${item?.amount_due_per_share} </strong></p>
                              <p>Number of Shares : <strong>{item?.number} </strong></p>
                              <p>Total Amount Paid Per Share : <strong>{item?.total_amount_paid_per_share} </strong></p>
                              <p>Total Amount Unpaid Per Share : <strong>{item?.total_amount_due_per_share} </strong></p>
                              <p>Will these Shares be held on behalf of another trust, individual or organisation? : <strong>{item?.beneficial_owner === 'Y' ? 'Yes' : 'No'} </strong></p>
                                      
                              <hr />
                              <h3 style={{textAlign: 'left', margin: '10px 0px 0px 0px'}}>Residential Address</h3>
                              <p>Care Of : <strong>${item?.role_care_of} </strong></p>
                              <p>Address room, floor, building : <strong>${item?.role_line2} </strong></p>
                              <p>Street number, name and type : <strong>${item?.role_street} </strong></p>
                              <p>Suburb, town, city : <strong>${item?.role_locality} </strong></p>
                              <p>Australian state or territory : <strong>${item?.role_state} </strong></p>
                              <p>Australia Post postcode : <strong>${item?.role_postcode} </strong></p>
                         
                         
                              </div>))
                         : null}
                         
                         <Title style={{textAlign: 'left'}}>Billing Information</Title> <hr/>
                         {billing_info()}
                         
                    </DTable>
               :
               null
          
                    
     );
}
 
export default connect(null,{getServiceDetails,AsicSubmission,AsicError,asicFileDownloader})(CompanyRegistrationDetails);