import React from 'react'
import { Title,DTable} from './detailStyle';

const PartnershipPackageDetails = ({data}) => {
     const formData = data?.data?.form_data;
     const bill = data?.data?.bill_info;
     const billing_info = () => {
     let res  = Object.keys(bill).map((item) => {
          return (<p>{item} : <strong>${bill[item] }</strong></p>)
     })
     return res;
     }
     return ( 
               <DTable>
                         
                    <Title style={{textAlign: 'left'}}>ABN Registration</Title> <hr/>
                    
                    {formData.partner ? formData.partner.map((item,id) =>(
                         <>
                              <p style={{textAlign:'center'}}>partner {id + 1 }</p>
                              <p>Given Name: <strong>{item?.given_name} </strong></p>
                              <p>Family Name: <strong>{item?.family_name} </strong></p>
                              <p>Do you have a former name? : <strong>{item?.former_name==="true"?"yes":"no"} </strong></p>
                              <p>Provide your former name: <strong>{item?.former_name1} </strong></p>
                              <p>Given Name: <strong>{item?.given_name1} </strong></p>
                              <p>Family Name: <strong>{item?.family_name1} </strong></p>
                              <p>Email address: <strong>{item?.email} </strong></p>
                              <p>Phone number: <strong>{item?.phone_number} </strong></p>
                              <p>Date of birth: <strong>{item?.date_of_birth} </strong></p>
                              <p>Country of birth: <strong>{item?.country_of_birth} </strong></p>
                              <p>State of birth: <strong>{item?.state_of_birth} </strong></p>
                              <p>City of birth: <strong>{item?.city_of_birth} </strong></p>
                              <p>Unit/Level: <strong>{item?.unit} </strong></p>
                              <p>Street address: <strong>{item?.state_address} </strong></p>
                              <p>Suburb: <strong>{item?.subrub} </strong></p>
                              <p>State: <strong>{item?.state} </strong></p>
                              <p>Post code : <strong>{item?.post_code} </strong></p>
                              <p>Australia: <strong>{item?.australia} </strong></p>
                              <p>Tax file number (TFN) : <strong>{item?.tfn} </strong></p>
                              <p>I am an Australian resident for tax purpose: <strong>{item?.resident_for_tax_purpose?"yes":"no"} </strong></p>
                         </>
                         
                    ))
                    :
                    null}
               
               
               
                    <Title style={{textAlign: 'left'}}>Business address</Title> <hr/>
                    <p>Unit/Level : <strong>{formData?.unit1} </strong></p>
                    <p>Street address : <strong>{formData?.street_address1} </strong></p>
                    <p>Suburb : <strong>{formData?.suburb1} </strong></p>
                    <p>State : <strong>{formData?.state1} </strong></p>
                    <p>Postcode : <strong>{formData?.postcode1} </strong></p>
                    
                    <p>Is the business entity’s postal address the same as the business address? : <strong>{formData?.business_address_postal} </strong></p>
                    <p>Unit/house number : <strong>{formData?.unit2 } </strong></p>
                    <p>Street address : <strong>{formData?.street_address2}</strong></p>
                    <p>Suburb: <strong>{formData?.suburb2 }</strong></p>
                    <p>State: <strong>{formData?.state2} </strong></p>
                    <p>Postcode : <strong>{formData?.postcode2} </strong></p>
                    
                    <p>Country : <strong>{formData?.Country2} </strong></p>
                    <p>Provide your email address for service of notices and correspondence? : <strong>{formData?.correspondence } </strong></p>
                    <p>ABN activation date : <strong>{formData?.abn_date}</strong></p>
                    <p>Industry sector : <strong>{formData?.industry_sector }</strong></p>
                    <p>Describe your main business activity : <strong>{formData?.business_activity} </strong></p>
                    <Title>Business Name</Title>
                    <p>Provide your Proposed Business Name : <strong>{formData?.p_business_name} </strong></p>
                    <p>Propose your second Business Name : <strong>{formData?.p_business_name2 }</strong></p>
                    
                    <Title style={{textAlign: 'left'}}>GST Registration</Title> <hr/>
                    <p>Estimated turn-over  : <strong>{formData?.estimmted_turn_over} </strong></p>
                    <p>When will you send GST results to the ATO (?) : <strong>{formData?.gst_result} </strong></p>
                    <p>How often would like to report to the Tax Office? : <strong>{formData?.tax_office} </strong></p>
                    
                    <p>Do you import goods and services into Australia : <strong>{formData?.good_service==="true" ?"yes":"no"} </strong></p>
                    <p>Does your business have employee : <strong>{formData?.business_employee==="true" ?"yes":"no"} </strong></p>
                    <p>Do you wish to sign up for PAYG through us : <strong>{formData?.payg_us==="true" ?"yes":"no"} </strong></p>
                    <p>When do you want to commence : <strong>{formData?.want_commence} </strong></p>
                    <p>How many employees are you going to pay: <strong>{formData?.going_pay} </strong></p>
                    <p>How much do you expect to withhold from the payments each year? : <strong>{formData?.payments_each_year} </strong></p>
                    <p>GST account commence date : <strong>{formData?.gst_account} </strong></p>
                    <p>Are you employing any working holiday makers?  : <strong>{formData?.holiday_marks==="true" ?"yes":"no"} </strong></p>
                         <p>How are you going to provide your PAYG withholding payment annual summary report to the ATO?: <strong>{formData?.ato} </strong></p>
                         <p>How will you provide payment summaries to your payees?: <strong>{formData?.provide_payment} </strong></p>
                         <p>Will you pay royalties, dividends or interest to non-residents? : <strong>{formData?.non_residents==="true" ?"yes":"no" } </strong></p>
                         <p>What is the main industry that you operate in?	: <strong>{formData?.operate_in} </strong></p>
                         <p>Describe your business activity? : <strong>{formData?.b_activity} </strong></p>
                         
                    <Title style={{textAlign: 'left'}}>TFN For Partnership </Title> <hr/>
                              <p>Is the applicant a type of organisation that fits within an income tax exempt category?  : <strong>{formData?.organisation_category ==="true" ?"yes":"no"} </strong></p>
                              <p>From what date does the Partnership need its TFN? : <strong>{formData?.tfn_partnership} </strong></p>
                              <p>Does this partnership operate in an agricultural property? : <strong>{formData?.agricultural_poperty==="true" ?"yes":"no"} </strong></p>
                              <h2>Authorized Person </h2>
                              {formData.person ? formData.person.map((item,id) =>(
                              <>
                                   <p style={{textAlign:'center'}}>Authorised person  {id + 1 }</p>
                                   <p>Tile: <strong>{item?.tile} </strong></p>
                                   <p>Preferred name : <strong>{item?.preferred_name} </strong></p>
                                   <p>Family name : <strong>{item?.family_name} </strong></p>
                                   <p>Position: <strong>{item?.position} </strong></p>
                                   <p>Contact number with area code: <strong>{item?.contact_number} </strong></p>
                                   <p>Mobile number : <strong>{item?.mobile_number} </strong></p>
                                   <p>Email : <strong>{item?.email_no} </strong></p>
                              </>
                    ))
                    :
                    null}
                              
                              <h2>Associated Organization </h2>
                              <p>Does the partnership have any associated organisation? : <strong>{formData?.organisation_category==="true" ?"yes":"no"} </strong></p>
                              
                              {formData.Associated ? formData.Associated.map((item,id) =>(
                              <>
                                   <p style={{textAlign:'center'}}>Associated Organisation {id + 1 }</p>
                                   <p>ACN of the associated organization : <strong>{item?.organization_acn} </strong></p>
                                   <p>ABN of the associated organization : <strong>{item?.organization_abn} </strong></p>
                                   <p>Name of associated organization : <strong>{item?.organization_name} </strong></p>
                                   <p>Position held: <strong>{item?.positon_held} </strong></p>
                                   <p>Tax file number: <strong>{item?.tax_file} </strong></p>
                              </>
                         
                    ))
                    :
                    null}
                              
                    
               
               
                    <Title style={{textAlign: 'left'}}>Partnership Agreement Details </Title> <hr/>
                    <p>Date of the agreement	Calendar: <strong>{formData?.date_of_agreement} </strong></p>
                              <p>Family Name: <strong>{formData?.family_name} </strong></p>
                              <p>Commencement date of the partnership : <strong>{formData?.date_of_partnership} </strong></p>
                              <Title>Partnership Details</Title>
                              <p>Name of the partnership : <strong>{formData?.name_of_partnership} </strong></p>
                              <p>Duration of partnership : <strong>{formData?.duration_of_partnership} </strong></p>
                              <p>End date: <strong>{formData?.end_date} </strong></p>
                              <p>What vote is required to dissolve your partnership: <strong>{formData?.dissolve_partnership} </strong></p>
                              <p>How will assets be distributed when winding up your partnership? : <strong>{formData?.winding_partnership} </strong></p>
                              <p>Please specify: <strong>{formData?.specify_plz} </strong></p>
                              <h1>MultiParty</h1>
                    {formData.Party ? formData.Party.map((item,id) =>(
                         <>
                              <p style={{textAlign:'center'}}>Party {id + 1 }</p>
                              <p> Name: <strong>{item?.name} </strong></p>
                              <p>Given name: <strong>{item?.g_name_m1} </strong></p>
                         
                              <p>Family Name: <strong>{item?.f_name_m1} </strong></p>
                              <p>ABN : <strong>{item?.abnm1} </strong></p>
                              <h2>Name of the witness</h2>
                              <p>Given name: <strong>{item?.given_name_m1} </strong></p>
                              <p>Family name: <strong>{item?.f_name_m2} </strong></p>
                              <p>Address: <strong>{item?.address_m2} </strong></p>
                              <p>Profit and loss distribution : <strong>{item?.profin_distribution} </strong></p>
                         </>     
                         
                    ))
                    :
                    null}
               
               
                    <Title style={{textAlign: 'left'}}>Partners </Title> <hr/>
                    <p>How much a partner can borrow for the partnership without other partners approval : <strong>{formData?.partner_borrow} </strong></p>
                    <p>How much notice a partner should provide before retiring/leaving: <strong>{formData?.leaving} </strong></p>
                    <p>How much notice a partner should receive before getting expelled?: <strong>{formData?.getting_expelled} </strong></p>
                    <p>Will your partnership allow new partners in future?: <strong>{formData?.future==="true"?"yes":"no"} </strong></p>
                    <p>What is the requirement?: <strong>{formData?.requirement_vote} </strong></p>
                    
                    <p>Will the partners receive compensation for work done for the partnership? : <strong>{formData?.partnership_done==="true"?"yes":"no"} </strong></p>
                    
                    <h1>Account</h1>
                    <p>Bank Account details of the partnership : <strong>{formData?.bank_partnership } </strong></p>
                    <p>Who will setup the bank account : <strong>{formData?.setup_bank_account}</strong></p>
                    <p>How will partnership raise additional fund if needed in the future: <strong>{formData?.need_future }</strong></p>
                    
                    
               
               
                    <Title style={{textAlign: 'left'}}>Management  </Title> <hr/>
                    <p>Who will be responsible for managing the partnership?" : <strong>{formData?.managing_partnership} </strong></p>
                    <p>What vote is required to remove a managing partner?: <strong>{formData?.managing_partner} </strong></p>
                    <h1>Decision Making</h1>
                    <p>What vote is required to make financial decisions?: <strong>{formData?.financial_decision} </strong></p>
                    <p>What vote is required to make significant business decisions?: <strong>{formData?.business_decison} </strong></p>
                    <p>Voting right of each partner: <strong>{formData?.each_partner} </strong></p>
                    
                    <p>How often will regular meetings be held? : <strong>{formData?.meeting_held} </strong></p>
                        
               <Title>Billing Information</Title> <hr/>
               {billing_info()}
          </DTable>
     );
}
 
export default PartnershipPackageDetails;