import React from 'react'
import { Title,DTable} from './detailStyle';

const LetterOfDemandDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>

                        
                           
                              <Title>Details</Title> <hr/>
                              <p>Date of this letter : <strong>{formData?.date_of_letter} </strong></p>
                              <p>Type of Letter : <strong>{formData?.type_of_letter} </strong></p>
                              <p>Name of your Legal entity  : <strong>{formData?.name_of_legal_entity} </strong></p>
                              <p>ABN : <strong>{formData?.abn} </strong></p>
                              <p>Address : <strong>{formData?.address} </strong></p>
                              <p>Name of the party receiving the letter : <strong>{formData?.name_of_party} </strong></p>
                              <p>Name and the title of the person receiving the letter : <strong>{formData?.name_of_person} </strong></p>
                              <p>ABN of the second party (if known) : <strong>{formData?.abn_of_second_party} </strong></p>
                              <p>Address of the second party : <strong>{formData?.address_of_second_party} </strong></p>
                              <p>What is the amount owing?  : <strong>{formData?.amount_owing} </strong></p>
                           
                           
                           
                              <Title>Service Type</Title> <hr/>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                              <p>Describe if you have any other specific need that you want us to incorporate in the contract  : <strong> {formData?.describe_other_specific } </strong></p>
                              
                              <Title>Billing Information</Title> <hr/>
                              {billing_info()}   
                              
                         
                     </DTable>
     );
}
 
export default LetterOfDemandDetails;