import React from 'react'
import { Title,DTable} from './detailStyle';
import { fileDownloader } from '../../../actions/orders';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { connect } from 'react-redux';

const BasDetails = ({data,fileDownloader}) => {
      const formData = data?.data?.form_data;
      const bill = data?.data?.bill_info;
      const billing_info = () => {
      let res  = Object.keys(bill).map((item) => {
         return (<p>{item} : <strong>${bill[item] }</strong></p>)
      })
      return res;
      }
      return ( 
               <DTable >

                              <Title>Basic information </Title> <hr/>
                              <Title>Personal information </Title>
                              <p>Title : <strong>{formData?.title_of_person} </strong></p>
                              <p>Last name : <strong>{formData?.fname_of_person} </strong></p>
                              <p>First name : <strong>{formData?.lname_of_person} </strong></p>
                              <p>Gender : <strong>{formData?.gender_of_person} </strong></p>
                              <p>Date of birth : <strong>{formData?.dob_of_person} </strong></p>
                              <p>Email address : <strong>{formData?.email_of_person} </strong></p>
                              <p>Contact number : <strong>{formData?.number_of_person} </strong></p>
                             
                              <Title>Home Address</Title>
                              <p>Address line one : <strong>{formData?.address1_of_home} </strong></p>
                              <p>Address line two (optional) : <strong>{formData?.addess2_of_home} </strong></p>
                              <p>Suburb : <strong>{formData?.subrub_of_home} </strong></p>
                              <p>State : <strong>{formData?.state_of_home} </strong></p>
                              <p>Postcode : <strong>{formData?.postcode_of_home} </strong></p>
                              <p>Country : <strong>{formData?.country_of_home} </strong></p>
                              
                              <Title>Postal Address</Title>
                              <p>Is your postal address the same as home address? : <strong>{formData?.is_postal_address_same === 'true' ? 'Yes' : 'No'} </strong></p>
                              {formData?.is_postal_address_same === 'true' ?
                                 <>
                                 <p>Address line one : <strong>{formData?.address1_of_home} </strong></p>
                                 <p>Address line two (optional) : <strong>{formData?.address2_of_home} </strong></p>
                                 </>
                              :null}
                              {formData?.is_postal_address_same === 'false' ?
                                 <>
                                 <p>Address line one : <strong>{formData?.address1_of_postal} </strong></p>
                                 <p>Address line two (optional) : <strong>{formData?.address2_of_postal} </strong></p>
                                 </>
                              :null}<p>Suburb : <strong>{formData?.subrub_of_postal} </strong></p>
                              <p>State : <strong>{formData?.state_of_postal} </strong></p>
                              <p>Postcode : <strong>{formData?.postcode_of_postal} </strong></p>
                              <p>country : <strong>{formData?.country_of_postal} </strong></p>
                           
                              
                        
                           
                              <Title>Tax information</Title> <hr/>
                              <p>Provide the name of your Business : <strong>{formData?.business_name} </strong></p>
                              <p>What is your TFN? : <strong>{formData?.tfn} </strong></p>
                              <p>What is your Business Structure?  : <strong>{formData?.business_structure} </strong></p>
                              {formData?.business_structure === 'Other' ?
                                 <>
                                 <p>Specification : <strong>{formData?.structure_specification} </strong></p>
                                </>
                              :null}
                              {formData?.business_structure === 'Solo trader/ individual' ?
                                 <>
                                 <p>Specify your income source : <strong>{formData?.income_source} </strong></p>
                                </>
                              :null}
                              <p>Do you have a GST Account? : <strong>{formData?.have_gst} </strong></p>
                              {formData?.have_gst === 'true' ?
                                 <>
                                 <p>Provide your GST number : <strong>{formData?.gst_number} </strong></p>
                                </>
                              :null}
                           
                              
                        
                           
                              <Title>Business activity information</Title> <hr/>
                              <p>Select the year for your BAS lodgement : <strong>{formData?.year_for_bas} </strong></p>
                              <p>Select the frequency of BAS lodgement : <strong>{formData?.frequency_of_bas} </strong></p>
                              {formData?.frequency_of_bas === 'Monthly' ?
                                 <>
                                    <p>Provide the months for your BAS lodgement : <strong>{formData?.month_for_bas} </strong></p>
                                 </>
                              :null}
                              {formData?.frequency_of_bas === 'Quarterly' ?
                                 <>
                                 <p>Select the quarter for which BAS is lodged  : <strong>{formData?.quarter_for_bas} </strong></p>
                                </>
                              :null}
                              <p>Total sales/ revenue : <strong>{formData?.total_sales} </strong></p>
                              <p>GST on Sales : <strong>{formData?.final_gst_on_sales} </strong></p>
                              <p>Total purchases : <strong>{formData?.total_purchases} </strong></p>
                              <p>GST on purchases (10%) : <strong>{formData?.final_gst_on_purchases} </strong></p>
                              <p>Total GST : <strong>{formData?.total_gst} </strong></p>
                              {data?.data?.files.map((item,id)=> {
                                 if(item.scanned_image) return 
                                 else return <p>Upload supporting document {id}  : <strong>{item["supporting_documents"+id] ? <Button type="primary" icon={<DownloadOutlined />} size="middle" onClick = { () => fileDownloader(item["supporting_documents"+id])} /> : 'Not Submitted'} </strong></p>
                              })}
                              
                           
                        
                           
                              <Title>PAYG and Wage</Title> <hr/>
                              <p>Does your company employes : <strong>{formData?.does_company_employes === 'true' ? 'Yes' : 'No'} </strong></p>
                             {formData?.does_company_employes === 'true' ?
                                 <>
                                    <p>Do you have a PAYG account : <strong>{formData?.have_payg === 'true' ? 'Yes' : 'No'} </strong></p>
                                 </>
                              :null}
                              {formData?.have_payg === 'true' ?
                                 <>
                                 <p>Do you want to include PAYG tax withheld information : <strong>{formData?.want_include_payg === 'true' ? 'Yes' : 'No'} </strong></p>
                                </>
                              :null}
                              {formData?.want_include_payg === 'true' ?
                                 <>
                                 <p>Provide gross wage (including GST) for the selected period : <strong>{formData?.gross_wage} </strong></p>
                                 <p>What is the withheld tax calculated above? : <strong>{formData?.withheld_tax} </strong></p>
                                </>
                              :null}
                              
                           
                              
                        
                           
                              <Title>Declaration</Title> <hr/>
                              <p>Choosen Proof of ID : <strong>{formData?.id_type === 'true' ? 'Yes' : 'No'} </strong></p>
                              {/* <p>Scanned image of your chosen ID : <strong>{data?.data?.files[0]?.scanned_image ? <Button type="primary" icon={<DownloadOutlined />} size="middle" onClick = { () => fileDownloader(data?.data?.files[0].scanned_image)} /> : 'Not Submitted'} </strong></p> */}
                              {data?.data?.files.map((item,id)=> (
                                 item.scanned_image ?
                                 <p>Scanned image of your chosen ID: <strong>{item?.scanned_image ? <Button type="primary" icon={<DownloadOutlined />} size="middle" onClick = { () => fileDownloader(item?.scanned_image)} /> : 'Not Submitted'} </strong></p>
                                 :null
                              ))}


                              <Title>Billing Information</Title> <hr/>
                               {billing_info()}
                              
                         
                     </DTable>
     );
}
 
export default connect(null,{fileDownloader})(BasDetails); 