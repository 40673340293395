import React from 'react'
import { Title,DTable} from './detailStyle';


const GiftDeedDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable >
 
                        
                           
                              <Title>Donor, Recipient and Agreement Details</Title> <hr/>
                              <Title>Donor’s Details</Title>
                              <p>Name of the donor : <strong>{formData?.name_of_donor} </strong></p>
                              <p>Donor’s address : <strong>{formData?.address_of_donor} </strong></p>
                              <br />

                              <Title>Recipient’s Details</Title>
                              <p>What is the donee’s name? : <strong>{formData?.name_of_donee} </strong></p>
                              <p>What is the donee’s address? : <strong>{formData?.address_of_donee} </strong></p>
                              <Title>Agreement Details</Title>
                              <p>On what date is this document being made?: <strong>{formData?.date_of_made} </strong></p>
                              <p>On what date will the property be transferred? : <strong>{formData?.date_of_transfer} </strong></p>
                              <p>What are the goods being donated? : <strong>{formData?.donated} </strong></p>
                              
                           
                           
                              <Title>Service Type</Title> <hr/>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                              <p>Describe if you have any other specific need that you want us to incorporate in the contract  : <strong> {formData?.describe_other_specific } </strong></p>
                           
                              <Title>Billing Information</Title> <hr/>
                              {billing_info()}
      
                         
                     </DTable>
     );
}
 
export default GiftDeedDetails; 