import React from 'react'
import { Title,DTable} from './detailStyle';
import { fileDownloader } from '../../../actions/orders';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { connect } from 'react-redux';


const PartnershipTaxReturnDetails = ({data,fileDownloader}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                        
                           
                              <Title>Basic information </Title> <hr/>
                              <Title>Tax Return filling (Company or Trade Licence)</Title>
                              <p>Tax Return : <strong>{formData?.tax_return} </strong></p>
                              {formData?.tax_return === 'Others' ?
                                 <p>Specify amount : <strong>{formData?.structure_specification} </strong></p>
                              : null}
                              <Title>Basic Information</Title>
                              <p>Tax year : <strong>{formData?.tax_year} </strong></p>
                              <p>Applicant Title : <strong>{formData?.title_of_person} </strong></p>
                              <p>Applicant First name : <strong>{formData?.fname_of_person} </strong></p>
                              <p>Applicant Last name : <strong>{formData?.lname_of_person} </strong></p>
                              <p>Applicant Date of birth : <strong>{formData?.dob_of_person} </strong></p>
                              <p>Applicant Personal TFN : <strong>{formData?.tfn_of_person} </strong></p>
                              <p>Applicant Email address : <strong>{formData?.email_of_person} </strong></p>
                              <p>Applicant Mobile number : <strong>{formData?.number_of_person} </strong></p>
                              <p>Applicant Position in the company : <strong>{formData?.position_of_person} </strong></p>
                              
                              
                              <Title>Address of the applicant </Title>
                              <p>Address line one : <strong>{formData?.address1_of_home} </strong></p>
                              <p>Address line two (optional) : <strong>{formData?.addess2_of_home} </strong></p>
                              <p>Suburb : <strong>{formData?.subrub_of_home} </strong></p>
                              <p>State : <strong>{formData?.state_of_home} </strong></p>
                              <p>Postcode : <strong>{formData?.postcode_of_home} </strong></p>
                              <p>Country : <strong>{formData?.country_of_home} </strong></p>
                              <p>Upload an ID with Photo : <strong>{data?.data?.files[0]?.id_image ? <Button type="primary" icon={<DownloadOutlined />} size="middle" onClick = { () => fileDownloader(data?.data?.files[0].id_image)} /> : 'Not Submitted'} </strong></p>


                              <Title>Company's information</Title>
                              <p>Tax File Number (TFN) : <strong>{formData?.partnerships_tfn} </strong></p>
                              <p>Company Name : <strong>{formData?.partnerships_name} </strong></p>
                              <p>Australian Business Number (ABN) : <strong>{formData?.partnerships_abn} </strong></p>
                              <p>Did you ever change the name of this company?  : <strong>{formData?.did_name_change ? 'Yes' : 'No'} </strong></p>
                              {formData.did_name_change === 'true' ?
                              <p>What is the former name?  : <strong>{formData?.partnerships_former_name} </strong></p>
                              : null}
                              

                              <Title>Current Postal Address</Title>
                              <p>Address line one : <strong>{formData?.address1_of_current} </strong></p>
                              <p>Address line two (optional) : <strong>{formData?.addess2_of_current} </strong></p>
                              <p>Suburb : <strong>{formData?.subrub_of_current} </strong></p>
                              <p>State : <strong>{formData?.state_of_current} </strong></p>
                              <p>Postcode : <strong>{formData?.postcode_of_current} </strong></p>
                              <p>Country : <strong>{formData?.country_of_current} </strong></p>
                              
                              <Title>Details of Partner</Title>
                              {formData?.partner?.map((item,id) => (
                                 <>
                                    <h4>Partner  {id}</h4>
                                    <p>Partner's Title : <strong>{item?.title_of_partner} </strong></p>
                                    <p>Partner's Surname name : <strong>{item?.fname_of_partner} </strong></p>
                                    <p>Partner's Given name : <strong>{item?.lname_of_partner} </strong></p>
                                    <p>Is the partner a company?  : <strong>{item?.is_partner_company === 'true' ? 'Yes' : 'No'} </strong></p>
                                    {item?.is_partner_company === 'true' ?
                                       <>
                                          <p>Name of the company : <strong>{item?.company_name} </strong></p>
                                          <p>ABN : <strong>{item?.company_abn} </strong></p>
                                       </>
                                    : null}
                                 </>
                              ))}

                              
                              <Title>Company's banking details</Title> <hr/>
                              <p>Account name : <strong>{formData?.account_name} </strong></p>
                              <p>Account number	: <strong>{formData?.account_number} </strong></p>
                              <p>BSB  : <strong>{formData?.bsb} </strong></p>
                              
                           
                              
                        
                           
                              <Title>Partnership's business details</Title> <hr/>
                              <p>Describe your primary business activity : <strong>{formData?.business_activity} </strong></p>
                              <p>Does the company generate revenue through selling online  : <strong>{formData?.selling_online==='true'?"yes":"no"} </strong></p>
                             
                              <p>Do you have the company's financial report?: <strong>{formData?.financial_report==='true'?"yes":"no"} </strong></p>
                              {formData?.haveFinancialReport === 'true' ?
                                 <>
                                 <p>Upload the financial report : <strong>{data?.data?.files[1]?.upload_f_report ? <Button type="primary" icon={<DownloadOutlined />} size="middle" onClick = { () => fileDownloader(data?.data?.files[1].upload_f_report)} /> : 'Not Submitted'} </strong></p>
                                 <p>Do you want us to prepare your company's financial report? : <strong>{formData?.company_f_report==='true'?"yes":"no"} </strong></p>
                              
                                </>
                              :null}
                              
                            
                        
                           
                              <Title>Declaration</Title> <hr/>
                              <p>Choosen Proof of ID : <strong>{formData?.id_type === 'true' ? 'Yes' : 'No'} </strong></p>
                              <p>Please attach a clear scanned image of your chosen ID : <strong>{data?.data?.files[2]?.scanned_image ? <Button type="primary" icon={<DownloadOutlined />} size="middle" onClick = { () => fileDownloader(data?.data?.files[2].scanned_image)} /> : 'Not Submitted'} </strong></p>

                 
                              <Title>Billing Information</Title> <hr/>
                              {billing_info()} 
                         
                     </DTable>
     );
}
 
export default connect(null,{fileDownloader})(PartnershipTaxReturnDetails); 