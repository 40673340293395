import React from 'react'
import { Title,DTable} from './detailStyle';

const TFNDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
        let res  = Object.keys(bill).map((item) => {
         return (<p>{item} : <strong>${bill[item]}</strong> </p>)
        })
        return res;
   }
   return ( 
             <DTable>
                        
               <Title>Business Details</Title> <hr/>
               <p>What type of organisation is the applicant? : <strong>{formData?.type_of_organisation}</strong></p>
               <p>Is the applicant an Australian resident for tax purposes? : <strong>{formData?.is_aus_res ? 'Yes' : 'No'}</strong></p>
               <p>Is the applicant a type of organisation that fits within an income tax exempt category? : <strong>{formData?.is_fit ? 'Yes' : 'No'}</strong></p>
            
            
                  {formData?.type_of_organisation === 'Australian private company' ?
                  <>
                     <Title>Business Activity (Private Company)</Title>
                     <p>ACN /ARBN :<strong>{formData?.private_acn}</strong> </p>
                     <p>If the Australian Private Company is a subsidiary company, what is the ACN or ARBN of your ultimate holding company? : <strong>{formData?.acn_of_holding_company}</strong></p>
                     <p>From what date do you require the TFN?: <strong>{formData?.date_of_tfn}</strong></p>
                     <p>Describe your main business activities : <strong>{formData?.describe_activites}</strong></p>
                     <p>Does the Australian Private Company operate an agricultural property? : <strong>{formData?.does_operate_agricultural_property ? 'Yes' : 'No'}</strong></p>
                  </>
                  :null}
                  {formData?.type_of_organisation === 'Family partenrship' ?
                  <>
                     <Title>Business Activity (Family Partnership and Other Partnership)</Title>
                     <p>Describe your primary business activity:<strong>{formData?.describe_Primary_activites}</strong> </p>
                     <p>Does this Partnership operate in an agricultural property? : <strong>{formData?.does_partnership_operate_agricultural_property ? 'Yes' : 'No'}</strong></p>
                  </>
                  :null}
                  {formData?.type_of_organisation === 'Other Partnership ' ?
                  <>
                     <Title>Business Activity (Family Partnership and Other Partnership)</Title>
                     <p>Describe your primary business activity:<strong>{formData?.describe_Primary_activites}</strong> </p>
                     <p>Does this Partnership operate in an agricultural property? : <strong>{formData?.does_partnership_operate_agricultural_property ? 'Yes' : 'No'}</strong></p>
                  </>
                  :null}
                  {/* ==============Partner=========== */}
                  
                  
            
            
                     <Title>Address</Title> <hr/>
                     <p>Unit/Level : <strong>{formData?.unit} </strong></p>
                     <p>Street address : <strong>{formData?.street_address} </strong></p>
                     <p>Suburb : <strong>{formData?.suburb} </strong></p>
                     <p>State : <strong>{formData?.state} </strong></p>
                     <p>Postcode : <strong>{formData?.postcode} </strong></p>
                     <p>Country : <strong>{formData?.country} </strong></p>
                        
                     <p>Is the business entity's postal address the same as the business address : <strong>{formData?.is_same ? 'Yes' : 'No'} </strong></p>
                     {formData?.is_same === 'false' ? 
                     <>
                     <p>Unit/ house number : <strong>{formData?.postal_unit} </strong></p>
                     <p>Street number and name : <strong>{formData?.postal_street_address} </strong></p>
                     <p>Suburb : <strong>{formData?.postal_suburb} </strong></p>
                     <p>State/Territory : <strong>{formData?.postal_state} </strong></p>
                     <p>Postcode: <strong>{formData?.postal_postcode} </strong></p>
                     <p>Country: <strong>{formData?.postal_country} </strong></p>
                     </>
                     : null}
                     <p>Provide your email address for service of notices and correspondence? : <strong>{formData?.email} </strong></p>
                  
               
               
                  
                        <>
                           <Title>Authorised Person (Private Company)</Title> <hr/>
                        
                           {formData.authorised_person ? formData.authorised_person.map((item, id) => (
                                 <div key={id}>
                                    <h4>Authorised Person - {id+1}</h4>
                                    <p>Title : <strong>{item?.person_title} </strong></p>
                                    <p>Given Name : <strong>{item?.person_given_name} </strong></p>
                                    <p>Family Name : <strong>{item?.person_family_name} </strong></p>
                                    <p>Position : <strong>{item?.position_held} </strong></p>
                                    <p>Contact number with area code : <strong>{item?.auth_contact_with_code} </strong></p>
                                    <p>After hour contact number : <strong>{item?.auth_contact_number} </strong></p>
                                    <p>Mobile number : <strong>{item?.auth_mobile} </strong></p>
                                    <p>Email : <strong>{item?.auth_email} </strong></p>
                                 </div>
                           )) : null}
                           <Title>Associated Person (Private Company)</Title> <hr/>
                        
                           {formData.associated_person ? formData.associated_person.map((item, id) => (
                                 <div key={id}>
                                    <h4>Person - {id+1}</h4>
                                    <p>Title : <strong>{item?.person_title} </strong></p>
                                    <p>Given Name : <strong>{item?.person_given_name} </strong></p>
                                    <p>Family Name : <strong>{item?.person_family_name} </strong></p>
                                    <p>Date of birth : <strong>{item?.person_date_of_birth} </strong></p>
                                    <p>Position held : <strong>{item?.position_held} </strong></p>
                                    <p>Tax file number: <strong>{item?.person_tfn} </strong></p>
                                    <p>Unit/ house number : <strong>{item?.person_unit} </strong></p>
                                    <p>Street number and name : <strong>{item?.person_street_number} </strong></p>
                                    <p>Suburb : <strong>{item?.person_suburb} </strong></p>
                                    <p>State/Territory : <strong>{item?.person_state} </strong></p>
                                    <p>Postcode : <strong>{item?.person_postcode} </strong></p>
                                    <p>Country : <strong>{item?.person_country} </strong></p>
                                 </div>
                           )) : null}
                           <Title>Associated Organisation (Private Company)</Title> <hr/>
                        
                           {formData.associated_organisation ? formData.associated_organisation.map((item, id) => (
                                 <div key={id}>
                                    <h4>Associated organisation - {id+1}</h4>
                                    <p>ACN of the associated organisation : <strong>{item?.organisation_acn} </strong></p>
                                    <p>ABN of the associated organisation : <strong>{item?.organisation_abn} </strong></p>
                                    <p>Name of the associated organisation : <strong>{item?.organisation_name} </strong></p>
                                    <p>Position held : <strong>{item?.position_held} </strong></p>
                                    <p>Tax file number : <strong>{item?.organisation_tfn} </strong></p>
                                 </div>
                           )) : null}
                           
                        </>
                  
               <Title style={{textAlign: 'left'}}>Billing Information</Title> <hr/>
               {billing_info()}
            </DTable>
     );
}
 
export default TFNDetails;