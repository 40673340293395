import React from 'react'
import { Title,DTable} from './detailStyle';

const IssueofNewSharesDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                              
                                 <Title>Company Details </Title> <hr/>
                                  <p>Name of company  : <strong>{formData?.name_company2} </strong></p>
                                 <p>ABN or ACN : <strong>{formData?.abn1} </strong></p>
                                
                              
                              
                                 <Title>Details of share issue  </Title> <hr/>
                                 <p>Number of shares issued  : <strong>{formData?.no_share_issue } </strong></p>
                                 <p>Amount paid per share : <strong>{formData?.paid_per_share} </strong></p>
                                 <p>Amount unpaid per share   : <strong>{formData?.unpaid_per_share } </strong></p>
                                 
                                 <p>Date of issue   : <strong>{formData?.date_issue } </strong></p>
                                 <p>Were shares issued for other than cash?   : <strong>{formData?.share_issue_cash === true ? "Yes":"No" } </strong></p>
                                < p>Were some or all of the shares issued under a written contract? : <strong>{formData?.written_contract === true ? "Yes":"No" } </strong></p>
                                 
                                 
                                 
                                
                                 
                               

                                 
                                 <Title>Add a share class </Title> <hr/>
                                 <p>Please select a share class from the list of common classes:   : <strong>{formData?.common_class} </strong></p>
                                 <p>Effective Date : <strong>{formData?.effective_date} </strong></p>
                                 <p>Reason for a new class : <strong>{formData?.reason_new_class} </strong></p>
                                 <p>Late lodgement fee : <strong>{formData?.late_lodgement_fee } </strong></p>
                                 
                                 
                                 
                                 <Title>Declaration </Title> <hr/>
                                 
                                 <p>I authorize onecompany to manage all legal documentation related to ASIC and submit all required documentations on behalf of my company.  : <strong>{formData?.authorize_company === true ? "yes" : "no"} </strong></p>
                                 
                                 <p>Declarant Name  : <strong>{formData?.d_name} </strong></p>
                                 <p>Designation within the Company  : <strong>{formData?.designation1} </strong></p>

                                 
                            
                                 <Title>Billing Information</Title> <hr/>
                              {billing_info()}  

                         
                     </DTable>
     );
}
 
export default IssueofNewSharesDetails;