import React from 'react'
import { Title,DTable} from './detailStyle';

const ChangeShareholderDetailsDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                              
                                 <Title>Company Details </Title> <hr/>
                                  <p>Name of company  : <strong>{formData?.name_company2} </strong></p>
                                 <p>ABN or ACN : <strong>{formData?.abn1} </strong></p>
                                 <p>Please select the specific change of shareholder : <strong>{formData?.change_of_shareholder } </strong></p>
                                
                              
                              
                                 <Title>change of shareholder </Title> <hr/>
                                 {formData?.change_of_shareholder==="Add new a shareholder $49"?
                                 <>
                                 <p>Given name : <strong>{formData?.given_name_s1 } </strong></p>
                                 <p>Family name : <strong>{formData?.family_name_s1} </strong></p>
                                 <p>When the shareholder was added   : <strong>{formData?.shareholder_added_s1 } </strong></p>
                                 <p>Share class : <strong>{formData?.share_class_s1 } </strong></p>
                                 <p>Number of shares : <strong>{formData?.number_class_s1} </strong></p>
                                 <p>Total amount paid : <strong>{formData?.paid_ammount_s1 } </strong></p>
                                 <p>Total amount unpaid : <strong>{formData?.upaid_ammount_s1 } </strong></p>
                                 <p>Beneficially held? : <strong>{formData?.benificially_held_s1} </strong></p>
                                 <p>Is this an unchanged shareholder, whom you wish to add to the top 20 shareholding list for this class?   : <strong>{formData?.unchange_shareholder_s1=== true?"yes":"no" } </strong></p>
                                 <p>Late lodgement fee : <strong>{formData?.late_lodgement_fee_s1 } </strong></p>
                                 
                                 </>
                                 :null}
                                 {formData?.change_of_shareholder==="Cease a shareholder $49"?
                                 <>
                                 <p>Given name : <strong>{formData?.given_name_s2 } </strong></p>
                                 <p>Family name : <strong>{formData?.family_name_s2} </strong></p>
                                 <p>Date of cessation   : <strong>{formData?.shareholder_added_s2 } </strong></p>
                                 <p>Share class : <strong>{formData?.share_class_s2 } </strong></p>
                                 <p>Number of shares : <strong>{formData?.number_class_s2} </strong></p>
                                 <p>Total amount paid : <strong>{formData?.paid_ammount_s2 } </strong></p>
                                 <p>Total amount unpaid : <strong>{formData?.upaid_ammount_s2 } </strong></p>
                                 <p>Beneficially held? : <strong>{formData?.benificially_held_s2} </strong></p>
                                 <p>Late lodgement fee : <strong>{formData?.late_lodgement_fee_s2 } </strong></p>
                                 
                                 </>
                                 :null}
                                 {formData?.change_of_shareholder==="Replace an existing shareholder $49"?
                                 <>
                                 <p>Given name : <strong>{formData?.given_name_s3 } </strong></p>
                                 <p>Family name : <strong>{formData?.family_name_s3} </strong></p>
                                 <p>When the shareholder was added   : <strong>{formData?.shareholder_added_s3 } </strong></p>
                                 <p>Share class : <strong>{formData?.share_class_s3 } </strong></p>
                                 <p>Number of shares : <strong>{formData?.number_class_s3} </strong></p>
                                 <p>Total amount paid : <strong>{formData?.paid_ammount_s3 } </strong></p>
                                 <p>Total amount unpaid : <strong>{formData?.upaid_ammount_s3 } </strong></p>
                                 <p>Beneficially held? : <strong>{formData?.benificially_held_s3} </strong></p>
                                 <p>Is this an unchanged shareholder, whom you wish to add to the top 20 shareholding list for this class?   : <strong>{formData?.unchange_shareholder_s3=== true?"yes":"no" } </strong></p>
                                 <p>Late lodgement fee : <strong>{formData?.late_lodgement_fee_s3 } </strong></p>
                                 
                                 </>
                                 :null}
                                 {formData?.change_of_shareholder==="Change a shareholder’s name $49"?
                                 <>
                                 <h2>Existing Name</h2>
                                 <p>Given name : <strong>{formData?.given_name_s4_1 } </strong></p>
                                 <p>Family name : <strong>{formData?.family_name_s4_1} </strong></p>
                                 <h2>New Name</h2>
                                 <p>Given name : <strong>{formData?.given_name_s4_2 } </strong></p>
                                 <p>Family name : <strong>{formData?.family_name_s4_2} </strong></p>
                                 
                                 
                                 <p>Date of Change   : <strong>{formData?.shareholder_added_s4 } </strong></p>
                                 <p>Share class : <strong>{formData?.share_class_s4 } </strong></p>
                                  <p>Late lodgement fee : <strong>{formData?.late_lodgement_fee_s4 } </strong></p>
                                 
                                 </>
                                 :null}

                                 {formData?.change_of_shareholder==="Change a shareholder’s address $49"?
                                 <>
                                 <h2>Existing Name</h2>
                                 <p>Given name : <strong>{formData?.given_name_s5 } </strong></p>
                                 <p>Family name : <strong>{formData?.family_name_s5} </strong></p>
                                 <h2>Existing Address</h2>
                                 <p>Unit/ house number : <strong>{formData?.unit5 } </strong></p>
                                 <p>Street number and name : <strong>{formData?.street_number5} </strong></p>
                                 
                                 
                                 <p>Suburb   : <strong>{formData?.suburb5 } </strong></p>
                                 <p>State : <strong>{formData?.state5 } </strong></p>
                                 <p>Post code: <strong>{formData?.post_code5 } </strong></p>
                                 <p>Country : <strong>{formData?.country5} </strong></p>
                                 <h2>New Address</h2>
                                 
                                 <p>Unit/ house number : <strong>{formData?.unit5_1 } </strong></p>
                                 <p>Street number and name : <strong>{formData?.street_number5_1} </strong></p>
                                 
                                 
                                 <p>Suburb   : <strong>{formData?.suburb5_1 } </strong></p>
                                 <p>State : <strong>{formData?.state5_1 } </strong></p>
                                 <p>Post code: <strong>{formData?.post_code5_1 } </strong></p>
                                 <p>Country : <strong>{formData?.country5_1} </strong></p>
                                 
                                 
                                 <p>Date of Change   : <strong>{formData?.shareholder_added_s5 } </strong></p>
                                
                                  
                                 </>
                                 :null}

                                 {formData?.change_of_shareholder==="Change a shareholder’s beneficial status $49"?
                                 <>
                                 
                                 <p>Given name : <strong>{formData?.given_name_s6 } </strong></p>
                                 <p>Family name : <strong>{formData?.family_name_s6} </strong></p>
                                 <p>Is this an unchanged shareholder, whom you wish to add to the top 20 shareholding list for this class?  : <strong>{formData?.unchange_shareholder_s3=== true?"yes":"no" } </strong></p>
                                
                                 <p>Late lodgement fee  : <strong>{formData?.late_lodgement_fee_s6 } </strong></p>
                                
                                  
                                 </>
                                 :null}
                                 
                              
                                 
                               

                                 
                                 <Title>Late lodgement fee </Title> <hr/>
                                 <p>Late lodgement fee : <strong>{formData?.late_lodgement_fee } </strong></p>
                                 
                                 
                                 
                                 <Title>Declaration </Title> <hr/>
                                 
                                 <p>I authorize onecompany to manage all legal documentation related to ASIC and submit all required documentations on behalf of my company.  : <strong>{formData?.authorize_company === true ? "yes" : "no"} </strong></p>
                                 
                                 <p>Declarant Name  : <strong>{formData?.d_name} </strong></p>
                                 <p>Designation within the Company  : <strong>{formData?.designation1} </strong></p>

                                 
                            
                                 <Title>Billing Information</Title> <hr/>
                              {billing_info()}  

                         
                     </DTable>
     );
}
 
export default ChangeShareholderDetailsDetails;