import React from 'react'
import { Title,DTable} from './detailStyle';

const AppointmentofanewdirectorDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                              
                                 <Title>Company Details </Title> <hr/>
                                  <p>Name of company  : <strong>{formData?.name_company2} </strong></p>
                                 <p>ABN or ACN : <strong>{formData?.abn1} </strong></p>
                                
                              
                              
                                 
                                 <Title>Director’s Details </Title> <hr/>
                                 <h2>Name of the  director   </h2>
                                 <p>Given name   : <strong>{formData?.given_name_s21 } </strong></p>
                                 <p>Family name   : <strong>{formData?.family_name_s22 } </strong></p>
                                 <p>Date of birth   : <strong>{formData?.date_of_birth2 } </strong></p>
                                 <p>Country of birth  : <strong>{formData?.country_of_birth2} </strong></p>
                                 <p>Town of birth  : <strong>{formData?.town_of_birth2 } </strong></p>
                                 <p>Does this person have any former names?: <strong>{formData?.former_name2 === true ? "yes" : "no"} </strong></p>
                                 <p>Given name   : <strong>{formData?.given_name_s23 } </strong></p>
                                 <p>Family name   : <strong>{formData?.family_name_s24 } </strong></p>
                                 
                                 <h2>Residential address of the alternate director </h2>
                                 <p>Unit/ house number : <strong>{formData?.unit2 } </strong></p>
                                 
                                 <p>Street number and name   : <strong>{formData?.street_number2 } </strong></p>
                                 <p>Suburb : <strong>{formData?.suburb2 } </strong></p>
                                 <p>State/Territory : <strong>{formData?.state2} </strong></p>
                                 <p>Post code  : <strong>{formData?.post_code2 } </strong></p>
                                 
                                 <p>Country: <strong>{formData?.country2 } </strong></p>
                                
                                 
                                
                                 
                                 
                                 <Title>Late lodgement fee </Title> <hr/>
                                 <p>Late lodgement fee : <strong>{formData?.late_lodgement_fee } </strong></p>
                                 

                                 
                                 <Title>Declaration </Title> <hr/>
                                
                                 <p>I authorize onecompany to manage all legal documentation related to ASIC and submit all required documentations on behalf of my company.  : <strong>{formData?.authorize_company === true ? "yes" : "no"} </strong></p>
                                 <p>Declarant Name: <strong>{formData?.d_name} </strong></p>
                                 <p>Designation within the Company  : <strong>{formData?.designation1} </strong></p>

                                 
                                                          
                                 <Title>Billing Information</Title> <hr/>
                              {billing_info()}


                         
                     </DTable>
     );
}
 
export default AppointmentofanewdirectorDetails;