import api from "./apis";
import { ERROR_MESSAGE, LOADED, LOADEDING, SUCCESS_MESSAGE, VALIDATION_ERROR } from "./types";
import history from "../history";
import axios from "axios";

export const getServices = (ds, de, sn, o, s, s_p, p) => async (dispatch) => {
  

  dispatch({ type: LOADEDING });
  try{
    let response = null;
    if(ds && de){
        response = await api(localStorage.getItem("token")).get(`/order-management/order?ds=${ds}&de=${de}&sn=${sn?sn:""}&o=${o?o:""}&s=${s?s:""}&s_p=${s_p?s_p:""}&p=${p?p:1}`);
    }
    else{
      response = await api(localStorage.getItem("token")).get(`/order-management/order?o=${o?o:""}&s=${s?s:""}&sn=${sn?sn:""}&s_p=${s_p?s_p:""}&p=${p?p:1}`);
    }
    
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}
export const getOrdersForFinance = (p_r,s_p, o, ds, de, s, p_i, p) => async (dispatch) => {
  

  dispatch({ type: LOADEDING });
  try{
    let response = null;
    if(ds && ds){
        response = await api(localStorage.getItem("token")).get(`/finance-management/order?p_r=${p_r?p_r:''}&s_p=${s_p?s_p:''}&o=${o?o:''}&ds=${ds?ds:''}&de=${de?de:""}&s=${s?s:""}&p_i=${p_i?p_i:""}&p=${p?p:1}`);
    }
    else{
      response = await api(localStorage.getItem("token")).get(`/finance-management/order?p_r=${p_r?p_r:''}&s_p=${s_p?s_p:''}&o=${o?o:''}&s=${s?s:""}&p_i=${p_i?p_i:""}&p=${p?p:1}`);
    }
    
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}


export const getServiceDetails = (order) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  try{
    
    const response = await api(localStorage.getItem("token")).get(`order-management/order/detail?order=${order}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data.order;
      
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}
export const getOrderDetailsForFinance = (order) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  try{
    
    const response = await api(localStorage.getItem("token")).get(`finance-management/order/payment/detail?order=${order}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      // console.log(response.data.order)
      return response.data.order;
      
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}
export const fileDownloader = (name) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  const ext = name.split(".")[1]
  
  try{
    const response = await axios(`https://api-ispt2.hidayahsmart.solutions/sailor/v1/order-management/order/file/download?file_name=${name}`,
        {
          headers: {
            'authorization': `Bearer ${localStorage.getItem("token")}`
            },
            method: 'GET',
            responseType: 'blob',
          }
        );
        
    if(response.status === 200 ) {
      // FileDown
      dispatch({ type: LOADED });
      const url = window.URL.createObjectURL(new Blob([response.data]),);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `FileName.${ext}`,
      );
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}
export const fileUploader = (data, order) => async (dispatch) => {
  dispatch({ type: LOADEDING });
    const files = []
    Object.keys(data).map((ele) => {
     
      let title = data[ele].title
      let download_able = data[ele].download_able
        if(typeof(data[ele]) === 'object'){

            Object.keys(data[ele]).map((nEle) => {
              
          if(typeof(data[ele][nEle]) === 'object'){
            Object.keys(data[ele][nEle]).map((last,id) => {
              if(last === 'fileList'){
                
                files.push({
                  title : title,
                  download_able : download_able,
                  file :data[ele][nEle][last][0].response.name
                })
                }
            })
          }
        }
        )}
    });
    
  
  try{
    const obj = {
          "order" : order,
          "files": [ ...files ]
    }
    const response = await api(localStorage.getItem("token")).patch('order-management/order/file/upload', {...obj});
    
    if(response.status === 201 ){
        dispatch({ type: SUCCESS_MESSAGE, payload: 'Documents Uploaded successfully' });
        dispatch({type: LOADED})
        return response.status
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};
export const fileDelete = (file) => async (dispatch) => {
  dispatch({ type: LOADEDING });
    
  try{

    const response = await axios('https://api-ispt2.hidayahsmart.solutions/sailor/v1/order-management/file/delete',
        {
            headers: {
                'content-type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem("token")}`
            },
            data: {
              file: file,
            },
            method: 'delete'
        }
        
        );
    
    if(response.status === 201 ){
        dispatch({type: LOADED})
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};

export const updateOrderStatus = (order,remark) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  try {
    const obj = {
      order: order,
      remark: remark.remark,
    }
    const response = await api(localStorage.getItem("token")).patch("order-management/order/done", {...obj});
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Operation successful",
      });
      
      dispatch({ type: LOADED });
      return response.status
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};
export const rejectOrder = (order,remark) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  try {
    const obj = {
      order: order,
      remark: remark.remark,
    }
    const response = await api(localStorage.getItem("token")).patch("order-management/order/rejected", {...obj});
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Operation successful",
      });
      
      dispatch({ type: LOADED });
      return response.status
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};
export const rejectFinanceOrder = (order,remark) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  try {
    const obj = {
      order: order,
      remark: remark.remark,
    }
    const response = await api(localStorage.getItem("token")).patch("finance-management/order/rejected", {...obj});
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Operation successful",
      });
      
      dispatch({ type: LOADED });
      return response.status
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};
export const refundFinanceOrder = (order,remark) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  try {
    const obj = {
      order: order,
      remark: remark.remark,
    }
    const response = await api(localStorage.getItem("token")).patch("finance-management/order/payment/refund", {...obj});
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Operation successful",
      });
      
      dispatch({ type: LOADED });
      return response.status
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};
export const reviewOrder = (order,remark) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  try {
    const obj = {
      order: order,
      remark: remark.remark,
    }
    const response = await api(localStorage.getItem("token")).patch("finance-management/order/payment/review", {...obj});
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Operation successful",
      });
      
      dispatch({ type: LOADED });
      return response.status
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};


export const makeReturn = (shop, merchant,recipient_mobile_number, order_id,remark) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  const obj = {
    "shop": shop,
    "merchant": merchant,
    "recipient_mobile_number": recipient_mobile_number,
    "order_id": order_id ,
    "remark": remark,
  }
 
  // dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).patch("order-management/return-order", {...obj});

    if (response.status === 201) {
      const res = await api(localStorage.getItem("token")).get(`order-management/get-order-details?merchant=${merchant}&order=${order_id}&shop=${shop}`);
        if(res.status === 200 ) {
          dispatch({ type: LOADED });
          localStorage.setItem('parcel', JSON.stringify(res.data.order));
          dispatch({
            type: SUCCESS_MESSAGE,
            payload: "Order is returned succesfully",
          });
          return res.data.order;
          
          // history.push('/parcelDetails')
          
        }
      
      dispatch({ type: LOADED });
      // history.push(`/storeDetails?shop_id=${data.shop_id}`);
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};

export const deleteParcel = (recipient_mobile_number, order_id, shop, merchant, remark) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  let from = new Date();
  let to = new Date();
  const dd = String(from.getDate()).padStart(2, '0'); 
  const ldd = String(to.getDate()).padStart(2, '0'); 
  const mm = String(from.getMonth() + 1).padStart(2, '0'); //January is 0!
  const lmm = String(to.getMonth()).padStart(2, '0'); //January is 0!
  const yyyy = from.getFullYear();
  const lyyyy = to.getFullYear();

  to = yyyy + '-' + mm + '-' + dd;
  from = lyyyy + '-' + lmm + '-' + ldd;
  const obj = {
    "shop": shop,
    "merchant": merchant,
    "recipient_mobile_number": recipient_mobile_number,
    "order_id": order_id ,
    "remark": remark,
  }
 
  // dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).patch("order-management/cancel-order", {...obj});
    if(response.status === 201 ) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Order is Canceled succesfully",
      });
      const res = await api(localStorage.getItem("token")).get(`/order-management/get-merchant-orders?merchant=${merchant}&startDate=${from}&endDate=${to}`);
      if(res.status === 200 ) {
        dispatch({ type: LOADED });
        return res.data.orders;
      }
      dispatch({ type: LOADED });
      
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}
export const cancelParcel = (recipient_mobile_number, order_id, shop, merchant, remark) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  const obj = {
    "shop": shop,
    "merchant": merchant,
    "recipient_mobile_number": recipient_mobile_number,
    "order_id": order_id ,
    "remark": remark,
  }
 
  // dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).patch("order-management/return-order", {...obj});
    if(response.status === 201 ) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Order is returned succesfully",
      });
      dispatch({ type: LOADED });
      return [];
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const getOutForDeliveryParcel = () => async (dispatch) => {
  dispatch({ type: LOADEDING });
  
  try{
    
    const response = await api(localStorage.getItem("token")).get(`/order-management/out-for-delivery`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data.orders;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}
export const deliverParcel = (order_id) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  const obj = {
    "order_id": order_id ,
  }
  try{
    
    const response = await api(localStorage.getItem("token")).patch(`/order-management/deliver-order`, {...obj});
    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

// =====================ASIC====================
export const AsicSubmission = (order,data) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  try {
    const obj = {
      order: order,
      admin: {
        has_asic_consent_for_name: data?.has_asic_consent_for_name,
        text_manual_review: data?.text_manual_review.toUpperCase(),
      }
    }
    const response = await api(localStorage.getItem("token")).post("order-management/order/company-registration/submit", {...obj});
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Operation successful",
      });
      
      dispatch({ type: LOADED });
      return response.status
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};
export const AsicError = (order) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  try {
  
    const response = await api(localStorage.getItem("token")).get(`order-management/order/company-registration/error-logs?order=${order}`);
    if (response.status === 200) {
    
      
      dispatch({ type: LOADED });
      return response?.data?.logs
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};
export const asicFileDownloader = (order,type) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  
  try{
    const response = await axios(`https://api-ispt2.hidayahsmart.solutions/sailor/v1/order-management/order/company-registration/file/download?order=${order}&type=${type}`,
        {
          headers: {
            'authorization': `Bearer ${localStorage.getItem("token")}`
            },
            method: 'GET',
            responseType: 'blob',
          }
        );
        
    if(response.status === 200 ) {
      // FileDown
      dispatch({ type: LOADED });
      const url = window.URL.createObjectURL(new Blob([response.data]),);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `FileName`,
      );
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}
export const AsicNotification = (p,ds,de) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  
  try{
    let response = null;
    if(ds && de){
        response = await api(localStorage.getItem("token")).get(`/order-management/notification?p=${p?p:1}&ds=${ds}&de=${de}`);
    }
    else{
      response = await api(localStorage.getItem("token")).get(`/order-management/notification?p=${p?p:1}`);
    }
        
    if(response.status === 200 ) {
      dispatch({type: LOADED});
      return response?.data
    }
    dispatch({type: LOADED});
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}