import React from 'react'
import { Title,DTable} from './detailStyle';

const ResidentialSubleaseDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>

                        
                           
                              <Title >Important Dates and Property</Title> <hr/>
                              <p>What is the start date of the sublease term?: <strong>{formData?.starting_date_of_sublease} </strong></p>
                              <p>What is the end date of the sublease term?: <strong>{formData?.end_date_of_sublease} </strong></p>
                              <p>What date is this sublease agreement effective from?: <strong>{formData?.date_of_sublease_agreement} </strong></p>
                              <p>Is the sublease renewable? : <strong>{formData?.is_sublease_renewable ? 'Yes' : "No"} </strong></p>
                              {formData?.is_lease_renewable ? 
                                 <p>Sublease renewable option? : <strong>{formData?.sublease_renewable_option} </strong></p>
                              : null}
                              
                              <Title >Property</Title>
                              <p>Property type : <strong>{formData?.type_of_property} </strong></p>
                              {formData?.type_of_property === 'Others' ?
                                 <p>Specification : <strong>{formData?.other_property_specification} </strong></p>
                              :null}
                              <p>Area to be sublet : <strong>{formData?.area_of_sublet} </strong></p>
                              <p>Address of the property : <strong>{formData?.address_of_property} </strong></p>
                              <p>List of items included in the property : <strong>{formData?.list_item } </strong></p>
                           

                           
                              <Title >Tenant & Subtenant</Title> <hr/>
                              <p>What is the name of the tenant? : <strong>{formData?.name_of_tenant}</strong></p>
                              <p>What is the address of the tenant? : <strong>{formData?.address_of_tenant}</strong></p>
                              <p>What is the name of the Subtenant? : <strong>{formData?.name_of_subtenant}</strong></p>
                              <p>What is the address of the Subtenant? : <strong>{formData?.address_of_subtenant}</strong></p>
                              <p>How many tenants are there in total occupying the premise?  : <strong>{formData?.number_of_tenant}</strong></p>
                              <p>Will the Subtenant be sharing a bedroom? : <strong>{formData?.will_share ? 'Yes' : 'No'}</strong></p>
                              <p>Are the Subtenant's rights to make improvements the same as under the Master Tenancy agreement? : <strong>{formData?.rights_to_make ? 'Yes' : 'No'}</strong></p>
                              
                           
                           
                              <Title >Landlord</Title> <hr/>
                              <p>What is the name of the landlord? : <strong>{formData?.name_of_landlord}</strong></p>
                              <p>What was the date of the master lease? : <strong>{formData?.date_of_lease}</strong></p>
                           

                           
                              <Title >Payment Details</Title> <hr/>
                              <p>How much is each sublease payment? : <strong>{formData?.sublease_payment} </strong></p>
                              <p>The first date of payment : <strong>{formData?.date_of_payment} </strong></p>
                              <p>What is the frequency of the sublease payments? : <strong>{formData?.what_is_frequency} </strong></p>
                              {formData?.what_is_frequency === 'Others' ? 
                              <p>Specification?  : <strong>{formData?.frequency_specification} </strong></p>
                              : null}
                              <p>What are the payment details/ bank details for the tenant to pay sublease payments? : <strong>{formData?.payment_details} </strong></p>
                              <p>Who is the security deposit (bond) payable to? : <strong>{formData?.deposit_payable} </strong></p>
                              <p>What is the security deposit (bond) amount?  : <strong>{formData?.security_bond} </strong></p>
                              <p>Are the utility payments the same as under the Master Tenancy Agreement? : <strong>{formData?.are_same} </strong></p>
                              {formData?.are_same === 'true' ?
                              <p>Specification : <strong>{formData?.specification} </strong></p>
                              :  null}
                              
                           

                           
                              <Title >Service Type</Title> <hr/>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                              <p>Describe if you have any other specific need that you want us to incorporate in the contract  : <strong> {formData?.describe_other_specific } </strong></p>
                              
          
                              <Title>Billing Information</Title> <hr/>
                              {billing_info()}

               
                     </DTable>
     );
}
 
export default ResidentialSubleaseDetails;