import React from 'react'
import { Title,DTable} from './detailStyle';
const PAYGDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
        let res  = Object.keys(bill).map((item) => {
         return (<p>{item} : <strong>${bill[item]}</strong> </p>)
        })
        return res;
   }
    return ( 
             <DTable style={{width: '100%'}}>

               <Title style={{textAlign: 'left'}}>Business Details</Title> <hr/>
               <p>Your business structure : <strong> {formData?.business_structure}</strong></p>
               <p>Provide your ABN : <strong> {formData?.abn}</strong></p>
               <p>Your business name : <strong> {formData?.business_name}</strong></p>
               <p>Applicant’s Given name : <strong> {formData?.applicants_given_name}</strong></p>
               <p>Applicant’s Family name : <strong> {formData?.applicants_family_name}</strong></p>
               <p>Applicant’s Email address : <strong> {formData?.applicants_email}</strong></p>
               <p>Applicant’s Phone number : <strong> {formData?.applicants_phone}</strong></p>
               <p>Business Date of birth : <strong> {formData?.applicants_dob}</strong></p>
               <p>Business Unit/Level : <strong> {formData?.applicants_unit}</strong></p>
               <p>Business Level : <strong> {formData?.applicants_level}</strong></p>
               <p>Business Street address : <strong> {formData?.applicants_street_address}</strong></p>
               <p>Business Suburb : <strong> {formData?.applicants_suburb}</strong></p>
               <p>Business State : <strong> {formData?.applicants_state}</strong></p>
               <p>Business Postcode : <strong> {formData?.applicants_Postcode}</strong></p>

               <Title style={{textAlign: 'left'}}>PAYG</Title> <hr/>
               <p>When do you want to commence : <strong>{formData?.when_commence} </strong></p>
               <p>How many employees are you going to pay : <strong>{formData?.employee_number} </strong></p>
               <p>How much do you expect to withhold from the payments each year? : <strong>{formData?.payments} </strong></p>
               <p>Are you employing any working holiday makers? : <strong>{formData?.holiday_makers ? 'Yes' : 'No'} </strong></p>
               <p>How are you going to provide your PAYG withholding payment annual summary report to the ATO? : <strong>{formData?.report_to_ato} </strong></p>
               <p>How will you provide payment summaries to your payees? : <strong>{formData?.payment_summaries} </strong></p>
               <p>Will you pay royalties, dividends or interest to non-residents? : <strong>{formData?.will_pay_royalties ? 'Yes' : 'No'} </strong></p>
               <p>What is the primary industry that you operate in? : <strong>{formData?.primary_industry} </strong></p>
               <p>Describe your business activity? : <strong>{formData?.describe_activity} </strong></p>
                     
            
               <Title style={{textAlign: 'left'}}>Declaration</Title> <hr/>
               <p>Declarant Given Name : <strong>{formData?.declarant_given_name} </strong></p>
               <p>Declarant Family Name : <strong>{formData?.declarant_family_name} </strong></p>
               <p>Designation within the Company : <strong>{formData?.designation_business} </strong></p>
            
               <Title>Billing Information</Title> <hr/>
               {billing_info()}
                         
            </DTable>
     );
}
 
export default PAYGDetails;