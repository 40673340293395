import React from 'react'
import { Title,DTable} from './detailStyle';

const ConfidentialityAgreementDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable >

                        
                           
                              <Title>Agreement Details</Title> <hr/>
                              <p>Date of the agreement : <strong>{formData?.date_of_agreement} </strong></p>
                              <p>Type of Agreement : <strong>{formData?.type_of_agreement} </strong></p>
                              <p>First Party type : <strong>{formData?.first_party_type} </strong></p>
                              {formData?.first_party_type === 'Individual' ?
                              <>
                              <p>Given Name : <strong>{formData?.first_party_g_name}</strong></p>
                              <p>Family Name : <strong>{formData?.first_party_f_name}</strong></p>
                                 </>: null}
                              {formData?.first_party_type === 'Organisation' ?
                              <p>ABN : <strong>{formData?.first_party_abn} </strong></p>
                                 : null}
                              <p>Address : <strong>{formData?.first_party_address} </strong></p>
                           

                           
                              <Title>Other Party</Title> <hr/>
                              <p>Other Party type : <strong>{formData?.other_party_type} </strong></p>
                              {formData?.other_party_type === 'Individual' ?
                              <>
                              <p>Given Name : <strong>{formData?.other_party_g_name}</strong></p>
                              <p>Family Name : <strong>{formData?.other_party_f_name}</strong></p>
                                 </>: null}
                              {formData?.other_party_type === 'Organisation' ?
                              <p>ABN : <strong>{formData?.other_party_abn} </strong></p>
                                 : null}
                              <p>Address : <strong>{formData?.other_party_address} </strong></p>
                           
                           
                           
                              <Title>Confidential Information</Title> <hr/>

                              <p>Why is confidential information being disclosed? : <strong>{formData?.fonfidential_information} </strong></p>
                              <p>How long do you want the information to be protected under this agreement? : <strong>{formData?.how_long_to_be_protected} </strong></p>
                              <p>What type of confidential information needs protection? : <strong>{formData?.type_of_confidential} </strong></p>
                              {formData?.type_of_confidential === 'Others'?
                              <p>Specification : <strong> {formData?.specification } </strong></p>
                                 : null}
                           
                           
                           
                              <Title>Agreement Type</Title> <hr/>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                              
                              <Title>Billing Information</Title> <hr/>
                              {billing_info()}  
                         
                     </DTable>
     );
}
 
export default ConfidentialityAgreementDetails;