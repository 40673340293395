import React from 'react'
import { Title,DTable} from './detailStyle';

const ConversionofSharesIntoSmallerorLargerNumbersDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                              
                                 <Title>Company Details </Title> <hr/>
                                  <p>Name of company  : <strong>{formData?.name_company2} </strong></p>
                                 <p>ABN or ACN : <strong>{formData?.abn1} </strong></p>
                                
                              
                              
                                 <Title>Share Class</Title> <hr/>
                                 <p>Select service   : <strong>{formData?.service_type } </strong></p>
                                 <p>Chose share class : <strong>{formData?.share_service} </strong></p>
                                 <p>Date of Conversion   : <strong>{formData?.date_of_conversion } </strong></p>
                                 
                                 <p>Total number of current shares after changes?  : <strong>{formData?.total_number_after_change } </strong></p>
                                 <p>Total amount paid on these shares? : <strong>{formData?.total_number} </strong></p>
                                 <p>Total amount unpaid on these shares?  : <strong>{formData?.total_ammount_unpaid } </strong></p>
                               
                                 < p>Do you have resolution for share division/conversion  : <strong>{formData?.share_divison === true ? "yes" : "no"} </strong></p>
                                 <p>Do you want us to prepare the resolution? : <strong>{formData?.prepare_resolution === true ? "yes $89" : "no"} </strong></p>
                                 
                                 
                                
                                 
                               

                                 
                                 <Title>Declaration </Title> <hr/>
                                 <p>Late lodgement fee : <strong>{formData?.late_lodgement_fee } </strong></p>
                                 
                                 <p>I authorize onecompany to manage all legal documentation related to ASIC and submit all required documentations on behalf of my company.  : <strong>{formData?.authorize_company === true ? "yes" : "no"} </strong></p>
                                 <p>Given name  : <strong>{formData?.g_name1} </strong></p>
                                 <p>Family name : <strong>{formData?.f_name1} </strong></p>
                                 <p>Designation within the Company  : <strong>{formData?.designation1} </strong></p>

                                 
                            
                                 <Title>Billing Information</Title> <hr/>
                              {billing_info()} 

                         
                     </DTable>
     );
}
 
export default ConversionofSharesIntoSmallerorLargerNumbersDetails;