import React from 'react'
import { Title,DTable} from './detailStyle';

const NotificationofHalfYearlyReportsDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                              
                                 <Title>Company Details </Title> <hr/>
                                 <p>Name of company : <strong>{formData?.company_name} </strong></p>
                                 <p>ACN : <strong>{formData?.acn} </strong></p>
                                
                                 <p>ABN: <strong>{formData?.abn} </strong></p>
                                 <p>Financial period</p>
                                 <p>From  : <strong>{formData?.from} </strong></p>
                                 <p>To  : <strong>{formData?.to} </strong></p>
                                 <p>Late Lodgement fee  : <strong>{formData?.late_lodgement_fee} </strong></p>
                                
                              
                              
                                 <Title>Declaration</Title> <hr/>
                                 <p>I authorize onecompany to manage all legal documentation related to ASIC and submit all required documentations on behalf of my company.  : <strong>{formData?.authorize_company === true ? "yes" : "no"} </strong></p>
                                 <p>Given name  : <strong>{formData?.g_name1} </strong></p>
                                 <p>Family name : <strong>{formData?.f_name1} </strong></p>
                                 <p>Designation within the Company  : <strong>{formData?.designation1} </strong></p>

                                 <Title>Billing Information</Title> <hr/>
                              {billing_info()} 
                     </DTable>
     );
}
 
export default NotificationofHalfYearlyReportsDetails;