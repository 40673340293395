import React from 'react'
import { Title,DTable} from './detailStyle';

const CorrectionofSubmittedFormDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                              
                                 <Title>Company Details </Title> <hr/>
                                  <p>Name of company  : <strong>{formData?.name_company2} </strong></p>
                                 <p>ABN or ACN : <strong>{formData?.abn1} </strong></p>
                                
                              
                              
                                 <Title>Correction Details  </Title> <hr/>
                                 <p>Name and number of the form you want to correct : <strong>{formData?.name_number } </strong></p>
                                
                                 
                                 
                                 <p>Date of change   : <strong>{formData?.date_appoint } </strong></p>
                                 
                                 <p>What was the error/mistake : <strong>{formData?.error_mistake } </strong></p>
                                
                                 <p>Mention the correction you want make : <strong>{formData?.mention_correction } </strong></p>
                                 

                                 
                                 <Title>Declaration </Title> <hr/>
                                
                                 <p>I authorize onecompany to manage all legal documentation related to ASIC and submit all required documentations on behalf of my company.  : <strong>{formData?.authorize_company === true ? "yes" : "no"} </strong></p>
                                 
                                 <p>Declarant Name: <strong>{formData?.d_name} </strong></p>
                                 <p>Designation within the Company  : <strong>{formData?.designation1} </strong></p>

                                 
                                 <Title>Billing Information</Title> <hr/>
                              {billing_info()}


                         
                     </DTable>
     );
}
 
export default CorrectionofSubmittedFormDetails;