import React from 'react'
import { Title,DTable} from './detailStyle';

const PrivacyPolicyDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable >
                           
                              <Title >Details</Title> <hr/>
                              <p>URL of your website : <strong>{formData?.url_of_website} </strong></p>
                              <p>Name of the business/individual operating the website (provide full name) : <strong>{formData?.name_of_website} </strong></p>
                              <p>Will the information collected by your website be stored, processed be transferred between parties outside of Australia : <strong>{formData?.information_collect ? 'Yes' : 'No'} </strong></p>
                              {formData?.information_collect ?
                                 <p>Name the country/countries : <strong>{formData?.name_of_country} </strong></p>
                              : null}
                              <p>The email address of the business operating the website: <strong>{formData?.email_address_of_business} </strong></p>
                              <p>Name of the person authorising this privacy policy : <strong>{formData?.name_of_the_person} </strong></p>
                              <p>On which date this privacy policy becomes effective : <strong>{formData?.date_of_effective} </strong></p>
                              <p>Type of services provided by the website? : <strong>{formData?.type_of_service} </strong></p>
                              <p>What are the personal information do you collect from your subscribers/visitors : <strong>{formData?.personal_information } </strong></p>
                              <p>Other personal information you will collect : <strong>{formData?.other_personal_information} </strong></p>
                           
                           
                           
                              <Title >Product Type</Title> <hr/>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                           
                              
                         
                         <Title>Billing Information</Title> <hr/>
                        {billing_info()}

                     </DTable>
     );
}
 
export default PrivacyPolicyDetails; 