import React from 'react'
import { Title,DTable} from './detailStyle';


const MemorandumOfUnderstandingDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>

         
                              <Title>Party Details</Title> <hr/>
                              <Title>First Party</Title>
                              <p>What is the name of the first party? : <strong>{formData?.name_of_first_party} </strong></p>
                              <p>What is the ACN/ABN of the first party? : <strong>{formData?.abn_of_first_party} </strong></p>
                              <p>What is the address of the first party? : <strong>{formData?.address_of_first_party} </strong></p>
                              
                              <br />
                              <Title>Second Party</Title>
                              <p>What is the name of the second party? : <strong>{formData?.name_of_second_party} </strong></p>
                              <p>What is the ACN/ABN of the second party? : <strong>{formData?.abn_of_second_party} </strong></p>
                              <p>"What is the address of the second party? : <strong>{formData?.address_of_second_party} </strong></p>
                           
                           
                           
                              <Title>Agreement and Obligations Details</Title> <hr/>
                              <Title>Agreement Details</Title>
                              <p>What type of agreement is this MOU for? : <strong>{formData?.type_of_agreement} </strong></p>
                              <p>What is the date that the final written Proposed Agreement is expected to be signed? : <strong>{formData?.date_of_agreement} </strong></p>
                              <Title>Obligations Details</Title>
                              <p>What are the primary obligations of the first party? : <strong>{formData?.obligations_of_first_party} </strong></p>
                              <p>What are the primary obligations of the second party?  : <strong>{formData?.obligations_of_second_party} </strong></p>
                              <p>What are the mutual obligations for both parties?  : <strong>{formData?.manual_obligation} </strong></p>
                           
                           
                           
                              <Title>Consideration and Restrictions</Title> <hr/>
                              <Title>Consideration</Title>
                              <p>Is there consideration for the final Proposal Agreement? : <strong>{formData?.is_there_consideration ? 'Yes' : 'No'} </strong></p>
                              {formData?.is_there_consideration ?
                              <p>Please describe the considerations in detail : <strong>{formData?.describe_the_consideration} </strong></p>
                              : null}
                              <Title>Restrictions</Title>
                              <p>Restrictions : <strong>{formData?.restriction} </strong></p>
                              {formData?.restriction === 'Confidentiality' ?
                                 <p>Parties are restricted from communicating confidential information to others for : <strong>{formData?.restriction_duration_for_confidentiality} </strong></p>
                              :  null}
                              {formData?.restriction === 'Non-compete' ?
                                 <p>Parties are prohibited from unfairly competing against each other : <strong>{formData?.restriction_duration_for_nonCompete} </strong></p>
                              :  null}
                           
                           
                           
                              <Title>Service Type</Title> <hr/>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                              <p>Describe if you have any other specific need that you want us to incorporate in the contract  : <strong> {formData?.describe_other_specific } </strong></p>
                              
                              <Title>Billing Information</Title> <hr/>
                               {billing_info()}

                     </DTable>
     );
}
 
export default MemorandumOfUnderstandingDetails; 