import React from 'react'
import { Title,DTable} from './detailStyle';

const EndUserLicenseAgreementDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable style={{width: '100%'}}>

                        
                           
                              <Title>Owner Details</Title> <hr/>
                              <p>Provide the full name of the company : <strong>{formData?.name_of_company} </strong></p>
                              <p>Provide the ABN number of the company : <strong>{formData?.abn_of_company} </strong></p>
                              <p>Provide the address of the company : <strong>{formData?.address_of_company} </strong></p>
                              <p>Provide the contact number of the company : <strong>{formData?.number_of_company} </strong></p>
                              <p>Briefly describe the product the product : <strong>{formData?.description_of_product} </strong></p>
                           
                           
                           
                              <Title>Product Details</Title> <hr/>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                              <p>Describe if you have any other specific need that you want us to incorporate in the contract  : <strong> {formData?.describe_other_specific } </strong></p>
                           
                              <Title>Billing Information</Title> <hr/>
                               {billing_info()}  
                         
                     </DTable>
     );
}
 
export default EndUserLicenseAgreementDetails; 