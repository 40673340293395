import React from 'react'
import { Title,DTable} from './detailStyle';

const NotificationofChangeofRegisteredOfficeDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                              
                                 <Title>Details of foreign company</Title> <hr/>
                                 <p>Company Name   : <strong>{formData?.c_name} </strong></p>
                                 <p>ARBN : <strong>{formData?.arbn} </strong></p>
                                 <p>Select the service types  : <strong>{formData?.service_type} </strong></p>

                                 {formData?.service_type=== "Registered office in Australia" ?
                                 <>
                                 <p>Unit/Level   : <strong>{formData?.unit} </strong></p>
                                 <p>Street address  : <strong>{formData?.street_address} </strong></p>
                                 <p>Suburb : <strong>{formData?.suburb} </strong></p>
                                 <p>State  : <strong>{formData?.state} </strong></p>
                                 <p>Post code  : <strong>{formData?.post_code} </strong></p>
                                 <p>Date of change	  : <strong>{formData?.date_of_change} </strong></p>
                                 <p>Late Lodgement fee  : <strong>{formData?.late_lodgement_fee} </strong></p>
                                 <p>Is the registered office occupied by an entity other than this new company  : <strong>{formData?.registered ==true ? "yes" : "no"} </strong></p>
                                 <p>I authorise onecompany to manage all the legal documentation related to ASIC and submit all required documentation on behalf of my Company. : <strong>{formData?.final_declaration === true ? "yes" : "no"} </strong></p>
                                 
                                 </>
                                 :null}
                                 {formData?.service_type=== "Office hours in Australia" ?
                                 <>
                                 <p>Is registered office is open to the public each business day for at least 3 hours between 9 am and 5 pm  : <strong>{formData?.registered_office ===true ? "yes" : "no"} </strong></p>
                                 <p>Insert hours open to close   : <strong>{formData?.open_hours} </strong></p>
                                 <p>Date of change	  : <strong>{formData?.date_of_change1} </strong></p>
                                 <p>Late Lodgement fee  : <strong>{formData?.late_lodgement_fee1} </strong></p>
                                 
                                 </>
                                 :null}
                                    {formData?.service_type=== "Registered office in place of origin" ?
                                 <>
                                 <p>Unit/Level   : <strong>{formData?.unit1} </strong></p>
                                 <p>Street address  : <strong>{formData?.street_address1} </strong></p>
                                 <p>Suburb : <strong>{formData?.suburb1} </strong></p>
                                 <p>State  : <strong>{formData?.state1} </strong></p>
                                 <p>Post code  : <strong>{formData?.post_code1} </strong></p>
                                 <p>Country : <strong>{formData?.country1} </strong></p>
                                 <p>Date of change	  : <strong>{formData?.date_of_change2} </strong></p>
                                 <p>Late Lodgement fee  : <strong>{formData?.late_lodgement_fee2} </strong></p>
                                 </>
                                 :null}

                                 
                              
                              
                              
                                 <Title>Declaration </Title> <hr/>
                                 <p>Declarant’s Name  : <strong>{formData?.d_name} </strong></p>
                                 <p>Designation within the Company   : <strong>{formData?.d_company} </strong></p>
                                 <p>onecompany local agent ID*  : <strong>{formData?.agent_id} </strong></p>
                                <p> I authorize onecompany to manage all legal documentation related to ASIC and submit all required documentations on behalf of my company.: <strong>{formData?.final_declaration1 === true ? "yes" : "no"} </strong> </p>
                                 
                                <Title>Billing Information</Title> <hr/>
                              {billing_info()}  

                         
                     </DTable>
     );
}
 
export default NotificationofChangeofRegisteredOfficeDetails;