import React from 'react'
import { Title,DTable} from './detailStyle';

const ReservationofCompanyNameDetails = ({data}) => {
    const formData = data?.data?.form_data;
    const bill = data?.data?.bill_info;
    const billing_info = () => {
    let res  = Object.keys(bill).map((item) => {
         return (<p>{item} : <strong>${bill[item] }</strong></p>)
    })
    return res;
    }
    return ( 
             <DTable>
                              
                                 <Title>Reservation Details  </Title> <hr/>
                                 <p>Is the proposed name identical to a registered company name(s)?  : <strong>{formData?.proposed_name_identical} </strong></p>
                                 
                                  <p>Provide the name you want to reserve   : <strong>{formData?.proposed_company_name} </strong></p>
                                 <p>Select service  : <strong>{formData?.select_service} </strong></p>
                                
                                 <p>Name of company  : <strong>{formData?.n_company } </strong></p>
                                
                                 <p>ABN/ ACN : <strong>{formData?.abn   } </strong></p>
                                 
                                 <p>Select the legal elements that you wish to apply to the company name. : <strong>{formData?.legal_elements } </strong></p>
                                
                                 
                                 
                                 
                                 <Title>Declaration </Title> <hr/>
                                
                                 <p>I authorize onecompany to manage all legal documentation related to ASIC and submit all required documentations on behalf of my company.  : <strong>{formData?.authorize_company === true ? "yes" : "no"} </strong></p>
                                 
                                 <p>Declarant Name: <strong>{formData?.d_name} </strong></p>
                                 <p>Designation within the Company  : <strong>{formData?.designation1} </strong></p>

                                 <Title>Billing Information</Title> <hr/>
                              {billing_info()}  
                            


                         
                     </DTable>
     );
}
 
export default ReservationofCompanyNameDetails;