import React from 'react'
import { connect } from 'react-redux';
import { Title,DTable} from './detailStyle';
import { fileDownloader } from '../../../actions/orders';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const ForeignCompanyRegDetails = ({data,fileDownloader}) => {
     const formData = data?.data?.form_data;
     const bill = data?.data?.bill_info;
     const billing_info = () => {
          let res  = Object.keys(bill).map((item) => {
               return (<p>{item} : <strong>${bill[item]}</strong> </p>)
          })
          return res;
     }
    return ( 
     <DTable>
               <Title style={{textAlign: 'left'}}>Current registration</Title> <hr/>
               <p>Corporation name : <strong>{formData?.corporation_name} </strong></p>
               <p>Corporation number in place of origin : <strong>{formData?.corporation_number} </strong></p>
               <p>Date of incorporation : <strong>{formData?.date_of_incorporation} </strong></p>
               <p>Place of incorporation/origin : <strong>{formData?.place_of_incorporation} </strong></p>
               <p>Has a name reservation been lodged to reserve the body’s name? : <strong>{formData?.has_name_reservation ? 'Yes' : 'No'} </strong></p>
               <p>Provide name reservation number : <strong>{formData?.reservation_number}</strong></p>
               <p>Is the proposed name identical to a egistered business name(s) in Australia? : <strong>{formData?.is_identical ? 'Yes' : 'No'}</strong></p>
               <p>Business number : <strong>{formData?.business_number} </strong></p>
               <p>State/Territory of registration : <strong>{formData?.state_of_registration} </strong></p>
               
            
            
            
               <Title style={{textAlign: 'left'}}>Foreign company</Title> <hr/>
               <p>At the office of, C/- (if applicable) : <strong>{formData?.aus_at_office} </strong></p>
               <p>Office : <strong>{formData?.aus_office} </strong></p>
               <p>Unit : <strong>{formData?.aus_unit} </strong></p>
               <p>Level : <strong>{formData?.aus_level} </strong></p>
               <p>Street number : <strong>{formData?.aus_street_number} </strong></p>
               <p>Street name : <strong>{formData?.aus_street_name} </strong></p>
               <p>Suburb/city : <strong>{formData?.aus_suburbCity} </strong></p>
               <p>State : <strong>{formData?.aus_state} </strong></p>
               <p>Postcode : <strong>{formData?.aus_postcode} </strong></p>
               <p>Office hours : <strong>{formData?.open_hour === 'a' ?
                  'Registered office open to the public each business day from at least 10 am to 12 noon and 2 pm to 4 pm.'
                  :
                  null
               } </strong>
                  </p>
               <p>Office hours : <strong>{formData?.open_hour === 'b' ?
                  'Registered office open to the public each business day for at least 3 hours between 9 am and 5 pm.'
                  :
                  null
               } </strong>
                  </p>
               <p>Does the company occupy the premises? : <strong>{formData?.does_occupy ? 'Yes' : 'No'} </strong></p>
               <p>Name of the occupier : <strong>{formData?.occupier_name} </strong></p>
               
            
            
            
               <Title style={{textAlign: 'left'}}>Local agent</Title> <hr/>
               <p>Name of the director : <strong>{formData?.director_name} </strong></p>
               {formData?.director ? formData?.director.map((item,id) =>(
                     <>
                        <p style={{textAlign:'center'}}>Director {id + 1 }</p>
                        <p>Family name: <strong>{item?.family_name} </strong></p>
                        <p>Given name: <strong>{item?.given_name} </strong></p>
                        <p>Former name: <strong>{item?.former_name} </strong></p>
                        <p>Company name: <strong>{item?.company_name} </strong></p>
                        <p>CAN/ARBN No: <strong>{item?.can_no} </strong></p>
                        <p>Phone Number: <strong>{item?.phone} </strong></p>
                        <p>Office: <strong>{item?.office} </strong></p>
                        <p>Unit: <strong>{item?.unit} </strong></p>
                        <p>Level: <strong>{item?.level} </strong></p>
                        <p>Street Number: <strong>{item?.street_number} </strong></p>
                        <p>Street Name: <strong>{item?.street_name} </strong></p>
                        <p>Subrub: <strong>{item?.subrub} </strong></p>
                        <p>State: <strong>{item?.state} </strong></p>
                        <p>Post Code": <strong>{item?.post_code} </strong></p>
                        <p>Country: <strong>{item?.country} </strong></p>
                        <p>Office held from: <strong>{item?.office_held_from} </strong></p>
                        <p>Date of birth: <strong>{item?.dob} </strong></p>
                        <p>Place of birth: <strong>{item?.pob} </strong></p>
                  </>
               ))
               :
               null}
            
            
            
               <Title style={{textAlign: 'left'}}>Upload Documents</Title> <hr/>
               <p>Certified copy of the Certificate of Incorporation : <strong> {data?.data?.files?.[0]?.certificate_of_incorporation ?  <Button type="primary" icon={<DownloadOutlined />} size="middle" onClick = { () => fileDownloader(data?.data?.files?.[0]?.certificate_of_incorporation)} /> : 'Not submitted' } </strong></p>
               <p>Certified copy of the body's Constitution or MOA & AOA : <strong> {data?.data?.files?.[1]?.certificate_of_constitution ? <Button type="primary" icon={<DownloadOutlined />} size="middle" onClick = { () => fileDownloader(data?.data?.files?.[1]?.certificate_of_constitution)} /> : 'Not submitted' } </strong></p>
               <p>Other : <strong> {data?.data?.files?.[3]?.other ? <Button type="primary" icon={<DownloadOutlined />} size="middle" onClick = { () => fileDownloader(data?.data?.files?.[2]?.other)} /> : 'Not submitted' } </strong></p>
            
            
            
               <Title style={{textAlign: 'left'}}>Declaration</Title> <hr/>
               
               <p>Given name of the declarant: <strong> {formData?.declarant_given_name } </strong></p>
               <p>Family name of the declarant : <strong> {formData?.declarant_family_name } </strong></p>
               
               <Title style={{textAlign: 'left'}}>Billing Information</Title> <hr/>
               
               <p>{Object.keys(data?.data?.bill_info)}: <strong> {Object.values(data?.data?.bill_info)} </strong></p>
                    
               <Title style={{textAlign: 'left'}}>Billing Information</Title> <hr/>
               {billing_info()}            
          </DTable>
     );

}
 
export default connect(null,{fileDownloader})(ForeignCompanyRegDetails);