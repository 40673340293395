import React from 'react'
import { Title,DTable} from './detailStyle';

const AdvertisementAgreementDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable >

                        
                           
                              <Title>Owner Details</Title> <hr/>
                              <p>Provide the full name of the Website Owner : <strong>{formData?.name_of_owner} </strong></p>
                              <p>Provide the ABN number of the Website Owner : <strong>{formData?.abn_of_owner} </strong></p>
                              <p>Provide the address of the Website Owner : <strong>{formData?.address_of_owner} </strong></p>
                              <p>Provide the contact number of the Website Owner : <strong>{formData?.number_of_owner} </strong></p>
                           
                           
                           
                              <Title>Advertiser Details</Title> <hr/>
                              <p>Provide the name of the website hosting the advertising : <strong>{formData?.name_of_website} </strong></p>
                              <p>Provide the URL of the website hosting the advertising : <strong>{formData?.url_of_website} </strong></p>
                              <p>Describe the fee for advertising : <strong>{formData?.fee_of_advertising} </strong></p>
                              <p>What is the full name of the advertiser? : <strong>{formData?.full_name_of_advertiser} </strong></p>
                              <p>What is the ABN of the advertiser?  : <strong>{formData?.abn_of_advertiser} </strong></p>
                              <p>What is the address of the advertiser? : <strong>{formData?.address_of_advertiser} </strong></p>
                              <p>What is the contact number of the advertiser? : <strong>{formData?.number_of_advertiser} </strong></p>
                              <p>Within how many days must the consumer pay an invoice?  : <strong>{formData?.number_of_days_to_pay} </strong></p>
                              <p>If the invoice is not paid, how many percentage points will the interest be above the cash rate? : <strong>{formData?.percentage_point} </strong></p>
                              <p>When will this agreement be signed? : <strong>{formData?.when_will_sign} </strong></p>
                           
                           
                           
                              <Title>Service Type</Title> <hr/>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                              <p>Describe if you have any other specific need that you want us to incorporate in the contract  : <strong> {formData?.describe_other_specific } </strong></p>
                           
                              <Title>Billing Information</Title> <hr/>
                              {billing_info()}
                         
                     </DTable>
     );
}
 
export default AdvertisementAgreementDetails; 