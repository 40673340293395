import React from 'react'
import { Title,DTable} from './detailStyle';

const CyberSecutiryPolicyDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>

                        
                           
                              <Title>Policy Details</Title> <hr/>
                              <p>Provide the legal name of your business : <strong>{formData?.legal_name} </strong></p>
                              <p>After how many months are employees required to change login passwords? : <strong>{formData?.number_of_month} </strong></p>
                              <p>Who is the information security manager? : <strong>{formData?.security_manager} </strong></p>
                           
                           
                           
                              <Title>Product Details</Title> <hr/>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                              <p>Describe if you have any other specific need that you want us to incorporate in the contract  : <strong> {formData?.describe_other_specific } </strong></p>
                              
                              <Title>Billing Information</Title> <hr/>
                              {billing_info()}

                     </DTable>
     );
}
 
export default CyberSecutiryPolicyDetails; 