import React from 'react'
import { Title,DTable} from './detailStyle';

const ChangeAnnualReviewDateDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                              
                                 <Title>Company Details </Title> <hr/>
                                  <p>Name of company  : <strong>{formData?.name_company2} </strong></p>
                                 <p>ABN or ACN : <strong>{formData?.abn1} </strong></p>
                                
                              
                              
                                 <Title>Changes Details  </Title> <hr/>
                                 <p>New review date requested  : <strong>{formData?.date_of_change } </strong></p>
                                 <p>Select the changes: <strong>{formData?.service_type} </strong></p>
                                 <p>Ultimate holding company name  : <strong>{formData?.holding_company_name } </strong></p>
                                 <p>ACN/ABN of the ultimate holding company	 : <strong>{formData?.holding_acn} </strong></p>
                                 <p>Country of incorporation  : <strong>{formData?.country_incorporation } </strong></p>
                                 
                                 <p>Company to align with or vary from  : <strong>{formData?.align_form } </strong></p>
                                 <p>ABN or ACN: <strong>{formData?.abn2} </strong></p>
                                 <p>Country of incorporation (if not Australia)  : <strong>{formData?.country_incorporation } </strong></p>
                                 
                                 
                                 <p>Family name	 : <strong>{formData?.family_name2} </strong></p>
                                 <p>Given name : <strong>{formData?.given_name2 } </strong></p>
                                 <p>Company to align with or vary from : <strong>{formData?.align_form2 } </strong></p>
                                 <p>ACN/ABN : <strong>{formData?.abn4 } </strong></p>
                                 <p>Country of incorporation (if not Australia) : <strong>{formData?.c_incorporation } </strong></p>
                                 
                                
                                 
                               

                                 
                                 <Title>Declaration </Title> <hr/>
                                 
                                 <p>I authorize onecompany to manage all legal documentation related to ASIC and submit all required documentations on behalf of my company.  : <strong>{formData?.authorize_company === true ? "yes" : "no"} </strong></p>
                                 <p>Given name  : <strong>{formData?.g_name1} </strong></p>
                                 <p>Family name : <strong>{formData?.f_name1} </strong></p>
                                 <p>Designation within the Company  : <strong>{formData?.designation1} </strong></p>

                                 
                                 <Title>Billing Information</Title> <hr/>
                              {billing_info()}  


                         
                     </DTable>
     );
}
 
export default ChangeAnnualReviewDateDetails;