import React, { useEffect } from 'react'
import { Button, Descriptions, Breadcrumb, PageHeader,Tag,Card, Col, Row, Timeline, Empty } from 'antd';
import { useState } from 'react';
import { connect } from 'react-redux';
import { getServiceDetails,fileDownloader,updateOrderStatus,rejectOrder } from '../../actions/orders';
import GSTDetails from './details/gstDetails';
import { Title, Wrapper } from './details/detailStyle';
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import { CheckCircleFilled, CheckSquareOutlined, DownloadOutlined, EditOutlined, PlusCircleFilled, UploadOutlined, WarningOutlined } from '@ant-design/icons';
import ForeignCompanyRegDetails from './details/foreignCompanyRegDetails';
import ABNRegiDetails from './details/abnRegiDetails';
import UploadDocs from './uploadDoc';
import Remarks from '../../component/remark';
import VirtualOfficeDetails from './details/virtualOfficeDetails';
import BusinessNameDetails from './details/businessNameDetails';
import TFNDetails from './details/TFNDetails';
import PAYGDetails from './details/paygDetails';
import SoloTraderDetails from './details/soloTraderDetails';
import PartnershipPackageDetails from './details/partnershipPackageDetails';
import CompanyPackageDetails from './details/companyPackageDetails';
import RideSharingPackageDetails from './details/rideSharingPackageDetails';
import ChangeofCompanyNameDetails from './details/changeofCompanyNameDetails';
import ChangeofCompanyTypeDetails from './details/changeofCompanyTypeDetails';
import ReservationofCompanyNameDetails from './details/reservationofCompanyNameDetails';
import ExtensionofCompanyNameReservationDetails from './details/extensionofCompanyNameReservationDetails';
import ChangeofCompanyNameReservationDetails from './details/changeofCompanyNameReservationDetails';
import ChangeofCompanyConstitutionDetails from './details/changeofCompanyConstitutionDetails';
import RequestforCompanyDetailsDetails from './details/requestforCompanyDetailsDetails';
import CompanySolvencyStatementDetails from './details/companySolvencyStatementDetails';
import CorrectionofSubmittedFormDetails from './details/correctionofSubmittedFormDetails';
import CommenceSpecialPurposeCompanyStatusDetails from './details/commenceSpecialPurposeCompanyStatusDetails';
import AppointmentofNewOfficeholderDetails from './details/appointmentofNewOfficeholderDetails';
import NameChangeofOfficeholderDetails from './details/nameChangeofOfficeholderDetails';
import CeaseOfficeholderDetails from './details/ceaseOfficeholderDetails';
import AddNewRoletoExistingOfficeholderDetails from './details/addNewRoletoExistingOfficeholderDetails';
import AppointmentofAlternateDirectorDetails from './details/appointmentofAlternateDirectorDetails';
import AppointmentofanewdirectorDetails from './details/appointmentofanewdirectorDetails';
import ChangeNameandDetailsofDirectorsDetails from './details/changeNameandDetailsofDirectorsDetails';
import UpdateYourBusinessAddressDetails from './details/updateYourBusinessAddressDetails';
import ChangeShareholderDetailsDetails from './details/changeShareholderDetailsDetails';
import IssueofNewSharesDetails from './details/issueofNewSharesDetails';
import CancellationofsharesDetials from './details/cancellationofsharesDetials';
import ConversionofSharesIntoSmallerorLargerNumbersDetails from './details/conversionofSharesIntoSmallerorLargerNumbersDetails';
import DivisionorConversionofClassofSharesDetials from './details/divisionorConversionofClassofSharesDetials';
import ChangeCompanyDetailsResponsetoCompanyStatementDetails from './details/changeCompanyDetailsResponsetoCompanyStatementDetails';
import ReissueAnnualStatementDetails from './details/reissueAnnualStatementDetails';
import ChangeAnnualReviewDateDetails from './details/changeAnnualReviewDateDetails';
import NotificationofHalfYearlyReportsDetails from './details/notificationofHalfYearlyReportsDetails';
import RequestCompanyDebtReportDetails from './details/requestCompanyDebtReportDetails';
import DeregistrationofCompanyDetails from './details/deregistrationofCompanyDetails';
import AppointUsAsYourLocalAgentDetails from './details/appointUsAsYourLocalAgentDetails';
import NotificationofChangetoDirectorsDetails from './details/notificationofChangetoDirectorsDetails';
import NotificationofChangeofRegisteredOfficeDetails from './details/notificationofChangeofRegisteredOfficeDetails';
import NotifyChangeofNameConstitutionPowerofDirectorsDetails from './details/notifyChangeofNameConstitutionPowerofDirectorsDetails';
import AnnualReturnofAForeignCompanyDetails from './details/annualReturnofAForeignCompanyDetails';
import PreparationandLodgementofFinancialStatementsDetails from './details/preparationandLodgementofFinancialStatementsDetails';
import NotificationofDissolutionofCompanyDetails from './details/notificationofDissolutionofCompanyDetails';
import AnnualReviewPaymentDetails from './details/annualReviewPaymentDetails';
import BasDetails from './details/basDetails';
import CompanyTaxReturnDetails from './details/companyTaxReturnDetails';
import ReturnToWorkPolicyDetails from './details/returnToWorkPolicyDetails';
import PerformenceManagementPolicyDetails from './details/performenceManagementPolicyDetails';
import ITPolicyDetails from './details/itPolicyDetails';
import HarassmentBullyingPolicyDetails from './details/harassmentBullyingPolicyDetails';
import DrugAlcoholPolicyDetails from './details/drugAlcoholPolicyDetails';
import DiscriminationPolicyDetails from './details/discriminationPolicyDetails';
import BusinessPlanDetails from './details/businessPlanDetails';
import SoloTraderTaxReturnDetails from './details/soloTraderTaxReturnDetails';
import ConfidencialityPolicyDetails from './details/confidencialityPolicyDetails';
import ResidentialSubleaseDetails from './details/residentialSubleaseDetails';
import GiftDeedDetails from './details/giftDeedDetails';
import SaleOfGoodsAgreemnet from './details/saleOfGoodsAgreemnet';
import ServiceAgreementDetails from './details/serviceAgreementDetails';
import PowerOfAttorneyDetails from './details/powerOfAttorneyDetails';
import MemorandumOfUnderstandingDetails from './details/memorandumOfUnderstandingDetails';
import CyberSecutiryPolicyDetails from './details/cyberSecutiryPolicyDetails';
import EndUserLicenseAgreementDetails from './details/endUserLicenseAgreementDetails';
import AdvertisementAgreementDetails from './details/advertisementAgreementDetails';
import DevelopmentAgreementDetails from './details/developmentAgreementDetails';
import PrivacyPolicyDetails from './details/privacyPolicyDetails';
import TermAndConditionsGoodsDetails from './details/termAndConditionsGoodsDetails';
import LetterOfDemandDetails from './details/letterOfDemandDetails';
import LoanAgreementDetails from './details/loanAgreementDetails';
import CommercialSubleaseAgreementDetails from './details/commercialSubleaseAgreementDetails';
import CommercialLeaseAgreementDetails from './details/commercialLeaseAgreementDetails';
import CompanyConstitutionDetails from './details/companyConstitutionDetails';
import PartnershipAgreementDetails from './old_details/partnershipAgreementDetails';
import ConfidentialityAgreementDetails from './details/confidentialityAgreementDetails';
import JointVentureAgreementDetails from './details/jointVentureAgreementDetails';
import EmploymentAgreementDetails from './details/employmentAgreementDetails';
import ShareholderAgreementDetails from './details/shareholderAgreementDetails';
import TermAndConditionsServiceDetails from './details/termAndConditionsServiceDetails';
import GrievancePolicyDetials from './details/grievancePolicyDetials';
import GarmentsUniformsDetails from './details/garmentsUniformsDetails';
import LogoDesignDetails from './details/logoDesignDetails';
import CompanySealDetails from './details/companySealDetails';
import MinuteBookDetails from './details/minuteBookDetails';
import ShareCertificateDetails from './details/shareCertificateDetails';
import ResidentailTenancyAgreementDetails from './details/residentailTenancyAgreementDetails';
import PartnershipTaxReturnDetails from './details/partnershipTaxReturnDetails';
import CompanyRegistrationDetails from './details/companyRegistration';
import history from '../../history';
const OrderDetails = ({location,getServiceDetails,fileDownloader,updateOrderStatus,rejectOrder}) => {
    const [data, setData] = useState([])
    const [file, setFile] = useState(null)
    const [visible, setVisible] = useState(false);
    const [remark, setRemark] = useState(false);
    const [reject, setReject] = useState(false);
    const [order, setOrder] = useState(null);
    useEffect(async() => {
        const order = new URLSearchParams(location.search).get("order");
        setOrder(order)
        const res = await getServiceDetails(order)
        setData(res)
    }, [])
    const showDrawer = () => {
      setVisible(true);
    };
    const onClose = () => {
      setVisible(false);
    };
    const onRemarkClose = () => {
      setRemark(false);
    };
    const onRejectClose = () => {
      setReject(false);
    };
    const handleFileDownloader = async (file) => {
      const res = await fileDownloader(file)
      setFile(res)
    }
    const handleRefresh = async () => {
      const res = await getServiceDetails(data?.order_id)
        setData(res)
    }
    
    const makeLocalTime = (date) => {
      let hours = date.split('T')[1].split(':')[0];
      let minutes = date.split('T')[1].split(':')[1];
      var am = true;
      if (hours > 12) {
          am = false;
          hours -= 12
      } else if (hours == 12) {
          am = false;
      } else if (hours == 0) {
          hours = 12
      }
      return date.split('T')[0] + "," + hours+":"+minutes + " " + (am ? "A.M" : "P.M")
    }
    return ( 
      <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{padding:"10px 0px"}}>
              <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
              <Breadcrumb.Item>Order Details</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeader
              ghost={false}
              onBack={() => window.history.back()}
              title="Order Details"
              subTitle="Order Management"
            >
            </PageHeader>
          <Wrapper>
            <h2>Service Name : {data?.data?.service_name}</h2>
            <h3>Total Bill: ${data?.total_cost}</h3>
            <Tag color="#2db7f5">Order ID {data?.order_id}</Tag>
            {data?.status === 'Pending' ? <Tag color="#f39c12">{data?.status}</Tag> : null}
            {data?.status === 'Rejected' ? <Tag color="#f50">{data?.status}</Tag> : null}
            {data?.status === 'Processing' ? <Tag color="#2db7f5">{data?.status}</Tag> : null}
            {data?.status === 'Done' ? <Tag color="#1ad1a3">{data?.status}</Tag> : null}

            {/* {data?.status === 'Pending' ? <Tag color="#f39c12">{data?.status}</Tag> : <Tag color="#108ee9">{data?.status}</Tag>} */}
            {data?.payment_status === 'Unpaid' ? <Tag color="#f50">{data?.payment_status}</Tag> : <Tag color="#108ee9">{data?.payment_status}</Tag>}
            {data?.payment_review === false ? <Tag color="#f50">Unreviewed</Tag> : <Tag color="#108ee9">Reviewed</Tag>}

            {/* ====Reject=== */}
            {data?.status !== 'Rejected' ?
              <Button type="ghost" icon={<WarningOutlined />} size="small" style={{float: 'right',backgroundColor: '#f04545',color:'#fff'}} onClick={()=>setReject(true)}>Reject</Button>
            :null}
            
            {data?.payment_status === 'Paid' ?
              data?.status === 'Processing' ?
                data?.payment_review === true ?
                  <Button type="ghost" icon={<CheckSquareOutlined />} size="small" style={{float: 'right',backgroundColor: 'green',color:'#fff'}} onClick={()=>setRemark(true)}>Make Done</Button>
                :null
              :null
            :null}
             {data?.data?.service_name === 'Company Registration' ?
              <Button type="ghost" icon={<EditOutlined />} size="small" style={{float: 'right',backgroundColor: '#f04545',color:'#fff'}} onClick={()=>history.push(`/edit-order?order=${order}`)}>Edit</Button>
             : null}

            <Tag color="#cd2df5" >{data?.created_at ? makeLocalTime(data?.created_at) : null}</Tag>
            <br />
            <br />
        {data ?
        <>
        <Title>Client Information</Title>
        <Descriptions>
          <Descriptions.Item label="ID"><strong>{data?.client?.client_id.toUpperCase()}</strong></Descriptions.Item>
          <Descriptions.Item label="FIRST NAME"><strong>{data?.client?.first_name.toUpperCase()}</strong></Descriptions.Item>
          <Descriptions.Item label="LAST NAME"><strong>{data?.client?.last_name.toUpperCase()}</strong></Descriptions.Item>
          <Descriptions.Item label="MOBILE"><strong>{data?.client?.mobile_number}</strong></Descriptions.Item>
          <Descriptions.Item label="EMAIL"><strong>{data?.client?.email.toUpperCase()}</strong></Descriptions.Item>
        </Descriptions>
    
        {data?.data?.service_name === 'Company Registration' ? <CompanyRegistrationDetails newData={data} />:null}
        {data?.data?.service_name === 'ABN Registration' ? <ABNRegiDetails data={data} />:null}
        {data?.data?.service_name === 'Foreign Company Registration' ? <ForeignCompanyRegDetails data={data} />:null}
        {data?.data?.service_name === 'Virtual Office' ? <VirtualOfficeDetails data={data} />:null}
        {data?.data?.service_name === 'Registered Agent Service' ? <VirtualOfficeDetails data={data} />:null}
        {data?.data?.service_name === 'Business Name Registration' ? <BusinessNameDetails data={data} />:null}
        {data?.data?.service_name === 'TFN Registration (Business)' ? <TFNDetails data={data} />:null}
        {data?.data?.service_name === 'GST Registration' ? <GSTDetails data={data} />:null}
        {data?.data?.service_name === 'PAYG Registration' ? <PAYGDetails data={data} />:null}

        {data?.data?.service_name === 'Solo Trader Package' ? <SoloTraderDetails data={data} />:null}
        {data?.data?.service_name === 'Partnership Package' ? <PartnershipPackageDetails data={data} />:null}
        {data?.data?.service_name === 'Company Package' ? <CompanyPackageDetails data={data} />:null}
        {data?.data?.service_name === 'Ride-S hare Taxi Package' ? <RideSharingPackageDetails data={data} />:null}
        
        {/* ==========Filling Obligation========= */}
        {data?.data?.service_name === 'Change of Company Name' ? <ChangeofCompanyNameDetails data={data} />:null}
        {data?.data?.service_name === 'Change of Company Type' ? <ChangeofCompanyTypeDetails data={data} />:null}
        {data?.data?.service_name === 'Reservation of Company Name' ? <ReservationofCompanyNameDetails data={data} />:null}
        {data?.data?.service_name === 'Change of Company Name Reservation' ? <ChangeofCompanyNameReservationDetails data={data} />:null}
        {data?.data?.service_name === 'Extension of Company Name Reservation' ? <ExtensionofCompanyNameReservationDetails data={data} />:null}
        {data?.data?.service_name === 'Change of Company Constitution' ? <ChangeofCompanyConstitutionDetails data={data} />:null}
        {data?.data?.service_name === 'Request for Company Details' ? <RequestforCompanyDetailsDetails data={data} />:null}
        {data?.data?.service_name === 'Company Solvency Statement' ? <CompanySolvencyStatementDetails data={data} />:null}
        {data?.data?.service_name === 'Correction of Submitted Form' ? <CorrectionofSubmittedFormDetails data={data} />:null}
        {data?.data?.service_name === 'Commence Special Purpose Company Status' ? <CommenceSpecialPurposeCompanyStatusDetails data={data} />:null}
        
        {data?.data?.service_name === 'Appointment of New Officeholder' ? <AppointmentofNewOfficeholderDetails data={data} />:null}
        {data?.data?.service_name === 'Name Change of Officeholder' ? <NameChangeofOfficeholderDetails data={data} />:null}
        {data?.data?.service_name === 'Cease Officeholder' ? <CeaseOfficeholderDetails data={data} />:null}
        {data?.data?.service_name === 'Add New Role to Existing Officeholder' ? <AddNewRoletoExistingOfficeholderDetails data={data} />:null}
        {data?.data?.service_name === 'Appointment of Alternate Director' ? <AppointmentofAlternateDirectorDetails data={data} />:null}
        {data?.data?.service_name === 'Appointment of a new director' ? <AppointmentofanewdirectorDetails data={data} />:null}
        {data?.data?.service_name === 'Change Name and Details of Directors' ? <ChangeNameandDetailsofDirectorsDetails data={data} />:null}
        {data?.data?.service_name === 'Update Your Business Address' ? <UpdateYourBusinessAddressDetails data={data} />:null}

        {data?.data?.service_name === 'Change Shareholder Details' ? <ChangeShareholderDetailsDetails data={data} />:null}
        {data?.data?.service_name === 'Issue of New Shares' ? <IssueofNewSharesDetails data={data} />:null}
        {data?.data?.service_name === 'Cancellation of Shares' ? <CancellationofsharesDetials data={data} />:null}
        {data?.data?.service_name === 'Conversion of Shares Into Smaller or Larger Numbers' ? <ConversionofSharesIntoSmallerorLargerNumbersDetails data={data} />:null}
        {data?.data?.service_name === 'Division or Conversion of a Class of Shares' ? <DivisionorConversionofClassofSharesDetials data={data} />:null}
        
        
        {data?.data?.service_name === 'Annual Review Payment' ? <AnnualReviewPaymentDetails data={data} />:null}
        {data?.data?.service_name === 'Change Company Details in Response to a Company Statement' ? <ChangeCompanyDetailsResponsetoCompanyStatementDetails data={data} />:null}
        {data?.data?.service_name === 'Re-issue Annual Statement' ? <ReissueAnnualStatementDetails data={data} />:null}
        {data?.data?.service_name === 'Change Annual Review Date' ? <ChangeAnnualReviewDateDetails data={data} />:null}

        {data?.data?.service_name === 'Notification of Half Yearly Reports' ? <NotificationofHalfYearlyReportsDetails data={data} />:null}
        {data?.data?.service_name === 'Request Company Debt Report' ? <RequestCompanyDebtReportDetails data={data} />:null}
        
        {data?.data?.service_name === 'Deregistration of Company' ? <DeregistrationofCompanyDetails data={data} />:null}
        
        {data?.data?.service_name === 'Appoint Us As Your Local Agent' ? <AppointUsAsYourLocalAgentDetails data={data} />:null}
        {data?.data?.service_name === 'Notification of Change to Directors' ? <NotificationofChangetoDirectorsDetails data={data} />:null}
        {data?.data?.service_name === 'Notification of Change of Registered Office or Office Hours' ? <NotificationofChangeofRegisteredOfficeDetails data={data} />:null}
        {data?.data?.service_name === 'Notify Change of Name Constitution Power of Directors' ? <NotifyChangeofNameConstitutionPowerofDirectorsDetails data={data} />:null}
        {data?.data?.service_name === 'Annual Return of A Foreign Company' ? <AnnualReturnofAForeignCompanyDetails data={data} />:null}
        {data?.data?.service_name === 'Preparation and Lodgment of Financial Statements from' ? <PreparationandLodgementofFinancialStatementsDetails data={data} />:null}
        {data?.data?.service_name === 'Notification of Dissolution of Company' ? <NotificationofDissolutionofCompanyDetails data={data} />:null}
        
        {/* ======Legal Solution====== */}
        {data?.data?.service_name === 'Shareholder Agreement' ? <ShareholderAgreementDetails data={data} />:null}
        {data?.data?.service_name === 'Employment Agreement' ? <EmploymentAgreementDetails data={data} />:null}
        {data?.data?.service_name === 'Joint Venture (JV) Agreement' ? <JointVentureAgreementDetails data={data} />:null}
        {data?.data?.service_name === 'Confidentiality Agreement-NDA' ? <ConfidentialityAgreementDetails data={data} />:null}
        {data?.data?.service_name === 'Partnership Agreement' ? <PartnershipAgreementDetails data={data} />:null}
        {data?.data?.service_name === 'Company Constitution' ? <CompanyConstitutionDetails data={data} />:null}

        {data?.data?.service_name === 'Commercial Lease Agreement' ? <CommercialLeaseAgreementDetails data={data} />:null}
        {data?.data?.service_name === 'Commercial Sublease Agreement' ? <CommercialSubleaseAgreementDetails data={data} />:null}
        {data?.data?.service_name === 'Loan Agreement' ? <LoanAgreementDetails data={data} />:null}
        {data?.data?.service_name === 'Letter of Demand' ? <LetterOfDemandDetails data={data} />:null}

        {data?.data?.service_name === 'Website Terms & Conditions (Goods)' ? <TermAndConditionsGoodsDetails data={data} />:null}
        {data?.data?.service_name === 'Website Terms & Conditions (Services)' ? <TermAndConditionsServiceDetails data={data} />:null}
        {data?.data?.service_name === 'Privacy Policy' ? <PrivacyPolicyDetails data={data} />:null}
        {data?.data?.service_name === 'Website Development Agreement' ? <DevelopmentAgreementDetails data={data} />:null}
        {data?.data?.service_name === 'Web Advertisement Agreement' ? <AdvertisementAgreementDetails data={data} />:null}
        {data?.data?.service_name === 'End User License Agreement' ? <EndUserLicenseAgreementDetails data={data} />:null}
        {data?.data?.service_name === 'Cyber Security Policy' ? <CyberSecutiryPolicyDetails data={data} />:null}

        {data?.data?.service_name === 'Memorandum of Understanding' ? <MemorandumOfUnderstandingDetails data={data} />:null}
        {data?.data?.service_name === 'Power of Attorney' ? <PowerOfAttorneyDetails data={data} />:null}
        {data?.data?.service_name === 'Service Agreement' ? <ServiceAgreementDetails data={data} />:null}
        {data?.data?.service_name === 'Sale of Goods Agreements' ? <SaleOfGoodsAgreemnet data={data} />:null}
        {data?.data?.service_name === 'Gift Deed' ? <GiftDeedDetails data={data} />:null}
        {data?.data?.service_name === 'Residential Lease Agreement' ? <ResidentailTenancyAgreementDetails data={data} />:null}
        {data?.data?.service_name === 'Residential Sublease Agreement' ? <ResidentialSubleaseDetails data={data} />:null}

        {/* ===========HR Solution============== */}
        {data?.data?.service_name === 'Confidentiality Policy' ? <ConfidencialityPolicyDetails data={data} />:null}
        {data?.data?.service_name === 'Business Plan' ? <BusinessPlanDetails data={data} />:null}
        {data?.data?.service_name === 'Discrimination Policy' ? <DiscriminationPolicyDetails data={data} />:null}
        {data?.data?.service_name === 'Drug & Alcohol Policy' ? <DrugAlcoholPolicyDetails data={data} />:null}
        {data?.data?.service_name === 'Grievance Policy' ? <GrievancePolicyDetials data={GrievancePolicyDetials} />:null}
        {data?.data?.service_name === 'Harassment & Bullying Policy' ? <HarassmentBullyingPolicyDetails data={data} />:null}
        {data?.data?.service_name === 'IT Policy' ? <ITPolicyDetails data={data} />:null}
        {data?.data?.service_name === 'Performance Management Policy' ? <PerformenceManagementPolicyDetails data={data} />:null}
        {data?.data?.service_name === 'Return to Work Policy' ? <ReturnToWorkPolicyDetails data={data} />:null}

        {/* ===========ID Solution============== */}
        {data?.data?.service_name === 'Logo Design' ? <LogoDesignDetails data={data} />:null}

        {/* ===========Tax============= */}
        {data?.data?.service_name === 'BAS' ? <BasDetails data={data} />:null}
        {data?.data?.service_name === 'Company Tax Return from' ? <CompanyTaxReturnDetails data={data} />:null}
        {/* {data?.data?.service_name === 'Individual Tax Return from' ? <SoloTraderTaxReturnDetails data={data} />:null} */}
        {data?.data?.service_name === 'Partnership Tax Return from' ? <PartnershipTaxReturnDetails data={data} />:null}

        {data?.data?.service_name === 'Garments, Uniforms' ? <GarmentsUniformsDetails data={data} />:null}
        {data?.data?.service_name === 'Company Seal' ? <CompanySealDetails data={data} />:null}
        {data?.data?.service_name === 'Minute Book' ? <MinuteBookDetails data={data} />:null}
        {data?.data?.service_name === 'Share Certificate' ? <ShareCertificateDetails data={data} />:null}
      </>
    :null}
    <br /><br />
    {data?.processed_files ?
    <>
    <Title>Other Documents from admin</Title>
    <div className="site-card-wrapper">
        <Row gutter={16}>
          {data?.processed_files.map((item,id) => (
          <Col span={8} key={id}>
            <Card title={item?.title} bordered={false}>
              <Button type="primary" onClick={()=>handleFileDownloader(item?.file)} shape="round" icon={<DownloadOutlined />} size="middle">
                Download
              </Button>
            </Card>
          </Col>
          ))}
          <Col span={8}>
            <Card title="Upload More Documents" bordered={false} onClick={showDrawer}>
              <Button type="link" shape="round" icon={<UploadOutlined />} size="middle">
                Upload
              </Button>
            </Card>
          </Col>
        </Row>
    </div>
    
    <Title>Logs</Title>
        <br />
        {data?.logs ? 
          <Timeline mode="left">
            {data.logs.map((item,index) => (
            <Timeline.Item label={makeLocalTime(item?.created_at)} keys={index}>{item?.message}</Timeline.Item>
            ))}
          </Timeline>
        : <Empty /> }
    </>
    :null}
          
    </Wrapper>
      <Remarks title=" " btn="Update" placement="bottom" visible={remark} fnc={onRemarkClose} id={data?.order_id} delFnc = {updateOrderStatus} refresh = {handleRefresh} />
      <Remarks title=" " btn="Reject" placement="bottom" visible={reject} fnc={onRejectClose} id={data?.order_id} delFnc = {rejectOrder} refresh = {handleRefresh} />
      <UploadDocs onClose={onClose} visible={visible} order={data?.order_id} />
    </Content>
     );
}
 
export default connect(null, {getServiceDetails,fileDownloader,updateOrderStatus,rejectOrder})(OrderDetails);