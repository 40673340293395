import React from 'react'
import { Title,DTable} from './detailStyle';

const EmploymentAgreementDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
 
                        
                            
                                 <Title>Basics</Title> <hr/>
                                 <p>Employment type : <strong>{formData?.date_of_agreement} </strong></p>
                                 <p>Employment Jurisdiction : <strong>{formData?.employement_jurisdiction} </strong></p>
                                 <p>Employment commence date : <strong>{formData?.employe_commence_date} </strong></p>
                                 <Title>Employment location </Title>
                                 <p>Unit/Level (optional) : <strong>{formData?.employe_unit} </strong></p>
                                 <p>Street address : <strong>{formData?.employe_street} </strong></p>
                                 <p>Suburb : <strong>{formData?.employe_subrub}</strong></p>
                                 <p>State : <strong>{formData?.employe_state}</strong></p>
                                 <p>Postcode : <strong>{formData?.employe_postcode}</strong></p>
                            
                            
                              
                                 <Title>Parties</Title> <hr/>

                                 <Title>Employer</Title>
                                 <p>Employer Type: <strong>{formData?.employer_type} </strong></p>
                                 <p>Name : <strong>{formData?.employer_name} </strong></p>
                                 <p>Unit/Level (optional) : <strong>{formData?.employer_unit} </strong></p>
                                 <p>Street address : <strong>{formData?.employer_street} </strong></p>
                                 <p>Suburb : <strong>{formData?.employer_subrub}</strong></p>
                                 <p>State : <strong>{formData?.employer_state}</strong></p>
                                 <p>Postcode : <strong>{formData?.employer_postcode}</strong></p>
                                 <p>Phone number : <strong>{formData?.employer_phone}</strong></p>
                                 <p>Email : <strong>{formData?.employer_email}</strong></p>
                                 <p>Fax : <strong>{formData?.employer_fax}</strong></p>

                                 <Title>Employee</Title>
                                 <p>Employee Given Name: <strong>{formData?.employee_g_name} </strong></p>
                                 <p>Employee Family Name: <strong>{formData?.employee_f_name} </strong></p>
                                 <p>Unit/Level (optional) : <strong>{formData?.employee_unit} </strong></p>
                                 <p>Street address : <strong>{formData?.employee_street} </strong></p>
                                 <p>Suburb : <strong>{formData?.employee_subrub}</strong></p>
                                 <p>State : <strong>{formData?.employee_state}</strong></p>
                                 <p>Postcode : <strong>{formData?.employee_postcode}</strong></p>
                                 <p>Mobile number : <strong>{formData?.employee_mobile}</strong></p>
                                 <p>Email : <strong>{formData?.employee_email}</strong></p>
                                  
                              
                            
                              
                                 <Title>Job details </Title> <hr/>
                                 <p>Job title : <strong> {formData?.job_title } </strong></p>
                                 <p>Job description : <strong> {formData?.job_des } </strong></p>
                                 <p>Probationary period : <strong> {formData?.probationary_period} </strong></p>
                                 <Title>Compensation</Title>
                                 <p>Compensation type : <strong> {formData?.compensation_type} </strong></p>
                                 <p>Compensation amount : <strong> {formData?.compensation_amount} </strong></p>
                                 <p>Pay period : <strong> {formData?.pay_period}</strong></p>
                                 <p>Daily work hours : <strong> {formData?.work_hours} </strong></p>
                                 <p>Annual leave entitlements : <strong> {formData?.annual_leave} </strong></p>
                                 <p>Will the employer provide additional superannuation payments beyond the required minimum? : <strong> {formData?.will_provide_additional ? 'Yes' : 'No' } </strong></p>
                                 <p>Post-employment restrictions : <strong> {formData?.post_employement_restriction} </strong></p>
                                 {formData?.post_employement_restriction === 'Confidentiality clause' ?
                                 <p>Ex-employee restricted from communicating confidential information obtained during their employment for: <strong> {formData?.for_confidential_clause} </strong></p>
                                 : null}
                                 {formData?.post_employement_restriction === 'Non-compete clause' ?
                                    <>
                                       <p>Ex-employee prevented from unfairly competing against the employer: <strong> {formData?.for_non_compete_clause} </strong></p>
                                       <p>Where is the employee restricted from competing?: <strong> {formData?.where_restricted} </strong></p>
                                    </>
                                 : null}
                                 {formData?.post_employement_restriction === 'Non-solicitation clause' ?
                                 <p>Ex-employee prevented from recruiting any of the employer's current employees or contractors for: <strong> {formData?.for_non_solicitation_clause} </strong></p>
                                 : null}
                                 <p>Termination notice period: <strong> {formData?.termination_notice} </strong></p>
                                 <p>Employee resignation notice period : <strong> {formData?.resignation_period} </strong></p>
                                 <p>Contract signing date: <strong> {formData?.contract_date} </strong></p>
                              
                             
                              
                                 <Title>Agreement Type</Title> <hr/>
                                 <p>Your product : <strong> {formData?.product_type } </strong></p>
                                 <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                                 <p>Describe if you have any other specific need that you want us to incorporate in the contract : <strong> {formData?.describe_other_specific } </strong></p>
                              
                              
                              <Title>Billing Information</Title> <hr/>
                               {billing_info()}
                     </DTable>
     );
}
 
export default EmploymentAgreementDetails;