import moment from 'moment';
import React, { useEffect, useState } from 'react';

function TimeSince({date, status, flag}) {
  const [seconds, setSeconds] = useState(0);
  const [differ, setDiffer] = useState(0);
  const calculateSeconds = () => {
    const startDate = new Date(date).getTime();
    const now = new Date().getTime();
    const diff = now - startDate;
    setSeconds(Math.floor(diff / 1000));
  };
  
  const getDayDiff = (val) => {
    // const date1 = new Date();
    // const date2 = new Date(val);
    // const diffDays = parseInt((moment(date2).format('L') - moment(date1).format('L')) / (1000 * 60 * 60 * 24), 10); 
    // console.log(moment(date1).format('L'))
    // console.log(moment(date2).format('l'))
    // console.log(moment(moment(date2).format('L'), "YYYYMMDD").fromNow);
    const date2 = moment()
    const date1 = moment(val)
    const diff = date2.diff(date1, 'hours')  
    setDiffer(diff) 
  }
    useEffect(() => {
      getDayDiff(date);
      calculateSeconds();
    }, [])
    
  // Calculate the number of seconds between the input date and the current time
  

  // Format the seconds as years, months, days, hours, minutes, and seconds
  const formatTime = () => {
    const yearInSeconds = 31536000;
    const monthInSeconds = 2592000;
    const dayInSeconds = 86400;
    const hourInSeconds = 3600;
    const minuteInSeconds = 60;
    const years = Math.floor(seconds / yearInSeconds);
    const months = Math.floor((seconds % yearInSeconds) / monthInSeconds);
    const days = Math.floor(((seconds % yearInSeconds) % monthInSeconds) / dayInSeconds);
    const hours = Math.floor((((seconds % yearInSeconds) % monthInSeconds) % dayInSeconds) / hourInSeconds);
    const minutes = Math.floor(((((seconds % yearInSeconds) % monthInSeconds) % dayInSeconds) % hourInSeconds) / minuteInSeconds);
    const remainingSeconds = (((seconds % yearInSeconds) % monthInSeconds) % dayInSeconds) % hourInSeconds % minuteInSeconds;
    if( years !== 0 && months !== 0 && days !== 0) return `${years} years, ${months} months, ${days} days, ${hours} hours, ${minutes} minutes`;
    if( years == 0 && months !== 0 && days !== 0) return `${months} months, ${days} days, ${hours} hours, ${minutes} minutes`;
    else if( years == 0 && months == 0 && days !== 0) return `${days} days, ${hours} hours, ${minutes} minutes`;
    else if( years == 0 && months == 0 && days == 0 && hours !== 0 ) return `${hours} hours, ${minutes} minutes`;
    else return `${minutes} minutes`;
  };
  // and ${remainingSeconds} seconds
//   calculateSeconds();

  return (
    <div>
      {console.log(date)}
      {status !== 'completed' 
      ? 
      <p style={{ fontWeight: 'bold', 
        color:
          (differ >= 3 && differ < 6) ? 'yellow' :
          differ >= 6 ? 'red' :
          null }}>
          {formatTime()}
      </p>
      :
        null
      }
      {status === 'completed' 
      ? 
        <p style={{ fontWeight: 'bold', color: 'lightgray' }}>{formatTime()}</p>
      :
        null
      }
      
    </div>
  );
}

export default TimeSince;