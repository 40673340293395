import React from 'react'
import { Title,DTable} from './detailStyle';


const SoloTraderDetails = ({data}) => {
     const formData = data?.data?.form_data;
     const bill = data?.data?.bill_info;
     const billing_info = () => {
     let res  = Object.keys(bill).map((item) => {
          return (<p>{item} : <strong>${bill[item] }</strong></p>)
     })
     return res;
     }
    return ( 
             <DTable>
                    <Title >ABN Registration</Title> <hr/>
                    <p>Given Name : <strong>{formData?.given_name} </strong></p>
                    <p>Family Name : <strong>{formData?.family_name} </strong></p>
                    <p>Do you have a former name? : <strong>{formData?.has_name_reservation? 'Yes' : 'No'} </strong></p>
                    <p>Given Name : <strong>{formData?.given_name1} </strong></p>
                    <p>Family Name : <strong>{formData?.family_name1} </strong></p>
                    
                    <p>Email address : <strong>{formData?.email} </strong></p>
                    <p>Phone number : <strong>{formData?.phone_number } </strong></p>
                    <p>Date of birth : <strong>{formData?.date_of_birth}</strong></p>
                    <p>Country of birth : <strong>{formData?.country_of_birth }</strong></p>
                    <p>State of birth : <strong>{formData?.state_of_birth} </strong></p>
                    <p>City of birth : <strong>{formData?.city_of_birth} </strong></p>
                    <Title>Home address</Title>
                    <p>Unit/Level : <strong>{formData?.unit} </strong></p>
                    <p>Street address : <strong>{formData?.street_address } </strong></p>
                    <p>Suburb : <strong>{formData?.suburb}</strong></p>
                    <p>State : <strong>{formData?.state }</strong></p>
                    <p>Post code : <strong>{formData?.postcode} </strong></p>
                    <p>Australia : <strong>{formData?.australia} </strong></p>
                    <p>State : <strong>{formData?.state }</strong></p>
                    
                    <p>Australia : <strong>{formData?.australia} </strong></p>
                    <p>I am an Australian resident for tax purpose : <strong>{formData?.declaration === true ? 'Yes' : 'No'} </strong></p>
                    <Title>Business address</Title>
                    <p>Unit/Level : <strong>{formData?.unit1} </strong></p>
                    <p>Street address : <strong>{formData?.street_address1 } </strong></p>
                    <p>Suburb : <strong>{formData?.suburb1}</strong></p>
                    <p>State : <strong>{formData?.state1 }</strong></p>
                    <p>Post code : <strong>{formData?.postcode1} </strong></p>
                    
               
                    <Title >Business Details</Title> <hr/>
                    <p>ABN activation date : <strong>{formData?.abn_date} </strong></p>
                    <p>Industry sector : <strong>{formData?.industry_sector} </strong></p>
                    <p>Describe your main business activity : <strong>{formData?.business_activity} </strong></p>
                    <Title>Business Name</Title>
                    <p>Provide your Proposed Business Name  : <strong>{formData?.p_business_name} </strong></p>
                    <p>Propose your second Business Name : <strong>{formData?.p_business_name2} </strong></p>
                    <p>Propose your Third Business Name : <strong>{formData?.p_business_name3} </strong></p>
                    
               
               <Title >GST Registration</Title> <hr/>
               <p>Estimated turn-over  : <strong>{formData?.estimmted_turn_over} </strong></p>
               <p>When will you send GST results to the ATO (?) : <strong>{formData?.gst_result} </strong></p>
               <p>How often would like to report to the Tax Office?: <strong>{formData?.tax_office} </strong></p>
               <p>GST account commence date : <strong>{formData?.gst_account} </strong></p>
               <p>Do you import goods and services into Australia: <strong>{formData?.good_serviceholiday_marks} </strong></p>
               <p>Does your business have employee : <strong>{formData?.business_employee==="true" ?"yes":"no"} </strong></p>
               <p>Do you wish to sign up for PAYG through us: <strong>{formData?.payg_us === "true" ?"yes":"no"} </strong></p>
                    {formData?.payg_us ==="true"?
                    <>
                    <p>When do you want to commence : <strong>{formData?.want_commence} </strong></p>
                    <p>How many employees are you going to pay: <strong>{formData?.going_pay} </strong></p>
                    <p>How much do you expect to withhold from the payments each year? : <strong>{formData?.payments_each_year} </strong></p>
                    <p>Are you employing any working holiday makers?: <strong>{formData?.holiday_marks==="true" ?"yes":"no"} </strong></p>
                    <p>How are you going to provide your PAYG withholding payment annual summary report to the ATO?: <strong>{formData?.ato} </strong></p>
                    <p>How will you provide payment summaries to your payees?: <strong>{formData?.provide_payment} </strong></p>
                    <p>Will you pay royalties, dividends or interest to non-residents?: <strong>{formData?.non_residents==="true" ?"yes":"no"} </strong></p>
                    <p>What is the main industry that you operate in?	: <strong>{formData?.operate_in} </strong></p>
                    <p>Describe your business activity?: <strong>{formData?.b_activity} </strong></p>
                    
                    </>:null}
               <p>I declare that the information I have given in this application is accurate, full and true: <strong>{formData?.accurate===true?"yes":"no"} </strong></p> 
                            
               <Title>Billing Information</Title> <hr/>
               {billing_info()}   
     </DTable>
     );
}
 
export default SoloTraderDetails;