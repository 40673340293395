import React from 'react'
import { Title,DTable} from './detailStyle';

const VirtualOfficeDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
        let res  = Object.keys(bill).map((item) => {
             return (<p>{item} : <strong>${bill[item]}</strong> </p>)
        })
        return res;
   }
   return ( 
            <DTable>

               <Title>Company details</Title> <hr/>
               <p>Name of the Company  : <strong>{formData?.company_name} </strong></p>
               <p>ACN/ABN : <strong>{formData?.abn_acn} </strong></p>
            
               <Title>Declaration</Title> <hr/>
               <p>Declarant Name : <strong>{formData?.declarant_name} </strong></p>
               <p>Designation within the Company : <strong>{formData?.designation} </strong></p>
               <p>Contact Number : <strong>{formData?.contact_number} </strong></p>
                     
               <Title style={{textAlign: 'left'}}>Billing Information</Title> <hr/>
               {billing_info()}
            </DTable>
     );
}
 
export default VirtualOfficeDetails;