import React from 'react'
import { Title,DTable  } from './detailStyle';

const companySealDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
            <DTable>  
               <Title>Contact Details</Title> <hr/>
               <h3 style={{textAlign: 'left'}}>Contact Details</h3> <hr/>
               <p>First Name : <strong>{formData?.first_name} </strong></p>
               <p>Last Name : <strong>{formData?.last_name} </strong></p>
               <p>Organization Name : <strong>{formData?.organization_name} </strong></p>
               <p>Organization ABN/ACN : <strong>{formData?.organization_abn_acn} </strong></p>
               <h3 style={{textAlign: 'left'}}>Delivery Address</h3> <hr/>
               <p>House Number : <strong>{formData?.d_house_number} </strong></p>
               <p>Street number : <strong>{formData?.d_street_address} </strong></p>
               <p>City : <strong>{formData?.d_city} </strong></p>
               <p>State : <strong>{formData?.d_state} </strong></p>
               <p>Postcode : <strong>{formData?.d_postcode} </strong></p>
               <p>Country : <strong>{formData?.d_country} </strong></p>
               <p>Phone No : <strong>{formData?.d_phone} </strong></p>
               <p>Email : <strong>{formData?.d_email} </strong></p>
                              
               <Title>Product Details</Title> <hr/>
               <br />
               {formData?.seal === 'Common Seal' ?
               <>
                  <p>Type of seal : <strong>{formData?.product_type} </strong></p>
                  <p>Text for 1st line: <strong>{formData?.line1} </strong></p>
                  <p>Font for 1st line: <strong>{formData?.font2} </strong></p>
                  <p>Size for 1st line: <strong>{formData?.size3} </strong></p>
                  <p>Style for 1st line: <strong>{formData?.style4} </strong></p>
                  <br />
                  
                  <p>Text for 2nd line: <strong>{formData?.line2} </strong></p>
                  <p>Font for 2nd line: <strong>{formData?.font2} </strong></p>
                  <p>Size for 2nd line: <strong>{formData?.size2} </strong></p>
                  <p>Style for 2nd line: <strong>{formData?.style2} </strong></p>
                  <br />
                  
                  <p>Text for 3rd line: <strong>{formData?.line3} </strong></p>
                  <p>Font for 3rd line: <strong>{formData?.font3} </strong></p>
                  <p>Size for 3rd line: <strong>{formData?.size3} </strong></p>
                  <p>Style for 3rd line: <strong>{formData?.style3} </strong></p>
                  <br />
                  
                  <p>Text for 4th line: <strong>{formData?.line4} </strong></p>
                  <p>Font for 4th line: <strong>{formData?.font4} </strong></p>
                  <p>Size for 4th line: <strong>{formData?.size4} </strong></p>
                  <p>Style for 4th line: <strong>{formData?.style4} </strong></p>
                  <br />
               </>
               : null}
               {formData?.seal === 'Rubber Seal' ?
               <>
                  <p>Text for 1st line: <strong>{formData?.line1} </strong></p>
                  <p>Font for 1st line: <strong>{formData?.font2} </strong></p>
                  <p>Size for 1st line: <strong>{formData?.size3} </strong></p>
                  <p>Style for 1st line: <strong>{formData?.style4} </strong></p>
                  <br />
                  
                  <p>Text for 2nd line: <strong>{formData?.line2} </strong></p>
                  <p>Font for 2nd line: <strong>{formData?.font2} </strong></p>
                  <p>Size for 2nd line: <strong>{formData?.size2} </strong></p>
                  <p>Style for 2nd line: <strong>{formData?.style2} </strong></p>
                  <br />
                  
                  <p>Text for 3rd line: <strong>{formData?.line3} </strong></p>
                  <p>Font for 3rd line: <strong>{formData?.font3} </strong></p>
                  <p>Size for 3rd line: <strong>{formData?.size3} </strong></p>
                  <p>Style for 3rd line: <strong>{formData?.style3} </strong></p>
                  <br />
                  
                  <p>Text for 4th line: <strong>{formData?.line4} </strong></p>
                  <p>Font for 4th line: <strong>{formData?.font4} </strong></p>
                  <p>Size for 4th line: <strong>{formData?.size4} </strong></p>
                  <p>Style for 4th line: <strong>{formData?.style4} </strong></p>
                  <br />
                  
                  <p>Text for 5th line: <strong>{formData?.line5} </strong></p>
                  <p>Font for 5th line: <strong>{formData?.font5} </strong></p>
                  <p>Size for 5th line: <strong>{formData?.size5} </strong></p>
                  <p>Style for 5th line: <strong>{formData?.style5} </strong></p>
                  <br />
                  
                  <p>Text for 6th line: <strong>{formData?.line6} </strong></p>
                  <p>Font for 6th line: <strong>{formData?.font6} </strong></p>
                  <p>Size for 6th line: <strong>{formData?.size6} </strong></p>
                  <p>Style for 6th line: <strong>{formData?.style6} </strong></p>
                  <br />
                  <p>Rubber stamp type : <strong>{formData?.stamp_type} </strong></p>
                  <p>Stamp alignment : <strong>{formData?.stamp_alignment} </strong></p>
                  <p>Stamp colour : <strong>{formData?.stamp_color} </strong></p>
                  <p>Border around the stamp? : <strong>{formData?.stamp_border} </strong></p>
                  
               </>
               : null}
            
               <Title>Product Details</Title> <hr/>
               <p>Type of Minute Book : <strong>{formData?.book_type} </strong></p>
               <p>Your jurisdiction : <strong>{formData?.jurisdiction} </strong></p>
                        
               <Title>Billing Information</Title> <hr/>
               {billing_info()}   
                         
            </DTable>
     );
}
 
export default companySealDetails; 