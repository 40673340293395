import React from 'react'
import { Title,DTable} from './detailStyle';

const PartnershipAgreementDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>

                     
                        <h3>Agreement Details</h3> <hr/>
                        <p>Date of the agreement : <strong>{formData?.date_of_agreement} </strong></p>
                        <p>The commencement date of the partnership : <strong>{formData?.date_of_commencement} </strong></p>
                        <h3>Partnership Details</h3>
                        <p>Name of the partnership : <strong>{formData?.name_of_partnership} </strong></p>
                        <p>Business activities : <strong>{formData?.business_activities} </strong></p>
                        <p>Address of the partnership : <strong>{formData?.address_of_partnership} </strong></p>
                        <p>Duration of partnership : <strong>{formData?.duration_of_partnership} </strong></p>
                        {formData?.duration_of_partnership === 'Until a specific time' ? 
                        <p>End date : <strong>{formData?.end_date} </strong></p>
                        :null}
                        <p>What is the required percentage of the vote to dissolve the partnership? : <strong>{formData?.required_percentage} </strong></p>
                        <p>How will the assets be distributed when winding up your partnership? : <strong>{formData?.how_asset_distributed} </strong></p>
                        {formData?.how_asset_distributed === 'Others' ?
                           <p>Specification : <strong>{formData?.specification} </strong></p>
                        :null}
                     

                     
                        <h3>Party</h3> <hr/>
                        {formData?.party.map(item => (
                           <>
                           <p>Party type : <strong> {item?.party_type } </strong></p>
                           {item?.party_type === 'Individual' ? 
                           <>
                              <p>Given Name : <strong> {item?.given_name } </strong></p>
                              <p>Family Name : <strong> {item?.family_name } </strong></p>
                           </>: null }
                           {item?.party_type === 'Organisation' ? 
                              <p>ABN : <strong> {item?.abn } </strong></p>
                           : null }
                           <p>Name of the witness : <strong> {item?.name_of_g_witness } {item?.name_of_f_witness } </strong></p>
                           <p>Address : <strong> {item?.party_address } </strong></p>
                           <p>Party’s contributions : <strong> {item?.party_contribution } </strong></p>
                           <p>Capital contributions : <strong> {item?.capital_contribution } </strong></p>
                           <p>Goods/services contributions : <strong> {item?.goods_contribution } </strong></p>
                           <p>Total value : <strong> {item?.total_value } </strong></p>
                           <p>Percentage of the partnership : <strong> {item?.percentage_of_partnership } </strong></p>
                           <p>Profit and loss distribution : <strong> {item?.profit_distribution } </strong></p>
                           </>
                        ))}
                     
                              
                     
                        <h3>Partners</h3> <hr/>
                        <p>How much a partner can borrow for the partnership without other partners approval : <strong> {formData?.number_of_partner_borrow } </strong></p>
                        <p>How much notice a partner should provide before retiring/leaving : <strong> {formData?.notice_for_leaving } </strong></p>
                        <p>How much notice should a partner receive before getting expelled? : <strong> {formData?.notice_for_expelled } </strong></p>
                        <p>Will your partnership allow new partners in future? : <strong> {formData?.will_allow_new_partner } </strong></p>
                        {formData?.will_allow_new_partner === 'true' ?
                           <p>What is the requirement? : <strong> {formData?.requirement } </strong></p>
                        : null }
                        <p>Will the partners be given remuneration/compensation for work done for the partnership? : <strong> {formData?.will_given_remuneration } </strong></p>
                     

                     
                        <h3>Account</h3> <hr/>
                        
                        <p>Bank Account details of the partnership : <strong> {formData?.bank_account_details } </strong></p>
                        <p>Who will set up the bank account : <strong> {formData?.who_will_setup } </strong></p>
                        <p>How will partnership raise additional fund if needed in the future : <strong> {formData?.how_will_raise } </strong></p>

                        <h3>Management</h3>
                        <p>Who will be managing the partnership? : <strong> {formData?.who_will_manage } </strong></p>
                        {formData?.who_will_manage === 'Managing partner' ? 
                           <p>Specification : <strong> {formData?.managing_partner_spacification } </strong></p>
                        :null }
                        <p>What vote is required to remove a managing partner? : <strong> {formData?.what_vote_required } </strong></p>
                        
                        <h3>Decision Making</h3>
                        <p>What vote is required to make financial decisions? : <strong> {formData?.what_vote_required_to_make_financial } </strong></p>
                        <p>What vote is required to make significant business decisions? : <strong> {formData?.what_vote_required_to_make_significant } </strong></p>
                        <p>The voting right of each partner : <strong> {formData?.voting_right_partner } </strong></p>
                        <p>How often will regular meetings be held? : <strong> {formData?.when_regular_meeting_held } </strong></p>
                     

                     
                        <h3>Agreement Type</h3> <hr/>
                        <p>Your product : <strong> {formData?.product_type } </strong></p>
                        <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                        <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                        <p>Description : <strong> {formData?.describe_other_specific } </strong></p>
                     
                        <Title>Billing Information</Title> <hr/>
                         {billing_info()}

                     </DTable>
     );
}
 
export default PartnershipAgreementDetails;