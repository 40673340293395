import React from 'react'
import { Title,DTable} from './detailStyle';

const CompanyConstitutionDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
 
                        
                           
                                 <Title>Company and shareholder Details</Title> <hr/>
                                 
                                 <Title>Company  Details</Title>
                                 <p>Registered Name of the Company : <strong>{formData?.registered_name} </strong></p>
                                 <p>ACN : <strong>{formData?.acn} </strong></p>
                                 <Title>Director Details</Title>
                                 <p>Is the company a solo member/director company? : <strong>{formData?.is_company_solo ? 'Yes' : 'No'} </strong></p>
                                 {formData?.is_company_solo === 'false' ? 
                                 <>
                                    <p>To constitute a quorum (minimum number) what percentage of voting is required? : <strong>{formData?.what_percentage} </strong></p>
                                    <p>Are shareholders allowed to convene virtual meeting? : <strong>{formData?.are_shareholder_allowed_to_meeting ? 'Yes' : 'No'} </strong></p>
                                    <p>Are shareholders allowed to form a voting trust? : <strong>{formData?.are_shareholder_allowed_to_voting ? 'Yes' : 'No'} </strong></p>
                                    <p>Will cumulative voting be allowed when electing a director? : <strong>{formData?.will_cumulation ? 'Yes' : 'No'} </strong></p>
                                    <Title>Shareholder  Details</Title>
                                    <p>How many directors the company have? : <strong>{formData?.how_many_director} </strong></p>
                                    <p>How many days of notice is required to call a directors meeting? : <strong>{formData?.how_many_days} </strong></p>
                                    <p>What per cent of directors will constitute a quorum? : <strong>{formData?.percent_of_constitute} </strong></p>
                                    <p>Are directors allowed to hold virtual meetings? : <strong>{formData?.are_director_allowed_meeting ? 'Yes' : 'No'} </strong></p>
                                    <p>Is the company allowed to lend money to its members and officers? : <strong>{formData?.company_allowed_to_lend} </strong></p>
                                 </> : null}
                            
                            
                           
                                 <Title>Office Structure</Title> <hr/>
                                 <p>Select your preferred office structure : <strong>{formData?.preferred_office_stucture} </strong></p>
                                 <p>Who has the power to appoint the officers of the company?: <strong>{formData?.who_has_power} </strong></p>
                                 {formData?.who_has_power === 'Others' ?
                                    <p>Specification: <strong>{formData?.structure_specification} </strong></p>
                                 : null}
                                 <p>On what date the Shareholders Agreement was adopted?: <strong>{formData?.on_what_date} </strong></p>
                                   
                            
                            
                        
                           <Title>Service Type</Title> <hr/>
                           <p>Select your product : <strong> {formData?.product_type } </strong></p>
                           <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                           <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                           <p>Describe if you have any other specific need that you want us to incorporate in the Agreement : <strong> {formData?.describe_other_specific } </strong></p>
                        
          
                <Title>Billing Information</Title> <hr/>
               {billing_info()}

               
                     </DTable>
     );
}
 
export default CompanyConstitutionDetails;