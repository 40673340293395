import React from 'react'
import { Title,DTable} from './detailStyle';

const ReturnToWorkPolicyDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable >
                        
                           
                              <Title>Details</Title> <hr/>
                              <p>In what state is your business or Company registered?  : <strong>{formData?.state_of_business} </strong></p>
                              <p>Who is the person responsible for managing injuries at the Company? : <strong>{formData?.who_should_responsible} </strong></p>
                              <p>What is the contact number of the person responsible for managing injuries at the Company? : <strong>{formData?.number_of_person} </strong></p>
                              <p>How often will the companies return to work policy be reviewed each year?  : <strong>{formData?.how_often_return} </strong></p>
                              <p>What is the name of the person who approved this Policy? : <strong>{formData?.name_of_person} </strong></p>
                              <p>What date was this Policy approved? : <strong>{formData?.date_of_approved} </strong></p>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                              <p>Describe if you have any other specific need that you want us to incorporate in the policy : <strong> {formData?.describe_other_specific } </strong></p>
                           
                              
                           <Title>Billing Information</Title> <hr/>
                              {billing_info()}   
                            
                     </DTable>
     );
}
 
export default ReturnToWorkPolicyDetails; 