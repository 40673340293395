import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import './index.css';
import { Layout, Menu} from 'antd';
import {
  BoxPlotOutlined,
  ApartmentOutlined,
  CustomerServiceOutlined,
  PieChartOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  ContactsOutlined,
  NotificationOutlined,
  SettingOutlined,
  ToolFilled,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Logo from './logo.png'; 
const { Sider } = Layout;
const { SubMenu } = Menu;

class Sidebar extends Component {
  state = {
    collapsed: false,
    access: null
  };
componentDidMount = () => {
  this.setState({access: JSON.parse(localStorage.getItem('access'))})
}
  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  render() {
    const { collapsed,access } = this.state;
    return (
        <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse} style={{backgroundColor: '#ffffff'}}>
          <div className="logo" style={{backgroundColor: '#ffffff'}}>
            <img src={Logo} alt="logo" width="35%" />
          </div>
          <Menu theme="light" defaultSelectedKeys={['1']} mode="inline">
            <Menu.Item key="1" icon={<PieChartOutlined />}>
            <Link to="/dashboard">Dashboard</Link>
            </Menu.Item>

            {access?.user_management !== 'n' ?
            <SubMenu key="user" icon={<UserOutlined />} title="User Management">
              <Menu.Item key="role"><Link to="/roles">Role</Link></Menu.Item>
              <Menu.Item key="3"><Link to="/users">User</Link></Menu.Item>
            </SubMenu>
            :null}

            {access?.client_management !== 'n' ?
            <SubMenu key="clientList" icon={<UsergroupAddOutlined />} title="Client Management">
              <Menu.Item key="clientList"><Link to="/clientList">Clients</Link></Menu.Item>
              <Menu.Item key="subclientList"><Link to="/subclientList">Client Service </Link></Menu.Item>
              {/* <Menu.Item key="/client/health-check"><Link to="/client/health-check">Health Check</Link></Menu.Item> */}
            </SubMenu>
            :null}

            {access?.pop_management !== 'n' ?
              <>
                <SubMenu key="pop" icon={<ApartmentOutlined />} title="Pop Management">
                  <Menu.Item key="/pop/all"><Link to="/pop/all">All POP</Link></Menu.Item>
                  <Menu.Item key="/router/all"><Link to="/router/all">Router</Link></Menu.Item>
                  <Menu.Item key="/switch/all"><Link to="/switch/all">Switch</Link></Menu.Item>
                </SubMenu>
              </>
            :null}

            {access?.user_management !== 'n' ?
            <SubMenu key="setting" icon={<ToolFilled />} title="Setting Management">
              <Menu.Item key="/setting/district"><Link to="/setting/district">District</Link></Menu.Item>
              <Menu.Item key="/setting/clientType"><Link to="/setting/clientType">Client Type</Link></Menu.Item>
              <Menu.Item key="/setting/businessType"><Link to="/setting/businessType">Business Type</Link></Menu.Item>
              <Menu.Item key="/setting/departmentEntry"><Link to="/setting/departmentEntry">Department</Link></Menu.Item>
              <Menu.Item key="/setting/designationEntry"><Link to="/setting/designationEntry">Designation</Link></Menu.Item>
              <Menu.Item key="/setting/problemType"><Link to="/setting/problemType">Problem</Link></Menu.Item>
              <Menu.Item key="/setting/connection"><Link to="/setting/connectionType">Connection Type</Link></Menu.Item>
              <Menu.Item key="/setting/priorityType"><Link to="/setting/priorityType">Priority Type</Link></Menu.Item>
              <Menu.Item key="/setting/assetType"><Link to="/setting/assetType">Asset Type</Link></Menu.Item>
              <Menu.Item key="/setting/vendor"><Link to="/setting/vendor">Vendor</Link></Menu.Item>
              <Menu.Item key="/setting/employee"><Link to="/setting/employee">Employee</Link></Menu.Item>
            </SubMenu>
            :null}  
            {/* {access?.order_management ?
            <SubMenu key="order" icon={<BoxPlotOutlined />} title="Order Management">
              <Menu.Item key="orders"><Link to="/orders">All Orders</Link></Menu.Item>
              <Menu.Item key="notification"><Link to="/order-notification">Notification</Link></Menu.Item>
            </SubMenu>
            :null}
            
            {access?.finance_management ?
            <SubMenu key="finance" icon={<DollarCircleOutlined />} title="Finance Management">
              <Menu.Item key="finance/orders"><Link to="/finance/orders">All Orders</Link></Menu.Item>
            </SubMenu>
            :null}
            {access?.message_management ?
            <></>
            :null}
            */}
            
            {access?.ticket_management !== 'n' ?
            <>
              <SubMenu key="ticket" icon={<CustomerServiceOutlined />} title="Ticket Management">
                <Menu.Item key="/ticket/list"><Link to="/ticket/list">Ticket Board</Link></Menu.Item>
                <Menu.Item key="/ticket/all"><Link to="/ticket/all">Ticket List</Link></Menu.Item>
                {(access?.ticket_management === 'a' || access?.ticket_management === 'w' ) ?
                  <>
                    <Menu.Item key="/support/create"><Link to="/support/create">Create Ticket Client</Link></Menu.Item>
                    <Menu.Item key="/transmission/create"><Link to="/transmission/create">Create Ticket POP</Link></Menu.Item>
                  </>
                 : null}
              </SubMenu>
            </>
            :null}

            {/* {access?.setting_management ?
            <SubMenu key="setting" icon={<SettingOutlined />} title="Setting">
              <Menu.Item key="/setting/api"><Link to="/setting/api">Api List</Link></Menu.Item>
            </SubMenu>
           : null} */}
          </Menu>
        </Sider>
    );
  }
}

 
export default Sidebar;