import React from 'react'
import { Title,DTable} from './detailStyle';

const NotificationofChangetoDirectorsDetails = ({data}) => {
    const formData = data?.data?.form_data;
    const bill = data?.data?.bill_info;
    const billing_info = () => {
    let res  = Object.keys(bill).map((item) => {
         return (<p>{item} : <strong>${bill[item] }</strong></p>)
    })
    return res;
    }
    return ( 
             <DTable>
                                 <Title>Company Details </Title> <hr/>
                                 <p>Company Name  : <strong>{formData?.company_name} </strong></p>
                                 <p>ARBN : <strong>{formData?.arbn} </strong></p>
                                
                                 <p>Australian Passport Fund Registration Number (APFRN) : <strong>{formData?.apfrn} </strong></p>
                                 <p>Notified Foreign Passport Fund Registration Number (NFPFRN)   : <strong>{formData?.nfpfrn} </strong></p>
                                 <p>Please select the service needed  : <strong>{formData?.service_needed} </strong></p>
                                
                              
                              
                              
                                 <Title>Particulars of new director</Title> <hr/>
                                 {formData?.service_needed=== "Appoint director" ?
                                 <>
                                 <p>Given name : <strong>{formData?.given_name} </strong></p>
                                 <p>Surname name  : <strong>{formData?.surname} </strong></p>
                                 <p>Date of birth   : <strong>{formData?.date_of_birth} </strong></p>
                                 <p>Place of birth : <strong>{formData?.place_of_birth} </strong></p>
                                 <p>Country : <strong>{formData?.country} </strong></p>
                                 <p>Unit/Level   : <strong>{formData?.unit} </strong></p>
                                 <p>Street address  : <strong>{formData?.street_address} </strong></p>
                                 <p>Suburb : <strong>{formData?.suburb} </strong></p>
                                 <p>State  : <strong>{formData?.state} </strong></p>
                                 <p>Post code  : <strong>{formData?.post_code} </strong></p>
                                 <p>Country : <strong>{formData?.country1} </strong></p>
                                 <p>Date of change	  : <strong>{formData?.date_of_change} </strong></p>
                                 <p>Late Lodgement fee  : <strong>{formData?.late_lodgement_fee} </strong></p>
                             </>
                             :null}
                             {formData?.service_needed=== "Cease director" ?
                                 <>
                                 <p>Given name : <strong>{formData?.given_name1} </strong></p>
                                 <p>Family name  : <strong>{formData?.family_name1} </strong></p>
                                 <p>Date of birth   : <strong>{formData?.date_of_birth1} </strong></p>
                                 <p>Place of birth : <strong>{formData?.place_of_birth1} </strong></p>
                                 <p>Country : <strong>{formData?.country2} </strong></p>
                                 <p>Unit/Level   : <strong>{formData?.unit1} </strong></p>
                                 <p>Street address  : <strong>{formData?.street_address1} </strong></p>
                                 <p>Suburb : <strong>{formData?.suburb1} </strong></p>
                                 <p>State  : <strong>{formData?.state1} </strong></p>
                                 <p>Post code  : <strong>{formData?.post_code1} </strong></p>
                                 <p>Country : <strong>{formData?.country3} </strong></p>
                                 <p>Date of change	  : <strong>{formData?.date_of_change1} </strong></p>
                                 <p>Late Lodgement fee  : <strong>{formData?.late_lodgement_fee1} </strong></p>
                             </>
                             :null}
                             {formData?.service_needed=== "Change of director name" ?
                                 <>
                                 <p>Previous given name	 : <strong>{formData?.given_name2} </strong></p>
                                 <p>Previous family name: <strong>{formData?.family_name2} </strong></p>
                                 <p>Date of birth   : <strong>{formData?.date_of_birth2} </strong></p>
                                 <p>Place of birth : <strong>{formData?.place_of_birth2} </strong></p>
                                 <p>Country : <strong>{formData?.country4} </strong></p>
                                 <p>New given name  : <strong>{formData?.n_given_name} </strong></p>
                                 <p>New family name  : <strong>{formData?.n_family_name} </strong></p>
                                 <p>Date of change	  : <strong>{formData?.date_of_change2} </strong></p>
                                 <p>Upload evidence of the change	  : <strong>{formData?.upload_link} </strong></p>
                                 
                                 <p>Late Lodgement fee  : <strong>{formData?.late_lodgement_fee2} </strong></p>
                             </>

                             :null}
                           {formData?.service_needed=== "Change of director address" ?
                                 <>
                                 <p>Directors Given name : <strong>{formData?.given_name3} </strong></p>
                                 <p>Directors Family name  : <strong>{formData?.family_name3} </strong></p>
                                 <p>Date of birth   : <strong>{formData?.date_of_birtTitle} </strong></p>
                                 <p>Place of birth : <strong>{formData?.place_of_birtTitle} </strong></p>
                                 <p>Country : <strong>{formData?.country5} </strong></p>
                                 <p>Unit/Level   : <strong>{formData?.unit3} </strong></p>
                                 <p>Street address  : <strong>{formData?.street_address3} </strong></p>
                                 <p>Suburb : <strong>{formData?.suburb3} </strong></p>
                                 <p>State  : <strong>{formData?.state3} </strong></p>
                                 <p>Post code  : <strong>{formData?.post_code3} </strong></p>
                                 <p>Country : <strong>{formData?.country6} </strong></p>
                                 <p>Date of change	  : <strong>{formData?.date_of_change3} </strong></p>
                                 <p>Late Lodgement fee  : <strong>{formData?.late_lodgement_fee3} </strong></p>
                             </>
                              :null}

                                 
                                 <Title>Declaration </Title> <hr/>
                                 <p>Declarant’s Name  : <strong>{formData?.d_name} </strong></p>
                                 <p>Designation within the Company   : <strong>{formData?.d_company} </strong></p>
                                 <p>onecompany local agent ID*  : <strong>{formData?.agent_id} </strong></p>
                                <p> I authorize onecompany to manage all legal documentation related to ASIC and submit all required documentations on behalf of my company.: <strong>{formData?.final_declaration === true ? "yes" : "no"} </strong> </p>
                                 
                                <Title>Billing Information</Title> <hr/>
                              {billing_info()} 

                              
                         
                     </DTable>
     );
}
 
export default NotificationofChangetoDirectorsDetails;