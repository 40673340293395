import React from 'react'
import { Title,DTable} from './detailStyle';

const BusinessPlanDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                        
                           
                              <Title>Business Basics</Title> <hr/>
                              <p>What is the business name? : <strong>{formData?.business_name} </strong></p>
                              <p>Who owns the business? : <strong>{formData?.business_owner} </strong></p>
                              <p>Where is your business located? : <strong>{formData?.business_location} </strong></p>
                              <p>What is your ABN? : <strong>{formData?.abn} </strong></p>
                              <p>What does your business do? : <strong>{formData?.business_do} </strong></p>
                              <p>What is your business vision? : <strong>{formData?.business_vision} </strong></p>
                              <p>What products/ services does your business offer? : <strong>{formData?.business_offer} </strong></p>
                              <p>What is your business' unique selling proposition? : <strong>{formData?.selling_proposition} </strong></p>
                              <p>What is the target market of your business? : <strong>{formData?.target_market} </strong></p>
                           
                        
                           
                              <Title>Background</Title> <hr/>
                              <p>Provide an overview of the history of your company : <strong>{formData?.history_of_company} </strong></p>
                              <p>When was your business created?  : <strong>{formData?.when_created} </strong></p>
                              <p>Explain the current position of your company : <strong>{formData?.current_position} </strong></p>
                           
                        
                           
                              <Title>Goals and Objectives </Title> <hr/>
                              <p>What are the business goals and objectives you have set over the coming year? : <strong>{formData?.business_goal_for_nextyear} </strong></p>
                              <p>What is the action required to meet this short-term goal? : <strong>{formData?.action_responsible_short_time} </strong></p>
                              <p>Who is responsible for meeting this short-term goal? : <strong>{formData?.responsible_short_time} </strong></p>
                              <p>What are the business goals and objectives you have set for the next few years? : <strong>{formData?.business_goal_for_fewyear} </strong></p>
                              <p>What is the action required to meet this long-term goal? : <strong>{formData?.action_required} </strong></p>
                              <p>Who is responsible for meeting this long-term goal? : <strong>{formData?.responsible_long_term} </strong></p>
                           
                        
                           
                              <Title>Ownership</Title> <hr/>
                              <p>Who owns the business? How much ownership do the owners have? : <strong>{formData?.quantity_of_ownership} </strong></p>
                              <p>What is the business structure? : <strong>{formData?.business_structure} </strong></p>
                              <p>List the key personnel of your business. What revenue do they generate or what skill, qualifications, and knowledge they have in your industry, product or service? : <strong>{formData?.key_list} </strong></p>
                              <p>Describe the structure of the staff of your business. : <strong>{formData?.structure_of_staff} </strong></p>
                           
                        
                           
                              <Title>Products and Services </Title> <hr/>
                              <p>List your products and services. : <strong>{formData?.list_of_product} </strong></p>
                              <p>Describe the distribution process of your goods/ services. : <strong>{formData?.distribution_process} </strong></p>
                              <p>How do you set a price for your products & services?  : <strong>{formData?.set_a_price} </strong></p>
                              <p>Provide your plan to grow your business : <strong>{formData?.plan_to_grow} </strong></p>
                              <p>How do you facilitate payment of your products/ services? : <strong>{formData?.payment_of_product} </strong></p>
                              <p>How will you produce these products/services? : <strong>{formData?.whow_will_poroduce} </strong></p>
                              <p>What is the name of your supplier?  : <strong>{formData?.name_of_supplier} </strong></p>
                              <p>What service or product is provided? : <strong>{formData?.what_service_provided} </strong></p>
                              <p>Provide a short description of the supplier's product range : <strong>{formData?.description_of_product_range} </strong></p>
                              <p>What are the payment terms of the supplier? : <strong>{formData?.payment_term} </strong></p>
                              <p>What is the price of the supplied service or product?  : <strong>{formData?.price_of_supplier} </strong></p>
                           

                           
                              <Title>Assets</Title> <hr/>
                              <p>Does your business own or lease the premise you operate in?  : <strong>{formData?.does_premise } </strong></p>
                              <p>List the key assets of your business : <strong>{formData?.asset_key_list} </strong></p>
                              <p>List your key business assets, including plants and equipment: E.g. premises, computers, software, vehicles and machinery : <strong>{formData?.business_asset_key_list} </strong></p>
                              <p>Describe the main stock and inventory of your business : <strong>{formData?.describe_the_stock} </strong></p>
                              <p>List any business names that your business owns : <strong>{formData?.business_name_list} </strong></p>
                              <p>List any domain names that your business owns : <strong>{formData?.domain_list} </strong></p>
                              <p>List any intellectual property that your business owns : <strong>{formData?.intellectual_property} </strong></p>
                           
                        
                           
                              <Title>Risk Management and Compliance</Title> <hr/>
                              <p>Describe the risks that your business may face : <strong>{formData?.description_of_risk} </strong></p>
                              <p>Describe the impact of the risk if it happens  : <strong>{formData?.description_of_impact} </strong></p>
                              <p>How is the risk being mitigated? : <strong>{formData?.how_risk_mitigated} </strong></p>
                              <p>Describe the succession plan you have in place for your business : <strong>{formData?.description_of_succession} </strong></p>
                              <p>List all essential regulatory requirements for your industry : <strong>{formData?.regulatory_list} </strong></p>
                              <p>List any insurance policies that you have taken out for your business : <strong>{formData?.policy_list} </strong></p>
                           
                        
                           
                              <Title>Market Overview and Target Market</Title> <hr/>
                              <p>Describe the market's current situation, both locally and internationally : <strong>{formData?.description_of_situation} </strong></p>
                              <p>How is the market structured? : <strong>{formData?.how_market_structure} </strong></p>
                              <p>How is the market opportunity?  : <strong>{formData?.how_market_opportunity} </strong></p>
                              <p>List how your product/ service will appeal to customers : <strong>{formData?.list_of_product} </strong></p>
                              <p>What is your market proposition? : <strong>{formData?.market_proposition} </strong></p>
                              <p>What is your competitive advantage : <strong>{formData?.competitive_advantage} </strong></p>
                              <p>How do you acquire new customers? : <strong>{formData?.new_customer} </strong></p>
                           
                        
                           
                              <Title>Customer Management</Title> <hr/>
                              <p>How many customers do you expect to acquire each year and over the following year? : <strong>{formData?.customer_expect_to_acquire} </strong></p>
                              <p>Describe your customer service model : <strong>{formData?.description_of_service_model} </strong></p>
                              <p>Describe your customer retention strategy : <strong>{formData?.description_of_retention_strategy} </strong></p>
                              <p>How do you manage or move on with customers you would prefer not to keep? : <strong>{formData?.how_do_move} </strong></p>
                           
                        
                           
                              <Title>Marketing Position Analysis and Competitor Analysis</Title> <hr/>
                              <p>List the strengths within your business that provide an advantage over competitors : <strong>{formData?.list_of_strength} </strong></p>
                              <p>List the weaknesses within your business that place you at a disadvantage compared to others : <strong>{formData?.list_of_weakness} </strong></p>
                              <p>List the opportunities outside your business that you could leverage to your advantage : <strong>{formData?.list_of_opportunities} </strong></p>
                              <p>List the threats in the external environment that could cause trouble for your business : <strong>{formData?.list_of_threat} </strong></p>
                              <p>Who are your main competitors? : <strong>{formData?.who_competitor} </strong></p>
                              <p>What do they offer? : <strong>{formData?.what_offer} </strong></p>
                              <p>What price do they offer their products or services? : <strong>{formData?.what_price_offer} </strong></p>
                              <p>What is their market share? : <strong>{formData?.market_share} </strong></p>
                              <p>Who are their target customers? : <strong>{formData?.target_customer} </strong></p>
                              <p>What is their unique value proposition?  : <strong>{formData?.what_proposition} </strong></p>
                              <p>What are their strengths? : <strong>{formData?.what_strength} </strong></p>
                              <p>What are their weaknesses?  : <strong>{formData?.what_weakness} </strong></p>
                           
                        
                           
                              <Title>Advertising and Promotion</Title> <hr/>
                              <p>List the methods of marketing that you intend to use to promote your business : <strong>{formData?.list_of_methods_of_marketing} </strong></p>
                              <p>Describe the objectives of each method of marketing listed above : <strong>{formData?.description_of_objective} </strong></p>
                              <p>Describe the key message of each method of marketing listed above : <strong>{formData?.description_of_key_message} </strong></p>
                              <p>Describe the timing of each method of marketing listed above : <strong>{formData?.description_of_timing} </strong></p>
                              <p>Describe the location of each method of marketing listed above (where applicable) : <strong>{formData?.description_of_location} </strong></p>
                              <p>Describe the cost of each method of marketing listed above : <strong>{formData?.description_of_cost} </strong></p>
                              <p>Describe the expected return on each method of marketing listed above : <strong>{formData?.name_of_person} </strong></p>
                           
                        
                           
                              <Title>Service Type</Title> <hr/>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                              <p>Describe if you have any other specific need that you want us to incorporate in the Policy : <strong> {formData?.describe_other_specific } </strong></p>
                           
                         
                           
                           <Title>Billing Information</Title> <hr/>
                              {billing_info()}   
                         
                     </DTable>
     );
}
 
export default BusinessPlanDetails; 