import api from "./apis";
import { ERROR_MESSAGE, LOADED, LOADEDING, SUCCESS_MESSAGE, VALIDATION_ERROR } from "./types";
import history from "../history";

export const deleteApi = (id, name) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const response = await api(localStorage.getItem("token")).patch(
      `/setting-management/${name}/delete`,
      { id }
    );
    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload:  "Deleted Successfully"});
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const getDistrict = (p) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    let response = null;
      response = await api(localStorage.getItem("token")).get(`/setting-management/district?p=${p}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const addDistrict = (data) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const object = {
      id: data?.id,
      name: data.name,
    };

    
    const response = await api(localStorage.getItem("token")).post(
      "/setting-management/district/create",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const UpdateDistrict = (data, id) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const object = {
      id: id,
      name: data.name,
    };

    
    const response = await api(localStorage.getItem("token")).patch(
      "/setting-management/district/update",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const getClientType = (p) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    let response = null;
      response = await api(localStorage.getItem("token")).get(`/setting-management/client-type?p=${p}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const addClientType = (data) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const object = {
      name: data?.name,
      short_name: data?.short_name,
      vrn: data?.vrn,
    };

    
    const response = await api(localStorage.getItem("token")).post(
      "/setting-management/client-type/create",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const UpdateClientType = (data, id) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const object = {
      id: id,
      name: data?.name,
      short_name: data?.short_name,
      vrn: data?.vrn,
    };

    
    const response = await api(localStorage.getItem("token")).patch(
      "/setting-management/client-type/update",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}


export const getBusinessType = (p) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    let response = null;
      response = await api(localStorage.getItem("token")).get(`/setting-management/business-type?p=${p}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const addBusinessType = (data) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const object = {
      id: data?.id,
      name: data.name,
    };

    
    const response = await api(localStorage.getItem("token")).post(
      "/setting-management/business-type/create",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const UpdateBusinessType = (data, id) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const object = {
      id: id,
      name: data.name,
    };

    
    const response = await api(localStorage.getItem("token")).patch(
      "/setting-management/business-type/update",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}


export const getConnectionType = (p) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    let response = null;
      response = await api(localStorage.getItem("token")).get(`/setting-management/connection-type?p=${p}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const addConnectionType = (data) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const object = {
      name: data.name,
    };

    
    const response = await api(localStorage.getItem("token")).post(
      "/setting-management/connection-type/create",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const UpdateConnectionType = (data, id) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const object = {
      id: id,
      name: data.name,
    };

    
    const response = await api(localStorage.getItem("token")).patch(
      "/setting-management/connection-type/update",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}


export const getDepartment = (p) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    let response = null;
      response = await api(localStorage.getItem("token")).get(`/setting-management/department?p=${p}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const addDepartment = (data) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const object = {
      name: data.name,
      email: data.email,
    };

    
    const response = await api(localStorage.getItem("token")).post(
      "/setting-management/department/create",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const UpdateDepartment = (data, id) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const object = {
      id: id,
      name: data.name,
      email: data.email,
    };

    
    const response = await api(localStorage.getItem("token")).patch(
      "/setting-management/department/update",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}


export const getDesignation = (p) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    let response = null;
      response = await api(localStorage.getItem("token")).get(`/setting-management/designation?p=${p}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const addDesignation = (data) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const object = {
      name: data?.name,
    };

    
    const response = await api(localStorage.getItem("token")).post(
      "/setting-management/designation/create",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const UpdateDesignation = (data, id) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const object = {
      id: id,
      name: data?.name,
    };

    
    const response = await api(localStorage.getItem("token")).patch(
      "/setting-management/designation/update",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}


export const getProblemType = (p) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    let response = null;
      response = await api(localStorage.getItem("token")).get(`/setting-management/problem-type?p=${p}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const addProblemType = (data) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const object = {
      name: data.name,
    };

    
    const response = await api(localStorage.getItem("token")).post(
      "/setting-management/problem-type/create",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const UpdateProblemType = (data, id) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const object = {
      id: id,
      name: data.name,
    };

    
    const response = await api(localStorage.getItem("token")).patch(
      "/setting-management/problem-type/update",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}


export const getPriorityType = (p) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    let response = null;
      response = await api(localStorage.getItem("token")).get(`/setting-management/priority-type?p=${p}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const addPriorityType = (data) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const object = {
      name: data.name,
    };

    
    const response = await api(localStorage.getItem("token")).post(
      "/setting-management/priority-type/create",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const UpdatePriorityType = (data, id) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const object = {
      id: id,
      name: data.name,
    };

    
    const response = await api(localStorage.getItem("token")).patch(
      "/setting-management/priority-type/update",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}


export const getAssetType = (p) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    let response = null;
      response = await api(localStorage.getItem("token")).get(`/setting-management/asset?p=${p}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const addAssetType = (data) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const object = {
      name: data.name,
    };

    
    const response = await api(localStorage.getItem("token")).post(
      "/setting-management/asset/create",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const UpdateAssetType = (data, id) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const object = {
      id: id,
      name: data.name,
    };

    
    const response = await api(localStorage.getItem("token")).patch(
      "/setting-management/asset/update",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}


export const getVendor = (p) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    let response = null;
      response = await api(localStorage.getItem("token")).get(`/setting-management/vendor?p=${p}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const addVendor = (data) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const object = {
      name: data.name,
      contact_name: data.contact_name,
      mobile_number: data.mobile_number,
      email: data.email,
      web: data.web,
    };

    
    const response = await api(localStorage.getItem("token")).post(
      "/setting-management/vendor/create",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const UpdateVendor = (data, id) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const object = {
      id: id,
      name: data.name,
      contact_name: data.contact_name,
      mobile_number: data.mobile_number,
      email: data.email,
      web: data.web,
    };

    
    const response = await api(localStorage.getItem("token")).patch(
      "/setting-management/vendor/update",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}


export const getEmployee = (p) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    let response = null;
      response = await api(localStorage.getItem("token")).get(`/setting-management/employee?p=${p}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const addEmployee  = (data) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    const object = {
      name: data.name,
      employee_id: data.employee_id,
      dob: data.dob,
      join_date: data.join_date,
      mobile_number: data.mobile_number,
      email: data.email,
      designation: data.designation,
      department: data.department,
      address: data.address,
    };

    
    const response = await api(localStorage.getItem("token")).post(
      "/setting-management/employee/create",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const UpdateEmployee = (data, id) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    let object = { 
      id: id,
      employee_id: data.employee_id,
      name: data.name,
      mobile_number: data.mobile_number,
      email: data.email,
      designation: data.designation,
      department: data.department,
      address: data.address,}

    if(data?.join_date){
      object.join_date = data.join_date
        ;
    }

    if(data?.dob){
      object.dob = data.dob
      ;
    }

    const response = await api(localStorage.getItem("token")).patch(
      "/setting-management/employee/update",
      { ...object }
    );

    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response.status;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}




export const getOptions = (p = 1) => async (dispatch) => {
  
  dispatch({ type: LOADEDING });
  try{
    let response = null;
      response = await api(localStorage.getItem("token")).get(`/setting-management/department?p=${p}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}


export const helper = () => async (dispatch) => {
  dispatch({ type: LOADEDING });
  
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/setting-management/helper`
    );
    
    if (response.status === 200) {
      
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};