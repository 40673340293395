import { Content } from 'antd/lib/layout/layout';
import React from 'react';
import { Statistic, Row, Col, Card } from 'antd';
import { LikeOutlined,ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Line,Column, Radar, RadialBar, RingProgress, Rose, Funnel, Pie } from '@ant-design/charts';
const Dashboard = () => {
    const data = [
        { year: '1991', value: 3 },
        { year: '1992', value: 4 },
        { year: '1993', value: 3.5 },
        { year: '1994', value: 5 },
        { year: '1995', value: 4.9 },
        { year: '1996', value: 6 },
        { year: '1997', value: 7 },
        { year: '1998', value: 9 },
        { year: '1999', value: 13 },
      ];
    
      const config = {
        data,
        height: 400,
        xField: 'year',
        yField: 'value',
        point: {
          size: 5,
          shape: 'diamond',
        },
      };
    return ( 
        <Content style={{ margin: '0 16px' }}>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <Row gutter={8}>
                <Col span={6}>
                <Statistic title="Total Users" value={12} />
                </Col>
                <Col span={6}>
                <Statistic title="Total Client" value={12} />
                </Col>
                <Col span={6}>
                <Statistic title="Total POP" value={12} />
                </Col>
                <Col span={6}>
                <Statistic title="Total Line" value={12} />
                </Col>
                
            </Row>

            {/* <br />
            <Row gutter={8}>
                <Col span={12}>
                    <Card>
                        <Statistic
                            title="Active"
                            value={11.28}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={<ArrowUpOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <Statistic
                            title="Idle"
                            value={9.3}
                            precision={2}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={<ArrowDownOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
            </Row>
            <br />
            <Row gutter={8}>
                <Col span={12}>
                    <Card>
                        <Line {...config} />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <Column
                            {...config}
                            onReady={(plot) => {
                                plot.on('plot:click', (evt) => {
                                const { x, y } = evt;
                                const { xField } = plot.options;
                                const tooltipData = plot.chart.getTooltipItems({ x, y });
                                });
                            }}
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <Radar
                            {...config}
                            onReady={(plot) => {
                                plot.on('plot:click', (evt) => {
                                const { x, y } = evt;
                                const { xField } = plot.options;
                                const tooltipData = plot.chart.getTooltipItems({ x, y });
                                });
                            }}
                        />
                    </Card>
                </Col>
                
                <Col span={12}>
                    <Card>
                        <RadialBar
                            {...config}
                            onReady={(plot) => {
                                plot.on('plot:click', (evt) => {
                                const { x, y } = evt;
                                const { xField } = plot.options;
                                const tooltipData = plot.chart.getTooltipItems({ x, y });
                                });
                            }}
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <RingProgress
                            {...config}
                            onReady={(plot) => {
                                plot.on('plot:click', (evt) => {
                                const { x, y } = evt;
                                const { xField } = plot.options;
                                const tooltipData = plot.chart.getTooltipItems({ x, y });
                                });
                            }}
                        />
                    </Card>
                </Col>
                
                <Col span={12}>
                    <Card>
                        <Rose
                            {...config}
                            onReady={(plot) => {
                                plot.on('plot:click', (evt) => {
                                const { x, y } = evt;
                                const { xField } = plot.options;
                                const tooltipData = plot.chart.getTooltipItems({ x, y });
                                });
                            }}
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <Funnel
                            {...config}
                            onReady={(plot) => {
                                plot.on('plot:click', (evt) => {
                                const { x, y } = evt;
                                const { xField } = plot.options;
                                const tooltipData = plot.chart.getTooltipItems({ x, y });
                                });
                            }}
                        />
                    </Card>
                </Col>
                
                
            </Row>
             */}
            <br />
            </div>
        </Content>
     );
}
 
export default Dashboard;