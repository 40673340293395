
import React, { useEffect, useState } from 'react'
import { Breadcrumb, PageHeader,Form, Button, Row, Col } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { connect } from 'react-redux';
import {getServiceDetails} from '../../../actions/orders'
import { Link } from 'react-router-dom';
import { renderCheckbox, renderInput, renderSelect, renderTextField } from '../../../component/form/form';
import { Title } from './detailStyle';

const common = [
    {value:"Y", label:"Yes"},
    {value:"N", label:"No"}
  ];
  const company_structure = [
    {value:"PTY LTD", label:"PTY LTD"},
    {value:"PTY LTD.", label:"PTY LTD."},
    {value:"PTY. LTD", label:"PTY. LTD"},
    {value:"PTY. LTD.", label:"PTY. LTD."},
    {value:"PTY LIMITED", label:"PTY LIMITED"},
    {value:"PTY. LIMITED", label:"PTY. LIMITED"},
    {value:"PROPRIETARY LTD", label:"PROPRIETARY LTD"},
    {value:"PROPRIETARY LTD.", label:"PROPRIETARY LTD."},
    {value:"PROPRIETARY LIMITED", label:"PROPRIETARY LIMITED"}
    ];

    const jurisdiction = [
        {value:"ACT", label:"Australian Capital Territory."},
        {value:"CCK", label:"Cocos (Keeling) Islands."},
        {value:"CXR", label:"Christmas Island."},
        {value:"JBT", label:"Jervis Bay Territory."},
        {value:"NSW", label:"New South Wales."},
        {value:"NFK", label:"Norfolk Island."},
        {value:"NT", label:"Northern Territory. "},
        {value:"QLD", label:"Queensland."},
        {value:"SA.", label:"South Australia."},
        {value:"TAS", label:"Tasmania."},
        {value:"VIC", label:"Victoria."},
        {value:"WA", label:"Western Australia."}
        ];
        
const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};

const EditCompanyRegistration = ({getServiceDetails, location}) => {
    const [data, setData] = useState(null)
    const [form] = Form.useForm();
    useEffect( async() => {
        const order = new URLSearchParams(location.search).get("order");
        const res = await getServiceDetails(order)
        setData(res)
        const val = res?.data?.form_data
        
    form.setFieldsValue({
        company_name: val?.company_name,
        company_structure: val?.company_structure,
        care_of: val?.care_of,
        is_same: val?.is_same,
        juridiction: val?.juridiction,
        line2: val?.line2,
        locality: val?.locality,
        occupy: val?.occupy,
        postcode: val?.postcode,
        state: val?.state,
        street: val?.street,
        // individual: val?.individual,
        // company: val?.company,
        
      })
      data?.data?.form_data?.individual.map((item,id) => {
        form.setFieldsValue({
            family_name: item?.family_name,
            former_family_name: item?.former_family_name,
            former_given_name1: item?.former_given_name1,
            former_given_name2: item?.former_given_name2,
            given_name1: item?.given_name1,
            given_name2: item?.given_name2,
            has_former: item?.has_former,
            is_aus: item?.is_aus,
            locality: item?.locality,
            locality_qualifier: item?.locality_qualifier
        })
    })
    
    }, [])
    const dv = () => {
        
    }
    console.log(form)
    const onFinish = async (values) => {
        
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
    return ( 
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{padding:"10px 0px"}}>
              <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
              <Breadcrumb.Item>Edit Order</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeader
              ghost={false}
              onBack={() => window.history.back()}
              title="Edit Order"
              subTitle="Order Management"
            >
            </PageHeader>
            
            <Form
                form={form}
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                style={{alignSelf: 'center'}}
                requiredMark={false}
              >
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    
                    <Title>Name Check</Title>
                        {renderInput('company_name', 'Your Desire Company Name',
                        [{ required: true, message: 'This field is required' },] )}
                       
                        {renderSelect('company_structure', 
                            'Company Structure',
                            [
                            { required: true},
                            ],
                            'Select a option',
                            company_structure,
                            'none')
                        }
                    </Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <Title>Registered Office Address</Title>
                    
                        {renderInput('care_of', 'Care Of',
                        [{ required: true, message: 'This field is required' },] )}
                    
                        {renderInput('line2', 'Address room, floor, building',
                        [{ required: true, message: 'This field is required' },] )}
                    
                        {renderInput('street', 'Street number, name and type',
                        [{ required: true, message: 'This field is required' },] )}
                    
                        {renderInput('locality', 'Suburb, town, city',
                        [{ required: true, message: 'This field is required' },] )}
                    
                        {renderSelect('state', 
                            'Australian state or territory',
                            [{ required: true},],'Select a option',jurisdiction,'none')
                        }
                        {renderInput('postcode', 'Australia Post postcode',
                        [{ required: true, message: 'This field is required' },] )}
                       

                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <Title>Registered Office Occupier</Title>
                        {renderSelect('occupy', 
                            'Will the company occupy the address?',
                            [{ required: true},],'Select a option',common,'none')
                        }
                        {data?.data?.form_data?.occupy === 'N' ?
                        <>
                            {renderInput('occupier_name', 'Occupier\'s name',
                            [{ required: true, message: 'This field is required' },] )}
                            {renderCheckbox('occupant_consent',
                            'I declare that the occupier of the premises has consented in writing to the use of the specified address as the address of the registered office of the company and has not withdrawn that consent.',
                            [{ required: true},]
                            )}
                       
                        </>
                        :null}
                        
                        <Title>Principal place of business details</Title>
                        {renderSelect('is_same', 
                            'Is the principal place of business the same as the registered office',
                            [{ required: true},],'Select a option',common,'none')
                        }
                        
                        {data?.data?.form_data?.is_same === 'Y' ?
                        <>
                            {renderInput('care_of', 'Care Of',
                            [{ required: true, message: 'Remark is required' },] )}
                        
                            {renderInput('line2', 'Address room, floor, building',
                            [{ required: true, message: 'Remark is required' },] )}
                        
                            {renderInput('street', 'Street number, name and type',
                            [{ required: true, message: 'Remark is required' },] )}
                        
                            {renderInput('locality', 'Suburb, town, city',
                            [{ required: true, message: 'Remark is required' },] )}
                        
                            {renderSelect('state', 
                                'Australian state or territory',
                                [{ required: true},],'Select a option',jurisdiction,'none')
                            }
                            {renderInput('postcode', 'Australia Post postcode',
                            [{ required: true, message: 'Remark is required' },] )}
                       
                        </>
                        :null}
                        {data?.data?.form_data?.is_same === 'N' ?
                        <>
                            {renderInput('principal_care_of', 'Care Of',
                            [{ required: true, message: 'Remark is required' },] )}
                        
                            {renderInput('principal_line2', 'Address room, floor, building',
                            [{ required: true, message: 'Remark is required' },] )}
                        
                            {renderInput('principal_street', 'Street number, name and type',
                            [{ required: true, message: 'Remark is required' },] )}
                        
                            {renderInput('principal_locality', 'Suburb, town, city',
                            [{ required: true, message: 'Remark is required' },] )}
                        
                            {renderSelect('principal_state', 
                                'Australian state or territory',
                                [{ required: true},],'Select a option',jurisdiction,'none')
                            }
                            {renderInput('principal_postcode', 'Australia Post postcode',
                            [{ required: true, message: 'Remark is required' },] )}
                       
                        </>
                        :null}
                        
                        {renderSelect('juridiction', 
                                'Which State or Territory is the company to be registered in?',
                                [{ required: true},],'Select a option',jurisdiction,'none')
                            }
                    
                        {renderTextField('text_manual_review', 'Text in support of application, to be considered in manual review',
                        [{ required: true, message: 'This field is required' },] )}

                    </Col>
                    
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        
                    <Title>Individual</Title>
                    {data?.data?.form_data?.individual.map(item =>(
                        <>

                            {renderInput('family_name', 'First Name',
                            [{ required: true, message: 'This field is required' },] )}

                            {renderInput('family_name', 'Middle Name',
                            [{ required: true, message: 'This field is required' },] )}

                            {renderInput('family_name', 'Last Name',
                            [{ required: true, message: 'This field is required' },] )}

                            {/* {renderInput('family_name', 'First',
                            [{ required: true, message: 'This field is required' },] )}

                            {renderInput('family_name', 'First',
                            [{ required: true, message: 'This field is required' },] )}

                            {renderInput('family_name', 'First',
                            [{ required: true, message: 'This field is required' },] )}
                        
                            {renderInput('family_name', 'First',
                            [{ required: true, message: 'This field is required' },] )} */}
                        </>
                        ))}
                    </Col>
                </Row>
              <Form.Item {...tailLayout}> 
                <Button type="primary" htmlType="submit">
                  Sunmit
                </Button>
              </Form.Item>
            </Form>
        </Content>
     );
}
 
export default connect(null,{getServiceDetails})(EditCompanyRegistration);