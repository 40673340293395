// import React, { Component } from 'react'
// import { Form, Input, Button, Checkbox, PageHeader, Select, Breadcrumb, Divider, Row, Col } from 'antd';
// import { Content } from 'antd/lib/layout/layout';
// import { Link } from 'react-router-dom';
// import { TabletFilled } from '@ant-design/icons';
// import history from '../../../history';
// import CommonForm from '../../../component/form/form';
// import { connect } from 'react-redux';
// import {createNewRole} from '../../../actions/role'
// // import CheckBox from '../../../component/form/check';
// const { Option } = Select;
// const layout = {
//     labelCol: { span: 4 },
//     wrapperCol: { span: 16 },
//   };
//   const tailLayout = {
//     wrapperCol: { offset: 4, span: 16 },
//   };

//   const gender = [
//     {value: 'male', label: 'Male'},
//     {value: 'female', label: 'Female'},
//     {value: 'other', label: 'Other'},
//   ]
  
//   class CreateRole extends CommonForm {
//     state = { 
//       userValue: false,
//       clientValue: false,
//       employeeValue: false,
//       fiberValue: false,
//       settingValue: false,
//       popValue: false,
//       reportValue: false,
//       supportValue: false,
//       ticketValue: false,
//       data: {
//         username: '',
//         password: '',
//       }
//      }
//     onFinish = (values) => {
//       const { 
//         userValue, clientValue, employeeValue, fiberValue, 
//         settingValue, popValue, reportValue, supportValue, ticketValue
//       } = this.state;
//         this.props.createNewRole(
//           values, userValue, clientValue, employeeValue, fiberValue, 
//           settingValue, popValue, reportValue, supportValue, ticketValue
//           )
//       };
   
//       onFinishFailed = (errorInfo) => {
//         console.log('Failed:', errorInfo);
//       };

//       user = [
//         { label: "All", value: "user_all" },
//         { label: "Read", value: "user_read" },
//         { label: "Write", value: "user_write" },
//         { label: "None", value: "user_none" },
//       ];
      
//       client = [
//         { label: "All", value: "client_all" },
//         { label: "Read", value: "client_read" },
//         { label: "Write", value: "client_write" },
//         { label: "None", value: "client_none" },
//       ];
      
//       employee = [
//         { label: "All", value: "employee_all" },
//         { label: "Read", value: "employee_read" },
//         { label: "Write", value: "employee_write" },
//         { label: "None", value: "employee_none" },
//       ];
      
//       fiber = [
//         { label: "All", value: "fiber_all" },
//         { label: "Read", value: "fiber_read" },
//         { label: "Write", value: "fiber_write" },
//         { label: "None", value: "fiber_none" },
//       ];
      
//       setting = [
//         { label: "All", value: "setting_all" },
//         { label: "Read", value: "setting_read" },
//         { label: "Write", value: "setting_write" },
//         { label: "None", value: "setting_none" },
//       ];
      
//       pop = [
//         { label: "All", value: "pop_all" },
//         { label: "Read", value: "pop_read" },
//         { label: "Write", value: "pop_write" },
//         { label: "None", value: "pop_none" },
//       ];
      
//       report = [
//         { label: "All", value: "report_all" },
//         { label: "Read", value: "report_read" },
//         { label: "Write", value: "report_write" },
//         { label: "None", value: "report_none" },
//       ];
      
//       support = [
//         { label: "All", value: "support_all" },
//         { label: "Read", value: "support_read" },
//         { label: "Write", value: "support_write" },
//         { label: "None", value: "support_none" },
//       ];
      
//       ticket = [
//         { label: "All", value: "ticket_all" },
//         { label: "Read", value: "ticket_read" },
//         { label: "Write", value: "ticket_write" },
//         { label: "None", value: "ticket_none" },
//       ];

//        handleUserChange = (checkedValues) => {
//         this.setState({userValue: checkedValues.target.value})
//       }
//        handleClientChange = (checkedValues) => {
//         this.setState({clientValue: checkedValues.target.value})
//       }
//        handleEmployeeChange = (checkedValues) => {
//         this.setState({employeeValue: checkedValues.target.value})
//       }
//        handleFiberChange = (checkedValues) => {
//         this.setState({fiberValue: checkedValues.target.value})
//       }
//        handleSettingChange = (checkedValues) => {
//         this.setState({settingValue: checkedValues.target.value})
//       }
//        handlePopChange = (checkedValues) => {
//         this.setState({popValue: checkedValues.target.value})
//       }
//        handleReportChange = (checkedValues) => {
//         this.setState({reportValue: checkedValues.target.value})
//       }
//        handleSupportChange = (checkedValues) => {
//         this.setState({supportValue: checkedValues.target.value})
//       }
//        handleTicketChange = (checkedValues) => {
//         this.setState({ticketValue: checkedValues.target.value})
//       }
//       render() { 
//     return (
//         <Content style={{ margin: '0 16px' }}>
//             <Breadcrumb style={{padding:"10px 0px"}}>
//               <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
//               <Breadcrumb.Item>Create Role</Breadcrumb.Item>
//             </Breadcrumb>
//             <PageHeader
//               ghost={false}
//               onBack={() => window.history.back()}
//               title="Create Role"
//               subTitle="Role Management"
//               extra={[
//                 <Button key="1"  type="primary" icon={<TabletFilled />} onClick = { () => history.push('/roles')}>Role List
//                 </Button>,
//               ]}
//             >
//             </PageHeader>
//             <br />
//             <Form
//               {...layout}
//               name="basic"
//               initialValues={{ remember: true }}
//               onFinish={this.onFinish}
//               onFinishFailed={this.onFinishFailed}
//             >
//               {this.renderInput('role', 'Role Name', [
//                   { required: true, message: 'Role name is required' },
//                 ])
//               }
//                <Divider orientation="center">Assign role's access</Divider>
//               <Row>
//                 <Col xs={12}>
//                   <Row>
//                     <Col xs={11}>
//                       {this.renderCheckbox('user_management', 'User Management',  [
//                           { required: false },
//                         ])
//                       }
//                     </Col>
//                     {this.user?.map((item, key) => {
//                       return (
//                           <Checkbox
//                             key={item.label}
//                             onChange={this.handleUserChange}
//                             checked={item.value == this.state.userValue}
//                             value={item.value}
//                           >
//                             {item.label}
//                           </Checkbox>
//                       )
//                     })}
//                   </Row>

//                   <Row>
//                     <Col xs={11}>
//                       {this.renderCheckbox('client_management', 'Client Management',   [
//                           { required: false },
//                         ])
//                       }
//                     </Col>
//                     {this.client?.map((item, key) => {
//                       return (
                        
//                           <Checkbox
//                             key={item.label}
//                             onChange={this.handleClientChange}
//                             checked={item.value == this.state.clientValue}
//                             value={item.value}
//                           >
//                             {item.label}
//                           </Checkbox>
                      
//                       )
//                     })}
//                   </Row>
                  
//                   <Row>
//                     <Col xs={11}>
//                       {this.renderCheckbox('employee_management', 'Employee Management', [
//                           { required: false },
//                         ])
//                       }
//                     </Col>
//                     {this.employee?.map((item, key) => {
//                       return (
                        
//                           <Checkbox
//                             key={item.label}
//                             onChange={this.handleEmployeeChange}
//                             checked={item.value == this.state.employeeValue}
//                             value={item.value}
//                           >
//                             {item.label}
//                           </Checkbox>
                      
//                       )
//                     })}
//                   </Row>

                  
//                   <Row>
//                     <Col xs={11}>
//                       {this.renderCheckbox('fiber_management', 'Fiber Management',  [
//                           { required: false },
//                         ])
//                       }
//                     </Col>
//                     {this.fiber?.map((item, key) => {
//                       return (
                        
//                           <Checkbox
//                             key={item.label}
//                             onChange={this.handleFiberChange}
//                             checked={item.value == this.state.fiberValue}
//                             value={item.value}
//                           >
//                             {item.label}
//                           </Checkbox>
                      
//                       )
//                     })}
//                   </Row>
                  
//                   <Row>
//                     <Col xs={11}>
//                       {this.renderCheckbox('setting_management', 'Setting Management', [
//                           { required: false },
//                         ])
//                       }
//                     </Col>
//                     {this.setting?.map((item, key) => {
//                       return (
                        
//                           <Checkbox
//                             key={item.label}
//                             onChange={this.handleSettingChange}
//                             checked={item.value == this.state.settingValue}
//                             value={item.value}
//                           >
//                             {item.label}
//                           </Checkbox>
                      
//                       )
//                     })}
//                   </Row>
//                 </Col>
                
//                 <Col xs={12}>
                  
//                 <Row>
//                   <Col xs={11}>
//                     {this.renderCheckbox('pop_management', 'POP Management', [
//                         { required: false },
//                       ])
//                     }
//                   </Col>
//                     {this.pop?.map((item, key) => {
//                       return (
                        
//                           <Checkbox
//                             key={item.label}
//                             onChange={this.handlePopChange}
//                             checked={item.value == this.state.popValue}
//                             value={item.value}
//                           >
//                             {item.label}
//                           </Checkbox>
                      
//                       )
//                     })}
//                 </Row>

//                 <Row>
//                   <Col xs={11}>
//                     {this.renderCheckbox('report_management', 'Report Management', [
//                         { required: false },
//                       ])
//                     }
//                   </Col>
//                     {this.report?.map((item, key) => {
//                       return (
                        
//                           <Checkbox
//                             key={item.label}
//                             onChange={this.handleReportChange}
//                             checked={item.value == this.state.reportValue}
//                             value={item.value}
//                           >
//                             {item.label}
//                           </Checkbox>
                      
//                       )
//                     })}
//                 </Row>
                  
//                 <Row>
//                   <Col xs={11}>
//                     {this.renderCheckbox('support_management', 'Support Management', [
//                         { required: false },
//                       ])
//                     }
//                   </Col>
//                     {this.support?.map((item, key) => {
//                       return (
                        
//                           <Checkbox
//                             key={item.label}
//                             onChange={this.handleSupportChange}
//                             checked={item.value == this.state.supportValue}
//                             value={item.value}
//                           >
//                             {item.label}
//                           </Checkbox>
                      
//                       )
//                     })}
//                 </Row>
                  
//                 <Row>
//                   <Col xs={11}>
//                     {this.renderCheckbox('ticket_management', 'Ticket Management', [
//                         { required: false },
//                       ])
//                     }
//                   </Col>
//                     {this.ticket?.map((item, key) => {
//                       return (
                        
//                           <Checkbox
//                             key={item.label}
//                             onChange={this.handleTicketChange}
//                             checked={item.value == this.state.ticketValue}
//                             value={item.value}
//                           >
//                             {item.label}
//                           </Checkbox>
                      
//                       )
//                     })}
//                 </Row>
//                 </Col>
//               </Row>
              
//               <Form.Item {...tailLayout}> 
//                 <Button type="primary" htmlType="submit">
//                   Submit
//                 </Button>
//               </Form.Item>
            
//             </Form>
    
//     </Content>
//     )
//   }
// }

 
// export default connect(null,{createNewRole})(CreateRole);


import React, { Component, useEffect, useState } from 'react'
import { Form, Input, Button, Checkbox, PageHeader, Select, Breadcrumb, Divider, Row, Col } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import { TabletFilled } from '@ant-design/icons';
import history from '../../../history';
import CommonForm, { renderCheckbox, renderInput, renderSelect, renderTextField } from '../../../component/form/form';
import { connect } from 'react-redux';
import {createNewRole,getRoleById,updateRole} from '../../../actions/role'
const { Option } = Select;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 1, span: 20 },
  };


const CreateRole = ({createNewRole}) => {
  const [data, setData] = useState(null)
  const [roleId, setRoleId] = useState(null)
  const [userValue, setUserValue] = useState(false)
  const [clientValue, setClientValue] = useState(false)
  const [employeeValue, setEmployeeValue] = useState(false)
  const [fiberValue, setFiberValue] = useState(false)
  const [settingValue, setSettingValue] = useState(false)
  const [popValue, setPopValue] = useState(false)
  const [reportValue, setReportValue] = useState(false)
  const [supportValue, setSupportValue] = useState(false)
  const [ticketValue, setTicketValue] = useState(false)
  const onFinish = (values) => {
            // console.log('Success:', values);
      createNewRole(
      values, userValue, clientValue, employeeValue, fiberValue, 
      settingValue, popValue, reportValue, supportValue, ticketValue)
  };
   
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const [form] = Form.useForm();
  
        const user = [
          { label: "All", value: "user_all" },
          { label: "Read", value: "user_read" },
          { label: "Write", value: "user_write" },
          { label: "None", value: "user_none" },
        ];
        
        const client = [
          { label: "All", value: "client_all" },
          { label: "Read", value: "client_read" },
          { label: "Write", value: "client_write" },
          { label: "None", value: "client_none" },
        ];
        
        const employee = [
          { label: "All", value: "employee_all" },
          { label: "Read", value: "employee_read" },
          { label: "Write", value: "employee_write" },
          { label: "None", value: "employee_none" },
        ];
        
        const fiber = [
          { label: "All", value: "fiber_all" },
          { label: "Read", value: "fiber_read" },
          { label: "Write", value: "fiber_write" },
          { label: "None", value: "fiber_none" },
        ];
        
        const setting = [
          { label: "All", value: "setting_all" },
          { label: "Read", value: "setting_read" },
          { label: "Write", value: "setting_write" },
          { label: "None", value: "setting_none" },
        ];
        
        const pop = [
          { label: "All", value: "pop_all" },
          { label: "Read", value: "pop_read" },
          { label: "Write", value: "pop_write" },
          { label: "None", value: "pop_none" },
        ];
        
        const report = [
          { label: "All", value: "report_all" },
          { label: "Read", value: "report_read" },
          { label: "Write", value: "report_write" },
          { label: "None", value: "report_none" },
        ];
        
        const support = [
          { label: "All", value: "support_all" },
          { label: "Read", value: "support_read" },
          { label: "Write", value: "support_write" },
          { label: "None", value: "support_none" },
        ];
        
        const  ticket = [
          { label: "All", value: "ticket_all" },
          { label: "Read", value: "ticket_read" },
          { label: "Write", value: "ticket_write" },
          { label: "None", value: "ticket_none" },
        ];
  
        const handleUserChange = (checkedValues) => {
          setUserValue(checkedValues.target.value)
          form.setFieldsValue({
            user_management : true,
          })
        }

        const handleClientChange = (checkedValues) => {
          setClientValue(checkedValues.target.value)
          form.setFieldsValue({
            client_management : true,
          })
        }

        const handleEmployeeChange = (checkedValues) => {
          setEmployeeValue(checkedValues.target.value)
          form.setFieldsValue({
            employee_management : true,
          })
        }

        const handleFiberChange = (checkedValues) => {
          setFiberValue(checkedValues.target.value)
          form.setFieldsValue({
            fiber_management : true,
          })
        }

        const handleSettingChange = (checkedValues) => {
          setSettingValue(checkedValues.target.value)
          form.setFieldsValue({
            setting_management : true,
          })
        }

        const handlePopChange = (checkedValues) => {
          setPopValue(checkedValues.target.value)
          form.setFieldsValue({
            pop_management : true,
          })
        }

        const handleReportChange = (checkedValues) => {
          setReportValue(checkedValues.target.value)
          form.setFieldsValue({
            report_management : true,
          })
        }

        const handleSupportChange = (checkedValues) => {
          setSupportValue(checkedValues.target.value)
          form.setFieldsValue({
            support_management : true,
          })
        }

        const handleTicketChange = (checkedValues) => {
          setTicketValue(checkedValues.target.value)
          form.setFieldsValue({
            ticket_management : true,
          })
        }
  return ( 
    <Content>
      <Breadcrumb style={{padding:"10px 0px"}}>
               <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
               <Breadcrumb.Item>Create Role</Breadcrumb.Item>
             </Breadcrumb>
             <PageHeader
              ghost={false}
              onBack={() => window.history.back()}
              title="Create Role"
              subTitle="Role Management"
              extra={[
                <Button key="1"  type="primary" icon={<TabletFilled />} onClick = { () => history.push('/roles')}>Role List
                </Button>,
              ]}
            >
            </PageHeader>
            <br />

          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            style={{alignSelf: 'center'}}
            requiredMark={false}
            form={form}
          >
          {renderInput ('role', 'Role Name', [
              { required: true, message: 'Role name is required' },
            ])
          }
            <Divider orientation="center">Assign role's access</Divider>
            <Row>
                <Col md={12} xs={24}>
                  <Row>
                    <Col xs={11}>
                      {renderCheckbox('user_management', 'User Management',  [
                          { required: false },
                        ])
                      }
                    </Col>
                    {user?.map((item, key) => {
                      return (
                          <Checkbox
                            key={item.label}
                            onChange={handleUserChange}
                            checked={item.value == userValue}
                            value={item.value}
                          >
                            {item.label}
                          </Checkbox>
                      )
                    })}
                  </Row>

                  <Row>
                    <Col xs={11}>
                      {renderCheckbox('client_management', 'Client Management',   [
                          { required: false },
                        ])
                      }
                    </Col>
                    {client?.map((item, key) => {
                      return (
                        
                          <Checkbox
                            key={item.label}
                            onChange={handleClientChange}
                            checked={item.value == clientValue}
                            value={item.value}
                          >
                            {item.label}
                          </Checkbox>
                      
                      )
                    })}
                  </Row>
                  
                  <Row>
                    <Col xs={11}>
                      {renderCheckbox('employee_management', 'Employee Management', [
                          { required: false },
                        ])
                      }
                    </Col>
                    {employee?.map((item, key) => {
                      return (
                        
                          <Checkbox
                            key={item.label}
                            onChange={handleEmployeeChange}
                            checked={item.value == employeeValue}
                            value={item.value}
                          >
                            {item.label}
                          </Checkbox>
                      
                      )
                    })}
                  </Row>

                  
                  <Row>
                    <Col xs={11}>
                      {renderCheckbox('fiber_management', 'Fiber Management',  [
                          { required: false },
                        ])
                      }
                    </Col>
                    {fiber?.map((item, key) => {
                      return (
                        
                          <Checkbox
                            key={item.label}
                            onChange={handleFiberChange}
                            checked={item.value == fiberValue}
                            value={item.value}
                          >
                            {item.label}
                          </Checkbox>
                      
                      )
                    })}
                  </Row>
                  
                  <Row>
                    <Col xs={11}>
                      {renderCheckbox('setting_management', 'Setting Management', [
                          { required: false },
                        ])
                      }
                    </Col>
                    {setting?.map((item, key) => {
                      return (
                        
                          <Checkbox
                            key={item.label}
                            onChange={handleSettingChange}
                            checked={item.value == settingValue}
                            value={item.value}
                          >
                            {item.label}
                          </Checkbox>
                      
                      )
                    })}
                  </Row>
                </Col>
                
                <Col md={12} xs={24}>
                  
                <Row>
                  <Col xs={11}>
                    {renderCheckbox('pop_management', 'POP Management', [
                        { required: false },
                      ])
                    }
                  </Col>
                    {pop?.map((item, key) => {
                      return (
                        
                          <Checkbox
                            key={item.label}
                            onChange={handlePopChange}
                            checked={item.value == popValue}
                            value={item.value}
                          >
                            {item.label}
                          </Checkbox>
                      
                      )
                    })}
                </Row>

                <Row>
                  <Col xs={11}>
                    {renderCheckbox('report_management', 'Report Management', [
                        { required: false },
                      ])
                    }
                  </Col>
                    {report?.map((item, key) => {
                      return (
                        
                          <Checkbox
                            key={item.label}
                            onChange={handleReportChange}
                            checked={item.value == reportValue}
                            value={item.value}
                          >
                            {item.label}
                          </Checkbox>
                      
                      )
                    })}
                </Row>
                  
                <Row>
                  <Col xs={11}>
                    {renderCheckbox('support_management', 'Support Management', [
                        { required: false },
                      ])
                    }
                  </Col>
                    {support?.map((item, key) => {
                      return (
                        
                          <Checkbox
                            key={item.label}
                            onChange={handleSupportChange}
                            checked={item.value == supportValue}
                            value={item.value}
                          >
                            {item.label}
                          </Checkbox>
                      
                      )
                    })}
                </Row>
                  
                <Row>
                  <Col xs={11}>
                    {renderCheckbox('ticket_management', 'Ticket Management', [
                        { required: false },
                      ])
                    }
                  </Col>
                    {ticket?.map((item, key) => {
                      return (
                        
                          <Checkbox
                            key={item.label}
                            onChange={handleTicketChange}
                            checked={item.value == ticketValue}
                            value={item.value}
                          >
                            {item.label}
                          </Checkbox>
                      
                      )
                    })}
                </Row>
                </Col>
              </Row>
             
              
              <Form.Item {...tailLayout}> 
                <Button type="primary" htmlType="submit">
                  Create
                </Button>
              </Form.Item>
          </Form>

    </Content>
   );
}
 
// export default CreateRole;
 
export default connect(null,{createNewRole,getRoleById,updateRole})(CreateRole);