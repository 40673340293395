import React from 'react'
import { Title,DTable} from './detailStyle';

const ExtensionofCompanyNameReservationDetails = ({data}) => {
    const formData = data?.data?.form_data;
    const bill = data?.data?.bill_info;
    const billing_info = () => {
    let res  = Object.keys(bill).map((item) => {
         return (<p>{item} : <strong>${bill[item] }</strong></p>)
    })
    return res;
    }
    return ( 
             <DTable>
                                 <Title>Extension Details </Title> <hr/>
                                  <p>Proposed company name  : <strong>{formData?.proposed_company_name} </strong></p>
                                 <p>Provide name reservation number : <strong>{formData?.name_reservation} </strong></p>
                                
                                 <p>Select legal elements that you with to apply : <strong>{formData?.legal_elements } </strong></p>
                                
                                 <p>Select ACN types : <strong>{formData?.acn_types   } </strong></p>
                                 
                                 <p>Proposed extended date : <strong>{formData?.extended_date } </strong></p>
                                
                                 
                                 
                                 
                                 <Title>Declaration </Title> <hr/>
                                
                                 <p>I authorize onecompany to manage all legal documentation related to ASIC and submit all required documentations on behalf of my company.  : <strong>{formData?.authorize_company === true ? "yes" : "no"} </strong></p>
                                 
                                 <p>Declarant Name: <strong>{formData?.d_name} </strong></p>
                                 <p>Designation within the Company  : <strong>{formData?.designation1} </strong></p>

                                 
                                 <Title>Billing Information</Title> <hr/>
                              {billing_info()}  


                         
                     </DTable>
     );
}
 
export default ExtensionofCompanyNameReservationDetails;