import React, { Component } from 'react'
//import 'antd/dist/antd.css';
import { Table, Input, Button, Space, PageHeader, Popconfirm, Breadcrumb,Tag, Drawer,DatePicker,Select, Row, Col, Tooltip     } from 'antd';
import Highlighter from 'react-highlight-words';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, EyeOutlined, PlusCircleFilled, SearchOutlined,SettingOutlined,StopOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { getServices,getServiceDetails } from "../../actions/orders";
import { Link } from 'react-router-dom';
import history from '../../history';
import { connect } from 'react-redux';
import { renderTextField } from '../../component/form/form';
import Remarks from '../../component/remark';
import { Pagination } from 'antd';
import ServiceDetails from './orderDetails';
const { RangePicker } = DatePicker;
const { Option } = Select;
class OrderList extends Component {
    state = {
        searchText: '',
        searchedColumn: '',
        data: [],
        serviceDetails: null,
        detail: false,
        visible: false, 
        passVisible: false, 
        modalVisible: false, 
        editModalVisible: false, 
        placement: 'bottom',
        id: '',
        ds: null,
        de: null,
        sn: null,
        o: null,
        s: null,
        s_p: null,
        p: null,
        current: 1,
      };
      componentDidMount = async () => {
        const res = await this.props.getServices(this.state.ds,this.state.de,this.state.sn,this.state.o,this.state.s,this.state.s_p,this.state.p);
        this.setState({data: res})
      };
      handleStatus = async (user,active) => {
          const code = await this.props.updateAuth(user,active);
          if(code === 201 ){
            const res = await this.props.getUsers();
            this.setState({data: res})
          }
        }
      getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    
      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
      createData = (sl,order_id,service_name,status, payment_status,total_cost,created_at,action) => {
        return {sl,order_id, service_name,status, payment_status,total_cost,created_at,action};
      };
      
      showDrawer = (id) => {
        this.setState({
          visible: true,
          id: id
        });
      };
      handleRefresh = async () => {
        const res = await this.props.getUsers();
        this.setState({data: res})
      }
      
      handlePassVisible = (id) => {
        this.setState({passVisible: true,
          id: id
        })
      }
      handleModalVisible = () => {
        this.setState({modalVisible: !this.state.modalVisible})
      }
      handleVisible = async (order) => {
          history.push(`/order-details?order=${order}`)
      }
      handleEditModalVisible = (user_id) => {
        this.setState({editModalVisible: !this.state.editModalVisible, id : user_id})
      }
      onClosePass = () => {
        this.setState({
          passVisible: false,
        });
      };
      onClose = () => {
        this.setState({
          visible: false,
        });
      };
      makeLocalTime = (date) => {
        let mainDate = date.split('T')[0];
        let hours = date.split('T')[1].split(':')[0];
        let minutes = date.split('T')[1].split(':')[1];
        var am = true;
        if (hours > 12) {
            am = false;
            hours -= 12
        } else if (hours == 12) {
            am = false;
        } else if (hours == 0) {
            hours = 12
        }
        return mainDate + " , " + hours+":"+minutes + " " + (am ? "a.m" : "p.m")
      }
      handleDate = (date) => {
        if(date){ 
          const ds = new Date(date[0]._d)
          const de = new Date(date[1]._d)
          this.setState({ds: ds.toISOString(),de:de.toISOString()})
        }
        else {
          this.setState({ds: null,de:null})
          return
        }
      }
      handlePageChange = async (page) => {
        const res = await this.props.getServices(this.state.ds,this.state.de,this.state.sn,this.state.o,this.state.s,this.state.s_p,page);
        this.setState({current: page,data: res})
      }
      handleApiSearch = async () => {
        const res = await this.props.getServices(this.state.ds,this.state.de,this.state.sn,this.state.o,this.state.s,this.state.s_p,this.state.p);
        this.setState({data: res})
      }
      
      render() {
        const columns = [
          {
            title: 'SL',
            dataIndex: 'sl',
            key: 'sl',
            width: '10%',
          },
          {
            title: 'Order ID',
            dataIndex: 'order_id',
            key: 'order_id',
            width: '15%',
            ...this.getColumnSearchProps('order_id'),
          },
          {
            title: 'Service Name',
            dataIndex: 'service_name',
            key: 'service_name',
            width: '20%',
            ...this.getColumnSearchProps('service_name'),
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '15%',
            ...this.getColumnSearchProps('status'),
          },
          {
            title: 'Payment Status',
            dataIndex: 'payment_status',
            key: 'payment_status',
            width: '15%',
            ...this.getColumnSearchProps('payment_status'),
          },
          {
            title: 'Total Cost',
            dataIndex: 'total_cost',
            key: 'total_cost',
            width: '15%',
            ...this.getColumnSearchProps('total_cost'),
          },
          {
            title: 'Order Date',
            dataIndex: 'created_at',
            key: 'created_at',
            width: '20%',
            ...this.getColumnSearchProps('created_at'),
          },
          {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '25%',
          },
        ];
        const {data} = this.state
        const rows = [
          data?.orders
            ? data?.orders.map((item,id) => this.createData(
                id + 1,
                item.order_id, 
                item.service_name,
                
                item.status === 'Rejected' ?
                <Tag color="#f01e1e">&nbsp; {item.status} &nbsp;</Tag>
                :
                item.status === 'Done' ?
                <Tag color="#04836e">&nbsp; {item.status} &nbsp;</Tag>
                :
                <Tag color="#00798f">{item.status}</Tag>,
                item.payment_status === 'Paid' ?
                    <Tag color="#04836e">&nbsp; {item.payment_status} &nbsp;</Tag>
                :
                    <Tag color="#d36409">{item.payment_status} </Tag>,
                item.total_cost + '$', 
                this.makeLocalTime(item.created_at), 
                <>
                <Button size="small" type="primary" icon={<EyeOutlined  />} onClick={() => this.handleVisible(item.order_id)}>View</Button> &nbsp;
                {/* <Button size="small" type="primary" icon={<EditOutlined  />} onClick={() => this.handleEditModalVisible(item.order_id)}>Edit</Button> &nbsp; */}
                {/* <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => this.showDrawer(item.order_id)}><Button size="small" type="danger" icon={<DeleteOutlined  />}>Delete</Button></Popconfirm> */}
              
              </>
              ))
            : "",
        ];
        return (
          <Content style={{ margin: '0 16px' }}>


            <Breadcrumb style={{padding:"10px 0px"}}>
              <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
              <Breadcrumb.Item>Order List</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeader
              ghost={false}
              onBack={() => window.history.back()}
              title="Order List"
              subTitle="Order Management"
              extra={
                <Tag color="#2db7f5"> {data?.count} orders found</Tag>
              }
            >
            </PageHeader>
            <Row>
                <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Input placeholder="Service Name" allowClear style={{width:'99%'}} onChange={(e)=>this.setState({sn:e.target.value})} />
                </Col>
                <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Input placeholder="Order Id" allowClear style={{width:'99%'}} onChange={(e)=>this.setState({o:e.target.value})} />
               
                </Col>
                <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Select
                        showSearch
                        style={{ width: "99%" }}
                        placeholder="Select a status"
                        name="status"
                        optionFilterProp="children"
                        onChange={(e)=>this.setState({s:e})}
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value="">None</Option>
                        <Option value="Pending">Pending</Option>
                        <Option value="Processing">Processing</Option>
                        <Option value="Rejected">Rejected</Option>
                        <Option value="Done">Done</Option>
                    </Select>
                 </Col>
                <br />
                <br />
                <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Select
                        showSearch
                        style={{ width: "99%" }}
                        placeholder="Select a Payment status"
                        name="payment_status"
                        optionFilterProp="children"
                        onChange={(e)=>this.setState({s_p:e})}
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value="">None</Option>
                        <Option value="Unpaid">Unpaid</Option>
                        <Option value="Paid">Paid</Option>
                        <Option value="Refund">Refund</Option>
                    </Select>
                 </Col>
                
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <RangePicker onChange={this.handleDate} format="YYYY/MM/DD" style={{width: '80%'}} />
                    &nbsp;
                    &nbsp;
                    <Tooltip title="search" onClick={this.handleApiSearch}>
                        <Button type="primary" shape="circle" icon={<SearchOutlined />} />
                    </Tooltip>
                </Col>
            </Row>
            <Table columns={columns} dataSource={rows[0]} scroll={{ x: 1000 }} sticky pagination={false}/>
            <Pagination current={this.state.current} total={data?.count} pageSize="20" onChange={this.handlePageChange} pageSizeOptions = {[20]} />
            {/* <Remarks placement={placement} visible={visible} fnc={this.onClose} id={id} delFnc = {this.props.deleteUser} data = {this.state.data} refresh = {this.handleRefresh} />
            <ChangePass placement={placement} visible={passVisible} fnc={this.onClosePass} id={id} passFnc = {this.props.getServices} data = {this.state.data} refresh = {this.handleRefresh} />
            
            {this.state.editModalVisible ? <EditModalForm visible={this.state.editModalVisible} setVisible={this.handleEditModalVisible} user_id={this.state.id} refresh = {this.handleRefresh} /> : null}
            <ModalForm visible={this.state.modalVisible} setVisible={this.handleModalVisible} refresh = {this.handleRefresh}/> */}
        </Content>
        );
      }
    }
 
export default connect(null, {getServices,getServiceDetails })(OrderList);