import React from 'react'
import { Title,DTable} from './detailStyle';

const RequestCompanyDebtReportDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                              
                                 <Title>Company Details </Title> <hr/>
                                 <p>Name of company : <strong>{formData?.company_name} </strong></p>
                                 <p>Provide ACN : <strong>{formData?.acn} </strong></p>
                                
                                 <p>Provide ABN: <strong>{formData?.abn} </strong></p>
                                 
                              
                              
                              
                                 <Title>Declaration</Title> <hr/>
                                 <p>I authorize onecompany to manage all legal documentation related to ASIC and submit all required documentations on behalf of my company.  : <strong>{formData?.authorize_company === true ? "yes" : "no"} </strong></p>
                                 <p>Given name  : <strong>{formData?.g_name1} </strong></p>
                                 <p>Family name : <strong>{formData?.f_name1} </strong></p>
                                 <p>Designation within the Company  : <strong>{formData?.designation1} </strong></p>

                                 <Title>Billing Information</Title> <hr/>
                              {billing_info()}   

                              
                         
                     </DTable>
     );
}
 
export default RequestCompanyDebtReportDetails;