import React from 'react'
import { Title,DTable  } from './detailStyle';

const LogoDesignDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
            <DTable>  
               <Title>Logo design</Title> <hr/>
               <p>Logo Design Package : <strong>{formData?.plan} </strong></p>
               <p>Full Name : <strong>{formData?.full_name} </strong></p>
               <p>Email Address : <strong>{formData?.email} </strong></p>
               <p>Contact number : <strong>{formData?.contact_number} </strong></p>
               <p>Your Business Name : <strong>{formData?.business_name} </strong></p>
               <p>Your creative brief : <strong>{formData?.organization_abn_acn} </strong></p>
                                  
          
               <Title>Billing Information</Title> <hr/>
               {billing_info()}   
                         
            </DTable>
     );
}
 
export default LogoDesignDetails; 