import React, { Component } from 'react'
//import 'antd/dist/antd.css';
import { Table, Input, Button, Space, PageHeader, Popconfirm, Breadcrumb,Tag, Drawer, Modal, Form, Pagination, Row, Col, Divider, Tooltip, Select    } from 'antd';
import Highlighter from 'react-highlight-words';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, EyeOutlined, PlusCircleFilled, RollbackOutlined, SearchOutlined,SettingOutlined,StopOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { helper, helperDetails } from "../../actions/clients";
import { getSubClients, getSubClientDetails, createSubClient, updateSubClient, updateSubClientBandwidth, deleteSubClient } from "../../actions/subClients";
import { getBusinessType, getEmployee } from "../../actions/setting";

import { Link } from 'react-router-dom';
import history from '../../history';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { renderDate, renderInput, renderSelect, renderTextField } from '../../component/form/form';
import moment from 'moment';
import ClientServiceDetails from './clientsServiceDetails';
import Remarks from '../../component/remark';
// import { renderTextField } from '../../../component/form/form';
// import Remarks from '../../../component/remark';
// import ModalForm from './modalForm';
// import EditModalForm from './editModalForm';
const Search = Input.Search;
const { Option } = Select;
const showTotal = (total) => `Total ${total} items`;

const SubClientList = ({ 
  getSubClients, getSubClientDetails, updateSubClient, updateSubClientBandwidth, deleteSubClient, createSubClient, helper, helperDetails,
  getBusinessType, getEmployee, 
 }) => {
  const [ID, setID] = useState('')
  const [CID, setCID] = useState(null)
  const [SCID, setSCID] = useState('')
  const [name, setName] = useState('')
  const [state, setState] = useState(null)
  const [page, setPage] = useState(1)
  const [allPops, setAllPops] = useState([])
  const [allClient, setAllClient] = useState([])
  const [allRouters, setAllRouters] = useState([])
  const [allSwitch, setAllSwitch] = useState([])
  const [allConnectionType, setAllConnectionType] = useState([])
  const [data, setData] = useState(null)
  const [details, setDetails] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isBandwidthModalOpen, setIsBandwidthModalOpen] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [deleteOption, setDeleteOption] = useState(false);
  const [businesstype, setBusinesstype] = useState([])
  const [employee, setEmployee] = useState([])
  const [district, setDistrict] = useState([])
  const [invoice, setInvoice] = useState([])
  const [access, setAccess] = useState([])
  const [form] = Form.useForm();
  const [cform] = Form.useForm();
  const [bform] = Form.useForm();

  const createOption = (value, label) => {
    return {value, label};
  };

  const getData = async () => {
    const res = await getSubClients(page, SCID, name, state,  CID)
    setData(res);
    const option = await helper();
    const bt = [... 
      option?.businessType?.map((item, key) => createOption(
      item, 
      item,
      ))
    ]

    const emp = [... 
      option?.employee?.map((item, key) => createOption(
      item, 
      item,
      ))
    ]

    const dis = [... 
      option?.district?.map((item, key) => createOption(
      item, 
      item,
      ))
    ]
    const inv = [... 
      option?.invoice?.map((item, key) => createOption(
      item, 
      item,
      ))
    ]
    const cli = [... 
      option?.client_name?.map((item, key) => (
        item?.status === 'active' ?
          createOption(
            item?.name + ',' +  item?.id,
            item?.name,
          )
        :
        []
      ))
    ]
    const pops = [... 
      option?.pop?.map((item, key) => createOption(
        item?.name + ',' +  item?.id, 
      item?.name,
      ))
    ]
    const cnType = [... 
      option?.connection_type?.map((item, key) => createOption(
      item, 
      item,
      ))
    ]

    setBusinesstype(bt)
    setEmployee(emp)
    setDistrict(dis)
    setInvoice(inv)
    setAllClient(cli)
    setAllConnectionType(cnType)
    setAllPops(pops)
    
  };
  
  const handleRefresh = async () => {
    const res = await getSubClients(page, SCID, name, state,  CID);
    setData(res);
  }


  useEffect(() => {
    setAccess(JSON.parse(localStorage.getItem('access'))?.client_management);
    getData()
  }, []);

  const handleApiSearch = async () => {
    const res = await getSubClients(page, SCID, name, state,  CID)
    setData(res);
  };

  const handleApiResetSearch = async () => {
    setPage('');
    setCID(null);
    setSCID('');
    setName('');
    setState(null);
    const res = await getSubClients('', '', '', '', '')
    setData(res);
  };

  const showModal = () => {
    setIsCreateModalOpen(true);
  };
  const showBModal = () => {
    setIsBandwidthModalOpen(true);
  };

  const handleOk = () => {
    setIsCreateModalOpen(false);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);
  };

  const handleEdit = async (item) => {
    const res =  await getSubClientDetails(item?.id)
    setIsEditModalOpen(true);
    handlePopSelect(res?.pop?._id);
    form.setFieldsValue({
      id: res?.id,
      name: res?.name,
      client: res?.client?.name+','+res?.client?.id,
      address: res?.address,
      status: res?.status,
      district: res?.district,
      area: res?.area,

      name1: res?.contact_person[0]?.name,
      number1: res?.contact_person[0]?.number,
      email1: res?.contact_person[0]?.email,
      description1: res?.contact_person[0]?.description,
      
      name2: res?.contact_person[1]?.name,
      number2: res?.contact_person[1]?.number,
      email2: res?.contact_person[1]?.email,
      description2: res?.contact_person[1]?.description,

      email: res?.email,
      bulk_email: res?.bulk_email,
      atm_name: res?.bank_info?.atm_name,
      atm_no: res?.bank_info?.atm_no,
      branch_name: res?.bank_info?.branch_name,
      service_in: res?.bank_info?.service_in,
      media: res?.bank_info?.media,
      branch_code: res?.bank_info?.branch_code,
      carrier: res?.bank_info?.carrier,

      link_from: res?.link_from,
      link_to: res?.link_to,
      connection_type: res?.connection_type,
      connection_description: res?.connection_description,
      pop: res?.pop?.name + ',' + res?.pop?._id,
      router: res?.router?.id,
      switch: res?.switch?.id,
      switch_port_no: res?.switch_port_no,
      vlanid: res?.vlanid,
      nated_from_ip: res?.nated_from_ip,
      ip_block: res?.ip_block,
      microtik_port: res?.microtik_port,
      mrtg: res?.mrtg,
      radio_ip: res?.radio_ip,
      sales_by: res?.sales_by,
      inst_date: moment(res?.inst_date),
      end_date: res?.end_date ? moment(res?.end_date) : '',
      description: res?.description,
     
    });
  };

  const handleBEdit = async (item) => {
    const res =  await getSubClientDetails(item?.id)
    setID(item?.id)
    setIsBandwidthModalOpen(true);
    bform.setFieldsValue({
      id: res?.id,
      unit_data: res?.unit_data,
      unit_data_band: res?.unit_data_band,
      internet_band: res?.internet_band,
      internet: res?.internet,
      youtube: res?.youtube,
      youtube_band: res?.youtube_band,
      other: res?.other,
      other_band: res?.other_band,
      facebook_band: res?.facebook_band,
      facebook: res?.facebook,
      bdix: res?.bdix,
      bdix_band: res?.bdix_band,
      unit_quantity: res?.unit_quantity,
      contact_amount: res?.contact_amount,
      vat_include: res?.vat_include,
      vat_rate: res?.vat_rate,
      rate_amount: res?.rate_amount,
      vat_amount: res?.vat_amount,
      bill_start_date: moment(res?.bill_start_date),
      rate_effect_date: moment(res?.rate_effect_date),
    });
  };
  
  const handleDelete = async (item) => {
    setID(item?.id)
    setDeleteOption(true);
    // const res =  await deleteSubClient(item?.id);
    // if(res === 201){
    //   getData();
    // };
  };

  const handleView = async (item) => {
    const res =  await getSubClientDetails(item?.id)
    setDetails(res)
    setIsDetailModalOpen(true)
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
      setSearchText('');
  };

  const makeLocalTime = (date) => {
    let hours = date.split("T")[1].split(":")[0];
    let minutes = date.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours == 12) {
      am = false;
    } else if (hours == 0) {
      hours = 12;
    }
    return (
      date.split("T")[0] +
      "," +
      hours +
      ":" +
      minutes +
      " " +
      (am ? "A.M" : "P.M")
    );
  };

  const capitalizer = s => 
    s.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
  
  const createData = (sl, id, client_id, name, area, address, pop, connection_type, district, status, inst_date, bill_start_date, end_date, action) => {
    return {sl, id, client_id, name, area, address, pop, connection_type, district, status, inst_date, bill_start_date, end_date, action};
  };

  const columns = [
    {
      title: 'SL',
      dataIndex: 'sl',
      key: 'sl',
      width: '10%',
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '20%',
      ...getColumnSearchProps('id'),
    },
    {
      title: 'Client',
      dataIndex: 'client_id',
      key: 'client_id',
      width: '20%',
      ...getColumnSearchProps('client_id'),
    },
    {
      title: 'Branch Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Area',
      dataIndex: 'area',
      key: 'area',
      width: '20%',
      ...getColumnSearchProps('area'),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: '20%',
      ...getColumnSearchProps('address'),
    },
    {
      title: 'Pop',
      dataIndex: 'pop',
      key: 'pop',
      width: '20%',
      ...getColumnSearchProps('pop'),
    },
    {
      title: 'Connection Type',
      dataIndex: 'connection_type',
      key: 'connection_type',
      width: '20%',
      ...getColumnSearchProps('connection_type'),
    },
    {
      title: 'District',
      dataIndex: 'district',
      key: 'district',
      width: '20%',
      ...getColumnSearchProps('district'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
    },
    {
      title: 'Inst. Date',
      dataIndex: 'inst_date',
      key: 'inst_date',
      width: '20%',
    },
    {
      title: 'Bill Start Date',
      dataIndex: 'bill_start_date',
      key: 'bill_start_date',
      width: '20%',
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      width: '20%',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
    },
  ];

  const rows = [
    data
      ? data?.list?.map((item, key) => createData(
        key + 1, 
        item?.id, 
        item?.client?.name + '(' + item?.client?.id + ')',
        item?.name,  
        item?.area, 
        item?.address, 
        item?.pop?.name + '(' + item?.pop?.id + ')',
        item?.connection_type, 
        item?.district,  
        
        item?.status == 'active' ? 
          (
            <Tag color="#6fcf20">Active</Tag>
          ) 
          : 
          item?.status == 'inactive' ? 
          (
            <Tag color="#e96710">Inactive</Tag>
          ) 
          : 
          item?.status == 'blocked' ? 
          (
            <Tag color="#e74c3c">Blocked</Tag>
          ) 
          : 
          (
            <Tag color="#f1c40f">Temporary close</Tag>
          ), 
        item?.inst_date ? makeLocalTime(item?.inst_date) : '', 
        item?.bill_start_date ? makeLocalTime(item?.bill_start_date) : '', 
        item?.end_date ? makeLocalTime(item?.end_date) : '', 
        <>
        {access === 'a' ?
          <>
          <Button size="small" type="primary" icon={<EditOutlined  />} onClick={() => handleEdit(item)}>Edit</Button> &nbsp;
            <Button size="small" type="primary" icon={<EditOutlined  />} onClick={() => handleBEdit(item)}>Edit Bandwidth</Button> &nbsp;
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this task?"
              okText="Yes"
              cancelText="No"
              type="danger"
              onConfirm={() => handleDelete(item)}
            >
              <Button size="small" type="danger" icon={<DeleteOutlined  />} >Delete</Button> &nbsp;
            </Popconfirm>
            
            <Button size="small" type="primary" icon={<EyeOutlined  />} onClick={() => handleView(item)}>View</Button> &nbsp;
          </>
        : null}
        {access === 'w' ?
          <>
          <Button size="small" type="primary" icon={<EditOutlined  />} onClick={() => handleEdit(item)}>Edit</Button> &nbsp;
            <Button size="small" type="primary" icon={<EditOutlined  />} onClick={() => handleBEdit(item)}>Edit Bandwidth</Button> &nbsp;
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this task?"
              okText="Yes"
              cancelText="No"
              type="danger"
              onConfirm={() => handleDelete(item)}
            >
              <Button size="small" type="danger" icon={<DeleteOutlined  />} >Delete</Button> &nbsp;
            </Popconfirm>
            
            <Button size="small" type="primary" icon={<EyeOutlined  />} onClick={() => handleView(item)}>View</Button> &nbsp;
          </>
        : null}
        {access === 'r' ? 
        <>
          <Button size="small" type="primary" icon={<EyeOutlined  />} onClick={() => handleView(item)}>View</Button> &nbsp;
          </>
        : null}
        </>
        ))
      : "",
  ];

  const onFinish = async (values) => {
    const res = await createSubClient(values, ID);
    if(res ===  201){
      getData();
      setIsCreateModalOpen(false);
      cform.resetFields();
    }
  };

  const onFinishEdit = async (values) => {
    const res = await updateSubClient(values, ID);
    if(res ===  201){
      getData();
      setIsEditModalOpen(false);
    }
  };

  const onFinishBandwidthEdit = async (values) => {
    const res = await updateSubClientBandwidth(values, ID);
    if(res ===  201){
      getData();
      setIsBandwidthModalOpen(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };
  
  const handlePagination = async (e) => {
    setPage(e);
    const res = await getSubClients(e, SCID, name, state,  CID)
    setData(res);
  };

  const handlePopSelect = async (e) => {
    if(e){
      const res =  await helperDetails(e?.split(',')[1] ? e?.split(',')[1] : e);
      if(res){
        const route = [... 
          res?.router?.map((item, key) => createOption(
          item?.id, 
          item?.name+ ' -- ' +item?.id,
          ))
        ]
        const switc = [... 
          res?.switch?.map((item, key) => createOption(
          item?.id, 
          item?.name+ ' -- ' +item?.id,
          ))
        ]
        setAllRouters(route)
        setAllSwitch(switc)
      }
      
    }
  };

  const handleEditPopSelect = async (e) => {
    
    form.setFieldsValue({
      router: '',
      switch: '',
     
    });
    if(e){
      const res =  await helperDetails(e?.split(',')[1] ? e?.split(',')[1] : e);
      if(res){
        const route = [... 
          res?.router?.map((item, key) => createOption(
          item?.id, 
          item?.name+ ' -- ' +item?.id,
          ))
        ]
        const switc = [... 
          res?.switch?.map((item, key) => createOption(
          item?.id, 
          item?.name+ ' -- ' +item?.id,
          ))
        ]
        setAllRouters(route)
        setAllSwitch(switc)
      }
      
    }
  };

  // const employee = [
  //   {value: 'ABC', label: 'ABC'},
  //   {value: 'DEF', label: 'DEF'},
  //   {value: 'GHI', label: 'GHI'},
  //   {value: 'JKL', label: 'JKL'},
  // ]

  const scheme = [
    {value: 'prepaid', label: 'Pre-paid'},
    {value: 'postpaid', label: 'Post-paid'},
  ]

  const status = [
    {value: 'active', label: 'Active'},
    {value: 'inactive', label: 'Inactive'},
    {value: 'hold', label: 'Temporary close'},
    {value: 'blocked', label: 'Blocked'}
  ]

  const vatStatus = [
    {value: true, label: 'Yes'},
    {value: false, label: 'No'},
  ]

  const band = [
    {value: 'kbps', label: 'Kbps'},
    {value: 'mbps', label: 'Mbps'},
    {value: 'gbps', label: 'Gbps'},
  ]

  return ( 
    <Content style={{ margin: '0 16px' }}>

      <Breadcrumb style={{padding:"10px 0px"}}>
        <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Client Service List</Breadcrumb.Item>
      </Breadcrumb>

      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Client Service List"
        subTitle="Client Management"
        extra={[
          <Button key="1"  type="primary" icon={<PlusCircleFilled />} onClick = { showModal}>Create New
          </Button>,
        ]}
      >
      </PageHeader>

      <Row>
        <Col xs={10} sm={10} md={6} lg={6} xl={6}>
            <Input name="b_id" value={SCID} size="middle" placeholder="Branch ID" allowClear style={{width:'99%'}} onChange={(e)=>setSCID(e.target.value)} />
        </Col>
        
        <Col xs={10} sm={10} md={6} lg={6} xl={6}>
            <Input name='b_name' value={name} size="middle" placeholder="Branch Name" allowClear style={{width:'99%'}} onChange={(e)=> setName(e.target.value)} />
        </Col>
        <Col xs={10} sm={10} md={6} lg={6} xl={6}>
          <Select
            placeholder="Select Status"
            size="middle"
            allowClear
            style={{width:'99%'}} onChange={(e)=> setState(e)}
            value={state}
          >
              <Option value=''>None</Option>
              {status.map(item => (
                  <Option value={item.value}>{item.label}</Option>
              ))}
          </Select>
        </Col>
        <Col xs={24}>
          <br />
        </Col>
        <Col xs={10} sm={10} md={6} lg={6} xl={6}>
          <Select
            showSearch
            name="client"
            placeholder="Select Client"
            size="middle"
            allowClear
            style={{width:'99%'}} onChange={(e)=> {setCID(e)}}
            value={CID}
            
          >
              <Option value=''>None</Option>
              {allClient.map(item => (
                item.value ? <Option value={item.value}>{item.label}</Option> : null
              ))}
          </Select>
        </Col>
        <br />
        <br />
        
        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
            <Tooltip title="search" onClick={handleApiSearch}>
            <Button type="primary" icon={<SearchOutlined />} >Search </Button>
            </Tooltip>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
            
            <Tooltip title="search" onClick={handleApiResetSearch}>
            <Button type="primary" icon={<RollbackOutlined />}>Reset </Button>
            </Tooltip>
        </Col>
      </Row>
      <br />

      <Table size='small' columns={columns} pagination={false} dataSource={rows[0]} scroll={{ x: 1000 ,y: 400 }} sticky/>
      <br />

      <Pagination  showTotal={showTotal} current={page} pageSize="20" total={data?.count} onChange={(e) => { handlePagination(e); }} />
      
      <Modal 
        title="Add Client Service" 
        open={isCreateModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel} 
        width={1100}
        footer={null}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={cform}
        >
          <Row gutter={0}>
            {/* ===========Basic============== */}
            <Col xs={24}>
              <Row gutter={0}>
                <Col xs={12}>
                  {renderInput('id', 'Branch ID', [
                    { required: true, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderInput('name', 'Client Branch Name', [
                      { required: true, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderSelect('client', 'Client', [
                      { required: true, message: 'This field is required!' },
                    ], "", allClient, 'single', 'small')
                  }
                  {renderTextField('address', 'Address', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                </Col>
                <Col xs={12}>
                  {renderSelect('district', 'District', [
                    { required: false, message: 'This field is required!' },
                    ], "", district, 'single', 'small')
                  }
                  {renderInput('area', 'Area', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderSelect('status', 'Client Status', [
                      { required: true, message: 'This field is required!' },
                    ], "", status, 'single', 'small')
                  }
                  {renderInput('email', 'Eamil Address', [
                     {
                       pattern: new RegExp(/\S+@\S+\.\S+/),
                      message: "Email is not valid",
                    },
                   { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderTextField('bulk_email', 'Bulk Email Address', [
                    ], 'small')
                  }
                  
                </Col>
                <Col xs={12}>
                  <Divider>Contact Person 1</Divider>
                  {renderInput('name1', 'Name', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderInput('number1', 'Phone', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderInput('email1', 'Email Address', [
                    { 
                      pattern: new RegExp(/\S+@\S+\.\S+/),
                      message: "Email is not valid",
                    },
                    { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderTextField('description1', 'Description', [
                      // { required: false, message: 'This field is required!' },
                    ], 'small')
                  } 
                </Col>
                <Col xs={12}>
                  <Divider>Contact Person 2</Divider>
                  {renderInput('name2', 'Name', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderInput('number2', 'Phone', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderInput('email2', 'Email Address', [
                    { 
                      pattern: new RegExp(/\S+@\S+\.\S+/),
                      message: "Email is not valid",
                    },
                    { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderTextField('description2', 'Description', [
                      // { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                </Col>
              </Row>
            </Col>
            {/* ========== Bank=============== */}
            <Col xs={24}>
              <Row gutter={0}>
                <Col xs={24}>
                  <Divider>Bank Information</Divider>
                </Col>
                <Col xs={12}>
                  {renderInput('atm_name', 'ATM Name', [
                    { required: false, message: 'This field is required!' },
                  ], 'small')
                  }
                  {renderInput('atm_no', 'ATM No', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderInput('branch_name', 'Branch Name', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                </Col>
                <Col xs={12}>
                  {renderInput('service_in', 'Service In', [
                    { required: false, message: 'This field is required!' },
                  ], 'small')
                  } 
                  {renderInput('branch_code', 'Branch Code', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  } 
                  {renderInput('carrier', 'Carrier', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
            <Row gutter={0}>
                <Col xs={24}>
                  <Divider>Connection Information</Divider>
                </Col>
                <Col xs={12}>
                  {renderTextField('link_from', 'Link From', [
                      { required: false, message: 'This field is required!' },
                    ], 'large')
                  }
                  {renderTextField('link_to', 'Link To', [
                      { required: false, message: 'This field is required!' },
                    ], 'large')
                  }
                  {renderSelect('connection_type', 'Connection Type', [
                      { required: true, message: 'This field is required!' },
                    ], "", allConnectionType, 'single', 'small')
                  }
                  {renderTextField('connection_description', 'Connection Description', [
                      { required: false, message: 'This field is required!' },
                    ], 'large')
                  } 
                  <Form.Item
                    name="pop"
                    label="POP"
                    rules={[{ required: true, message: 'This field is required!' }]}
                  >
                    <Select
                        showSearch
                        placeholder="Select a pop"
                        size="small"
                        allowClear
                        onChange={(e) => handlePopSelect(e)}
                    >
                        {allPops?.map(item => (
                            <Option value={item.value}>{item.label}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                {allRouters.length > 0 ? renderSelect('router', 'Router', [
                  { required: false, message: 'This field is required!' },
                ], "", allRouters, 'single', 'small')
                : null
                }
                
                { 
                (allRouters.length > 0 && allSwitch.length > 0) ?
                  renderSelect('switch', 'Switch', [
                    { required: false, message: 'This field is required!' },
                    ], "", allSwitch, 'single', 'small')
                : null
                }
                {renderInput('microtik_port', 'Router Port', [
                    { required: false, message: 'This field is required!' },
                  ], 'small')
                }
                {renderInput('switch_port_no', 'Switch Port No', [
                    { required: false, message: 'This field is required!' },
                  ], 'small', 'number')
                }
                {renderInput('vlanid', 'VLANID', [
                    { required: false, message: 'This field is required!' },
                  ], 'small')
                }
                {renderInput('ip_block', 'IP Block', [
                    { required: false, message: 'This field is required!' },
                  ], 'small')
                }
                {renderInput('nated_from_ip', 'Nated from IP', [
                    { required: false, message: 'This field is required!' },
                  ], 'small')
                }
                {renderInput('radio_ip', 'Radio IP Block', [
                    { required: false, message: 'This field is required!' },
                  ], 'small')
                }
                {renderInput('rate_amount', 'Rate Amount', [
                    { required: false, message: 'This field is required!' },
                  ], 'small', 'number')
                }
                {renderInput('vat_amount', 'Vat Amount', [
                    { required: false, message: 'This field is required!' },
                  ], 'small', 'number')
                }
                </Col>
                <Col xs={12}>
                  {renderInput('media', 'Media', [
                      { required: true, message: 'This field is required!' },
                    ], 'small')
                  } 
                  {renderInput('unit_quantity', 'Unit Quantity', [
                    { required: false, message: 'This field is required!' },
                  ], 'small', 'number')
                  }
                  {renderInput('contact_amount', 'Contact Amount', [
                      { required: false, message: 'This field is required!' },
                    ], 'small', 'number')
                  }
                  {renderInput('mrtg', 'MRTG', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderTextField('description', 'Description', [
                      { required: false, message: 'This field is required!' },
                    ], 'large')
                  }

                  <Row>
                    <Col  xs={11}>
                      {renderInput('internet', 'Internet', [
                          { required: false, message: 'This field is required!' },
                        ], 'small', 'number')
                      }
                    </Col>
                    <Col  xs={11}>
                      {renderSelect('internet_band', 'Unit', [
                          { required: false, message: 'This field is required!' },
                        ], "", band, 'single', 'small')
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={11}>
                      {renderInput('unit_data', 'Data Bandwidth', [
                          { required: false, message: 'This field is required!' },
                        ], 'small', 'number')
                      } 
                    </Col>
                    <Col  xs={11}>
                      {renderSelect('unit_data_band', 'Unit', [
                          { required: false, message: 'This field is required!' },
                        ], "", band, 'single', 'small')
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col  xs={11}>
                      {renderInput('youtube', 'Youtube', [
                          { required: false, message: 'This field is required!' },
                        ], 'small', 'number')
                      }
                    </Col>
                    <Col  xs={11}>
                      {renderSelect('youtube_band', 'Unit', [
                          { required: false, message: 'This field is required!' },
                        ], "", band, 'single', 'small')
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col  xs={11}>
                      {renderInput('facebook', 'Facebook', [
                          { required: false, message: 'This field is required!' },
                        ], 'small', 'number')
                      }
                    </Col>
                    <Col  xs={11}>
                      {renderSelect('facebook_band', 'Unit', [
                          { required: false, message: 'This field is required!' },
                        ], "", band, 'single', 'small')
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col  xs={11}>
                      {renderInput('bdix', 'BDIX', [
                          { required: false, message: 'This field is required!' },
                        ], 'small', 'number')
                      }
                    </Col>
                    <Col  xs={11}>
                      {renderSelect('bdix_band', 'Unit', [
                          { required: false, message: 'This field is required!' },
                        ], "", band, 'single', 'small')
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col  xs={11}>
                      {renderInput('other', 'Other', [
                          { required: false, message: 'This field is required!' },
                        ], 'small', 'number')
                      }
                    </Col>
                    <Col  xs={11}>
                      {renderSelect('other_band', 'Unit', [
                          { required: false, message: 'This field is required!' },
                        ], "", band, 'single', 'small')
                      }
                    </Col>
                  </Row>
               
                  {renderSelect('sales_by', 'Sales By', [
                      { required: false, message: 'This field is required!' },
                    ], "", employee, 'single', 'small')
                  }
                  {renderDate('inst_date', 'Inst. Date', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderDate('bill_start_date', 'Bill Start Date', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderDate('end_date', 'End Date', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderDate('rate_effect_date', 'Rate Effect Date', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                </Col>
                
              </Row>
            </Col>
          </Row>
           
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      </Modal>
      
      <Modal 
        title="Edit Client Service" 
        open={isEditModalOpen} 
        onOk={() => setIsEditModalOpen(false)} 
        onCancel={() => setIsEditModalOpen(false)} 
        width={1000}
        footer={null}
        form={form}
      >
        <Form
          {...layout}
          name="Edit "
          initialValues={{ remember: true }}
          onFinish={onFinishEdit}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          
          
          <Row gutter={0}>
            
            {/* ===========Basic============== */}
            <Col xs={24}>
              <Row gutter={0}>
                <Col xs={12}>
                  
                  <Form.Item
                      label="Branch ID"
                      name="id"
                      rules={[{ required: true, message: 'This field is required!' }]}
                      
                      >
                      <Input size="small" name="id"disabled />
                  </Form.Item>
                  {/* {renderInput('id', 'Branch ID', [
                    { required: true, message: 'This field is required!' },
                    ], 'small')
                  } */}
                  {renderInput('name', 'Client Branch Name', [
                      { required: true, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderSelect('client', 'Client', [
                      { required: true, message: 'This field is required!' },
                    ], "", allClient, 'single', 'small')
                  }
                  {renderTextField('address', 'Address', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                </Col>
                <Col xs={12}>
                  {renderSelect('district', 'District', [
                    { required: false, message: 'This field is required!' },
                    ], "", district, 'single', 'small')
                  }
                  {renderInput('area', 'Area', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderSelect('status', 'Client Status', [
                      { required: true, message: 'This field is required!' },
                    ], "", status, 'single', 'small')
                  }
                  {renderInput('email', 'Eamil Address', [
                     {
                       pattern: new RegExp(/\S+@\S+\.\S+/),
                      message: "Email is not valid",
                    },
                   { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderTextField('bulk_email', 'Bulk Email Address', [
                    ], 'small')
                  }
                  
                </Col>
                <Col xs={12}>
                  <Divider>Contact Person 1</Divider>
                  {renderInput('name1', 'Name', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderInput('number1', 'Phone', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderInput('email1', 'Eamil Address', [
                    { 
                      pattern: new RegExp(/\S+@\S+\.\S+/),
                      message: "Email is not valid",
                    },
                    { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderTextField('description1', 'Description', [
                      // { required: false, message: 'This field is required!' },
                    ], 'small')
                  } 
                </Col>
                <Col xs={12}>
                  <Divider>Contact Person 2</Divider>
                  {renderInput('name2', 'Name2', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderInput('number2', 'Phone', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderInput('email2', 'Eamil Address', [
                    { 
                      pattern: new RegExp(/\S+@\S+\.\S+/),
                      message: "Email is not valid",
                    },
                    { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderTextField('description2', 'Description', [
                      // { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                </Col>
              </Row>
            </Col>

            
            {/* ========== Bank=============== */}
            <Col xs={24}>
              <Row gutter={0}>
                <Col xs={24}>
                  <Divider>Bank Information</Divider>
                </Col>
                <Col xs={12}>
                  {renderInput('atm_name', 'ATM Name', [
                    { required: false, message: 'This field is required!' },
                  ], 'small')
                  }
                  {renderInput('atm_no', 'ATM No', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                  {renderInput('branch_name', 'Branch Name', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                </Col>
                <Col xs={12}>
                  {renderInput('service_in', 'Service In', [
                    { required: false, message: 'This field is required!' },
                  ], 'small')
                  } 
                  {renderInput('branch_code', 'Branch Code', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  } 
                  {renderInput('carrier', 'Carrier', [
                      { required: false, message: 'This field is required!' },
                    ], 'small')
                  }
                </Col>
              </Row>
            </Col>
            
            {/* ========== Connection=============== */}
            <Col xs={24}>
              <Row gutter={0}>
                <Col xs={24}>
                  <Divider>Connection Information</Divider>
                </Col>

            <Col xs={12}>
              {renderSelect('connection_type', 'Connection Type', [
                  { required: true, message: 'This field is required!' },
                ], "", allConnectionType, 'single', 'small')
              }
              {renderInput('mrtg', 'MRTG', [
                  { required: false, message: 'This field is required!' },
                ], 'small')
              }
              {renderTextField('description', 'Description', [
                  { required: false, message: 'This field is required!' },
                ], 'large')
              } <Form.Item
              name="pop"
              label="POP"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Select
                  showSearch
                  placeholder="Select a pop"
                  size="small"
                  allowClear
                  onChange={(e) => handleEditPopSelect(e)}
              >
                  {allPops?.map(item => (
                      <Option value={item.value}>{item.label}</Option>
                  ))}
              </Select>
            </Form.Item> 
            {allSwitch.length > 0 ?
            <>
              {renderSelect('router', 'Router', [
                { required: false, message: 'This field is required!' },
              ], "", allRouters, 'single', 'small')
              }
              {renderSelect('switch', 'Switch', [
                  { required: false, message: 'This field is required!' },
                ], "", allSwitch, 'single', 'small')
              }
            </>
            : null
            }
            
            {renderInput('microtik_port', 'Router Port', [
                { required: false, message: 'This field is required!' },
              ], 'small')
            }
            {renderInput('switch_port_no', 'Switch Port No', [
                { required: false, message: 'This field is required!' },
              ], 'small')
            }
            {renderInput('vlanid', 'VLANID', [
                { required: false, message: 'This field is required!' },
              ], 'small')
            }
            {renderSelect('sales_by', 'Sales By', [
                { required: false, message: 'This field is required!' },
              ], "", employee, 'single', 'small')
            }
            {renderDate('inst_date', 'Inst. Date', [
                { required: false, message: 'This field is required!' },
              ], 'small')
            }
            {renderDate('end_date', 'End Date', [
                { required: false, message: 'This field is required!' },
              ], 'small')
            }
            </Col>
            <br />
            <Col xs={12}>
              
            {renderInput('media', 'Media', [
                      { required: true, message: 'This field is required!' },
                    ], 'small')
                  } 
            {renderTextField('link_from', 'Link From', [
                  { required: false, message: 'This field is required!' },
                ], 'large')
              }
              {renderTextField('link_to', 'Link To', [
                  { required: false, message: 'This field is required!' },
                ], 'large')
              }
              {renderTextField('connection_description', 'Connection Description', [
                  { required: false, message: 'This field is required!' },
                ], 'large')
              }
             
              {renderInput('ip_block', 'IP Block', [
                  { required: false, message: 'This field is required!' },
                ], 'small')
              }
              {renderInput('nated_from_ip', 'Nated from IP', [
                  { required: false, message: 'This field is required!' },
                ], 'small')
              }
              {renderInput('radio_ip', 'Radio IP Block', [
                  { required: false, message: 'This field is required!' },
                ], 'small')
              }
            </Col>
            </Row>
            </Col>
            <Col xs={24}>
              {renderTextField('remark', 'Remark', [
                  { required: true, message: 'This field is required!' },
                ], 'large')
              }

            </Col>
          </Row>
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      </Modal>
      
      <Modal 
        title="Edit Bandwidth" 
        open={isBandwidthModalOpen} 
        onOk={() => setIsBandwidthModalOpen(false)} 
        onCancel={() => setIsBandwidthModalOpen(false)} 
        width={1100}
        footer={null}
        form={bform}
      >
        <Form
          {...layout}
          name="Edit "
          initialValues={{ remember: true }}
          onFinish={onFinishBandwidthEdit}
          onFinishFailed={onFinishFailed}
          form={bform}
        >
          
          <Row gutter={0}>
            <Col xs={12}> 
              {renderInput('id', 'Sub Client ID', [
                { required: true, message: 'This field is required!' },
              ], 'small')
              }
              
              {renderInput('unit_quantity', 'Unit Quantity', [
                  { required: false, message: 'This field is required!' },
                ], 'small', 'number')
              }
              {renderInput('contact_amount', 'Contact Amount', [
                  { required: false, message: 'This field is required!' },
                ], 'small', 'number')
              }

                  <Row>
                    <Col  xs={11}>
                      {renderInput('internet', 'Internet', [
                          { required: false, message: 'This field is required!' },
                        ], 'small', 'number')
                      }
                    </Col>
                    <Col  xs={11}>
                      {renderSelect('internet_band', 'Unit', [
                          { required: false, message: 'This field is required!' },
                        ], "", band, 'single', 'small')
                      }
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={11}>
                      {renderInput('unit_data', 'Data Bandwidth', [
                          { required: false, message: 'This field is required!' },
                        ], 'small', 'number')
                      } 
                    </Col>
                    <Col  xs={11}>
                      {renderSelect('unit_data_band', 'Unit', [
                          { required: false, message: 'This field is required!' },
                        ], "", band, 'single', 'small')
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col  xs={11}>
                      {renderInput('youtube', 'Youtube', [
                          { required: false, message: 'This field is required!' },
                        ], 'small', 'number')
                      }
                    </Col>
                    <Col  xs={11}>
                      {renderSelect('youtube_band', 'Unit', [
                          { required: false, message: 'This field is required!' },
                        ], "", band, 'single', 'small')
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col  xs={11}>
                      {renderInput('facebook', 'Facebook', [
                          { required: false, message: 'This field is required!' },
                        ], 'small', 'number')
                      }
                    </Col>
                    <Col  xs={11}>
                      {renderSelect('facebook_band', 'Unit', [
                          { required: false, message: 'This field is required!' },
                        ], "", band, 'single', 'small')
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col  xs={11}>
                      {renderInput('bdix', 'BDIX', [
                          { required: false, message: 'This field is required!' },
                        ], 'small', 'number')
                      }
                    </Col>
                    <Col  xs={11}>
                      {renderSelect('bdix_band', 'Unit', [
                          { required: false, message: 'This field is required!' },
                        ], "", band, 'single', 'small')
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col  xs={11}>
                      {renderInput('other', 'Other', [
                          { required: false, message: 'This field is required!' },
                        ], 'small', 'number')
                      }
                    </Col>
                    <Col  xs={11}>
                      {renderSelect('other_band', 'Unit', [
                          { required: false, message: 'This field is required!' },
                        ], "", band, 'single', 'small')
                      }
                    </Col>
                  </Row>
            
            </Col>
            <Col xs={12}>
            
              {renderSelect('vat_include', 'Vat Include', [
                  { required: false, message: 'This field is required!' },
                ], "", vatStatus, 'single', 'small')
              }
              {renderInput('vat_rate', 'Vat Rate (%)', [
                  { required: false, message: 'This field is required!' },
                ], 'small', 'number')
              }
              {renderInput('rate_amount', 'Rate Amount', [
                  { required: false, message: 'This field is required!' },
                ], 'small', 'number')
              }
              {renderInput('vat_amount', 'Vat Amount', [
                  { required: false, message: 'This field is required!' },
                ], 'small', 'number')
              }
              {renderDate('bill_start_date', 'Bill Start Date', [
                  { required: false, message: 'This field is required!' },
                ], 'small')
              }
              {renderDate('rate_effect_date', 'Rate Effect Date', [
                  { required: false, message: 'This field is required!' },
                ], 'small')
              }
             
            </Col>
            <Col xs={24}>
              {renderTextField('remark', 'Remark', [
                  { required: true, message: 'This field is required!' },
                ], 'small')
              } 
            </Col>
          </Row>
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      </Modal>
      
      {
        details ?
        <Modal 
          title="Client Service Details" 
          open={isDetailModalOpen} 
          onOk={() => setIsDetailModalOpen(false)} 
          onCancel={() => setIsDetailModalOpen(false)} 
          width={1300}
          footer={null}
          form={bform}
        >
          <ClientServiceDetails data={details} />
        </Modal>
        :
        null
      }
      {deleteOption ?
      
      <Remarks
        title=" "
        btn="Submit"
        placement="bottom"
        visible={deleteOption}
        fnc={() => setDeleteOption(false)}
        id={ID}
        delFnc={deleteSubClient}
        refresh={handleRefresh}
      />
      :null}
    </Content>
   );
}
 
export default connect(null, {getSubClients, getSubClientDetails, createSubClient, updateSubClient, updateSubClientBandwidth, deleteSubClient, getBusinessType, getEmployee, helper, helperDetails})(SubClientList);