import React from 'react'
import { Title,DTable} from './detailStyle';

const DevelopmentAgreementDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>

                              <h3 >Developer and Customer Details</h3> <hr/>
                              <h3 >Developer Details</h3>
                              <p>Provide the full name of the Web Developer : <strong>{formData?.name_of_developer} </strong></p>
                              <p>Provide the ABN of the Web Developer : <strong>{formData?.abn_of_developer} </strong></p>
                              <p>Address of the Web Developer? : <strong>{formData?.address_of_developer} </strong></p>
                              <p>Provide the contact number of the Web Developer : <strong>{formData?.number_of_developer} </strong></p>
                              <p>What is the website (URL) of the Web developer? : <strong>{formData?.url_of_developer} </strong></p>
                              
                              <h3 >Customer Details</h3>
                              <p>Provide the full name of the Web customer : <strong>{formData?.name_of_customer} </strong></p>
                              <p>Provide the ABN of the Web customer : <strong>{formData?.abn_of_customer} </strong></p>
                              <p>Address of the Web customer? : <strong>{formData?.address_of_customer} </strong></p>
                              <p>Provide the contact number of the Web customer : <strong>{formData?.number_of_customer} </strong></p>
                              
                           
                           
                           
                              <h3 >Maintenance and Development Details</h3> <hr/>
                              <h3 >Maintenance Details</h3>
                              <p>Will the Web Developer be providing regular maintenance services? : <strong>{formData?.provide_service === 'true' ? 'Yes' : 'No'} </strong></p>
                              {formData?.provide_service ?
                              <>
                                 <p>How many hours of maintenance will the Web Developer provide each month? : <strong>{formData?.how_many_hours} </strong></p>
                                 <p>For how many months will the Web Developer provide maintenance services? : <strong>{formData?.how_many_months} </strong></p>
                              </> :null}
                              <h3 >Development Details</h3>
                              <p>What are the Development Specifications? : <strong>{formData?.development_specificatin} </strong></p>
                              <p>Outline the development phases? : <strong>{formData?.outline_dev_phase} </strong></p>
                              <p>If there is a delay, how many days does the Web Developer have to report the customer?  : <strong>{formData?.is_delay} </strong></p>
                              <p>Explain how the Web Developer will test the development phase, and how the customer will sign off on the tests?  : <strong>{formData?.how_will_test} </strong></p>
                              <p>After each development phase, the Web Developer will have how many days to test the phase? : <strong>{formData?.days_to_test} </strong></p>
                           
                           
                           
                              <h3 >Software and Agreement Details</h3> <hr/>
                              <h3 >Software Details</h3>
                              <p>List and describe the software that the Web Developer will be providing?  : <strong> {formData?.list_and_description} </strong></p>
                              <p>What is the software license fee?  : <strong> {formData?.software_license_fee } </strong></p>
                              <h3 >Agreement Details</h3>
                              <p>What is the total service fee to be paid to the Web Developer? : <strong> {formData?.total_service_fee } </strong></p>
                              <p>On what date will this Agreement become effective?  : <strong> {formData?.date_of_agreement } </strong></p>
                              
                           
                           
                              <h3 >Service Type</h3> <hr/>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                              <p>Describe if you have any other specific need that you want us to incorporate in the contract  : <strong> {formData?.describe_other_specific } </strong></p>
                           
                              <Title>Billing Information</Title> <hr/>
                              {billing_info()}

                     </DTable>
     );
}
 
export default DevelopmentAgreementDetails; 