import React, { Component, useEffect, useState } from 'react'
import { Form, Input, Button, Checkbox, PageHeader, Select, Breadcrumb, Divider, Row, Col } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import { TabletFilled } from '@ant-design/icons';
import history from '../../../history';
import CommonForm, { renderCheckbox, renderInput, renderSelect, renderTextField } from '../../../component/form/form';
import { connect } from 'react-redux';
import {createNewRole,getRoleById,updateRole} from '../../../actions/role'
const { Option } = Select;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 1, span: 20 },
  };
  const auth = [
    {value: true, label: 'Active'},
    {value: false , label: 'Inactive'},
  ]

const CreateRole = ({getRoleById,updateRole,location}) => {
  const [data, setData] = useState(null)
  const [roleId, setRoleId] = useState(null)
  const [visible, setVisible] = useState(false)
  const [userValue, setUserValue] = useState(false)
  const [clientValue, setClientValue] = useState(false)
  const [employeeValue, setEmployeeValue] = useState(false)
  const [fiberValue, setFiberValue] = useState(false)
  const [settingValue, setSettingValue] = useState(false)
  const [popValue, setPopValue] = useState(false)
  const [reportValue, setReportValue] = useState(false)
  const [supportValue, setSupportValue] = useState(false)
  const [ticketValue, setTicketValue] = useState(false)
  const [position, setPosition] = useState('bottom')
  const onFinish = (values) => {
            // console.log('Success:', values);
    updateRole(
      values, roleId, userValue, clientValue, employeeValue, fiberValue, 
      settingValue, popValue, reportValue, supportValue, ticketValue)
  };
   
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const [form] = Form.useForm();
  
        useEffect(async() => {
          // const res = await getProfile()
          const search = location.search;
          const id = new URLSearchParams(search).get("id");
          setRoleId(id)
          const res = await getRoleById(id)
          const user = res?.access?.user_management;
          const client = res?.access?.client_management;
          const employee = res?.access?.employee_management;
          const fiber = res?.access?.fiber_management;
          const setting = res?.access?.setting_management;
          const support = res?.access?.support_management;
          const report = res?.access?.report_management;
          const pop = res?.access?.pop_management;
          const ticket = res?.access?.ticket_management;

          if(user.match('a')) setUserValue('user_all');
          if(user.match('r')) setUserValue('user_read');
          if(user.match('w')) setUserValue('user_write');
          if(user.match('n')) setUserValue('user_none');
          
          if(client.match('a')) setClientValue('client_all');
          if(client.match('r')) setClientValue('client_read');
          if(client.match('w')) setClientValue('client_write');
          if(client.match('n')) setClientValue('client_none');

          if(employee.match('a')) setEmployeeValue('employee_all');
          if(employee.match('r')) setEmployeeValue('employee_read');
          if(employee.match('w')) setEmployeeValue('employee_write');
          if(employee.match('n')) setEmployeeValue('employee_none');

          if(fiber.match('a')) setFiberValue('fiber_all');
          if(fiber.match('r')) setFiberValue('fiber_read');
          if(fiber.match('w')) setFiberValue('fiber_write');
          if(fiber.match('n')) setFiberValue('fiber_none');

          if(setting.match('a')) setSettingValue('setting_all');
          if(setting.match('r')) setSettingValue('setting_read');
          if(setting.match('w')) setSettingValue('setting_write');
          if(setting.match('n')) setSettingValue('setting_none');

          if(support.match('a')) setSupportValue('support_all');
          if(support.match('r')) setSupportValue('support_read');
          if(support.match('w')) setSupportValue('support_write');
          if(support.match('n')) setSupportValue('support_none');

          if(report.match('a')) setReportValue('report_all');
          if(report.match('r')) setReportValue('report_read');
          if(report.match('w')) setReportValue('report_write');
          if(report.match('n')) setReportValue('report_none');

          if(pop.match('a')) setPopValue('pop_all');
          if(pop.match('r')) setPopValue('pop_read');
          if(pop.match('w')) setPopValue('pop_write');
          if(pop.match('n')) setPopValue('pop_none');

          if(ticket.match('a')) setTicketValue('ticket_all');
          if(ticket.match('r')) setTicketValue('ticket_read');
          if(ticket.match('w')) setTicketValue('ticket_write');
          if(ticket.match('n')) setTicketValue('ticket_none');

          setData(res)
              form.setFieldsValue({
                role: res?.name,
                active: res?.active,
                user_management : res?.access?.user_management !== 'n' ? true : false,
                user_all : res?.access?.user_management.match('a') ? true : false,
                user_read : res?.access?.user_management.match('r') ? true : false,
                user_write : res?.access?.user_management.match('w') ? true : false,
                user_none : res?.access?.user_management.match('n') ? true : false,
                
                client_management : res?.access?.client_management!== 'n' ? true : false,
                client_all : res?.access?.client_management.match('a') ? true : false,
                client_read : res?.access?.client_management.match('r') ? true : false,
                client_write : res?.access?.client_management.match('w') ? true : false,
                client_none : res?.access?.client_management.match('n') ? true : false,

                employee_management : res?.access?.employee_management!== 'n' ? true : false,
                employee_all : res?.access?.employee_management.match('a') ? true : false,
                employee_read : res?.access?.employee_management.match('r') ? true : false,
                employee_write : res?.access?.employee_management.match('w') ? true : false,
                employee_none : res?.access?.employee_management.match('n') ? true : false,

                fiber_management : res?.access?.fiber_management!== 'n' ? true : false,
                fiber_all : res?.access?.fiber_management.match('a') ? true : false,
                fiber_read : res?.access?.fiber_management.match('r') ? true : false,
                fiber_write : res?.access?.fiber_management.match('w') ? true : false,
                fiber_none : res?.access?.fiber_management.match('n') ? true : false,

                setting_management : res?.access?.setting_management !== 'n' ? true : false,
                setting_all : res?.access?.setting_management.match('a') ? true : false,
                setting_read : res?.access?.setting_management.match('r') ? true : false,
                setting_write : res?.access?.setting_management.match('w') ? true : false,
                setting_none : res?.access?.setting_management.match('n') ? true : false,

                support_management : res?.access?.support_management !== 'n' ? true : false,
                support_all : res?.access?.support_management.match('a') ? true : false,
                support_read : res?.access?.support_management.match('r') ? true : false,
                support_write : res?.access?.support_management.match('w') ? true : false,
                support_none : res?.access?.support_management.match('n') ? true : false,

                report_management : res?.access?.report_management!== 'n' ? true : false,
                report_all : res?.access?.report_management.match('a') ? true : false,
                report_read : res?.access?.report_management.match('r') ? true : false,
                report_write : res?.access?.report_management.match('w') ? true : false,
                report_none : res?.access?.report_management.match('n') ? true : false,
                
                pop_management : res?.access?.pop_management!== 'n' ? true : false,
                pop_all : res?.access?.pop_management.match('a') ? true : false,
                pop_read : res?.access?.pop_management.match('r') ? true : false,
                pop_write : res?.access?.pop_management.match('w') ? true : false,
                pop_none : res?.access?.pop_management.match('n') ? true : false,

                ticket_management : res?.access?.ticket_management !== 'n' ? true : false,
                ticket_all : res?.access?.ticket_management.match('a') ? true : false,
                ticket_read : res?.access?.ticket_management.match('r') ? true : false,
                ticket_write : res?.access?.ticket_management.match('w') ? true : false,
                ticket_none : res?.access?.ticket_management.match('n') ? true : false,
                
                // mobile_number: res?.mobile_number,
              });
        }, []);

    
        const user = [
          { label: "All", value: "user_all" },
          { label: "Read", value: "user_read" },
          { label: "Write", value: "user_write" },
          { label: "None", value: "user_none" },
        ];
        
        const client = [
          { label: "All", value: "client_all" },
          { label: "Read", value: "client_read" },
          { label: "Write", value: "client_write" },
          { label: "None", value: "client_none" },
        ];
        
        const employee = [
          { label: "All", value: "employee_all" },
          { label: "Read", value: "employee_read" },
          { label: "Write", value: "employee_write" },
          { label: "None", value: "employee_none" },
        ];
        
        const fiber = [
          { label: "All", value: "fiber_all" },
          { label: "Read", value: "fiber_read" },
          { label: "Write", value: "fiber_write" },
          { label: "None", value: "fiber_none" },
        ];
        
        const setting = [
          { label: "All", value: "setting_all" },
          { label: "Read", value: "setting_read" },
          { label: "Write", value: "setting_write" },
          { label: "None", value: "setting_none" },
        ];
        
        const pop = [
          { label: "All", value: "pop_all" },
          { label: "Read", value: "pop_read" },
          { label: "Write", value: "pop_write" },
          { label: "None", value: "pop_none" },
        ];
        
        const report = [
          { label: "All", value: "report_all" },
          { label: "Read", value: "report_read" },
          { label: "Write", value: "report_write" },
          { label: "None", value: "report_none" },
        ];
        
        const support = [
          { label: "All", value: "support_all" },
          { label: "Read", value: "support_read" },
          { label: "Write", value: "support_write" },
          { label: "None", value: "support_none" },
        ];
        
        const  ticket = [
          { label: "All", value: "ticket_all" },
          { label: "Read", value: "ticket_read" },
          { label: "Write", value: "ticket_write" },
          { label: "None", value: "ticket_none" },
        ];
  
        const handleUserChange = (checkedValues) => {
          setUserValue(checkedValues.target.value)
          form.setFieldsValue({
            user_management : true,
          })
        }

        const handleClientChange = (checkedValues) => {
          setClientValue(checkedValues.target.value)
          form.setFieldsValue({
            client_management : true,
          })
        }

        const handleEmployeeChange = (checkedValues) => {
          setEmployeeValue(checkedValues.target.value)
          form.setFieldsValue({
            employee_management : true,
          })
        }

        const handleFiberChange = (checkedValues) => {
          setFiberValue(checkedValues.target.value)
          form.setFieldsValue({
            fiber_management : true,
          })
        }

        const handleSettingChange = (checkedValues) => {
          setSettingValue(checkedValues.target.value)
          form.setFieldsValue({
            setting_management : true,
          })
        }

        const handlePopChange = (checkedValues) => {
          setPopValue(checkedValues.target.value)
          form.setFieldsValue({
            pop_management : true,
          })
        }

        const handleReportChange = (checkedValues) => {
          setReportValue(checkedValues.target.value)
          form.setFieldsValue({
            report_management : true,
          })
        }

        const handleSupportChange = (checkedValues) => {
          setSupportValue(checkedValues.target.value)
          form.setFieldsValue({
            support_management : true,
          })
        }

        const handleTicketChange = (checkedValues) => {
          setTicketValue(checkedValues.target.value)
          form.setFieldsValue({
            ticket_management : true,
          })
        }
  return ( 
    <Content>
      <Breadcrumb style={{padding:"10px 0px"}}>
               <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
               <Breadcrumb.Item>Edit Role</Breadcrumb.Item>
             </Breadcrumb>
             <PageHeader
              ghost={false}
              onBack={() => window.history.back()}
              title="Edit Role"
              subTitle="Role Management"
              extra={[
                <Button key="1"  type="primary" icon={<TabletFilled />} onClick = { () => history.push('/roles')}>Role List
                </Button>,
              ]}
            >
            </PageHeader>
            <br />

      {data ? 
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            style={{alignSelf: 'center'}}
            requiredMark={false}
            form={form}
          >
          {renderInput ('role', 'Role Name', [
              { required: true, message: 'Role name is required' },
            ])
          }
            <Divider orientation="center">Assign role's access</Divider>
            <Row>
                <Col md={12} xs={24}>
                  <Row>
                    <Col xs={11}>
                      {renderCheckbox('user_management', 'User Management',  [
                          { required: false },
                        ])
                      }
                    </Col>
                    {user?.map((item, key) => {
                      return (
                          <Checkbox
                            key={item.label}
                            onChange={handleUserChange}
                            checked={item.value == userValue}
                            value={item.value}
                          >
                            {item.label}
                          </Checkbox>
                      )
                    })}
                  </Row>

                  <Row>
                    <Col xs={11}>
                      {renderCheckbox('client_management', 'Client Management',   [
                          { required: false },
                        ])
                      }
                    </Col>
                    {client?.map((item, key) => {
                      return (
                        
                          <Checkbox
                            key={item.label}
                            onChange={handleClientChange}
                            checked={item.value == clientValue}
                            value={item.value}
                          >
                            {item.label}
                          </Checkbox>
                      
                      )
                    })}
                  </Row>
                  
                  <Row>
                    <Col xs={11}>
                      {renderCheckbox('employee_management', 'Employee Management', [
                          { required: false },
                        ])
                      }
                    </Col>
                    {employee?.map((item, key) => {
                      return (
                        
                          <Checkbox
                            key={item.label}
                            onChange={handleEmployeeChange}
                            checked={item.value == employeeValue}
                            value={item.value}
                          >
                            {item.label}
                          </Checkbox>
                      
                      )
                    })}
                  </Row>

                  
                  <Row>
                    <Col xs={11}>
                      {renderCheckbox('fiber_management', 'Fiber Management',  [
                          { required: false },
                        ])
                      }
                    </Col>
                    {fiber?.map((item, key) => {
                      return (
                        
                          <Checkbox
                            key={item.label}
                            onChange={handleFiberChange}
                            checked={item.value == fiberValue}
                            value={item.value}
                          >
                            {item.label}
                          </Checkbox>
                      
                      )
                    })}
                  </Row>
                  
                  <Row>
                    <Col xs={11}>
                      {renderCheckbox('setting_management', 'Setting Management', [
                          { required: false },
                        ])
                      }
                    </Col>
                    {setting?.map((item, key) => {
                      return (
                        
                          <Checkbox
                            key={item.label}
                            onChange={handleSettingChange}
                            checked={item.value == settingValue}
                            value={item.value}
                          >
                            {item.label}
                          </Checkbox>
                      
                      )
                    })}
                  </Row>
                </Col>
                
                <Col md={12} xs={24}>
                  
                <Row>
                  <Col xs={11}>
                    {renderCheckbox('pop_management', 'POP Management', [
                        { required: false },
                      ])
                    }
                  </Col>
                    {pop?.map((item, key) => {
                      return (
                        
                          <Checkbox
                            key={item.label}
                            onChange={handlePopChange}
                            checked={item.value == popValue}
                            value={item.value}
                          >
                            {item.label}
                          </Checkbox>
                      
                      )
                    })}
                </Row>

                <Row>
                  <Col xs={11}>
                    {renderCheckbox('report_management', 'Report Management', [
                        { required: false },
                      ])
                    }
                  </Col>
                    {report?.map((item, key) => {
                      return (
                        
                          <Checkbox
                            key={item.label}
                            onChange={handleReportChange}
                            checked={item.value == reportValue}
                            value={item.value}
                          >
                            {item.label}
                          </Checkbox>
                      
                      )
                    })}
                </Row>
                  
                <Row>
                  <Col xs={11}>
                    {renderCheckbox('support_management', 'Support Management', [
                        { required: false },
                      ])
                    }
                  </Col>
                    {support?.map((item, key) => {
                      return (
                        
                          <Checkbox
                            key={item.label}
                            onChange={handleSupportChange}
                            checked={item.value == supportValue}
                            value={item.value}
                          >
                            {item.label}
                          </Checkbox>
                      
                      )
                    })}
                </Row>
                  
                <Row>
                  <Col xs={11}>
                    {renderCheckbox('ticket_management', 'Ticket Management', [
                        { required: false },
                      ])
                    }
                  </Col>
                    {ticket?.map((item, key) => {
                      return (
                        
                          <Checkbox
                            key={item.label}
                            onChange={handleTicketChange}
                            checked={item.value == ticketValue}
                            value={item.value}
                          >
                            {item.label}
                          </Checkbox>
                      
                      )
                    })}
                </Row>
                </Col>
              </Row>
              {renderSelect('active', 'Auth', [
                  { required: true},
                ],
                'Select a option and change input text above', auth, 'none')
              }
              {renderTextField('remark', 'Remark',
              [{ required: true, message: 'Remark is required' },] )}
              
              <Form.Item {...tailLayout}> 
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              </Form.Item>
          </Form>
          : null}

    </Content>
   );
}
 
// export default CreateRole;
 
export default connect(null,{createNewRole,getRoleById,updateRole})(CreateRole);