import axios from 'axios';
import history from '../history';
import api from './apis';

import {
    LOADED,
    LOADEDING,
    SUCCESS_MESSAGE,
    ERROR_MESSAGE,
    VALIDATION_ERROR
} from './types';



export const createNewRole = (
    data, userValue, clientValue, employeeValue, fiberValue, 
    settingValue, popValue, reportValue, supportValue, ticketValue
    ) => async (dispatch) => {

    dispatch({type: LOADEDING});
console.log(clientValue)
    const access = {
        user_management : data.user_management ? 
        (userValue  === 'user_all' ? 'a' : '') +
        (userValue  === 'user_read' ? 'r' : '') +
        (userValue  === 'user_write' ? 'w' : '') +
        (userValue  === 'user_none' ? 'n' : '') 
        : 'n',
        client_management : data.client_management ? 
        (clientValue === 'client_all' ? 'a' : '') +
        (clientValue === 'client_read' ? 'r' : '') +
        (clientValue === 'client_write' ? 'w' : '') +
        (clientValue === 'client_none' ? 'n' : '')
        : 'n',
        employee_management : data.employee_management ? 
        (employeeValue === 'employee_all' ? 'a' : '') +
        (employeeValue === 'employee_read' ? 'r' : '') +
        (employeeValue === 'employee_write' ? 'w' : '') +
        (employeeValue === 'employee_none' ? 'n' : '')
        : 'n',
        fiber_management : data.fiber_management ? 
        (fiberValue === 'fiber_all' ? 'a' : '') +
        (fiberValue === 'fiber_read' ? 'r' : '') +
        (fiberValue === 'fiber_write' ? 'w' : '') +
        (fiberValue === 'fiber_none' ? 'n' : '') 
        : 'n',
        pop_management : data.pop_management ? 
        (popValue === 'pop_all' ? 'a' : '') +
        (popValue === 'pop_read' ? 'r' : '') +
        (popValue === 'pop_write' ? 'w' : '') +
        (popValue === 'pop_none' ? 'n' : '') 
        : 'n',
        report_management : data.report_management ? 
        (reportValue === 'report_all' ? 'a' : '') +
        (reportValue === 'report_read' ? 'r' : '') +
        (reportValue === 'report_write' ? 'w' : '') +
        (reportValue === 'report_none' ? 'n' : '') 
        : 'n',
        support_management : data.support_management ? 
        (supportValue  === 'support_all' ? 'a' : '') +
        (supportValue  === 'support_read' ? 'r' : '') +
        (supportValue  === 'support_write' ? 'w' : '') +
        (supportValue  === 'support_none' ? 'n' : '') 
        : 'n',
        ticket_management : data.ticket_management ? 
        (ticketValue === 'ticket_all' ? 'a' : '') +
        (ticketValue === 'ticket_read' ? 'r' : '') +
        (ticketValue === 'ticket_write' ? 'w' : '') +
        (ticketValue === 'ticket_none' ? 'n' : '') 
        : 'n',
        setting_management : data.setting_management ? 
        (settingValue === 'setting_all' ? 'a' : '') +
        (settingValue === 'setting_read' ? 'r' : '') +
        (settingValue === 'setting_write' ? 'w' : '') +
        (settingValue === 'setting_none' ? 'n' : '')
        : 'n',
    }
    // const access = {
    //     user_management : data.user_management ? 
    //     (data.user_all ? 'a' : '') +
    //     (data.user_read ? 'r' : '') +
    //     (data.user_write ? 'w' : '') +
    //     (data.user_none ? 'n' : '') 
    //     : 'n',
    //     client_management : data.client_management ? 
    //     (data.client_all ? 'a' : '') +
    //     (data.client_read ? 'r' : '') +
    //     (data.client_write ? 'w' : '') +
    //     (data.client_none ? 'n' : '')
    //     : 'n',
    //     employee_management : data.employee_management ? 
    //     (data.employee_all ? 'a' : '') +
    //     (data.employee_read ? 'r' : '') +
    //     (data.employee_write ? 'w' : '') +
    //     (data.employee_none ? 'n' : '')
    //     : 'n',
    //     fiber_management : data.fiber_management ? 
    //     (data.fiber_all ? 'a' : '') +
    //     (data.fiber_read ? 'r' : '') +
    //     (data.fiber_write ? 'w' : '') +
    //     (data.fiber_none ? 'n' : '') 
    //     : 'n',
    //     pop_management : data.pop_management ? 
    //     (data.pop_all ? 'a' : '') +
    //     (data.pop_read ? 'r' : '') +
    //     (data.pop_write ? 'w' : '') +
    //     (data.pop_none ? 'n' : '') 
    //     : 'n',
    //     report_management : data.report_management ? 
    //     (data.report_all ? 'a' : '') +
    //     (data.report_read ? 'r' : '') +
    //     (data.report_write ? 'w' : '') +
    //     (data.report_none ? 'n' : '') 
    //     : 'n',
    //     support_management : data.support_management ? 
    //     (data.support_all ? 'a' : '') +
    //     (data.support_read ? 'r' : '') +
    //     (data.support_write ? 'w' : '') +
    //     (data.support_none ? 'n' : '') 
    //     : 'n',
    //     ticket_management : data.ticket_management ? 
    //     (data.ticket_all ? 'a' : '') +
    //     (data.ticket_read ? 'r' : '') +
    //     (data.ticket_write ? 'w' : '') +
    //     (data.ticket_none ? 'n' : '') 
    //     : 'n',
    //     setting_management : data.setting_management ? 
    //     (data.setting_all ? 'a' : '') +
    //     (data.setting_read ? 'r' : '') +
    //     (data.setting_write ? 'w' : '') +
    //     (data.setting_none ? 'n' : '')
    //     : 'n',
    // }
    const object =  {
        "name" : data.role,
        "active": true,
        access

    }
    
    try{
    
        const response = await api(localStorage.getItem("token")).post('/user-management/role/create', {...object});
        
        if(response.status === 201){
            dispatch({type: LOADED});
            dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
            history.push('/roles');
        }

    }catch(err){
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
    
    }
} 

export const getAssignableRoles = () => async (dispatch)=> {
    
    dispatch({type: LOADEDING});
    try{
        const response = await api(localStorage.getItem("token")).get('/user-management/role/assignable');
        
        dispatch({type: LOADED});
        return response.data.roles
        // dispatch({type: GET_ROLES, payload: response.data.roles})
        
    }catch(err){
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
          /* ===========End============*/
    
}
}

export const getRoles = () => async (dispatch)=> {
    
    dispatch({type: LOADEDING});
    try{
        const response = await api(localStorage.getItem("token")).get('/user-management/role/all');
        
        dispatch({type: LOADED});
        return response.data.roles
        // dispatch({type: GET_ROLES, payload: response.data.roles})
        
    }catch(err){
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
          /* ===========End============*/
    
}
}
export const getRoleById = (id) => async (dispatch)=> {
    
    dispatch({type: LOADEDING});
    try{
        const response = await api(localStorage.getItem("token")).get(`/user-management/role/detail?role=${id}`);
        
        dispatch({type: LOADED});
        return response.data.role
        
    }catch(err){
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
          /* ===========End============*/
    
}
}


export const updateRole = (
    data, roleId, userValue, clientValue, employeeValue, fiberValue, 
    settingValue, popValue, reportValue, supportValue, ticketValue
    ) => async (dispatch) => {

    dispatch({type: LOADEDING});
    
    
    const access = {
        user_management : data.user_management ? 
        (userValue  === 'user_all' ? 'a' : '') +
        (userValue  === 'user_read' ? 'r' : '') +
        (userValue  === 'user_write' ? 'w' : '') +
        (userValue  === 'user_none' ? 'n' : '') 
        : 'n',
        client_management : data.client_management ? 
        (clientValue === 'client_all' ? 'a' : '') +
        (clientValue === 'client_read' ? 'r' : '') +
        (clientValue === 'client_write' ? 'w' : '') +
        (clientValue === 'client_none' ? 'n' : '')
        : 'n',
        employee_management : data.employee_management ? 
        (employeeValue === 'employee_all' ? 'a' : '') +
        (employeeValue === 'employee_read' ? 'r' : '') +
        (employeeValue === 'employee_write' ? 'w' : '') +
        (employeeValue === 'employee_none' ? 'n' : '')
        : 'n',
        fiber_management : data.fiber_management ? 
        (fiberValue === 'fiber_all' ? 'a' : '') +
        (fiberValue === 'fiber_read' ? 'r' : '') +
        (fiberValue === 'fiber_write' ? 'w' : '') +
        (fiberValue === 'fiber_none' ? 'n' : '') 
        : 'n',
        pop_management : data.pop_management ? 
        (popValue === 'pop_all' ? 'a' : '') +
        (popValue === 'pop_read' ? 'r' : '') +
        (popValue === 'pop_write' ? 'w' : '') +
        (popValue === 'pop_none' ? 'n' : '') 
        : 'n',
        report_management : data.report_management ? 
        (reportValue === 'report_all' ? 'a' : '') +
        (reportValue === 'report_read' ? 'r' : '') +
        (reportValue === 'report_write' ? 'w' : '') +
        (reportValue === 'report_none' ? 'n' : '') 
        : 'n',
        support_management : data.support_management ? 
        (supportValue  === 'support_all' ? 'a' : '') +
        (supportValue  === 'support_read' ? 'r' : '') +
        (supportValue  === 'support_write' ? 'w' : '') +
        (supportValue  === 'support_none' ? 'n' : '') 
        : 'n',
        ticket_management : data.ticket_management ? 
        (ticketValue === 'ticket_all' ? 'a' : '') +
        (ticketValue === 'ticket_read' ? 'r' : '') +
        (ticketValue === 'ticket_write' ? 'w' : '') +
        (ticketValue === 'ticket_none' ? 'n' : '') 
        : 'n',
        setting_management : data.setting_management ? 
        (settingValue === 'setting_all' ? 'a' : '') +
        (settingValue === 'setting_read' ? 'r' : '') +
        (settingValue === 'setting_write' ? 'w' : '') +
        (settingValue === 'setting_none' ? 'n' : '')
        : 'n',
    }
    const object =  {
        "role": roleId,
        "name" : data.role,
        "active": data.active,
        "remark": data.remark,
        access

    }
    try{
        const response = await api(localStorage.getItem("token")).patch('/user-management/role/update', {...object});
        
        if(response.status === 201){
            dispatch({ type: SUCCESS_MESSAGE, payload: "Role is updated Successfully" });
            dispatch({type: LOADED});
            history.push('/roles')
        }
        else{
           
        }

    }catch(err){
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
          /* ===========End============*/
    
    }
}


export const updateRoleAuth = (role, active) => async (dispatch) => {
    dispatch({type: LOADEDING});
    
    const object = {
      role:role,
      active:active,
    };
    dispatch({type: LOADED});
  
    try {
      const response = await api(localStorage.getItem("token")).patch("/user-management/role/active",{ ...object });
  
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({type: SUCCESS_MESSAGE,payload: "Role authentication is updated succesfully"});
      
      return response.status
      }
    } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
          /* ===========End============*/
    
    }
  };

export const deleteRole = (role, data) => async (dispatch) => {
dispatch({type: LOADEDING});


    try {
        const response = await axios('https://api-ispt2.hidayahsmart.solutions/sailor/v1/user-management/role/delete',
        {
            headers: {
                'content-type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem("token")}`
            },
            data: {
                role: role,
                remark: data.remark,
            },
            method: 'delete'
        }
        
        );

        if (response.status === 201) {
        
        dispatch({type: LOADED});
        dispatch({type: SUCCESS_MESSAGE,payload: "User is deleted succesfully"});
        
        return response.status
        }
    } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
          /* ===========End============*/
    
    }
};