import React from 'react'
import { Title,DTable} from './detailStyle';

const CancellationofsharesDetials = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                              
                                 <Title>Company Details </Title> <hr/>
                                  <p>Name of company  : <strong>{formData?.name_company2} </strong></p>
                                 <p>ABN or ACN : <strong>{formData?.abn1} </strong></p>
                                
                              
                              
                                 <Title>Cancellation details </Title> <hr/>
                                 <p>Number of shares cancelled  : <strong>{formData?.no_share_cancel } </strong></p>
                                 <p>Amount paid for cancelled shares (cash or otherwise) : <strong>{formData?.paid_cancel} </strong></p>
                                 <p>Effective date of cancelation   : <strong>{formData?.date_cancel } </strong></p>
                                 
                                 <p>Single selection option    : <strong>{formData?.section_option } </strong></p>
                                 
                                
                                 
                               

                                 
                                 <Title>Declaration </Title> <hr/>
                                 <p>Late lodgement fee : <strong>{formData?.late_lodgement_fee } </strong></p>
                                 
                                 <p>I authorize onecompany to manage all legal documentation related to ASIC and submit all required documentations on behalf of my company.  : <strong>{formData?.authorize_company === true ? "yes" : "no"} </strong></p>
                                 <p>Given name  : <strong>{formData?.g_name1} </strong></p>
                                 <p>Family name : <strong>{formData?.f_name1} </strong></p>
                                 <p>Designation within the Company  : <strong>{formData?.designation1} </strong></p>

                                 
                                 <Title>Billing Information</Title> <hr/>
                              {billing_info()} 


                         
                     </DTable>
     );
}
 
export default CancellationofsharesDetials;