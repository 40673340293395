import React from 'react'
import { Title,DTable} from './detailStyle';

const DeregistrationofCompanyDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                              
                                 <Title>Company Details </Title> <hr/>
                                 <p>Given name  : <strong>{formData?.g_name2} </strong></p>
                                 <p>Family name: <strong>{formData?.f_name2} </strong></p>
                                 <p>Position/designation in the company : <strong>{formData?.position1} </strong></p>
                                 <p>Name of company  : <strong>{formData?.name_company2} </strong></p>
                                 <p>ABN or ACN : <strong>{formData?.abn1} </strong></p>
                                 <p>Do you have outstanding Balance to ASIC : <strong>{formData?.balance_asci} </strong></p>
                                 <p>Do you want us to find out you accounts details if you do not know about the outstanding balance?  : <strong>{formData?.account_details} </strong></p>
                                
                                 
                                 
                              
                              
                                 <Title>Declaration </Title> <hr/>
                                 <p>All members of the company agree to the deregistration.  : <strong>{formData?.deregistration === true ? "yes" : "no"} </strong></p>
                                 <p>the company is not carrying on business   : <strong>{formData?.carrying_business === true ? "yes" : "no"} </strong></p>
                                 <p>the company's assets are worth less than $1000  : <strong>{formData?.company_assets === true ? "yes" : "no"} </strong></p>
                                 <p>the company has paid all fees and penalties payable under this Act  : <strong>{formData?.penalties_payble === true ? "yes" : "no"} </strong></p>
                                 <p>the company has no outstanding liabilities   : <strong>{formData?.outstanding_liabilities === true ? "yes" : "no"} </strong></p>
                                 <p>the company is not a party to any legal proceedings : <strong>{formData?.proceedings === true ? "yes" : "no"} </strong></p>
                                 <p>Applicant   : <strong>{formData?.applicant} </strong></p>
                                 
                                 <p>Unit/ house number   : <strong>{formData?.unit} </strong></p>
                                 <p>Street number and name  : <strong>{formData?.street_number} </strong></p>
                                 <p>Suburb : <strong>{formData?.suburb} </strong></p>
                                 <p>State  : <strong>{formData?.state} </strong></p>
                                 <p>Post code  : <strong>{formData?.post_code} </strong></p>
                                 <p>Contact number  : <strong>{formData?.contact} </strong></p>

                                 <p>I authorize onecompany to manage all legal documentation related to ASIC and submit all required documentations on behalf of my company.  : <strong>{formData?.authorize_company === true ? "yes" : "no"} </strong></p>
                                 <p>Given name  : <strong>{formData?.g_name1} </strong></p>
                                 <p>Family name : <strong>{formData?.f_name1} </strong></p>
                                 <p>Designation within the Company  : <strong>{formData?.designation1} </strong></p>

                                
                                 
                               

                                 
                                 <Title>Notice</Title> <hr/>
                                  {formData.person ? formData.person.map((item,id) =>(
                                     <> 
                                        <p style={{textAlign:'center'}}>Person {id + 1 }</p>
                                        <p>Name <strong>{item?.name} </strong></p>
                                        <p>Email: <strong>{item?.email} </strong></p>
                                        
                                        <p>Mail Address: <strong>{item?.mail_address} </strong></p>
                                          </>
                                 ))
                                 :
                                 null}

                                    
                            
                              <Title>Billing Information</Title> <hr/>
                              {billing_info()}  


                         
                     </DTable>
     );
}
 
export default DeregistrationofCompanyDetails;