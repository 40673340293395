import { CheckOutlined, MailOutlined, SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, Col, PageHeader, Row, Tag , DatePicker, Pagination, Button, Tooltip, Table} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {AsicNotification} from '../../actions/orders'

const { RangePicker } = DatePicker;
const OrderNotification = ({AsicNotification}) => {
    const [data, setData] = useState(null)
    const [count, setCount] = useState(null)
    const [ds, setDs] = useState(null)
    const [de, setDe] = useState(null)
    const [current, setCurrent] = useState(1)
    useEffect(async() => {
        const res = await AsicNotification()
        setData(res?.notifications)
        setCount(res?.count)
    }, [])
    console.log(data)
    const columns = [
        {
          title: 'SL',
          dataIndex: 'sl',
          key: 'sl',
          width: '10%',
        },
        {
          title: 'Notification ID',
          dataIndex: 'notification_id',
          key: 'notification_id',
          width: '15%',
        //   ...this.getColumnSearchProps('notification_id'),
        },
        {
          title: 'Message',
          dataIndex: 'message',
          key: 'message',
          width: '20%',
        //   ...this.getColumnSearchProps('message'),
        },
        {
          title: 'View Status',
          dataIndex: 'seen',
          key: 'seen',
          width: '15%',
        //   ...this.getColumnSearchProps('seen'),
        },
        {
          title: 'Date',
          dataIndex: 'created_at',
          key: 'created_at',
          width: '20%',
        //   ...this.getColumnSearchProps('created_at'),
        },
     
      ];
      const createData = (sl,notification_id, message, seen, created_at) => {
        return {sl,notification_id, message, seen, created_at};
      };
      const makeLocalTime = (date) => {
        let mainDate = date.split('T')[0];
        let hours = date.split('T')[1].split(':')[0];
        let minutes = date.split('T')[1].split(':')[1];
        var am = true;
        if (hours > 12) {
            am = false;
            hours -= 12
        } else if (hours == 12) {
            am = false;
        } else if (hours == 0) {
            hours = 12
        }
        return mainDate + " , " + hours+":"+minutes + " " + (am ? "a.m" : "p.m")
      }
    const  rows = [
        data 
          ? data?.map((item,id) => createData(
            
              id + 1,
              item.notification_id, 
              item.message,
              
              item.seen ? 
                <Tag icon={<CheckOutlined />} style={{backgroundColor: '#04836e', color: '#fff'}}>
                  Seen &nbsp;&nbsp;
                </Tag> 
              : 
              <Tag icon={<MailOutlined />} style={{backgroundColor: '#ff7675', color: '#fff'}}>
                Unseen
              </Tag>,  

              makeLocalTime(item.created_at),
             
            ))
          : "",
      ];
      console.log(rows[0])
      const handleDate = (date) => {
        if(date){ 
          const sd = new Date(date[0]._d)
          const ed = new Date(date[1]._d)
          setDs(sd.toISOString())
          setDe(ed.toISOString())
        }
        else {
            setDs(null)
            setDe(null)
          return
        }
      }
      const handleApiSearch = async () => {
        const res = await AsicNotification(current,ds,de)
        setData(res?.notifications)
        setCount(res?.count)
      }
      const handlePageChange = async (page) => {
        const res = await AsicNotification(page, ds, de)
        setData(res?.notifications)
        setCount(res?.count)
        setCurrent(page)
      }
      
    return ( 
        <Content style={{ margin: '0 16px' }}>
          <Breadcrumb style={{padding:"10px 0px"}}>
            <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Support List</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Order Notification"
            subTitle="Order Management"
            extra={
              <Tag color="#2db7f5"> {count} found</Tag>
            }
          >
          </PageHeader>
          <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <RangePicker onChange={handleDate} format="YYYY/MM/DD" style={{width: '80%'}} />
                  &nbsp;
                  &nbsp;
                  <Tooltip title="search" onClick={handleApiSearch}>
                      <Button type="primary" shape="circle" icon={<SearchOutlined />} />
                  </Tooltip>
              </Col>
          </Row>
          <Table columns={columns} dataSource={rows[0]} scroll={{ x: 1000 }} sticky pagination={false}/>
          <Pagination current={current} total={count} pageSize="20" onChange={handlePageChange} pageSizeOptions = {[20]}  />
        </Content>
     );
}
 
export default connect(null, {AsicNotification})(OrderNotification);