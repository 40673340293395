import React from 'react'
import { Title,DTable} from './detailStyle';

const NotificationofDissolutionofCompanyDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                              
                                 <Title>Company Details </Title> <hr/>
                                 <p>Company Name  : <strong>{formData?.company_name} </strong></p>
                                 <p>ARBN : <strong>{formData?.arbn} </strong></p>
                                 <p>Please select one of the following events : <strong>{formData?.service_needed} </strong></p>
                                

                                 {formData?.service_needed !== "A liquidator was appointed" ?
                                 <p>Effective date   : <strong>{formData?.effective_date} </strong></p>

                                 :
                                 <>
                                 <p>Given name  : <strong>{formData?.given_name} </strong></p>
                                 <p>Family name : <strong>{formData?.family_name} </strong></p>
                                 <p>Unit/Level   : <strong>{formData?.unit} </strong></p>
                                 <p>Street address  : <strong>{formData?.street_address} </strong></p>
                                 <p>Suburb : <strong>{formData?.suburb} </strong></p>
                                 <p>State  : <strong>{formData?.state} </strong></p>
                                 <p>Post code  : <strong>{formData?.post_code} </strong></p>
                                 <p>Country : <strong>{formData?.country1} </strong></p>
                                </>
                                }
                                
                              
                              
                               
                            

                                 
                                 <Title>Details of local agents </Title> <hr/>
                                 <p>Late Lodgement fee  : <strong>{formData?.late_lodgement_fee} </strong></p>
                                 <p>Declarant’s Name  : <strong>{formData?.d_name} </strong></p>
                                 <p>Designation within the Company   : <strong>{formData?.d_company} </strong></p>
                                 <p>onecompany local agent ID*  : <strong>{formData?.agent_id} </strong></p>
                                <p> I authorize onecompany to manage all legal documentation related to ASIC and submit all required documentations on behalf of my company.: <strong>{formData?.final_declaration === true ? "yes" : "no"} </strong> </p>
                                 
                                 
                                <Title>Billing Information</Title> <hr/>
                              {billing_info()}   
                              
                         
                     </DTable>
     );
}
 
export default NotificationofDissolutionofCompanyDetails;