import React, { Component } from 'react'
import { Form, Input, Button, Checkbox, PageHeader, Select, Breadcrumb } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import { TabletFilled } from '@ant-design/icons';
import history from '../../history';
import CommonForm from '../../component/form/form';
const { Option } = Select;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const gender = [
    {value: 'male', label: 'Male'},
    {value: 'female', label: 'Female'},
    {value: 'other', label: 'Other'},
  ]
  
  class CreateUser extends CommonForm {
    state = { 
      data: {
        username: '',
        password: '',
      }
     }
    onFinish = (values) => {
        console.log('Success:', values);
      };
    
      onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      render() { 
    return (
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{padding:"10px 0px"}}>
              <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
              <Breadcrumb.Item>Create User</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeader
              ghost={false}
              onBack={() => window.history.back()}
              title="Create User"
              subTitle="User Management"
              extra={[
                <Button key="1"  type="primary" icon={<TabletFilled />} onClick = { () => history.push('/userList')}>User List
                </Button>,
              ]}
            >
            </PageHeader>
            <br />
            <Form
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
            >
              {this.renderInput('Username', 'username', [
                  { required: true, message: 'Please input your username!' },
                ])
              }
              {this.renderInput('Password', 'password', [
                  { required: true, message: 'Please input your password!' },
                  { pattern: /^[a-zA-Z\d]{8}$/, message: 'Please valid password!' }
                ])
              }
              {this.renderSelect('Gender', 'gender', [
                  { required: true},
                ],
                'Select a option and change input text above', gender, 'none')
              }
              {this.renderTextFeild('Address', 'address', [
                  { required: true, message: 'Please input your address' },
                ])
              }
              {this.renderCheckbox('Remember me', 'remember', [
                  { required: false },
                ])
              }
              
              <Form.Item {...tailLayout}> 
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            
            </Form>
    
    </Content>
    )
  }
}
  
// const CreateUser = () => {
//     const routes = [
//         {
//           path: 'index',
//           breadcrumbName: 'C2C',
//         },
//         {
//           path: 'first',
//           breadcrumbName: 'CREATE USER',
//         },
//         // {
//         //   path: 'second',
//         //   breadcrumbName: 'LIST',
//         // },
//       ];

    
//     return ( 
        
//      );
// }
 
export default CreateUser;