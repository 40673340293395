import React from 'react'
import { Title,DTable  } from './detailStyle';

const ShareCertificateDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
            <DTable>  
               <Title>Contact Details</Title> <hr/>
               <p>First Name : <strong>{formData?.first_name} </strong></p>
               <p>Last Name : <strong>{formData?.last_name} </strong></p>
               <p>Organization Name : <strong>{formData?.organization_name} </strong></p>
               <p>Organization ABN/ACN : <strong>{formData?.organization_abn_acn} </strong></p>
               <h3 style={{textAlign: 'left'}}>Delivery Address</h3> <hr/>
               <p>House Number : <strong>{formData?.d_house_number} </strong></p>
               <p>Street number : <strong>{formData?.d_street_address} </strong></p>
               <p>City : <strong>{formData?.d_city} </strong></p>
               <p>State : <strong>{formData?.d_state} </strong></p>
               <p>Postcode : <strong>{formData?.d_postcode} </strong></p>
               <p>Country : <strong>{formData?.d_country} </strong></p>
               <p>Phone No : <strong>{formData?.d_phone} </strong></p>
               <p>Email : <strong>{formData?.d_email} </strong></p>
                              
         
            
               <Title>Product Details</Title> <hr/>
               <p>The number of blank share certificates : <strong>{formData?.share_certificate} </strong></p>
               <p>Your jurisdiction : <strong>{formData?.jurisdiction} </strong></p>
                                      
                
               <Title>Billing Information</Title> <hr/>
               {billing_info()}   
                         
            </DTable>
     );
}
 
export default ShareCertificateDetails; 