import React, { Component } from 'react';
//import 'antd/dist/antd.css';
import { Layout, Breadcrumb} from 'antd';
import './index.css';
import Topbar from '../../component/topbar';
import Sidebar from '../../component/sidebar';
import Switcher from './switcher';
import MessageAlert from '../../component/messageAlert';
import { connect } from 'react-redux';
const { Content, Footer } = Layout;

class PageLayout extends Component {
  state = {
  };

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  render() {
    return (
      <Layout style={{ minHeight: '100vh' }} >
        {this.props.success ? <MessageAlert type="success" value={this.props.success} /> : null}
        {this.props.error ? <MessageAlert type="error" value={this.props.error} /> : null}
        {this.props.validationError ? 
          this.props.validationError.map((item,id )=> (
            <MessageAlert type="error" value={item.message} />
          ))
        :
        null
      }
        <Sidebar />
        <Layout className="site-layout">
          
          <Topbar />
          <Switcher />
          <Footer style={{ textAlign: 'center' }}>©2023 Hidayah Smart Solutions </Footer>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    success: state.message.success,
    error: state.message.error,
    validationError: state.message.validationError,
  };
};
export default connect(mapStateToProps)(PageLayout);