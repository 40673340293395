import React, { Component } from 'react'
//import 'antd/dist/antd.css';
import { Table, Input, Button, Space, PageHeader, Popconfirm, Breadcrumb   } from 'antd';
import Highlighter from 'react-highlight-words';
import { DeleteOutlined, EditOutlined, EyeOutlined, PlusCircleFilled, SearchOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import history from '../../history';
import ModalForm from './user/modalForm';
const handleDelete = () => {
    console.log('ok')
  }
const data = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park',
      action: 
        <>
          <Button size="small" type="ghost" icon={<EyeOutlined  />}>View</Button> &nbsp;
          <Button size="small" type="primary" icon={<EditOutlined  />}>Edit</Button> &nbsp;
          <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={handleDelete}><Button size="small" type="danger" icon={<DeleteOutlined  />}>Delete</Button></Popconfirm>
        </>
    },
    {
      key: '2',
      name: 'Joe Black',
      age: 42,
      address: 'London No. 1 Lake Park',
      action: 
        <>
          <Button size="small" type="ghost" icon={<EyeOutlined  />}>View</Button> &nbsp;
          <Button size="small" type="primary" icon={<EditOutlined  />}>Edit</Button> &nbsp;
          <Popconfirm title="Are you sure？" okText="Yes" cancelText="No"><Button size="small" type="danger" icon={<DeleteOutlined  />}>Delete</Button></Popconfirm>
        </>
    },
    {
      key: '3',
      name: 'Jim Green',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
      action: 
        <>
          <Button size="small" type="ghost" icon={<EyeOutlined  />}>View</Button> &nbsp;
          <Button size="small" type="primary" icon={<EditOutlined  />}>Edit</Button> &nbsp;
          <Popconfirm title="Are you sure？" okText="Yes" cancelText="No"><Button size="small" type="danger" icon={<DeleteOutlined  />}>Delete</Button></Popconfirm>
        </>
    },
    {
      key: '4',
      name: 'Jim Red',
      age: 32,
      address: 'London No. 2 Lake Park',
      action: 
        <>
          <Button size="small" type="ghost" icon={<EyeOutlined  />}>View</Button> &nbsp;
          <Button size="small" type="primary" icon={<EditOutlined  />}>Edit</Button> &nbsp;
          <Popconfirm title="Are you sure？" okText="Yes" cancelText="No"><Button size="small" type="danger" icon={<DeleteOutlined  />}>Delete</Button></Popconfirm>
        </>
    },
    {
      key: '4',
      name: 'Jim Red',
      age: 32,
      address: 'London No. 2 Lake Park',
      action: 
        <>
          <Button size="small" type="ghost" icon={<EyeOutlined  />}>View</Button> &nbsp;
          <Button size="small" type="primary" icon={<EditOutlined  />}>Edit</Button> &nbsp;
          <Popconfirm title="Are you sure？" okText="Yes" cancelText="No"><Button size="small" type="danger" icon={<DeleteOutlined  />}>Delete</Button></Popconfirm>
        </>
    },
  ];
class UserListModal extends Component {
    state = {
        searchText: '',
        searchedColumn: '',
        visible: false,
      };
    handleVisible = () => {
      this.setState({visible: !this.state.visible})
    }
      getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    
      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
    
      render() {
        const columns = [
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '25%',
            ...this.getColumnSearchProps('name'),
          },
          {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
            width: '10%',
            ...this.getColumnSearchProps('age'),
          },
          {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: '35%',
            ...this.getColumnSearchProps('address'),
          },
          {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
          },
        ];
        return (
          <Content style={{ margin: '0 16px' }}>


            <Breadcrumb style={{padding:"10px 0px"}}>
              <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
              <Breadcrumb.Item>User List</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeader
              ghost={false}
              onBack={() => window.history.back()}
              title="User List"
              subTitle="User Management"
              extra={[
                <Button key="1"   type="primary" icon={<PlusCircleFilled />} onClick = {this.handleVisible}>Create New
                </Button>,
              ]}
            >
            </PageHeader>


            <Table columns={columns} dataSource={data} scroll={{ x: 1000 }} sticky/>
            
            <ModalForm visible={this.state.visible} setVisible={this.handleVisible} />
        </Content>
        );
      }
    }
 
export default UserListModal;