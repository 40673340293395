import React from 'react'
import { Title,DTable} from './detailStyle';

import { fileDownloader } from '../../../actions/orders';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { connect } from 'react-redux';
const ChangeofCompanyTypeDetails = ({data,fileDownloader}) => {
    const formData = data?.data?.form_data;
    const bill = data?.data?.bill_info;
    const billing_info = () => {
    let res  = Object.keys(bill).map((item) => {
         return (<p>{item} : <strong>${bill[item] }</strong></p>)
    })
    return res;
    }
    return ( 
             <DTable>
                                 <Title>Company Details </Title> <hr/>
                                  <p>Name of company  : <strong>{formData?.name_company2} </strong></p>
                                 <p>ABN or ACN : <strong>{formData?.abn1} </strong></p>
                                
                              
                        
                              
                                 <Title>New Type Details  </Title> <hr/>
                                 <p>Do you have resolution for the changing the company type?  : <strong>{formData?.changing_company_type === 'true' ? "yes":"no"} </strong></p>
                                 {formData?.changing_company_type === 'true' ?
                                  <p>Upload the resolution for changing the company type   : <strong>{data?.data?.files[0].upload_company_type ? <Button type="primary" icon={<DownloadOutlined />} size="middle" onClick = { () => fileDownloader(data?.data?.files[0].upload_company_type)} /> : 'Not Submitted'} </strong></p>
                                 :null}
                                {formData?.changing_company_type === 'false' ?
                                 <p>do you want us to prepare the resolution on your behalf?   : <strong>{formData?.behalf } </strong></p>
                                : null}
                                 <p>Date of the Resolution   : <strong>{formData?.date_resolution   } </strong></p>
                                 <p>Insert the proposed types of the company : <strong>{formData?.insert_company } </strong></p>
                                
                                 
                                 <p>Select the legal elements that you wish to apply to the company name. : <strong>{formData?.legal_elements } </strong></p>
                                
                                 
                                 
                                 
                                 <Title>Declaration </Title> <hr/>
                                
                                 <p>I authorize onecompany to manage all legal documentation related to ASIC and submit all required documentations on behalf of my company.  : <strong>{formData?.authorize_company === true ? "yes" : "no"} </strong></p>
                                 
                                 <p>Declarant Name: <strong>{formData?.d_name} </strong></p>
                                 <p>Designation within the Company  : <strong>{formData?.designation1} </strong></p>

                                 
                            

                                 <Title>Billing Information</Title> <hr/>
                              {billing_info()}   
                         
                     </DTable>
     );
}
 
export default connect(null,{fileDownloader})(ChangeofCompanyTypeDetails);