import React, { Component } from 'react'
import { Form, Input, Button, Checkbox, PageHeader, Select, Breadcrumb,Modal, Divider } from 'antd';
import CommonForm, { renderDate, renderInput, renderSelect, renderTextField } from '../../../component/form/form';

import { connect } from 'react-redux';
import { getRoles } from "../../../actions/role";
import { updateUser,getUsersById, userHelper } from "../../../actions/user";
import { useEffect, useState} from 'react';
const { Option } = Select;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const gender = [
    {value: 'male', label: 'Male'},
    {value: 'female', label: 'Female'},
    {value: 'other', label: 'Other'},
  ]
  const status = [
    {value: true, label: 'Active'},
    {value: false, label: 'Inactive'},
  ]
  

  
const EditModalForm = ({visible, setVisible,user_id,getRoles,getUsersById, userHelper, updateUser,refresh}) => {
      const [data, setData] = useState(null);
      const [roles, setRoles] = useState(null);
      const [department, setDepartment] = useState(null);
      const [form] = Form.useForm();
      useEffect(async() => {
        const res = await getRoles();
        setRoles(res);
        const helper = await userHelper();
        helper?.department.push({'name': 'all'})
        setDepartment(helper?.department);
        const result = await getUsersById(user_id);
        const pt = result.personal_info
        setData(result)
              form.setFieldsValue({
                name: result?.name,
                authenticated: result?.authenticated,
                email : result?.email,
                mobile_number : result?.mobile_number,
                user_id: result?.user_id,
                department: result?.department,
                // date_of_birth : pt?.date_of_birth,
                emergency_number : result?.personal_info?.emergency_number,
                gender : result?.personal_info?.gender,
                permanent_address : result?.personal_info?.permanent_address,
                present_address : result?.personal_info?.present_address,
                role : result?.roles?.role_id,
                
              });
      }, [])
      const createRoles = (label,value) => {
        return {label,value}
      }
      const onFinish = async (values) => {
        // console.log('Success:', values);
        const code = await updateUser(values)
        if(code === 201){
          refresh()
          setVisible()
        }
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      let option = [];
      option = (
        roles ? roles.map(item => 
          createRoles(item.name , item.role_id ))
      : null)

      let deptOption = [];
      deptOption = (
        department ? department.map(item => 
          createRoles(item?.name , item?.name ))
      : null)
    return ( 
          
          <Modal
          title="Edit User"
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          width={1000}
        >
          {data ?
              <Form
              name="basic"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              style={{alignSelf: 'center'}}
              requiredMark={false}
              form={form}
              >
                {renderInput('user_id', 'User ID', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                {renderInput('name', 'Full Name', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                {renderInput('email', 'Email', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                {renderInput('mobile_number', 'Mobile', [
                    { required: true, message: 'This field is required!' },
                    { pattern: /^(01)[0-9][0-9]{8}$/, message: 'Please valid number!' }
                  ])
                }
                {renderSelect('department', 'Department', [
                    { required: true},
                  ],
                  'Select a option', deptOption, 'none')
                }
                {renderSelect('authenticated', 'Status', [
                    { required: true},
                  ],
                  'Select a option', status, 'none')
                }
                {roles ? renderSelect('role', 'Role', [
                    { required: true},
                  ],
                  'Select a option', option, 'none')
                :null}
                {/* <Divider orientation="center">Personal Information</Divider>

                {renderDate('date_of_birth', 'Date of Birth', [
                    { required: true, message: 'Please input your address' },
                  ])
                }
                {renderSelect('gender', 'Gender', [
                    { required: true},
                  ],
                  'Select a option and change input text above', gender, 'none')
                }
                {renderTextField('permanent_address', 'Permanent Address', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                {renderTextField('present_address', 'Present Address', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                
                {renderInput('emergency_number', 'Emergency Number', [
                    { required: true, message: 'This field is required!' },
                    { pattern: /^(01)[0-9][0-9]{8}$/, message: 'Please valid phone number!' }
                  ])
                } */}
                {renderTextField('remark', 'Remark', [
                  { required: true, message: 'This field is required!' },
                ])
              }
                
                <Form.Item {...tailLayout}> 
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              
              </Form>
          :null}
        </Modal>
     );
}
 
export default connect(null,{getRoles,updateUser,getUsersById, userHelper})(EditModalForm);
