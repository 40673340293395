import React from 'react'
import { Title,DTable} from './detailStyle';

const AppointUsAsYourLocalAgentDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                              
                                 <Title>Declaration</Title> <hr/>
                                 <p>Name of the declarant  : <strong>{formData?.name_declarant} </strong></p>
                                 <p>First name : <strong>{formData?.first_name} </strong></p>
                                 <p>Last name  : <strong>{formData?.last_name} </strong></p>
                                 <p>Company Designation : <strong>{formData?.company_designation} </strong></p>
                                 <p>I authorise onecompany to manage all the legal documentation related to ASIC and submit all required documentation on behalf of my Company.  : <strong>{formData?.final_declaration === true ? "yes" : "no"} </strong></p>
                                 
                              
                              
                              
                                 <Title>Company details</Title> <hr/>
                                 <p>Company information : <strong>{formData?.company_information} </strong></p>
                                 <p>Company name  : <strong>{formData?.company_name} </strong></p>
                                 <p>Business name  : <strong>{formData?.business_name} </strong></p>
                                 <p>ARBN : <strong>{formData?.arbn} </strong></p>
                                 <p>Address : <strong>{formData?.address} </strong></p>
                                 <p>House number  : <strong>{formData?.house_number} </strong></p>
                                 <p>Street number  : <strong>{formData?.street_number} </strong></p>
                                 <p>City : <strong>{formData?.city} </strong></p>
                                 <p>State  : <strong>{formData?.state} </strong></p>
                                 <p>Post code  : <strong>{formData?.post_code} </strong></p>
                                 <p>Country : <strong>{formData?.country} </strong></p>
                                 <p>Phone No  : <strong>{formData?.phone_no} </strong></p>
                                 <p>Late Lodgement fee  : <strong>{formData?.late_lodgement_fee} </strong></p>
                             
                                 <Title>Billing Information</Title> <hr/>
                              {billing_info()}
                              
                         
                     </DTable>
     );
}
 
export default AppointUsAsYourLocalAgentDetails;