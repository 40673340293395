import React from 'react'
import { Title,DTable} from './detailStyle';

const BusinessNameDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   
   const billing_info = () => {
      let res  = Object.keys(bill).map((item) => {
         return (<p>{item} : <strong>${bill[item]}</strong> </p>)
      })
      return res;
   }
  return ( 
            <DTable>
                        
               <Title>Business Name</Title> <hr/>
               <p>Business name registration option : <strong>{formData?.business_option}</strong></p>
               <p>Do you need to register an ABN? : <strong>{formData?.needAbn ? 'Yes' : 'No'}</strong></p>
               {formData?.needAbn ?
               <>
                  <p>ABN activation date : <strong>{formData?.activation_date}</strong></p>
                  <p>Industry sector : <strong>{formData?.industry_sector}</strong></p>
                  <p>Describe your primary business activity : <strong>{formData?.describe_business_activity}</strong></p>
               </> : 
                  <p>Enter your ABN : <strong>{formData?.abn}</strong></p>
               }
            
            
               <Title>Business Name and type</Title> <hr/>
               <p>Proposed Business Name : <strong>{formData?.business_name} </strong></p>
               <p>Proposed Business Second Name : <strong>{formData?.business_second_name} </strong></p>
               <p>Proposed Business Third Name : <strong>{formData?.business_third_name} </strong></p>
               <p>Business Type: <strong>{formData?.business_type}</strong></p>
                  {/* ================Solo ============== */}
                  {formData?.business_type === 'solo' ?
                  <>
                  
                     <Title>Solo Trader info</Title>
                     <p>Given Name :<strong>{formData?.solo_given_name}</strong> </p>
                     <p>"Family Name : <strong>{formData?.solo_family_name}</strong></p>
                     <p>Have you been known by any other name in the past? : <strong>{formData?.other_name}</strong></p>
                     <p>Email address : <strong>{formData?.solo_email}</strong></p>
                     <p>Phone number : <strong>{formData?.solo_phone_number}</strong></p>
                     <p>Date of birth : <strong>{formData?.solo_date_of_birth}</strong></p>
                     <p>Country of birth : <strong>{formData?.solo_country_of_birth}</strong></p>
                     <p>State of birth : <strong>{formData?.solo_state_of_birth}</strong></p>
                     <p>City of birth : <strong>{formData?.solo_city_of_birth}</strong></p>
                     <p>Unit/Level : <strong>{formData?.solo_unit}</strong></p>
                     <p>Level : <strong>{formData?.solo_level}</strong></p>
                     <p>Street address : <strong>{formData?.solo_street_address}</strong></p>
                     <p>Suburb : <strong>{formData?.solo_suburb}</strong></p>
                     <p>State : <strong>{formData?.solo_state}</strong></p>
                     <p>Postcode : <strong>{formData?.solo_Postcode}</strong></p>
                     <p>Tax file number (TFN) : <strong>{formData?.solo_tfn}</strong></p>
                  </>
                  :null}
                  {/* ==============Partner=========== */}
                  {formData?.business_type === 'partnership' ?
                  <>
                  
                     {formData.partner ? formData.partner.map((item, id) => (
                           <div key={id}>
                              <h4>Partner - {id+1}</h4>
                              <p>Given Name : <strong>{item?.given_name} </strong></p>
                              <p>Family Name : <strong>{item?.family_name} </strong></p>
                              <p>Have you been known by any other name in the past?: <strong>{item?.other_name} </strong></p>
                              <p>Email address : <strong>{item?.email} </strong></p>
                              <p>Phone number : <strong>{item?.phone_number} </strong></p>
                              <p>Date of birth : <strong>{item?.date_of_birth} </strong></p>
                              <p>Country of birth : <strong>{item?.country_of_birth} </strong></p>
                              <p>State of birth : <strong>{item?.state_of_birth} </strong></p>
                              <p>City of birth : <strong>{item?.city_of_birth} </strong></p>
                              <p>Level : <strong>{item?.unit} </strong></p>
                              <p>Street number : <strong>{item?.level} </strong></p>
                              <p>Street name : <strong>{item?.street_address} </strong></p>
                              <p>Suburb/city : <strong>{item?.suburb} </strong></p>
                              <p>State : <strong>{item?.state} </strong></p>
                              <p>Postcode : <strong>{item?.postcode} </strong></p>
                              <p>Tax file number (TFN) : <strong>{item?.tfn} </strong></p>
                           </div>
                     )) : null}
                     <p>Do you need a Partnership Agreement ?: <strong>{formData?.need_agreement ? 'Yes' : 'No'}</strong></p>
                     
                  </>
                  :null}
                  {/* ==============Company=========== */}
                  {formData?.business_type === 'company' ?
                  <>
                     <p>ACN no : <strong>{formData?.acn_no} </strong></p>
                     <p>Is the company a majority-owned subsidiary? : <strong>{formData?.is_subsidiary ? 'Yes' : 'No'} </strong></p>
                     {formData?.is_subsidiary ? 
                           <p>Holding company’s ACN: <strong>{formData?.holding_company_acn} </strong></p>
                     : null}
                     {formData.director ? formData.director.map((item, id) => (
                           <div key={id}>
                                 <h4>Director - {id+1}</h4>
                                 <p>Given Name : <strong>{item?.given_name} </strong></p>
                                 <p>Family Name : <strong>{item?.family_name} </strong></p>
                                 <p>Have you been known by any other name in the past?: <strong>{item?.other_name} </strong></p>
                                 <p>Email address : <strong>{item?.email} </strong></p>
                                 <p>Phone number : <strong>{item?.phone_number} </strong></p>
                                 <p>Date of birth : <strong>{item?.date_of_birth} </strong></p>
                                 <p>Country of birth : <strong>{item?.country_of_birth} </strong></p>
                                 <p>State of birth : <strong>{item?.state_of_birth} </strong></p>
                                 <p>City of birth : <strong>{item?.city_of_birth} </strong></p>
                                 <p>Level : <strong>{item?.unit} </strong></p>
                                 <p>Street number : <strong>{item?.level} </strong></p>
                                 <p>Street name : <strong>{item?.street_address} </strong></p>
                                 <p>Suburb/city : <strong>{item?.suburb} </strong></p>
                                 <p>State : <strong>{item?.state} </strong></p>
                                 <p>Postcode : <strong>{item?.postcode} </strong></p>
                                 <p>Tax file number (TFN) : <strong>{item?.tfn} </strong></p>
                     
                           </div>
                     )) : null}
                     <p>Do you need a Partnership Agreement ?: {formData?.need_agreement ? 'Yes' : 'No'}</p>
                     
                  </>
                  :null}
                  
            
            
               <Title>Business Address</Title> <hr/>
                  <p>Unit/Level : <strong>{formData?.unit} </strong></p>
                  <p>Street address : <strong>{formData?.street_address} </strong></p>
                  <p>Suburb : <strong>{formData?.suburb} </strong></p>
                  <p>State : <strong>{formData?.state} </strong></p>
                  <p>Postcode : <strong>{formData?.postcode} </strong></p>
                  
                  <p>Do you need to register for GST? : <strong>{formData?.need_gst ? 'Yes' : 'No'} </strong></p>
                  {formData?.need_gst ? 
                  <>
                  <p>Estimated turn-over : <strong>{formData?.turn_over} </strong></p>
                  <p>When will you send GST results to the ATO (?) : <strong>{formData?.when_send} </strong></p>
                  <p>How often would you like to report to the Tax Office? : <strong>{formData?.when_tax} </strong></p>
                  <p>GST account commence date : <strong>{formData?.commence_date} </strong></p>
                  <p>Do you import goods and services into Australia : <strong>{formData?.import_goods ? 'Yes' : 'No'} </strong></p>
                  </>
                  : null}
               <Title>PAYG</Title> <hr/>
                  <p>Does your business have employees? : <strong>{formData?.have_employees ? 'Yes' : 'No'} </strong></p>
                  <p>Do you wish to sign up for PAYG through us? : <strong>{formData?.wish_to_sign ? 'Yes' : 'No'} </strong></p>
                  {formData?.wish_to_sign ? 
                        <>
                        <p>When do you want to commence : <strong>{formData?.when_commence} </strong></p>
                        <p>How many employees are you going to pay : <strong>{formData?.employee_number} </strong></p>
                        <p>How much do you expect to withhold from the payments each year? : <strong>{formData?.payments} </strong></p>
                        <p>Are you employing any working holiday makers? : <strong>{formData?.holiday_makers ? 'Yes' : 'No'} </strong></p>
                        <p>How are you going to provide your PAYG withholding payment annual summary report to the ATO? : <strong>{formData?.report_to_ato} </strong></p>
                        <p>How will you provide payment summaries to your payees? : <strong>{formData?.payment_summaries} </strong></p>
                        <p>Will you pay royalties, dividends or interest to non-residents? : <strong>{formData?.will_pay_royalties} </strong></p>
                        </>
                  : null}
         
         
         
         
            <Title>Declaration</Title> <hr/>
            <p>Name of the declarant : <strong>{formData?.declarant_given_name} {formData?.declarant_family_name}</strong></p>
            <p>Designation within the Company : <strong>{formData?.designation} </strong></p>
                        
            <Title style={{textAlign: 'left'}}>Billing Information</Title> <hr/>
            {billing_info}
               
         </DTable>
     );
}
 
export default BusinessNameDetails;