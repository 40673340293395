import React, { Component } from 'react'
//import 'antd/dist/antd.css';
import { Table, Input, Button, Space, PageHeader, Popconfirm, Breadcrumb,Tag, Drawer    } from 'antd';
import Highlighter from 'react-highlight-words';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, EyeOutlined, PlusCircleFilled, SearchOutlined,StopOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { getRoles,updateRoleAuth,deleteRole } from "../../../actions/role";
import { Link } from 'react-router-dom';
import history from '../../../history';
import { connect } from 'react-redux';
import { renderTextField } from '../../../component/form/form';
import Remarks from '../../../component/remark';


class RoleList extends Component {
    state = {
        searchText: '',
        searchedColumn: '',
        data: [],
        visible: false, 
        placement: 'bottom',
        id: '',
      };
      componentDidMount = async () => {
        const res = await this.props.getRoles();
        this.setState({data: res})
      };
      handleStatus = async (role,active) => {
          const code = await this.props.updateRoleAuth(role,active);
          if(code === 201 ){
            const res = await this.props.getRoles();
            this.setState({data: res})
          }
        }
      getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    
      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
      createData = (name, active,super_admin,created_at,action) => {
        return { name, active,super_admin,created_at,action};
      };
      makeLocalTime = (date) => {
        let mainDate = date.split('T')[0];
        let hours = date.split('T')[1].split(':')[0];
        let minutes = date.split('T')[1].split(':')[1];
        var am = true;
        if (hours > 12) {
            am = false;
            hours -= 12
        } else if (hours == 12) {
            am = false;
        } else if (hours == 0) {
            hours = 12
        }
        return mainDate + " , " + hours+":"+minutes + " " + (am ? "a.m" : "p.m")
      }
      showDrawer = (id) => {
        this.setState({
          visible: true,
          id: id
        });
      };
      handleRefresh = async () => {
        const res = await this.props.getRoles();
        this.setState({data: res})
      }
      onClose = () => {
        this.setState({
          visible: false,
        });
      };
      
      render() {
        const columns = [
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '25%',
            ...this.getColumnSearchProps('name'),
          },
          {
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            width: '20%',
            ...this.getColumnSearchProps('active'),
          },
          {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            width: '25%',
            ...this.getColumnSearchProps('created_at'),
          },
          {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
          },
        ];
        const {data,placement, visible,id} = this.state
        const rows = [
          data
            ? data.map((role) => this.createData(role.name, 
              <>
              {role.active ?
              <> 
                <Tag color="#87d068">Active</Tag> 
                <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => this.handleStatus(role.role_id, false)}><Button size="small" type="danger" icon={<StopOutlined  />}>Disable</Button></Popconfirm>
              </>: 
              <>
              <Tag color="#f50">Inactive</Tag>
              <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => this.handleStatus(role.role_id, true)}><Button size="small" type="primary" icon={<CheckCircleOutlined  />}>Activate</Button></Popconfirm>
              </>}
              {/* <Tag count={5}><Button size="small" type="ghost" icon={<EyeOutlined  />}>{role.active ? 'Active' : 'Inactive'}</Button></Tag> */}
              
              </>,
              role.super_admin, this.makeLocalTime(role.created_at), 
              <>
              <Button size="small" type="primary" icon={<EditOutlined  />} onClick={()=>history.push(`/edit-role?id=${role.role_id}`)}>Edit</Button> &nbsp;
              <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => this.showDrawer(role.role_id)}><Button size="small" type="danger" icon={<DeleteOutlined  />}>Delete</Button></Popconfirm>
              </>
              ))
            : "",
        ];
        
        return (
          <Content style={{ margin: '0 16px' }}>


            <Breadcrumb style={{padding:"10px 0px"}}>
              <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
              <Breadcrumb.Item>Role List</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeader
              ghost={false}
              onBack={() => window.history.back()}
              title="Role List"
              subTitle="Role Management"
              extra={[
                <Button key="1"  type="primary" icon={<PlusCircleFilled />} onClick = { () => history.push('/create-role')}>Create New
                </Button>,
              ]}
            >
            </PageHeader>


            <Table size="small" columns={columns} dataSource={rows[0]} scroll={{ x: 1000 }} sticky/>
            <Remarks placement={placement} visible={visible} fnc={this.onClose} id={id} delFnc = {this.props.deleteRole} data = {this.state.data} refresh = {this.handleRefresh} />
        </Content>
        );
      }
    }
 
export default connect(null, { getRoles,updateRoleAuth,deleteRole })(RoleList);