import React from 'react'
import { Title,DTable} from './detailStyle';

const CommercialLeaseAgreementDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                           
                              <Title>Basics</Title> <hr/>
                              <p>What date is this Lease being made?: <strong>{formData?.date} </strong></p>
                              <p>What date is this document being signed?: <strong>{formData?.date} </strong></p>
                              <p>What date will the lease commerce?: <strong>{formData?.date} </strong></p>
                              <p>What date will the Lease terminate/end? : <strong>{formData?.date_of_terminate} </strong></p>
                              {formData.date_of_terminate === 'End on a set date' ?
                                 <p>End Date: <strong>{formData?.end_date} </strong></p>
                              :null}
                              <p>Frequency of rent payment?: <strong>{formData?.frequency_rent_payment} </strong></p>
                              {formData.frequency_rent_payment === 'Others' ?
                                 <p>Frequency Specification: <strong>{formData?.frequency_specification} </strong></p>
                              :null}
                              <p>Rent Amount (gross): <strong>{formData?.rent_amount} </strong></p>
                              <p>Provide the date of the month the rent will be paid/collected?: <strong>{formData?.date_of_collection} </strong></p>
                              <p>Will this Lease include an attached form registration purpose?: <strong>{formData?.will_include ? 'Yes' : 'No'} </strong></p>
                           

                           
                              <Title>The Property</Title> <hr/>
                              <p>What type of property is being leased? : <strong>{formData?.type_of_property} </strong></p>
                              {formData?.type_of_property === 'Others' ?
                                 <p>Specification : <strong>{formData?.other_property_specification} </strong></p>
                              :null}
                              <p>What is the leased property's address? : <strong>{formData?.leased_property_address} </strong></p>
                              <p>What is the legal description of the property? : <strong>{formData?.legal_description} </strong></p>
                              <p>What is the permitted use for the property? : <strong>{formData?.permitted_use} </strong></p>
                              <p>Will the tenant receive onsite parking? : <strong>{formData?.will_tenant_receive ? 'Yes' : 'No'} </strong></p>
                              {formData?.will_tenant_receive === 'true' ?
                              <p>How many parking spaces? : <strong>{formData?.number_of_parking_space ? 'Yes' : 'No'} </strong></p>
                              :null}
                              <p>List any fixtures and any other personal properties that will be included in the lessee : <strong>{formData?.list} </strong></p>
                              <p>Will a plan of the premises be attached? : <strong>{formData?.will_plan_premises ? 'Yes' : "no"} </strong></p>
                              <p>Will the landlord be obliged/required to make any improvements/enhancements to the property? : <strong>{formData?.will_landlord_obliged} </strong></p>
                              {formData?.will_landlord_obliged === 'Yes, specify in this Agreement' ?
                                 <p>Obligation Specification : <strong>{formData?.obligation_specification} </strong></p>
                              :null}
                              <p>Will the tenant be permitted to make improvements/enhancement to the property? : <strong>{formData?.will_tenant_permitted ? 'Yes' : 'No'} </strong></p>
                           

                           
                              <Title>Renewal</Title> <hr/>
                              <p>Will this Agreement include an option for the lessee to renew the Lease?  : <strong>{formData?.will_include_option ? 'Yes' : 'No'} </strong></p>
                              {formData?.will_include_option ? 
                              <p>What is the renewed time of the Lease?  : <strong>{formData?.renewed_time} </strong></p>
                              : null}
                              <p>What date will the annual cost of rental for the renewed term be reviewed? : <strong>{formData?.date_of_annual_cost} </strong></p>
                              <p>What method of review will be used to determine the increase of rent for the renewed term? : <strong>{formData?.method_of_review} </strong></p>
                              {formData?.method_of_review === 'A Fixed Percentage Increase' ?
                                 <p>Specification : <strong>{formData?.review_specification} </strong></p>
                              :null}
                              {formData?.method_of_review === 'A Fixed Dollar Amount' ?
                                 <p>Specification : <strong>{formData?.review_specification} </strong></p>
                              :null}
                           

                           
                              <Title>Parties</Title> <hr/>
                              <Title>Landlord</Title>
                              <p>Name of the landlord (Lessor) : <strong>{formData?.name_of_landlord} </strong></p>
                              <p>Is the landlord an individual or company? : <strong>{formData?.type_of_landlord} </strong></p>
                              {formData?.type_of_landlord === 'Company' ?
                                 <p>ACN number : <strong>{formData?.landlord_acn_number} </strong></p>
                              : null}
                              <p>Address of the landlord : <strong>{formData?.address_of_landlord} </strong></p>
                              <p>Phone number of the landlord : <strong>{formData?.number_of_landlord} </strong></p>
                              
                              <Title>Parties</Title>
                              <p>Name of the tenant (Lessee) : <strong>{formData?.name_of_tenant} </strong></p>
                              <p>Is the lessee (tenant) an individual or company? : <strong>{formData?.type_of_lessee} </strong></p>
                              {formData?.type_of_lessee === 'Company' ?
                                 <p>ACN number : <strong>{formData?.lessee_acn_number} </strong></p>
                              : null}
                              <p>Address of the tenant : <strong>{formData?.address_of_tenant} </strong></p>
                              <p>Phone number of the tenant : <strong>{formData?.number_of_tenant} </strong></p>
                              <p>Will a third party guarantee the tenant's financial obligation? : <strong>{formData?.will_guarantee ? 'Yes' : 'No'} </strong></p>
                              
                              <>
                              <Title>Guarantor</Title>
                              <p>Will the guarantor sign this Agreement? : <strong>{formData?.will_guarantor_sign} </strong></p>
                              {formData?.will_guarantor_sign === 'Yes' ?
                              <>
                              <p>Name of the guarantor : <strong>{formData?.name_of_guarantor} </strong></p>
                              <p>Is the guarantor an individual or company? : <strong>{formData?.type_of_guarantor} </strong></p>
                              {formData?.type_of_guarantor === 'Company' ? 
                                 <p>What is the ACN number? : <strong>{formData?.guarantor_acn_number} </strong></p>
                              :null}
                              <p>Address of the guarantor : <strong>{formData?.address_of_guarantor} </strong></p>
                              <p>Phone number of the guarantor : <strong>{formData?.number_of_guarantor} </strong></p>
                              </> :null}
                              </>
                           

                           
                              <Title>Bond and Liability</Title> <hr/>
                              <Title>Bond</Title> <hr/>
                              <p>How much money will the security bond be? : <strong>{formData?.security_bond} </strong></p>
                              <p>How will the lessee pay the security bond? : <strong>{formData?.lessee_pay_type} </strong></p>
                              {formData?.lessee_pay_type === 'Others' ?
                                 <p>Specification  : <strong>{formData?.pay_type_specification} </strong></p>
                              : null}
                              <Title>Liability</Title>
                              <p>Will the tenant be responsible for paying outgoings?  : <strong>{formData?.will_responsible ? 'Yes' : 'No'} </strong></p>
                              {formData?.will_responsible === 'true' ? 
                              <>
                              <p>What outgoing will the lessee be responsible for paying? : <strong>{formData?.what_outgoing_responsible} </strong></p>
                              <p>What percentage of the outgoings will the tenant be responsible for?  : <strong>{formData?.what_percentage} </strong></p>
                              </> :
                              null}
                              
                              <p>Besides plate glass insurance, public risk insurance and insurance on the lessee's property and fittings is there any other insurance the lessee that should be taken out by the lessee? : <strong>{formData?.is_there_other} </strong></p>
                           
                           
                           
                           
                              <Title>Service Type</Title> <hr/>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                              <p>Describe if you have any other specific need that you want us to incorporate in the contract  : <strong> {formData?.describe_other_specific } </strong></p>
                              
                           
                           <Title>Billing Information</Title> <hr/>
                              {billing_info()}   
                              
                     </DTable>
     );
}
 
export default CommercialLeaseAgreementDetails;