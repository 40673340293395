import React from 'react'
import { Title,DTable} from './detailStyle';


const ServiceAgreementDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable >

                        
                           
                              <Title >Party Details</Title> <hr/>
                              <p>Please select a service type for the agreement  : <strong>{formData?.type_of_agreement} </strong></p>
                              {formData?.type_of_agreement === 'Others' ?
                              <p>Name of the service : <strong>{formData?.name_of_service} </strong></p>
                              : null}
                              <p>What is the commencement date of the services? : <strong>{formData?.commencement_date} </strong></p>
                              <p>What is the completion date of the services? : <strong>{formData?.completion_date} </strong></p>
                              <p>Please provide a comprehensive description of the services : <strong>{formData?.comprehensice_description} </strong></p>
                              <p>How are the services going to be delivered : <strong>{formData?.how_delivered} </strong></p>
                           
                           
                           
                              <Title >Recipient’s Details</Title> <hr/>
                              <p>Name of the party receiving the services : <strong>{formData?.name_of_receiving} </strong></p>
                              <p>Is the recipient of the services an individual or company? : <strong>{formData?.recipient_type} </strong></p>
                              {formData?.recipient_type === 'Company' ?
                                 <p>What is the ABN or ACN of the party receiving the services? : <strong>{formData?.abn} </strong></p>
                              : null}
                              <p>What is the address of the party receiving the services? : <strong>{formData?.address_of_party} </strong></p>
                              <p>What does the recipient specialise in? : <strong>{formData?.recipient_apecialise} </strong></p>
                           
                           
                           
                              <Title >Recipient’s Details</Title> <hr/>
                              <p>Name of the party providing the services : <strong>{formData?.name_of_provider} </strong></p>
                              <p>Is the provider of the services an individual or company? : <strong>{formData?.provider_type} </strong></p>
                              {formData?.provider_type === 'Company' ?
                                 <p>What is the ABN or ACN of the party providing the services? : <strong>{formData?.provider_abn} </strong></p>
                              : null}
                              <p>What is the address of the party providing the services? : <strong>{formData?.address_of_provider} </strong></p>
                           
                           
                           
                              <Title >Payment</Title> <hr/>
                              <p>How much is the recipient paying the provider for providing the services? : <strong>{formData?.recipient_paying} </strong></p>
                              <p>Please describe the payment arrangement : <strong>{formData?.describe_payment_arrangement} </strong></p>
                              <p>What is the method of payment? : <strong>{formData?.method_of_payment} </strong></p> 
                           
                           
                           
                              <Title >Communication</Title> <hr/>
                              <p>How much is the recipient paying the provider for providing the services? : <strong>{formData?.recipient_paying} </strong></p>
                              <p>Please describe the payment arrangement : <strong>{formData?.describe_payment_arrangement} </strong></p>
                              <p>What is the method of payment? : <strong>{formData?.method_of_payment} </strong></p> 
                           
                            
                           
                           
                              <Title >Other Clauses</Title> <hr/>
                              <p>Do you want to include a non-solicitation clause? : <strong> {formData?.want_include } </strong></p>
                              {formData?.want_include === 'true' ?
                                 <p>Do you want your non-solicitation clause to be for your personnel or your clients? : <strong> {formData?.want_nonSoliciation } </strong></p>
                              :null }
                              <p>If you require mediation, and the parties do not agree on a mediator, who would appoint the mediator? : <strong> {formData?.who_appoint } </strong></p>
                              <p>What date will this agreement by signed on?  : <strong> {formData?.date_of_agreement } </strong></p>
                           
                           
                           
                              <Title >Service Type</Title> <hr/>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                              <p>Describe if you have any other specific need that you want us to incorporate in the contract  : <strong> {formData?.describe_other_specific } </strong></p>
                              
                              
                              <Title>Billing Information</Title> <hr/>
                              {billing_info()}
                         
                     </DTable>
     );
}
 
export default ServiceAgreementDetails; 