import React from 'react'
import { Title,DTable} from './detailStyle';

const ITPolicyDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                        
                          
                              <Title>Details</Title> <hr/>
                              <p>Who should be contacted if there are any questions regarding the IT Use Policy? : <strong>{formData?.who_should_concern} </strong></p>
                              <p>What is the title of the individual responsible for the IT Use Policy? : <strong>{formData?.title_of_individual} </strong></p>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                              <p>Describe if you have any other specific need that you want us to incorporate in the policy : <strong> {formData?.describe_other_specific } </strong></p>
                           
                              
                              <Title>Billing Information</Title> <hr/>
                              {billing_info()}   
                              
                     </DTable>
     );
}
 
export default ITPolicyDetails; 