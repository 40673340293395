import React from 'react'
import { Title,DTable} from './detailStyle';

const SaleOfGoodsAgreemnet = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>

                        
                           
                              <Title >Party Details</Title> <hr/>
                              <Title >Seller’s Details</Title>
                              <p>Provide the Seller’s name : <strong>{formData?.name_of_seller} </strong></p>
                              <p>Provide the Seller’s address : <strong>{formData?.address_of_seller} </strong></p>
                              <br />
                              <Title >Buyer’s Details</Title>
                              <p>Provide the Buyer’s name : <strong>{formData?.name_of_buyer} </strong></p>
                              <p>Provide the Buyer address : <strong>{formData?.address_of_buyer} </strong></p>
                              <Title >Agreement Details</Title>
                              <p>On what date will this agreement come into effect? : <strong>{formData?.date_of_agreement} </strong></p>
                              <p>What is the minimum number of days the Buyer must provide between their order and their requested delivery date? : <strong>{formData?.days_of_delivery} </strong></p>
                              
                           
                           
                              <Title >Payment Details</Title> <hr/>
                              <p>Will the goods be picked up or delivered?  : <strong>{formData?.will_picked} </strong></p>
                              <p>Where will the goods be delivered? : <strong>{formData?.where_delivered} </strong></p>
                              <p>How will the Buyer make payment for the goods purchased from the Seller?  : <strong>{formData?.payment_specification} </strong></p>
                              {formData?.payment_specification === 'Others' ?
                                 <p>Specification  : <strong>{formData?.payment_specification} </strong></p>
                              : null}
                              <p>What if the Buyer fails to make the payment by the due date, interest will be added onto the amount owing. According to which bank’s business indicator rate will interest be calculated from? : <strong>{formData?.which_bank} </strong></p>
                              <p>When will the title of the goods be passed from the Seller to the Buyer? : <strong>{formData?.when_passed_to_buyer} </strong></p>
                              <p>Please describe the goods in detail : <strong>{formData?.description_of_goods} </strong></p>
                              <p>Please provide the total price of the goods being sold : <strong>{formData?.total_price} </strong></p>
                           
                           
                           
                           
                              <Title >Service Type</Title> <hr/>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                              <p>Describe if you have any other specific need that you want us to incorporate in the contract  : <strong> {formData?.describe_other_specific } </strong></p>
                           
          
                              <Title>Billing Information</Title> <hr/>
                               {billing_info()}
                     </DTable>
     );
}
 
export default SaleOfGoodsAgreemnet; 