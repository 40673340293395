import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import Spinner from "../../component/spinner";
import history from "../../history";
import Dashboard from "../dashboard/dashboard";
import CreateUser from "../user/createUser";
import RoleList from "../user/role/roleList";
import CreateRole from "../user/role/createRole";
import UserList from "../user/user/userList";
import UserListModal from "../user/userListModal";
import EditRole from "../user/role/editRole";
import OrderList from "../orders/orderList";
import OrderDetails from "../orders/orderDetails";
import FinanceOrderList from "../finance/financeOrderList";
import FinanceOrderDetails from "../finance/financeOrderDetails";
// import SupportList from "../support/supportList";
// import SupportDetails from "../support/supportDetails";
import ContactResponseList from "../contactResponse/contactResponseList";
import ContactResponseDetails from "../contactResponse/contactResponseDetails";
import District from "../setting/district";
import MessageAlert from "../../component/messageAlert";
import OrderNotification from "../orders/orderNotification";
import EditCompanyRegistration from "../orders/details/editCompanyRegistration";

import client from "../setting/client";
import businessType from "../setting/businessType";
import departmentEntry from "../setting/departmentEntry";
import designationEntry from "../setting/designationEntry";
import problemType from "../setting/problemType";
import connectionType from "../setting/connectionType";
import priorityType from "../setting/priorityType";
import assetType from "../setting/assetType";
import vendor from "../setting/vendor";
import employee from "../setting/employee";
import clientList from "../clients/clientList";

import POPList from "../pop";

import './index.css';
import routerList from "../pop/routerList";
import switchList from "../pop/switchList";
import subClientList from "../clients/subClientList";
import createSupport from "../ticket/createSupport";
import createTransmssion from "../ticket/createTransmssion";
import TicketList from "../ticket/index";
import ticketDetails from "../ticket/ticketDetails";
import allTickets from "../ticket/allTickets";

const Switcher = ({ loader }) => {
  const [access, setAccess] = useState(null);
  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null
    ) {
      localStorage.clear();
      history.replace("/login");
    }
    setAccess(JSON.parse(localStorage.getItem("access")));
    // socket
  }, []);
  return (
    <div style={{ margin: '10px', backgroundColor: '#FFF', height: '100%'}}>
      {loader ? <Spinner /> : null}
      <switch>
        <Route path="/dashboard" component={Dashboard} />
        {/* <Redirect form="/" to="/dashboard" /> */}
        <>
          {/* ==Setting== */}
          <Route path="/setting/district" component={District} />
          <Route path="/setting/clientType" component={client} />
          <Route path="/setting/businessType" component={businessType} />
          <Route path="/setting/departmentEntry" component={departmentEntry} />
          <Route path="/setting/designationEntry" component={designationEntry} />
          <Route path="/setting/problemType" component={problemType} />
          <Route path="/setting/connectionType" component={connectionType} />
          <Route path="/setting/priorityType" component={priorityType} />
          <Route path="/setting/assetType" component={assetType} />
          <Route path="/setting/vendor" component={vendor} />
          <Route path="/setting/employee" component={employee} />
        </>

        <>
          {/* ==Contact== */}
          <Route path="/contact/responses" component={ContactResponseList} />
          <Route
            path="/contact/response-details"
            component={ContactResponseDetails}
          />

          {/* ==Support== */}
          <Route path="/support/create" component={createSupport} />
          <Route path="/ticket/all" component={allTickets} />
          <Route path="/ticket/list" component={TicketList} />
          <Route path="/ticket/details" component={ticketDetails} />

          <Route path="/transmission/create" component={createTransmssion} />
          {/* <Route path="/support/ticket-details" component={SupportDetails} /> */}
        </>

        <>
          {/* ==Finance== */}
          <Route path="/finance/orders" component={FinanceOrderList} />
          <Route
            path="/finance/order-details"
            component={FinanceOrderDetails}
          />
        </>

        <></>

        <>
          {/* ==POP== */}
          <Route path="/pop/all" component={POPList} />
          <Route path="/router/all" component={routerList} />
          <Route path="/switch/all" component={switchList} />
        </>

        <>
          {/* ==Client== */}
          <Route path="/clientList" component={clientList} />
          <Route path="/subclientList" component={subClientList} />
        </>

        <>
          {/* ==Orders== */}
          <Route path="/orders" component={OrderList} />
          <Route path="/order-details" component={OrderDetails} />
          <Route path="/order-notification" component={OrderNotification} />
          <Route path="/edit-order" component={EditCompanyRegistration} />
        </>

        <>
          {/* ==Role== */}
          <Route path="/roles" component={RoleList} />
          <Route path="/create-role" component={CreateRole} />
          <Route path="/edit-role" component={EditRole} />

          <Route path="/createUser" component={CreateUser} />
          <Route path="/userListModal" component={UserListModal} />
          <Route path="/users" component={UserList} />
        </>
      </switch>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.loader.loading,
    success: state.message.success,
    error: state.message.error,
    validationError: state.message.validationError,
  };
};
export default connect(mapStateToProps)(Switcher);
