import React from 'react'
import { Title,DTable} from './detailStyle';

const CommercialSubleaseAgreementDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>

                        
                           
                              <Title>Dates</Title> <hr/>
                              <p>What is the start date of the sublease term?: <strong>{formData?.starting_dat_of_sublease} </strong></p>
                              <p>What is the end date of the sublease term?: <strong>{formData?.end_date_of_sblease} </strong></p>
                              {formData?.end_date_of_sblease === 'End on a set date' ?
                                 <p>End date : <strong>{formData?.end_date} </strong></p>
                              :null}
                              <p>What date is this sublease agreement effective from?: <strong>{formData?.date_of_sublease_agreement} </strong></p>
                              <p>Is the sublease renewable? : <strong>{formData?.is_sublease_renewable ? 'Yes' : "No"} </strong></p>
                              {formData?.is_sublease_renewable ? 
                                 <p>Sublease renewable option? : <strong>{formData?.sublease_renewable_option} </strong></p>
                              : null}
                              
                              <Title>Property</Title>
                              <p>Property type : <strong>{formData?.type_of_property} </strong></p>
                              {formData?.type_of_property === 'Others' ?
                                 <p>Specification : <strong>{formData?.other_property_specification} </strong></p>
                              :null}
                              <p>Area to be sublet: <strong>{formData?.area_of_sublet} </strong></p>
                              <p>Address of the property : <strong>{formData?.address_of_property} </strong></p>
                              <p>Specify the size of the property being sublet	Leave it blank if you do not know : <strong>{formData?.size_of_property} </strong></p>
                              <p>Will you attach the diagram of the property in this agreement? : <strong>{formData?.will_attch_diagram ? 'Yes' : 'No'} </strong></p>
                           

                           
                              
                              <Title>Landlord (According to the master lease) </Title> <hr/>
                              {formData?.landlord ? formData?.landlord.map((item,id) => (
                                 <>
                                    <h5>Landlord {id}</h5>
                                    <p>Name of the landlord (Lessor)  : <strong>{item?.name_of_landlord}</strong></p>
                                    <p>Is the landlord an individual or company?  : <strong>{item?.landlord_type}</strong></p>
                                    {item?.type_of_property === 'Company' ?
                                       <p>What is the ACN number? : <strong>{item?.abn} </strong></p>
                                    :null}
                                    <p>Address of the landlord  : <strong>{item?.address_of_landlord} </strong></p>
                                 </>
                              )):null}
                              <Title>Sublandlord (Tenant in the master lease) </Title> <hr/>
                              {formData?.sublandlord ? formData?.sublandlord.map((item, id) => (
                                 <>
                                    <h5>SubLandlord {id}</h5>
                                    <p>Name of the sublandlord (Lessor)  : <strong>{item?.name_of_sublandlord}</strong></p>
                                    <p>Is the sublandlord an individual or company?  : <strong>{item?.sublandlord_type}</strong></p>
                                    {item?.type_of_property === 'Company' ?
                                       <p>What is the ACN number? : <strong>{item?.abn} </strong></p>
                                    :null}
                                    <p>Address of the sublandlord  : <strong>{item?.address_of_sublandlord} </strong></p>
                                 </>
                              )):null}
                              <Title>Subtenant</Title> 
                              {formData?.subtenant ? formData?.subtenant.map((item, id) => (
                                 <>
                                    <h5>Subtenant {id}</h5>
                                    <p>Name of the subtenant (Lessee)  : <strong>{item?.name_of_subtenant}</strong></p>
                                    <p>Is the subtenant an individual or company?  : <strong>{item?.subtenant_type}</strong></p>
                                    {item?.type_of_property === 'Company' ?
                                       <p>What is the ACN number? : <strong>{item?.abn} </strong></p>
                                    :null}
                                    <p>Address of the subtenant  : <strong>{item?.address_of_subtenant} </strong></p>
                                 </>
                              )):null}
                              <Title>Master Lease</Title>
                              <p>When was the master lease signed? : <strong>{formData?.when_lease_signed} </strong></p>
                              <p>Will a copy of the master lease be attached to this agreement? : <strong>{formData?.will_be_attached ? 'Yes' : 'No'} </strong></p>
                              <p>Has the landlord given written consent to the sublease? : <strong>{formData?.has_written_consent} </strong></p>
                           

                           
                              <Title>Payment/rent, Bond and Liability</Title> <hr/>
                              <p>How much is each sublease payment? : <strong>{formData?.sublease_payment} </strong></p>
                              <p>The first date of payment : <strong>{formData?.date_of_payment} </strong></p>
                              <p>What is the frequency of the lease payments? : <strong>{formData?.what_is_frequency} </strong></p>
                              {formData?.what_is_frequency === 'Others' ? 
                              <p>Specification?  : <strong>{formData?.frequency_specification} </strong></p>
                              : null}
                              <p>What are the payment details/ bank details for the tenant to pay lease payments? : <strong>{formData?.payment_details} </strong></p>
                              
                              <Title>Bond</Title>
                              <p>What is the security deposit (bond) amount?  : <strong>{formData?.security_bond} </strong></p>
                              <p>What is the date the security deposit must be received by?  : <strong>{formData?.security_deposit} </strong></p>
                              
                              <Title>Liability</Title>
                              <p>Will the sublessee be responsible for paying outgoings? : <strong>{formData?.will_responsible ? 'Yes' : 'No'} </strong></p>
                              {formData?.will_responsible === 'true' ? 
                              <>
                              <p>What outgoing will the lessee be responsible for paying? : <strong>{formData?.what_outgoing_responsible} </strong></p>
                              <p>What percentage of the outgoings will the tenant be responsible for?  : <strong>{formData?.what_percentage} </strong></p>
                              </> :
                              null}
                              
                              <p>Will the subtenant be allowed to make improvements to the property? : <strong>{formData?.will_subtenant_allowed} </strong></p>
                           
                           

                           
                              <Title>Service Type</Title> <hr/>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                              <p>Describe if you have any other specific need that you want us to incorporate in the contract  : <strong> {formData?.describe_other_specific } </strong></p>
                              
                           
                              <Title>Billing Information</Title> <hr/>
                              {billing_info()}   
                            
                         
                     </DTable>
     );
}
 
export default CommercialSubleaseAgreementDetails;