import React from 'react'
import { Title,DTable  } from './detailStyle';
import { fileDownloader } from '../../../actions/orders';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { connect } from 'react-redux';

const GarmentsUniformsDetails = ({data, fileDownloader}) => {
   console.log(data)
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
            <DTable>  
               <Title>Contact Details</Title> <hr/>
               <p>First Name : <strong>{formData?.first_name} </strong></p>
               <p>Last Name : <strong>{formData?.last_name} </strong></p>
               <p>Organization Name : <strong>{formData?.organization_name} </strong></p>
               <p>Organization ABN/ACN : <strong>{formData?.organization_abn_acn} </strong></p>
               <h3 style={{textAlign: 'left'}}>Delivery Address</h3> <hr/>
               <p>House Number : <strong>{formData?.d_house_number} </strong></p>
               <p>Street number : <strong>{formData?.d_street_address} </strong></p>
               <p>City : <strong>{formData?.d_city} </strong></p>
               <p>State : <strong>{formData?.d_state} </strong></p>
               <p>Postcode : <strong>{formData?.d_postcode} </strong></p>
               <p>Country : <strong>{formData?.d_country} </strong></p>
               <p>Phone No : <strong>{formData?.d_phone} </strong></p>
               <p>Email : <strong>{formData?.d_email} </strong></p>
                              
         
            
               <Title>Garments Details</Title> <hr/>
               <p>Garments Type : <strong>{formData?.garments_type} </strong></p>
               <p>Colour : <strong>{formData?.color} </strong></p>
               <p>Fabric Type : <strong>{formData?.fabricType} </strong></p>
               <p>Design: <strong>{data?.data?.files[0]?.design_photo ? <Button type="primary" icon={<DownloadOutlined />} size="middle" onClick = { () => fileDownloader(data?.data?.files[0].design_photo)} /> : 'Not Submitted'} </strong></p>
               <p>Design Description : <strong>{formData?.design_description} </strong></p>
                           
               <Title>Size & Quantity</Title> <hr/>
               <p>Size S : <strong>{formData?.s_count ? formData?.s_count : 0} pc </strong></p>
               <p>Size M : <strong>{formData?.m_count ? formData?.m_count : 0} pc </strong></p>
               <p>Size L : <strong>{formData?.l_count ? formData?.l_count : 0} pc </strong></p>
               <p>Size XL : <strong>{formData?.xl_count ? formData?.xl_count : 0} pc </strong></p>
               <p>Size 2XL : <strong>{formData?.xxl_count ? formData?.xxl_count : 0} pc </strong></p>
               <p>Size 3XL : <strong>{formData?.xxxl_count ? formData?.xxxl_count : 0} pc </strong></p>
               <p>Size 4XL : <strong>{formData?.xxxxl_count ? formData?.xxxxl_count : 0} pc </strong></p>
               <p>Size 5XL : <strong>{formData?.xxxxxl_count ? formData?.xxxxxl_count : 0} pc </strong></p>
               <p>Size 6XL : <strong>{formData?.xxxxxxl_count ? formData?.xxxxxxl_count : 0} pc </strong></p>
               <p>Speed of delivery : <strong>{formData?.delivery_type} </strong></p>
                           
               <Title>Billing Information</Title> <hr/>
               {billing_info()}   
                         
            </DTable>
     );
}
 
export default connect(null,{fileDownloader})(GarmentsUniformsDetails); 