import React from 'react'
import { Title,DTable} from './detailStyle';


const PowerOfAttorneyDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>

                        
                           
                              <Title>Principal’s details</Title> <hr/>
                              <p>When will the document be signed? : <strong>{formData?.when_sign} </strong></p>
                              <p>What is the name of the principal? : <strong>{formData?.name_of_principal} </strong></p>
                              <p>What is the principal’s address? : <strong>{formData?.address_of_principal} </strong></p>
                           
                           
                              <Title>Attorney’s Details</Title>
                              {formData?.attorney.map((item, id) => (
                              <div key={id}>
                                 <h4>Attorney {id}</h4>
                                 <p>What is the attorney’s name? : <strong>{item?.name_of_attorney} </strong></p>
                                 <p>What is the address of the alternate attorney?  : <strong>{item?.address_of_attorney} </strong></p>
                                 <br />
                              </div>
                              ))}
                           
                           
                              <Title>Alternate Attorney’s Details</Title>
                              {formData?.attorney.map((item, id) => (
                              <div key={id}>
                                 <h4>Alternate Attorney {id}</h4>
                                 <p>What is the alternate attorney’s name? : <strong>{item?.name_of_alternate_attorney} </strong></p>
                                 <p>What is the address of the alternate alternate attorney?  : <strong>{item?.address_of_alternate_attorney} </strong></p>
                                 <br />
                              </div>
                              ))}
                           
                           
                           
                              <Title>Agreement Details</Title> <hr/>
                              <p>How will your attorneys operate? : <strong>{formData?.operate_type} </strong></p>
                              <p>When will this Power of Attorney come into effect? : <strong>{formData?.when_effect} </strong></p>
                              {formData?.when_effect === 'On a selected date' ? 
                              <p>Selected Date : <strong>{formData?.selected_date} </strong></p>
                              : null}
                              {formData?.when_effect === 'On a specific occasion' ?
                              <p>Specify of the occasion : <strong>{formData?.occasion_specification} </strong></p> 
                              : null}
                           
                           
                              <Title>Service Type</Title> <hr/>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                              <p>Describe if you have any other specific need that you want us to incorporate in the contract  : <strong> {formData?.describe_other_specific } </strong></p>
                           
          
                              <Title>Billing Information</Title> <hr/>
                               {billing_info()}

               
                     </DTable>
     );
}
 
export default PowerOfAttorneyDetails; 