import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 20px;
`;
export const Title = styled.div`
    width: 100%;
    background-color: #3498db;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 5px;
`;
export const ErrorContainer = styled.div`
    padding: 5px;
    h4{
        background-color: #ff7979;
        color: #fff;
        padding: 5px;
    }
    span{
        
    }
    p{
        margin: 0px;
    }
`;
export const DTable = styled.div`
p{
    text-transform: uppercase;
    font-size: 14px;
    margin: 1px;
    border-bottom: 1px solid lightgray;
    /* letter-spacing: 1px; */
}
table{
    width: 100%;
}
    table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
th, td {
  padding: 5px;
  text-align: left;
}
`;
