import React from 'react'
import { connect } from 'react-redux';
import { Title,DTable} from './detailStyle';
import { fileDownloader } from '../../../actions/orders';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const ChangeofCompanyConstitutionDetails = ({data,fileDownloader}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                              
                                 <Title>Company Details </Title> <hr/>
                                  <p>Name of company  : <strong>{formData?.name_company2} </strong></p>
                                 <p>ABN or ACN : <strong>{formData?.abn1} </strong></p>
                                
                              
                              
                                 <Title>Special resolution details  </Title> <hr/>
                                 <p>Do you have resolution for the changing the company constitution?  : <strong>{formData?.company_constitution  === 'true' ? "yes":"no" } </strong></p>
                                 {formData?.company_constitution  === 'true' ?
                                   // <p>Upload the resolution for changing the company constitution  : <strong>{data?.data?.files[0]?.change_company_constitution } </strong></p>
                                   <p>Upload the resolution for changing the company constitution  : <strong>{data?.data?.files[0]?.change_company_constitution ? <Button type="primary" icon={<DownloadOutlined />} size="middle" onClick = { () => fileDownloader(data?.data?.files[0].change_company_constitution)} /> : 'Not Submitted'} </strong></p>
                                
                                   : null}
                                 {formData?.company_constitution  === 'false' ?

                                 <p>Do you want us to prepare the resolution on your behalf? : <strong>{formData?.on_behalf  === 'true1' ? "yes":"no" } </strong></p>
                                 :null}
                                 <p>Date of the Resolution : <strong>{formData?.date_of_resolution } </strong></p>
                                
                                 
                                 
                                 <Title>New constitution details  </Title> <hr/>
                                 <p>Do you want us to draft a new constitution for your company? : <strong>{formData?.company_constitution1  === 'true2' ? "yes":"no" } </strong></p>
                                 {formData?.company_constitution1  !== 'true2' ?
                                 <>
                                 <p>Upload your existing constitution  : <strong>{data?.data?.files[1]?.exsisting_company_constitution ? <Button type="primary" icon={<DownloadOutlined />} size="middle" onClick = { () => fileDownloader(data?.data?.files[1].exsisting_company_constitution)} /> : 'Not Submitted'} </strong></p>
                                 <p>Please describe what do you want to add or remove from the current Constitution?: <strong>{formData?.current_constitution } </strong></p>
                                </>
                                : null}
                                 

                                 
                                 <Title>Declaration </Title> <hr/>
                                
                                 <p>I authorize onecompany to manage all legal documentation related to ASIC and submit all required documentations on behalf of my company.  : <strong>{formData?.authorize_company === true ? "yes" : "no"} </strong></p>
                                 
                                 <p>Declarant Name: <strong>{formData?.d_name} </strong></p>
                                 <p>Designation within the Company  : <strong>{formData?.designation1} </strong></p>

                                 
                                 <Title>Billing Information</Title> <hr/>
                              {billing_info()} 


                         
                     </DTable>
     );
}
 
export default connect(null,{fileDownloader})(ChangeofCompanyConstitutionDetails);