import React from 'react'
import { Title,DTable} from './detailStyle';

const NotifyChangeofNameConstitutionPowerofDirectorsDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                              
                                 <Title>Company Details </Title> <hr/>
                                 <p>Company Name  : <strong>{formData?.company_name} </strong></p>
                                 <p>ARBN : <strong>{formData?.arbn} </strong></p>
                                
                                 <p>Australian Passport Fund Registration Number (APFRN) : <strong>{formData?.apfrn} </strong></p>
                                 <p>Notified Foreign Passport Fund Registration Number (NFPFRN)   : <strong>{formData?.nfpfrn} </strong></p>
                                
                              
                              
                              
                                 <Title>Details of the change</Title> <hr/>
                                 <p>Select the service  : <strong>{formData?.select_service} </strong></p>
                                 <p>Date of change	  : <strong>{formData?.date_of_change} </strong></p>
                                
                                 {formData?.select_service=== "Change of name" ?
                                 <>
                                <p>New foreign company name : <strong>{formData?.new_company_name} </strong></p>
                                <p>Has a name reservation been lodged to reserve the body’s name? : <strong>{formData?.body_name === true ? "yes" : "no"} </strong></p>
                                <p>Provide name reservation number: <strong>{formData?.reservation_number } </strong></p>
                               <p>Is the proposed name identical to a egistered business name(s) in Australia? : <strong>{formData?.is_identical ? 'Yes' : 'No'}</strong></p>
                               
                               <p>provide state/territory business number  : <strong>{formData?.state_business_number} </strong></p>
                               <p>State/Territory of registration  : <strong>{formData?.state_registration} </strong></p>
                               <p>I declare that I own, or am registering the company for the owner(s) of the identical business name(s), the registration details of which are listed below : <strong>{formData?.identical_declaration ? 'Yes' : 'No'}</strong></p>
                               
                               </>:null}
                                

                                 
                                 <Title>Declaration </Title> <hr/>
                                 <p>Upload  reevent documents  : <strong>{formData?.upload_link} </strong></p>
                                 <p>Late Lodgement fee  : <strong>{formData?.late_lodgement_fee} </strong></p>
                                 <p> I authorize onecompany to manage all legal documentation related to ASIC and submit all required documentations on behalf of my company.: <strong>{formData?.final_declaration === true ? "yes" : "no"} </strong> </p>
                                
                                 <p>Designation within the Company   : <strong>{formData?.d_company} </strong></p>
                                 <p>onecompany local agent ID*  : <strong>{formData?.agent_id} </strong></p>
                                 
                                 
                                 <Title>Billing Information</Title> <hr/>
                              {billing_info()} 
                              
                         
                     </DTable>
     );
}
 
export default NotifyChangeofNameConstitutionPowerofDirectorsDetails;