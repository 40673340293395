import React from 'react'
import { Title,DTable} from './detailStyle';

const TermAndConditionsGoodsDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
  
    return ( 
             <DTable>

                        
                           
                              <Title>Details</Title> <hr/>
                              <p>URL of your website : <strong>{formData?.url_of_website} </strong></p>
                              <p>Name of the business operating the website : <strong>{formData?.name_of_website} </strong></p>
                              <p>Contact address of the business operating the website : <strong>{formData?.contact_address} </strong></p>
                              <p>What is the business structure of the business operating the website : <strong>{formData?.business_structure} </strong></p>
                              <p>ABN/ACN: <strong>{formData?.abn} </strong></p>
                              <p>What are the personal information do you collect from your subscribers/visitors : <strong>{formData?.personal_information} </strong></p>
                              <p>Other personal information you will collect : <strong>{formData?.other_personal_information} </strong></p>
                           
                           
                           
                              <Title>Payment</Title> <hr/>
                              <p>Methods of payment : <strong> {formData?.payment_method } </strong></p>
                              <p>Are there any other methods of payment? : <strong> {formData?.other_method } </strong></p>
                              <p>Your payment gateway provider : <strong> {formData?.gateway_provider } </strong></p>
                              <p>If you have subscription-based service, how will the subscription be terminated? : <strong> {formData?.how_will_terminated } </strong></p>
                              <p>Does your business offer any other service through third parties? : <strong> {formData?.does_offer ?'Yes':'No'} </strong></p>
                              {formData?.does_offer ?
                              <p>Third party’s email address : <strong> {formData?.email_address } </strong></p>
                              : null}
                              
                           
                           
                           
                              <Title>Warranty</Title> <hr/>
                              <p>The warranty period for your product : <strong> {formData?.warranty_period } </strong></p>
                              <p>Email address for warranty inquiry  : <strong> {formData?.email_address_for_warranty } </strong></p>
                           
                           
                           
                              <Title>Dispute Resolution</Title> <hr/>
                              <p>The time period for raising a dispute : <strong> {formData?.period_for_dispute } </strong></p>
                              <p>If a dispute is not solved, within how many days do the parties need to appoint a mediator? : <strong> {formData?.number_of_days_to_appoint } </strong></p>
                              <p>Write the name of the mediation body for potential dispute  : <strong> {formData?.name_of_medidation } </strong></p>
                              <p>Where will the mediation be held : <strong> {formData?.where_mediation_held } </strong></p>
                              <p>If the dispute remains unsolved, when can a party terminate the mediator : <strong> {formData?.when_terminate } </strong></p>
                           
                           
                           
                              <Title>Service Type</Title> <hr/>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                              <p>Describe if you have any other specific need that you want us to incorporate in the contract  : <strong> {formData?.describe_other_specific } </strong></p>
                           
                                        
               <Title>Billing Information</Title> <hr/>
               {billing_info()}
                         
                     </DTable>
     );
}
 
export default TermAndConditionsGoodsDetails; 