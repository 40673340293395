import React from 'react'
import { Title,DTable} from './detailStyle';

const ResidentailTenancyAgreementDetails = ({data}) => {
   const formData = data?.data?.form_data;
   const bill = data?.data?.bill_info;
   const billing_info = () => {
   let res  = Object.keys(bill).map((item) => {
        return (<p>{item} : <strong>${bill[item] }</strong></p>)
   })
   return res;
   }
    return ( 
             <DTable>
                        <Title>Preview Details</Title>
                        <h4>Please check all the information before submit</h4>
                        
                        
                           
                              <Title >Dates and Product Details</Title> <hr/>
                              <p>What is the start date of the lease term?: <strong>{formData?.starting_dat_of_lease} </strong></p>
                              <p>What is the end date of the lease term?: <strong>{formData?.end_date_of_sblease} </strong></p>
                              {formData?.end_date_of_sblease === 'End on a set date' ?
                                 <p>End date : <strong>{formData?.end_date} </strong></p>
                              :null}
                              <p>What date is this lease agreement effective from?: <strong>{formData?.date_of_lease_agreement} </strong></p>
                              <p>Is the lease renewable? : <strong>{formData?.is_lease_renewable ? 'Yes' : "No"} </strong></p>
                              {formData?.is_lease_renewable ? 
                                 <p>lease renewable option? : <strong>{formData?.lease_renewable_option} </strong></p>
                              : null}
                              
                              <Title >Property</Title>
                              <p>Property type : <strong>{formData?.type_of_property} </strong></p>
                              {formData?.type_of_property === 'Others' ?
                                 <p>Specification : <strong>{formData?.other_property_specification} </strong></p>
                              :null}
                              <p>Address of the property : <strong>{formData?.address_of_property} </strong></p>
                              <p>List of items included in the property : <strong>{formData?.list_item } </strong></p>
                           

                           
                              <Title >Landlord Details and Tenant </Title> <hr/>
                              <Title >Landlord Details</Title>
                              {formData?.landlord ? formData?.landlord.map((item,id) => (
                                 <>
                                    <h5>Landlord {id}</h5>
                                    <p>Name of the landlord (Lessor)  : <strong>{item?.name_of_landlord}</strong></p>
                                    <p>Is the landlord an individual or company?  : <strong>{item?.landlord_type}</strong></p>
                                    {item?.type_of_property === 'Company' ?
                                       <p>What is the ACN number? : <strong>{item?.abn} </strong></p>
                                    :null}
                                    <p>Address of the landlord  : <strong>{item?.address_of_landlord} </strong></p>
                                    <p>Phone number of the landlord : <strong>{item?.number_of_landlord} </strong></p>
                                 </>
                              )) : null}
                              <h3 >Tenant Details </h3> <hr/>
                              {formData?.tenant ? formData?.tenant.map((item, id) => (
                                 <>
                                    <h5>Tenant {id}</h5>
                                    <p>Name of the tenant  : <strong>{item?.name_of_tenant}</strong></p>
                                    <p>Is the tenant an individual or company?  : <strong>{item?.tenant_type}</strong></p>
                                    {item?.type_of_property === 'Company' ?
                                       <p>What is the ACN number? : <strong>{item?.abn} </strong></p>
                                    :null}
                                    <p>Address of the tenant : <strong>{item?.address_of_tenant} </strong></p>
                                    <p>Phone of the tenant : <strong>{item?.number_of_tenant} </strong></p>
                                 </>
                              )) : null}
                           

                           
                              <h3 >Payment Details</h3> <hr/>
                              <p>How much is each lease payment? : <strong>{formData?.lease_payment} </strong></p>
                              <p>The first date of payment : <strong>{formData?.date_of_payment} </strong></p>
                              <p>What is the frequency of the lease payments? : <strong>{formData?.what_is_frequency} </strong></p>
                              {formData?.what_is_frequency === 'Others' ? 
                              <p>Specification?  : <strong>{formData?.frequency_specification} </strong></p>
                              : null}
                              <p>What are the payment details/ bank details for the tenant to pay lease payments? : <strong>{formData?.payment_details} </strong></p>
                              <p>Who is the security deposit (bond) payable to? : <strong>{formData?.deposit_payable} </strong></p>
                              <p>What is the security deposit (bond) amount?  : <strong>{formData?.security_bond} </strong></p>
                              <p>What is the date the security deposit must be received by?  : <strong>{formData?.security_deposit} </strong></p>
                              
                           

                           
                              <Title >Service Type</Title> <hr/>
                              <p>Your product : <strong> {formData?.product_type } </strong></p>
                              <p>Service speed : <strong> {formData?.service_speed } </strong></p>
                              <p>Select your jurisdiction : <strong> {formData?.jurisdiction } </strong></p>
                              <p>Describe if you have any other specific need that you want us to incorporate in the contract  : <strong> {data?.describe_other_specific } </strong></p>
                              
                              <Title>Billing Information</Title> <hr/>
                              {billing_info()}
                           
                              
                         
            </DTable>
     );
}
 
export default ResidentailTenancyAgreementDetails;